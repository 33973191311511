import { enums, useClient, useLazyQuery, useQuery } from '@weenat/client'
import { NetworkOrg } from '@weenat/client/dist/resources/networkOrgs'
import { Org } from '@weenat/client/dist/resources/orgs'
import { useIntl } from '@weenat/wintl'
import { SelectedOrgs, useToken } from 'app/state'
import isNil from 'lodash-es/isNil'
import { useMemo, useState } from 'react'

const EMPTY_ORGS: Omit<Org, 'virtualDevicesCount' | 'virtualDevicesQuota'>[] = []

export interface UseSelectOrgArgs {
  selectedOrgs: SelectedOrgs | undefined
  withoutChild?: boolean
}

/**
 * Hook used to handle default and change behavior of the SelectOrg
 */
const useSelectOrg = ({ selectedOrgs }: UseSelectOrgArgs) => {
  const client = useClient()
  const { t } = useIntl()
  const [token] = useToken()

  const [enableNetworkOrgRequest, setEnableNetworkOrgRequest] = useState<boolean>(
    !isNil(selectedOrgs?.childOrganizationId)
  )

  // organization data

  const orgsRequest = useQuery(client.orgs.getAllPages(), { enabled: !isNil(token) })
  const orgsData = orgsRequest.data ?? EMPTY_ORGS

  const org =
    orgsData.find(({ id }) => id === selectedOrgs?.organizationId) ??
    orgsData.find(({ id }) => id === selectedOrgs?.childOrganizationId)

  const isOrgNetwork = !isNil(org?.networkType)
  const isAdherentFarm = !isNil(org?.subscribedNetworkId)

  const { data: stillActiveNetworkOrgsData, isLoading } = useLazyQuery({
    isEnabled: isOrgNetwork && enableNetworkOrgRequest && !isNil(org?.id),
    fetcher: client.networkOrgs.getMany,
    queryParams: {
      isActive: true,
      networkId: org?.id as number
    }
  })

  const networkId = isNil(org?.subscribedNetworkId) ? org?.id : org?.subscribedNetworkId

  const networkRequest = useQuery(client.networks.get(networkId), {
    enabled: isOrgNetwork || isAdherentFarm
  })
  const network = networkRequest.data

  /**
   * Sorting organization by premium status
   * Not showing org user only has read access to, on admin page (on which he's not an Advisor)
   * Not showing networks with no active child
   */
  const orgOptions = useMemo(() => {
    return [...orgsData]
      .sort((a) =>
        [enums.BillingStatus.plus, enums.BillingStatus.expert].includes(a.billingStatus) ? -1 : 1
      )
      .map(({ id, name }) => ({
        label: name,
        value: id
      }))
  }, [orgsData])

  /**
   * Sorting childOrgs
   * NOTE : A users role on a child org is defined by it's role inside the network,
   *        if its network role isn't Reader then he's considered an Advisor
   */
  const childOrgOptions = useMemo(() => {
    const childOrgsToDisplay: NetworkOrg[] = isNil(org) ? [] : stillActiveNetworkOrgsData

    return [
      {
        value: null,
        label: t('map.network_plots')
      },
      ...childOrgsToDisplay.map(({ org: { id, name } }) => ({
        value: id,
        label: name
      }))
    ]
  }, [org, stillActiveNetworkOrgsData, t])

  const selectOrgLabel = isOrgNetwork
    ? t('models.network.model.singular_name', { capitalize: true })
    : t('models.organization.model.singular_name', { capitalize: true })

  const selectChildOrgLabel = t('models.networkOrganization.model.singular_name', {
    capitalize: true
  })

  return {
    org,
    selectOrgLabel,
    selectChildOrgLabel,
    selectedOrgs,
    orgOptions,
    childOrgOptions,
    isOrgNetwork,
    orgsRequest,
    isLoading,
    network,
    setEnableNetworkOrgRequest
  }
}

export default useSelectOrg
