import type { SVGProps } from 'react'

const SvgForecasts = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' {...props}>
    <path
      d='M14.25 14.25C13.083 15.417 11.667 16 10 16c-1.667 0-3.083-.583-4.25-1.75C4.583 13.083 4 11.667 4 10c0-1.667.583-3.083 1.75-4.25C6.917 4.583 8.333 4 10 4c1.667 0 3.083.583 4.25 1.75C15.417 6.917 16 8.333 16 10c0 1.667-.583 3.083-1.75 4.25m-9.925 1.425C5.875 17.225 7.767 18 10 18c.933 0 1.817-.146 2.65-.437a7.924 7.924 0 0 0 2.275-1.238L19.9 21.3a.948.948 0 0 0 .7.275.948.948 0 0 0 .7-.275.948.948 0 0 0 .275-.7.949.949 0 0 0-.275-.7l-4.975-4.975A7.926 7.926 0 0 0 18 10c0-2.233-.775-4.125-2.325-5.675S12.233 2 10 2c-2.233 0-4.125.775-5.675 2.325S2 7.767 2 10c0 2.233.775 4.125 2.325 5.675M7.6 9.727c-.884 0-1.6.733-1.6 1.637C6 12.267 6.716 13 7.6 13h3.2c.03 0 .06 0 .088-.002l.026-.002.026.001c.042.002.084.003.127.003C12.687 13 14 11.657 14 10s-1.313-3-2.933-3c-1.387 0-2.843.818-2.843 2.727z'
      clipRule='evenodd'
    />
  </svg>
)

export default SvgForecasts
