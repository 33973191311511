import { useOrgContext } from 'app/orgProvider'
import { Redirect } from 'app/routx-router'

export const Component = () => {
  const { id } = useOrgContext()

  return <Redirect to={`/farms/${id}`} replace />
}

export default Component
