import { Universe } from '@weenat/client/dist/core/universes'
import Icon from '../Icon'
import { ExploreNearby, Sprinkler, Weather } from './generic-icons'
import SvgIrrelis from './generic-icons/Irrelis'
import GenericIcon, { IconProps } from './GenericIcon'
import { AcUnit } from './metric-icons'

const UniverseIcons: Record<Universe, typeof Icon> = {
  [Universe.overview]: (props: IconProps) => <GenericIcon {...props} svg={<ExploreNearby />} />,
  [Universe.weather]: (props: IconProps) => <GenericIcon {...props} svg={<Weather />} />,
  [Universe.frost]: (props: IconProps) => <GenericIcon {...props} svg={<AcUnit />} />,
  [Universe.irrigation]: (props: IconProps) => <GenericIcon {...props} svg={<Sprinkler />} />,
  [Universe.irrelis]: (props: IconProps) => <GenericIcon {...props} svg={<SvgIrrelis />} />
} as const

export default UniverseIcons
