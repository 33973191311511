import {
  BackgroundMapUniverseMetric,
  univAggToAggregation,
  univAggToTimeStep,
  UniverseAgg
} from '@weenat/client/dist/core/universes'
import { useCurrentUnivMetric, useUniverseAggregate } from 'app/state'

/**
 * @return the focused metric & aggregate & span
 */
const useUniverseBackgroundMapContext = () => {
  const [universeAggregate] = useUniverseAggregate()
  const currentUnivMetric = useCurrentUnivMetric()

  const univFocusedMetricIdPerUniverseAggregate = () => {
    if (universeAggregate === 'real-time-FXY') return 'FXY'
    if (universeAggregate === 'real-time-DD') return 'DD'
    if (universeAggregate === 'real-time-SSI_MAX') return 'SSI_MAX'
    if (universeAggregate === 'real-time-SSI_MIN') return 'SSI_MIN'
    if (universeAggregate === 'real-time-PPFD_MAX') return 'PPFD_MAX'
    if (universeAggregate === 'real-time-PPFD_MIN') return 'PPFD_MIN'
    if (universeAggregate === 'cumul-measured-12H') return 'LW_D'
    if (universeAggregate === 'cumul-measured-4H') return 'LW_D'
    return currentUnivMetric
  }

  const focusedMetricId: BackgroundMapUniverseMetric | undefined =
    univFocusedMetricIdPerUniverseAggregate()
  const aggregate = univAggToAggregation[universeAggregate as UniverseAgg]
  const span = univAggToTimeStep[universeAggregate as UniverseAgg]

  return {
    span,
    focusedMetricId,
    aggregate,
    universeAggregate
  }
}

export default useUniverseBackgroundMapContext
