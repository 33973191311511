import { useSlotProvider } from 'app/state'
import { isEmpty } from 'lodash-es'
import { Children, cloneElement, useEffect } from 'react'

/**
 * Will add children to header actions
 * Can be empty if no actions
 */
const HeaderActions: FC = ({ children }) => {
  const [, setSlotContent] = useSlotProvider()

  useEffect(() => {
    const arrayOfChildren = Children.toArray(children)

    setSlotContent((currentSlotContent) => ({
      ...currentSlotContent,
      headerActions: isEmpty(arrayOfChildren)
        ? []
        : arrayOfChildren.map((c, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={idx} $pl='md'>
              {cloneElement(c)}
            </Box>
          ))
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children])

  return null
}

export default HeaderActions
