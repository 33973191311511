import { ClusterProperties, PointProperties } from '@weenat/client/dist/core/measurements'
import { getCountByMetric } from '@weenat/client/dist/core/metrics'
import { Universe } from '@weenat/client/dist/core/universes'
import {
  Summary,
  SummaryData,
  isDeviceSummary,
  isPlotSummary,
  isStationSummary
} from '@weenat/client/dist/resources/measurements.type'

import useDisplayParams from '@weenat/client/dist/hooks/useDisplayParams'
import { useOrgContext } from 'app/orgProvider'
import { useClusterSettings, useToggleFeature, useUniverse } from 'app/state'
import { debounce } from 'lodash-es'
import isNil from 'lodash-es/isNil'
import { useCallback, useEffect, useMemo } from 'react'
import { useDeepCompareEffect } from 'react-use'
import Supercluster from 'supercluster'
import useSupercluster from 'use-supercluster'
import {
  useBackgroundMapContext,
  useBackgroundMapDispatcher
} from '../contexts/BackgroundMapContext'
import ClusterMarker from '../markers/ClusterMarker'
import PODMarker from '../markers/PODMarker'

const CLUSTER_RADIUS = 80
const DEFAULT_CLUSTER_MAX_ZOOM = 12
const DEFAULT_CLUSTER_MIN_POINTS = 3
export const TOOLTIP_CLOSE_DELAY_MS = 400

interface UsePODClusterArgs {
  isPlotsMap?: boolean
  isFetching: boolean
  summaries: Summary[]
}

/**
 * POD stands for plots or devices
 */
const usePODClusters = ({ summaries, isPlotsMap = false, isFetching }: UsePODClusterArgs) => {
  const [clusterSettings] = useClusterSettings()
  const [universe] = useUniverse()
  const [{ redesign }] = useToggleFeature()
  const org = useOrgContext()
  const {
    mapDetails,
    hoverIntent,
    api: { map }
  } = useBackgroundMapContext<Supercluster.PointFeature<ClusterProperties | PointProperties>>()

  const { hideEmptyDataPOD } = useDisplayParams()
  const dispatch = useBackgroundMapDispatcher()

  const markers: Supercluster.PointFeature<PointProperties & { resourceId: number }>[] = useMemo(
    () =>
      summaries.reduce(
        (acc, summary) => {
          // A plot or a device
          const resource = isPlotSummary(summary)
            ? summary.metadata.plot
            : isDeviceSummary(summary)
              ? summary.metadata.device
              : summary.metadata.station

          const { id: resourceId, location } = resource

          // Because there is no point of adding a point that has no location to the map
          if (!isNil(location)) {
            const { countByMetric, isVirtualDeviceByMetric } = getCountByMetric(summary)

            acc.push({
              type: 'Feature',
              id: resourceId,
              properties: {
                resourceId,
                countByMetric,
                isVirtualDeviceByMetric,
                summary: summary,
                isFavorite: !isNil(resource) && 'isFavorite' in resource && resource.isFavorite,
                kind: isPlotSummary(summary)
                  ? 'plot'
                  : isDeviceSummary(summary)
                    ? 'device'
                    : 'station'
              },
              geometry: {
                type: 'Point',
                coordinates: location.coordinates
              }
            })
          }

          return acc
        },
        [] as Supercluster.PointFeature<PointProperties & { resourceId: number }>[]
      ),
    [summaries]
  )

  const superClusterOptions = useMemo(() => {
    const options: Supercluster.Options<
      PointProperties & { resourceId: number },
      {
        ids: Set<number>
        countByMetric: PointProperties['countByMetric']
        summaries: SummaryData[]
        isVirtualDeviceByMetric: PointProperties['isVirtualDeviceByMetric']
      }
    > = {
      reduce: (cluster, point) => {
        const keySet = new Set([
          ...Array.from(cluster.countByMetric.keys()),
          ...Array.from(point.countByMetric.keys())
        ])
        const newCountByMetric = new Map()
        const newIsVirtualDeviceByMetric = new Map()
        for (const key of keySet) {
          const metricCount =
            (cluster.countByMetric.get(key) ?? 0) + (point.countByMetric.get(key) ?? 0)

          newCountByMetric.set(key, metricCount)
          const isVirtualMetric =
            (cluster.isVirtualDeviceByMetric.get(key) ?? false) &&
            (point.isVirtualDeviceByMetric.get(key) ?? false)
          newIsVirtualDeviceByMetric.set(key, isVirtualMetric)
        }
        const newSummaries: SummaryData[] = []
        newSummaries.push(...cluster.summaries, ...point.summaries)
        cluster.summaries = newSummaries
        cluster.countByMetric = newCountByMetric
        cluster.isVirtualDeviceByMetric = newIsVirtualDeviceByMetric
        cluster.ids = new Set([...Array.from(cluster.ids), ...Array.from(point.ids)])
      },
      map: (c) => ({
        countByMetric: new Map(c.countByMetric),
        summaries: [c.summary],
        isVirtualDeviceByMetric: new Map(c.isVirtualDeviceByMetric),
        ids: new Set<number>([c.resourceId])
      }),
      radius: CLUSTER_RADIUS,
      generateId: false,
      maxZoom: !isNil(clusterSettings) ? clusterSettings.maxZoom : DEFAULT_CLUSTER_MAX_ZOOM,
      minPoints: !isNil(clusterSettings)
        ? clusterSettings.minPointsPerCluster
        : DEFAULT_CLUSTER_MIN_POINTS
    }

    return options
  }, [clusterSettings])

  const { clusters, supercluster } = useSupercluster({
    points: markers,
    zoom: !isNil(map) && !isNil(mapDetails.zoom) ? mapDetails.zoom : undefined,
    bounds: !isNil(map) && !isNil(mapDetails.bbox) ? mapDetails.bbox : undefined,
    options: superClusterOptions,
    disableRefresh: isFetching
  })

  const resetHoverIntent = useCallback(
    () =>
      dispatch({
        type: 'resetHoverIntent'
      }),
    [dispatch]
  )

  const debouncedReset = useMemo(
    () => debounce(resetHoverIntent, TOOLTIP_CLOSE_DELAY_MS),
    [debounce, resetHoverIntent]
  )

  const summaryToResource = (_summary: Summary | undefined) => {
    if (isNil(_summary)) return undefined
    if (isDeviceSummary(_summary)) return _summary.metadata.device
    if (isStationSummary(_summary)) return _summary.metadata.station
    if (isPlotSummary(_summary)) return _summary.metadata.plot
    return undefined
  }

  const renderCluster = useCallback(
    (cluster: Supercluster.PointFeature<ClusterProperties | PointProperties>) => {
      const [longitude, latitude] = cluster.geometry.coordinates
      /**
       * Properties changes depending on whether the cluster actually is one or whether it's just a regular marker
       * for example, common cluster properties only exists on actual clusters (not markers)
       */
      const { isFavorite, isVirtualDeviceByMetric, countByMetric } = cluster.properties

      if ('cluster' in cluster.properties && cluster.properties.cluster && !isNil(supercluster)) {
        const { point_count: count, summaries: clusterSummaries, ids } = cluster.properties

        const setZoomAndPan = () => {
          if (!isNil(map)) {
            const expansionZoom = Math.min(
              supercluster.getClusterExpansionZoom(cluster.id as number),
              20
            )
            map.setZoom(expansionZoom)
            map.panTo({ lat: latitude, lng: longitude })
          }
        }

        return count === 0 ? null : (
          <ClusterMarker
            key={cluster.id}
            lat={latitude}
            lng={longitude}
            onPress={setZoomAndPan}
            countByMetric={countByMetric}
            count={count}
            summaries={clusterSummaries}
            isVirtualDeviceByMetric={isVirtualDeviceByMetric}
            isPlotsCluster={isPlotsMap ?? false}
            ids={ids}
            hideEmptyDataPOD={hideEmptyDataPOD}
          />
        )
      } else if ('summary' in cluster.properties) {
        const { summary, kind } = cluster.properties

        const selectedSummary = summaries.find(
          (aSummary) => summaryToResource(aSummary)?.id === hoverIntent.itemId
        )

        const selectedDevice = (() => {
          if (!redesign && isPlotsMap) return undefined
          return summaryToResource(selectedSummary)
        })()

        const markerId = summaryToResource(summary)?.id

        const summaryLink =
          kind === 'plot'
            ? `/farms/${org.id}/plots/${cluster.id}/${
                universe === Universe.irrelis ? 'irrelis' : ''
              }`
            : kind === 'device'
              ? `/weather-map/${cluster.id}`
              : `/farms/${org.id}/stations/${cluster.id}`

        return (
          <PODMarker
            lat={latitude}
            lng={longitude}
            isFavorite={isFavorite}
            key={cluster.id}
            countByMetric={countByMetric}
            summary={summary}
            shape={kind === 'plot' ? 'circle' : 'square'}
            isVirtualDeviceByMetric={isVirtualDeviceByMetric}
            summaryLink={summaryLink}
            resourceId={markerId}
            device={selectedDevice}
            debouncedReset={debouncedReset}
            kind={kind}
            isPlotsMap={isPlotsMap}
          />
        )
      }
    },
    [
      supercluster,
      isPlotsMap,
      hideEmptyDataPOD,
      map,
      summaries,
      org.id,
      universe,
      hoverIntent.itemId,
      redesign
    ]
  )

  useDeepCompareEffect(() => {
    dispatch({ type: 'setMarkers', newMarkers: clusters })
  }, [clusters])

  useEffect(() => {
    dispatch({ type: 'setRenderMarkers', newRenderMarkers: renderCluster })
  }, [renderCluster, dispatch])
}

export default usePODClusters
