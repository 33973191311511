import styled from 'styled-components'

const FakeScreenShotHolder = styled(Box)`
  height: 196px;
  width: 320px;
  background-color: ${(p) => p.theme.colors.grayscale[50]};
  border-radius: ${(p) => p.theme.radiuses.md}px;
  overflow: hidden;
`

export default FakeScreenShotHolder
