import { isNil } from 'lodash-es'
import DelimitedFlex from '../DelimitedFlex'
import { FlexProps } from '../primitives/themeMappings/props'
import PanelSectionTitle from './PanelSectionTitle'

interface PanelSectionProps extends FlexProps {
  title?: string
}

const PanelSection: FC<PanelSectionProps> = ({ children, title, ref: _notUsed, ...props }) => {
  return (
    <DelimitedFlex $flexDirection='column' $p={'lg'} $gap='lg' {...props}>
      {!isNil(title) ? <PanelSectionTitle>{title}</PanelSectionTitle> : null}
      {children}
    </DelimitedFlex>
  )
}

export default PanelSection
