import { useIntl } from '@weenat/wintl'
import { Slot, useNavigate, useParams, usePathname, useSearchParams } from 'app/routx-router'
import useGoBack from 'app/src/hooks/useGoBack'
import GoBackButton from 'app/src/kit/GoBackButton'
import Text from 'app/src/kit/Text'
import AuthenticatedLayout from 'app/src/layouts/AuthenticatedLayout'
import MenuLayout from 'app/src/layouts/MenuLayout'
import ProtectedRoute from 'app/src/layouts/ProtectedRoute'
import { useToggleFeature } from 'app/state'
import isNil from 'lodash-es/isNil'
import { useTheme } from 'styled-components'

interface DiscoverMenuLayoutProps {
  isHome?: boolean
  title?: string
}

const DiscoverMenuLayout: FC<DiscoverMenuLayoutProps> = () => {
  const { colors } = useTheme()
  const [searchParams] = useSearchParams()
  const { articleSlug, sectionSlug } = useParams()
  const { goBack } = useGoBack()
  const nav = useNavigate()
  const [{ redesign }] = useToggleFeature()
  const pathname = usePathname()
  const { t } = useIntl()

  const handlePress = () => {
    if ('origin' in searchParams && !isNil(searchParams.origin)) {
      nav(searchParams.origin)
    } else {
      goBack()
    }
  }

  const contentColor = colors.grayscale.black

  const isHome = pathname === '/discover'

  const title =
    !isNil(sectionSlug) && !isNil(articleSlug)
      ? t(`discover_menu.sections.${sectionSlug}.articles.${articleSlug}.title`)
      : !isNil(sectionSlug)
        ? t(`discover_menu.sections.${sectionSlug}.title`)
        : undefined

  return (
    <ProtectedRoute>
      <AuthenticatedLayout>
        {redesign ? (
          <MenuLayout>
            <Flex $p={'lg'} $flexDirection='column' $flex={1} $maxWidth={980} $mx='auto'>
              <Slot />
            </Flex>
          </MenuLayout>
        ) : (
          <Flex
            $flexDirection='column'
            $zIndex={0}
            $width='100%'
            $height='100%'
            $backgroundColor='grayscale.50'
          >
            <Flex $flexDirection='column' $flex={1} $maxWidth={980} $my='lg' $mx='auto'>
              {/** Header */}

              {isHome ? null : (
                <Flex $alignItems='center' $py='lg'>
                  <Flex
                    $alignItems='center'
                    $justifyContent={isHome ? 'center' : 'start'}
                    $flex={1}
                  >
                    <GoBackButton mr='md' onPress={handlePress} />
                    {!isNil(title) ? (
                      <Text $fontSize='lg' $fontWeight='bold' $color={contentColor}>
                        {title}
                      </Text>
                    ) : null}
                  </Flex>
                </Flex>
              )}

              <Slot />
            </Flex>
          </Flex>
        )}
      </AuthenticatedLayout>
    </ProtectedRoute>
  )
}

export const Component = DiscoverMenuLayout
export default DiscoverMenuLayout
