import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import { useNavigate } from 'app/routx-router'
import AlertsList from 'app/src/alerts/AlertsList'
import CreateAlertButton from 'app/src/alerts/CreateAlertButton'
import FeatGuarded from 'app/src/kit/FeatGuarded'
import Text from 'app/src/kit/Text'
import HeaderActions from 'app/src/layouts/HeaderActions'
import { useToggleFeature } from 'app/state'

function Alerts() {
  const nav = useNavigate()
  const { t } = useIntl()
  const org = useOrgContext()
  const [{ redesign }] = useToggleFeature()

  return (
    <>
      {redesign ? (
        <HeaderActions>
          <CreateAlertButton variant='small' onPress={() => nav('/alerts/create')} />
        </HeaderActions>
      ) : null}
      <FeatGuarded org={org} feat={'FEAT-ALERT-GLOBAL'}>
        {redesign ? null : (
          <Flex $alignItems='center' $justifyContent='space-between'>
            <Text $fontSize='lg' $fontWeight='bold'>
              {t('alerts.my_alerts', { capitalize: true })}
            </Text>
            <CreateAlertButton onPress={() => nav('/alerts/create')} />
          </Flex>
        )}
        <AlertsList searchable />
      </FeatGuarded>
    </>
  )
}

export default Alerts

export const ErrorBoundary = () => null
