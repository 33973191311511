import { memo } from 'react'
import ContentLoader from 'react-content-loader'

const svgHeight = 200
const svgWidth = 800

const SizedRect = ({ size, position }: { size: number; position: number }) => (
  <rect x={15 + position * 65} y={svgHeight - size} rx={0} ry={0} width={55} height={size} />
)

const LoadingChart = memo(
  ({ height, uniqueKey }: { height: number | string; uniqueKey: string }) => (
    <Flex $justifyContent='center' $alignItems='center' $width='100%' $height={height}>
      <ContentLoader viewBox={`0 0 ${svgWidth} ${svgHeight}`} height={height} uniqueKey={uniqueKey}>
        <rect x='0' y='7' rx='0' ry='0' width='2' height={svgHeight - 7} />
        <rect x='0' y={svgHeight - 2} rx='0' ry='0' width={svgWidth} height='2' />
        {[20, 80, 120, 160, 180, 130, 80, 100, 110, 190, 120, 80].map((value, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <SizedRect key={`loadingRect${idx}`} size={value} position={idx} />
        ))}
      </ContentLoader>
    </Flex>
  )
)
LoadingChart.displayName = 'LoadingChart'

export default LoadingChart
