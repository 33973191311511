import { useItemContext } from '@weenat/client'
import { toDataPoint } from '@weenat/client/dist/core/charts'
import { DataPoint } from '@weenat/client/dist/core/charts/d3-charts.type'
import { getColorScalePerMetric } from '@weenat/client/dist/core/measurements'
import { fromWindAngleToDirection, useMetricSummaryChart } from '@weenat/client/dist/core/metrics'
import { UseMetricSummaryChartInterface } from '@weenat/client/dist/core/metrics/useMetricSummaryChart'
import TimeSteps from '@weenat/client/dist/enums/TimeSteps'
import { useConvertedValue } from '@weenat/client/dist/hooks'
import { DeviceAvailableMeasure } from '@weenat/client/dist/resources/devices'
import { PlotAvailableMeasure } from '@weenat/client/dist/resources/plots'
import { useIntl } from '@weenat/wintl'
import D3Axis from 'app/src/kit/d3-charts/Axis'
import D3Bar from 'app/src/kit/d3-charts/Bar'
import CursorChart from 'app/src/kit/d3-charts/CursorChart'
import D3Line from 'app/src/kit/d3-charts/Line'
import D3TimeAxis from 'app/src/kit/d3-charts/TimeAxis'
import { useToggleFeature } from 'app/state'
import * as d3 from 'd3'
import { isNil } from 'lodash-es'
import isEmpty from 'lodash-es/isEmpty'
import moment from 'moment-timezone'
import { useCallback, useMemo } from 'react'
import { styled, useTheme } from 'styled-components'
import { VictoryAxis, VictoryChartProps, VictoryGroup, VictoryScatter } from 'victory'
import Text from '../../Text'
import { CURSOR_HEIGHT } from '../../d3-charts/Cursor'
import D3ChartWrapper from '../../d3-charts/D3ChartWrapper'
import FutureArea from '../../d3-charts/FutureArea'
import SolarRadiationThresholdLine from '../../d3-charts/SolarRadiationThresholdLine'
import WindDirectionGutter, {
  WIND_DIRECTION_GUTTER_HEIGHT
} from '../../d3-charts/WindDirectionGutter'
import WindDirectionIcon from '../../icons/WindDirectionIcon'
import Chart from '../Chart'
import ChartWrapper from '../ChartWrapper'
import PastArea from '../PastArea'
import TimeAxis from '../TimeAxis'
import victoryTheme from '../victoryTheme'
import ChartWindDirectionIcon from './ChartWindDirectionIcon'
import MetricLine from './MetricLine'
import RainfallBars from './RainfallBars'

const CHART_HEIGHT = 148

const tickValues = [0, 0.5, 1]
const chartPadding = { top: 16, left: 24, right: 16, bottom: 40 }
const domainPadding = { bottom: 0, left: 4, right: 4, top: 16 }

const RelativelyPositionedContainer = styled(Box)`
  position: relative;
  height: ${CHART_HEIGHT}px;
`

const AbsolutelyPositionedContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: -16px;
  height: ${CHART_HEIGHT}px;
  width: 100%;
`

interface ChartProps extends VictoryChartProps {
  metric: DeviceAvailableMeasure | PlotAvailableMeasure
  prefetch?: UseMetricSummaryChartInterface['prefetch']
}

/**
 * Chart used inside summaries
 */
const MetricSummaryChart: FC<ChartProps> = ({ metric, prefetch, ...victoryChartProps }) => {
  const { colors } = useTheme()
  const { timezone } = useItemContext()
  const [{ chartWithD3 }] = useToggleFeature()
  const { convertValue, formatConvertedValue } = useConvertedValue()
  const { t } = useIntl()

  const {
    data,
    period,
    isLoading,
    absoluteYDomain,
    windDirectionData,
    xDomain,
    denormalizeFct,
    getGradient,
    yDomain
  } = useMetricSummaryChart({
    metric,
    prefetch
  })

  const chartStyle = useMemo(
    () => ({
      axis: {
        fill: 'transparent',
        strokeWidth: 0
      },
      ticks: { size: 0 },
      tickLabels: {
        textAlign: 'right',
        fontWeight: 'bold',
        fill: colors.metrics[metric]?.['500'] || colors.grayscale.black
      }
    }),
    [colors.grayscale.black, colors.metrics, metric]
  )
  const colorScale = getColorScalePerMetric(metric)

  const d3Data = useMemo(() => data.map(toDataPoint), [data])
  const d3WindDirection = useMemo(() => windDirectionData.map(toDataPoint), [windDirectionData])

  const { getUnit } = useConvertedValue()

  const ChartContent = useMemo(() => {
    if (chartWithD3) {
      switch (metric) {
        // T_DRY & T_WET are rendered in their own Chart
        case 'T':
        case 'U':
        case 'T_SOIL':
        case 'SSI':
        case 'PPFD':
        case 'LW_V':
          return (
            <>
              {metric === 'SSI' ? (
                <SolarRadiationThresholdLine
                  denormalizeFct={denormalizeFct}
                  normalizeFct={d3.scaleLinear().range([0, 1]).domain(yDomain)}
                />
              ) : null}
              <D3Line
                data={d3Data}
                metric={metric}
                denormalize={denormalizeFct}
                getGradient={getGradient}
                timeStep={TimeSteps.perHours}
                dataUnit={getUnit(metric)}
              />
            </>
          )
        case 'RR':
          return <D3Bar data={d3Data} $color={colorScale([0, 1])} />
        case 'FF':
          return (
            <>
              <WindDirectionGutter data={d3WindDirection} />
              <D3Line
                data={d3Data}
                metric={metric}
                denormalize={denormalizeFct}
                timeStep={TimeSteps.perHours}
              />
            </>
          )

        default:
          return null
      }
    } else {
      switch (metric) {
        // T_DRY & T_WET are rendered in their own Chart
        case 'T':
        case 'U':
        case 'T_SOIL':
        case 'SSI':
        case 'PPFD':
        case 'LW_V':
          return (
            <MetricLine
              metric={metric}
              data={data}
              absoluteYDomain={absoluteYDomain}
              getGradient={getGradient}
            />
          )
        case 'RR':
          return (
            <RainfallBars
              key={metric}
              data={data}
              cornerRadius={{ top: 2 }}
              absoluteYDomain={absoluteYDomain}
            />
          )
        case 'FF':
          return (
            <VictoryGroup>
              <MetricLine
                data={data}
                metric={metric}
                absoluteYDomain={absoluteYDomain}
                getGradient={getGradient}
                scatterComponent={<ChartWindDirectionIcon windDirectionData={windDirectionData} />}
              />
              <VictoryScatter data={windDirectionData} size={0} />
            </VictoryGroup>
          )

        default:
          return null
      }
    }
  }, [
    chartWithD3,
    metric,
    denormalizeFct,
    yDomain,
    d3Data,
    getGradient,
    getUnit,
    colorScale,
    d3WindDirection,
    data,
    absoluteYDomain,
    windDirectionData
  ])

  const renderTooltip = useCallback(
    ({ date, value }: DataPoint) => {
      let windDirectionValue: number | null | undefined = undefined
      if (metric === 'FF') {
        windDirectionValue = windDirectionData.find((d) => d.x.getTime() === date.getTime())?.value
      }
      return (
        <Box
          $backgroundColor='grayscale.white'
          $borderColor='grayscale.200'
          $borderRadius='sm'
          $borderWidth='rg'
          $p='md'
          $pointerEvents='none'
        >
          <Text>{moment(date).format('DD/MM HH:mm')}</Text>
          <Text $color={`metrics.${metric}.500`}>
            {metric === 'LW_V'
              ? formatConvertedValue({ metric, value: denormalizeFct(value) })
              : convertValue({ metric, value: denormalizeFct(value) })}
          </Text>
          {metric === 'FF' && !isNil(windDirectionValue) ? (
            <Flex $alignItems='center'>
              <WindDirectionIcon
                windOrigin={windDirectionValue}
                $color={'metrics.FF.500'}
                $size='rg'
              />
              <Text $fontSize='sm'>
                {t(`windDirections.${fromWindAngleToDirection(windDirectionValue)}`)}
              </Text>
            </Flex>
          ) : null}
        </Box>
      )
    },
    [convertValue, denormalizeFct, formatConvertedValue, metric, t, windDirectionData]
  )

  const domain = { x: xDomain, y: [0, 1] }

  return (
    <RelativelyPositionedContainer>
      <AbsolutelyPositionedContainer>
        {!isNil(data) && !chartWithD3 ? (
          <ChartWrapper height={CHART_HEIGHT} isEmpty={isEmpty(data)} isLoading={isLoading}>
            <Chart
              {...victoryChartProps}
              height={CHART_HEIGHT}
              domain={domain}
              padding={chartPadding}
              timeStep={TimeSteps.perHours}
              tooltipVariant='summary'
              theme={victoryTheme}
              timezone={timezone}
            >
              <VictoryAxis
                key={`${metric}-axis`}
                dependentAxis
                standalone
                style={chartStyle}
                tickValues={tickValues}
                tickFormat={(tick) => Math.ceil(denormalizeFct(tick) as number)}
              />

              {ChartContent}

              <PastArea data={!isEmpty(data) ? data : []} timezone={timezone} />

              <TimeAxis
                timeStep={TimeSteps.perHours}
                period={period}
                timezone={timezone}
                orientation={'bottom'}
                style={{ axis: { strokeWidth: 0 } }}
              />
            </Chart>
          </ChartWrapper>
        ) : !isNil(data) && chartWithD3 ? (
          <D3ChartWrapper height={CHART_HEIGHT} isEmpty={isEmpty(data)} isLoading={isLoading}>
            <CursorChart
              height={CHART_HEIGHT}
              width='100%'
              domain={domain}
              domainPadding={domainPadding}
              data={d3Data}
              renderTooltip={renderTooltip}
              ticksCount={3}
              timeStep={TimeSteps.perHours}
              marginTop={metric === 'FF' ? CURSOR_HEIGHT + WIND_DIRECTION_GUTTER_HEIGHT : undefined}
            >
              <FutureArea />
              {ChartContent}
              <D3Axis denormalize={denormalizeFct} />
              <D3TimeAxis timezone={timezone} tickCount={24} tickFormat='H[h]' period={xDomain} />
            </CursorChart>
          </D3ChartWrapper>
        ) : null}
      </AbsolutelyPositionedContainer>
    </RelativelyPositionedContainer>
  )
}

export default MetricSummaryChart
