import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import noop from 'lodash-es/noop'
import { transparentize } from 'polished'
import { memo, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useKeyPress } from 'react-use'
import { styled, useTheme } from 'styled-components'
import CloseButton from './CloseButton'
import DelimitedFlex from './DelimitedFlex'
import ModalOverlay from './ModalOverlay'
import { BoxProps, FlexProps } from './primitives/themeMappings/props'

export interface ModalProps {
  kind?: 'danger'
  $isBackgroundWeenatBlue?: boolean
  $noInnerPadding?: boolean
  title?: React.ReactElement | string
  close?: () => void
  isOpen: boolean
  hasCloseIcon?: boolean
  disableCloseOnBackgroundClick?: boolean
  width?: number | string
  maxWidth?: number | string
  maxHeight?: number | string
  minHeight?: number | string
  height?: number | string
  headerProps?: FlexProps
  footer?: React.ReactElement | null
  contentContainerProps?: BoxProps
  className?: string
  closeIconClassName?: string
  $backgroundColor?: BoxProps['$backgroundColor']
  style?: BoxProps['style']
  zIndex?: number
}

interface ModalBoxProps
  extends BoxProps,
    Pick<ModalProps, '$backgroundColor' | '$isBackgroundWeenatBlue'> {
  $kind: ModalProps['kind']
}

const StyledBox = styled(Box)<ModalBoxProps>`
  margin: auto;
  border-radius: ${(props) => props.theme.radiuses.lg}px;
  border: ${(p) =>
    p.$isBackgroundWeenatBlue
      ? undefined
      : p.$kind === 'danger'
        ? `1px solid ${p.theme.colors.feedback.error[500]}`
        : `1px solid ${p.theme.colors.grayscale[300]}`};
  box-shadow: ${(p) => p.theme.shadows.md.boxShadow};
  ${(props) =>
    !isNil(props.$height) &&
    `
      height: ${props.$height}${Number.isInteger(props.$height) ? 'px' : ''};
    `}
  ${(props) =>
    !isNil(props.$width) &&
    `      width: ${props.$width}${Number.isInteger(props.$width) ? 'px' : ''};
    `}
  background: ${(props) =>
    props.$isBackgroundWeenatBlue
      ? 'linear-gradient(to right, #8ac7c1, #5aacb0, #2194a6)'
      : !isNil(props.$backgroundColor)
        ? props.$backgroundColor
        : props.theme.colors.grayscale[50]};
  min-width: 320px;

  min-height: ${(p) =>
    !isNil(p.$minHeight)
      ? Number.isInteger(p.$minHeight)
        ? `${p.$minHeight}px`
        : `${p.$minHeight}`
      : 'auto'};

  /** We dont want modal to go off screen, we want its content to scroll */
  max-height: ${(p) =>
    !isNil(p.$maxHeight)
      ? Number.isInteger(p.$maxHeight)
        ? `${p.$maxHeight}px`
        : `${p.$maxHeight}`
      : '100%'};

  overflow-y: auto;

  /** Hiding scrollbar but keeping scroll */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /** Chromium based */
  &::-webkit-scrollbar {
    display: none;
  }
`

const Header = styled(DelimitedFlex)`
  position: sticky;
  top: 0;
  left: 0;
  /* Because https://gitlab.com/weenat-front/web/-/blob/65fb48e9ec5ad20e786b6b731f67c99ddfa67853/app/src/common/components/SectionList.tsx, see https://gitlab.com/weenat-front/web/-/blob/fc0b3a7a587ac1042f0cf51f77f5ee00b2f3981e/app/src/dashboard/components/userReports/IrrigationReportsModal.tsx#L43 */
  z-index: 2;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const ChildrenContainer = styled(Box)<{
  $isBackgroundWeenatBlue: boolean
  $noInnerPadding: boolean
}>`
  flex: 1;
  padding: ${(props) =>
    props.$isBackgroundWeenatBlue || props.$noInnerPadding
      ? props.theme.spacings.none + 'px'
      : props.theme.spacings.lg + 'px'};
  height: 100%;
  overflow-y: auto;

  /** Hiding scrollbar but keeping scroll */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /** Chromium based */
  &::-webkit-scrollbar {
    display: none;
  }
`

const Modal: FC<ModalProps> = ({
  kind,
  $backgroundColor,
  children,
  hasCloseIcon = true,
  close = noop,
  $isBackgroundWeenatBlue: $isBackgroundWeenatBlue = false,
  $noInnerPadding = false,
  isOpen = false,
  title = null,
  disableCloseOnBackgroundClick = false,
  width,
  height,
  maxWidth,
  headerProps,
  contentContainerProps,
  className,
  closeIconClassName,
  style,
  footer,
  zIndex,
  maxHeight,
  minHeight
}) => {
  const { colors } = useTheme()
  const [isEscapeKeyPressed] = useKeyPress('Escape')

  useEffect(() => {
    if (isEscapeKeyPressed) {
      close()
    }
  }, [isEscapeKeyPressed])

  const contentHasNoRightPadding =
    !isNil(contentContainerProps) &&
    (contentContainerProps.$p === 0 ||
      contentContainerProps.$px === 0 ||
      contentContainerProps.$pr === 0)

  return isOpen
    ? createPortal(
        <ModalOverlay
          $zIndex={zIndex}
          onClick={() => {
            disableCloseOnBackgroundClick ? noop() : close()
          }}
        >
          <StyledBox
            $kind={kind}
            $p={0}
            $width={width}
            $height={height}
            $maxWidth={maxWidth}
            $maxHeight={maxHeight}
            $minHeight={minHeight}
            onClick={(e) => e.stopPropagation()}
            $isBackgroundWeenatBlue={$isBackgroundWeenatBlue}
            $backgroundColor={$backgroundColor}
            className={!isNil(className) ? `${className} weenat-modal` : `weenat-modal`}
            style={style}
          >
            <Flex $flexDirection='column' $flex={1} $height='100%'>
              <Header
                $p='lg'
                $backgroundColor={
                  kind === 'danger'
                    ? colors.feedback.error[500]
                    : $isBackgroundWeenatBlue
                      ? 'transparent'
                      : !isNil($backgroundColor)
                        ? $backgroundColor
                        : colors.grayscale[50]
                }
                $borderWidth='none'
                {...headerProps}
              >
                <Box $flex={1}>
                  {!isNil(title) ? (
                    <>
                      {typeof title === 'string' ? (
                        <Text
                          $fontWeight='bold'
                          $fontSize='md'
                          $color={
                            $isBackgroundWeenatBlue || kind === 'danger'
                              ? colors.grayscale.white
                              : undefined
                          }
                        >
                          {title}
                        </Text>
                      ) : (
                        title
                      )}
                    </>
                  ) : null}
                </Box>
                {hasCloseIcon ? (
                  <CloseButton
                    onPress={close}
                    $backgroundColor={
                      kind === 'danger'
                        ? colors.grayscale.white
                        : transparentize(
                            $isBackgroundWeenatBlue ? 0.85 : 0.75,
                            colors.grayscale[100]
                          )
                    }
                    $color={
                      $isBackgroundWeenatBlue || !isNil($backgroundColor)
                        ? colors.grayscale.white
                        : undefined
                    }
                    $mr={contentHasNoRightPadding ? 'lg' : 0}
                    className={closeIconClassName}
                  />
                ) : null}
              </Header>
              {/* Spread props rest so that we can use width, p and everything else box related */}
              <ChildrenContainer
                $isBackgroundWeenatBlue={$isBackgroundWeenatBlue}
                $noInnerPadding={$noInnerPadding}
                {...contentContainerProps}
              >
                {children}
              </ChildrenContainer>
              {footer}
            </Flex>
          </StyledBox>
        </ModalOverlay>,
        document.body
      )
    : null
}

export default memo(Modal)
