import { useClient, useQuery } from '@weenat/client'
import { MY_FARM_ALLOWED_METRICS } from '@weenat/client/dist/core/devices'
import { TourName } from '@weenat/client/dist/core/tours'
import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import FakeUniversesScreenShot from 'app/src/alerts/svg/FakeUniversesScreenShot'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import FakeMyFarmScreenShot from '../kit/FakeMyFarmScreenshot'
import Pop from '../kit/Pop'
import SmartText from '../kit/SmartText'
import FakeScreenShotHolder from './FakeScreenShotHolder'

/**
 * Moved some those Pop to their own files so they can be reused over multiple files and not pollute others
 */
export const FindOutMorePop: FC<{
  link: string
  tourName: Exclude<TourName, 'discover_alerts'>
}> = ({ children, link, tourName }) => {
  const { t, locales } = useIntl()
  return (
    <Pop
      headerContent={
        <FakeScreenShotHolder>
          <FakeUniversesScreenShot />
        </FakeScreenShotHolder>
      }
      tourName={tourName}
      stepName='find_out_more'
      postTranslateItems={(items, stepKey) => {
        return !isEmpty(items)
          ? items.map((x) =>
              !isEmpty(x) ? (
                x.includes('{new_feat_link_url}') ? (
                  <SmartText
                    key={x}
                    stopPropagation
                    tkey={`${stepKey.replace('steps', 'stepItems')}.0`}
                    vars={{
                      new_feat_link_url: link,
                      new_feat_link_text:
                        locales.current !== 'fr'
                          ? t('tours.contact_referent')
                          : t(`tours.discover_universes.find_out_more_cta`)
                    }}
                  />
                ) : (
                  t(x)
                )
              ) : null
            )
          : null
      }}
    >
      {children}
    </Pop>
  )
}

export const MyFarmFirstStepPop: FC = ({ children }) => {
  const client = useClient()
  const org = useOrgContext()
  const currentOrgId = org.id

  const { data } = useQuery(
    client.devices.getMany({
      limit: 1,
      viewableByOrganizationId: currentOrgId,
      metrics: MY_FARM_ALLOWED_METRICS
    }),
    { enabled: !isNil(currentOrgId) }
  )

  const hasDevices = (data?.count ?? 0) > 1

  return hasDevices ? (
    <Pop
      headerContent={
        <FakeScreenShotHolder>
          <FakeMyFarmScreenShot />
        </FakeScreenShotHolder>
      }
      tourName='my_farm'
      stepName='discover'
      placement='bottom'
      firstDeviceId={data?.results.at(0)?.id}
    >
      <Pop
        tourName='my_farm'
        stepName='better_farm_monitoring'
        placement='bottom'
        firstDeviceId={data?.results.at(0)?.id}
      >
        {children}
      </Pop>
    </Pop>
  ) : (
    children
  )
}

export const MyFarmOutroStepPop: FC = ({ children }) => {
  return (
    <Pop tourName='my_farm' stepName='outro' placement='bottom'>
      {children}
    </Pop>
  )
}
