import { useClient, useQuery } from '@weenat/client'
import { Device } from '@weenat/client/dist/resources/devices'
import { PlotOrStationSummary } from '@weenat/client/dist/resources/measurements'
import { isPlotSummary } from '@weenat/client/dist/resources/measurements.type'
import { Plot } from '@weenat/client/dist/resources/plots'
import { Station } from '@weenat/client/dist/resources/stations'
import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import { useMatch, useNavigate, usePathname } from 'app/routx-router'
import { isEmpty, isNil } from 'lodash-es'
import { useMemo, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import styled from 'styled-components'
import useDisclosure from '../hooks/useDisclosure'
import DelimitedFlex from '../kit/DelimitedFlex'
import Icons from '../kit/Icons'
import MenuItem from '../kit/MenuItem'
import Text from '../kit/Text'
import Breadcrumbs from '../kit/breadcrumbs/Breadcrumbs'
import useBreadcrumbs from '../kit/breadcrumbs/useBreadcrumbs'
import InvisibleTextInput from '../kit/fields/InvisibleInput'
import { Dropdown, DropdownContent, DropdownTrigger } from '../kit/fields/Select/SelectDropdown'
import HeaderActionsArea from './HeaderActionsArea'
import ResourceNavigator from './ResourceNavigator'

const EMPTY_ELEMENTS: PlotOrStationSummary[] = []

const Interactive = styled(Flex)`
  position: relative;

  cursor: pointer;
  width: fit-content;

  &::after {
    content: '';
    position: absolute;
    background-color: transparent;
    inset: -8px;
    border-radius: ${(p) => p.theme.radiuses.md}px;
    opacity: 0.1;
  }

  &:hover::after,
  &.active::after {
    background-color: ${(p) => p.theme.colors.primary[500]};
  }
`

interface TitleTextProps {
  title: string
}

const TitleText: FC<TitleTextProps> = ({ title }) => {
  return (
    <Text $fontSize='md' $lineHeight='lg' $fontWeight='semiBold' $ellipsis>
      {title}
    </Text>
  )
}

const resourcePathRegex = /(\/farms\/\d*\/(plots|stations)\/\d*\/)/gm

interface MenuLayoutProps {
  resource?: Station | Device | Plot
}

const MenuLayout: FC<MenuLayoutProps> = ({ children, resource }) => {
  const breadcrumbs = useBreadcrumbs()
  const { t } = useIntl()
  const client = useClient()
  const org = useOrgContext()
  const nav = useNavigate()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const pathname = usePathname()

  // The segments after /farms/:farmId/:resourceType/:resourceId
  const segmentsAfterResourcePath = useMemo(() => {
    const temp = pathname
    const search = temp.match(resourcePathRegex)

    return !isNil(search) ? temp.replace(search[0], '') : ''
  }, [pathname])

  const interactiveRef = useRef<HTMLDivElement>(null)

  const elementDropdown = useDisclosure()

  const matchStations = useMatch(`/farms/*/stations/*`)
  const matchPlots = useMatch(`/farms/*/plots/*`)
  const matchArchivedPlots = useMatch(`/farms/*/plots/archived`)

  const showElementDropdown =
    !isNil(matchStations) || (!isNil(matchPlots) && isNil(matchArchivedPlots))

  const elementsSummariesQuery = useQuery(
    client.measurements.plotsAndStations.summaries.getAllPages({
      organizationId: org.id
    }),
    { enabled: showElementDropdown }
  )

  const elementsSummaries = !isNil(elementsSummariesQuery.data)
    ? elementsSummariesQuery.data.filter((el) => {
        const metadata = isPlotSummary(el) ? el.metadata.plot : el.metadata.station
        const { name } = metadata

        if (!isEmpty(searchQuery)) {
          return name.toLowerCase().includes(searchQuery.toLowerCase())
        } else {
          return true
        }
      })
    : EMPTY_ELEMENTS

  const titleCrumb = !isEmpty(breadcrumbs) && !isNil(breadcrumbs) ? [...breadcrumbs].pop() : null

  useClickAway(interactiveRef, () => {
    // I don't know why it captures the parent even though the ref is on the correct element
    const target = interactiveRef?.current?.firstChild
    const c = target?.className as string

    if (!isNil(c) && c.includes('active') && !isNil(target)) {
      target.className = c.replace('active', '').trimEnd()
    }
  })

  return (
    <Flex
      $position='relative'
      $flexDirection='column'
      $width='100%'
      $flex={1}
      $height={'100%'}
      $backgroundColor={'grayscale.white'}
      $pointerEvents={'auto'}
      $overflowY='auto'
    >
      {/* Crumbs */}
      {breadcrumbs.length > 1 ? (
        <DelimitedFlex $alignItems='center' $px={'lg'} $py={'md'}>
          <Breadcrumbs crumbs={breadcrumbs} />
        </DelimitedFlex>
      ) : null}

      <DelimitedFlex $height={48} $alignItems='center' $px={'lg'} $py={'md'} $gap={'md'}>
        <Box $flex={1} $minWidth={0}>
          {showElementDropdown ? (
            <Dropdown
              isVisible={elementDropdown.isOpen}
              onVisibleChange={elementDropdown.toggle}
              placement='bottom-start'
              role='listbox'
            >
              <DropdownTrigger>
                <Interactive
                  ref={interactiveRef}
                  onClick={(e) => {
                    e.currentTarget.setAttribute('class', `${e.currentTarget.className} active`)
                    elementDropdown.open()
                  }}
                >
                  <TitleText title={titleCrumb?.label} />
                  <Icons.Unfold $size='lg' />
                </Interactive>
              </DropdownTrigger>
              <DropdownContent
                width={480}
                Header={
                  <Flex $backgroundColor={'grayscale.100'} $p='md' $alignItems='center'>
                    <Icons.Search $size='md' />
                    <InvisibleTextInput
                      shouldFocusOnMount
                      id={`search-elements-dropdown`}
                      name={`search-elements-dropdown`}
                      autoComplete='on'
                      autoCorrect='off'
                      type='text'
                      value={searchQuery}
                      onChange={(e) => {
                        if (!isNil(e.target)) setSearchQuery(e.target.value)
                      }}
                      placeholder={t('actions.search', { capitalize: true })}
                    />
                  </Flex>
                }
              >
                <Box $py={'sm'}>
                  {elementsSummaries.map((el) => {
                    const isPlot = isPlotSummary(el)
                    const resourceFromMetadata = isPlot ? el.metadata.plot : el.metadata.station

                    const Icon = isPlot ? Icons.PlotsFilled : Icons.SingleSensorFilled
                    const pathToResource = `/farms/${org.id}/${isPlot ? 'plots' : 'stations'}/${resourceFromMetadata.id}`
                    const isActive = pathname.includes(pathToResource)

                    return (
                      <MenuItem
                        key={`${isPlot ? 'plot' : 'station'}-${resourceFromMetadata.id}`}
                        onPress={
                          !isActive
                            ? () =>
                                nav(
                                  isPlot
                                    ? `${pathToResource}/${segmentsAfterResourcePath}`
                                    : pathToResource
                                )
                            : elementDropdown.close
                        }
                        forceActivity={isActive}
                      >
                        <Flex $minWidth={0} $alignItems='center' $px='lg' $py='md' $gap='md'>
                          <Icon
                            $size='lg'
                            $backgroundColor='grayscale.black'
                            $color={'grayscale.white'}
                            $p={'sm'}
                            $rounded={isPlot}
                          />
                          <Box $flex={1} $minWidth={0}>
                            <Text $ellipsis $fontWeight='medium'>
                              {resourceFromMetadata.name}
                            </Text>
                            <Text $fontSize='sm' $ellipsis>
                              {resourceFromMetadata.reverseGeocoding}
                            </Text>
                          </Box>
                          {resourceFromMetadata.isFavorite ? (
                            <Icons.Star $color={'primary.500'} $size='lg' />
                          ) : null}
                        </Flex>
                      </MenuItem>
                    )
                  })}
                </Box>
              </DropdownContent>
            </Dropdown>
          ) : (
            <TitleText title={titleCrumb?.label} />
          )}
        </Box>
        <HeaderActionsArea />
      </DelimitedFlex>

      {!isNil(resource) ? <ResourceNavigator resource={resource} /> : null}

      <Flex $flexDirection='column' $flex={1} $backgroundColor={'grayscale.50'}>
        {children}
      </Flex>
    </Flex>
  )
}

export default MenuLayout
