import { useClient, useMutation, useQuery } from '@weenat/client'
import { timestampToMoment } from '@weenat/client/dist/core/history'
import { ApiKey } from '@weenat/client/dist/resources/api'
import { useIntl } from '@weenat/wintl'
import { Link } from 'app/routx-router'
import useDisclosure from 'app/src/hooks/useDisclosure'
import useToasts from 'app/src/hooks/useToasts'
import AdminClientList from 'app/src/kit/AdminClientList'
import Button from 'app/src/kit/Button'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'
import Icons from 'app/src/kit/Icons'
import Modal from 'app/src/kit/Modal'
import SmartText from 'app/src/kit/SmartText'
import { TableCell, TableColumn } from 'app/src/kit/Table'
import Text from 'app/src/kit/Text'
import TrashButtonWithConfirm from 'app/src/kit/TrashButtonWithConfirm'
import { TextFieldPrimitive } from 'app/src/kit/fields/TextField'
import HeaderActions from 'app/src/layouts/HeaderActions'
import copy from 'copy-to-clipboard'
import { isNil } from 'lodash-es'
import { useState } from 'react'
import { styled } from 'styled-components'

const VerticalCenteredTableCell = styled(TableCell)`
  margin: auto;
`

const ApiItem: FC<{ item: ApiKey }> = ({ item: key }) => {
  const { addSuccessToast } = useToasts()
  const client = useClient()
  const { t } = useIntl()
  const [deleteApiKey, deleteApiKeyState] = useMutation(client.api.delete(key.id), {
    onSuccess: () => {
      addSuccessToast(t('actions.delete_success'))
    }
  })

  const handleCopy = () => {
    copy(key.key)
    addSuccessToast(t('actions.copy_success'))
  }

  return (
    <>
      <VerticalCenteredTableCell $width={24} />
      <VerticalCenteredTableCell>{key.name}</VerticalCenteredTableCell>
      <VerticalCenteredTableCell>
        <Flex $alignItems='center' $gap='sm'>
          <Text>{key.key}</Text>
          <Icons.Copy $size='md' onPress={handleCopy} />
        </Flex>
      </VerticalCenteredTableCell>
      <VerticalCenteredTableCell>
        {timestampToMoment(key.createdAt).format(t('formats.date'))}
      </VerticalCenteredTableCell>
      <VerticalCenteredTableCell>
        <TrashButtonWithConfirm
          kind='danger'
          title={t('models.api.deleteModal.title')}
          confirmationMessage={
            <SmartText tkey='models.api.deleteModal.body' vars={{ name: key.name }} />
          }
          $isLoading={deleteApiKeyState.isPending}
          $size='md'
          $py='md'
          $px='lg'
          $mr='md'
          $backgroundColor={'feedback.error.500'}
          $color={'grayscale.white'}
          onPress={() => deleteApiKey()}
        />
      </VerticalCenteredTableCell>
    </>
  )
}

export const Component = () => {
  const client = useClient()
  const { t } = useIntl()
  const { isOpen, open, close } = useDisclosure()
  const [keyName, setKeyName] = useState<string>()
  const { addSuccessToast } = useToasts()
  const [createApiKey, createApiKeyState] = useMutation(client.api.create(), {
    onSuccess: () => {
      addSuccessToast(t('actions.success'))
      setKeyName('')
      close()
    }
  })
  const columns: TableColumn[] = [
    { label: '', width: '24px' },
    { label: t('models.account.fields.last_name.label', { capitalize: true }), width: 2 },
    { label: t('models.api.token', { capitalize: true }), width: 2 },
    { label: t('models.station.created_at', { capitalize: true }), width: 1 },
    { label: '', width: '64px' }
  ]
  const keyCount = useQuery(client.api.getMany()).data?.results.length ?? 0
  const couldAddKey = keyCount < 20

  return (
    <>
      <HeaderActions>
        <Flex $gap={'md'} $alignItems='center'>
          <Link to='https://dev.weenat.com/' target='_blank'>
            <Button
              variant='small'
              backgroundColor='grayscale.50'
              color='grayscale.black'
              leftAdornment={<Icons.Article $size='lg' />}
            >
              <Text $fontWeight='semiBold'>{t('models.api.documentationLink')}</Text>
            </Button>
          </Link>
        </Flex>
      </HeaderActions>
      <Modal
        isOpen={isOpen}
        close={close}
        title={t('models.api.createModalSuccessTitle')}
        $noInnerPadding
      >
        <DelimitedFlex $p='lg'>
          <TextFieldPrimitive
            label={t('models.api.name', { capitalize: true })}
            value={keyName}
            onChange={(e) => setKeyName(e.currentTarget.value)}
          />
        </DelimitedFlex>
        <Flex $justifyContent='flex-end' $gap='md' $p='lg'>
          <Button importance='sd' onPress={close}>
            {t('actions.cancel')}
          </Button>
          <Button
            state={createApiKeyState}
            onPress={() => {
              if (!isNil(keyName)) {
                createApiKey({ name: keyName })
              }
            }}
          >
            {t('actions.confirm')}
          </Button>
        </Flex>
      </Modal>
      <Flex $alignItems='center' $justifyContent='space-between'>
        <Text $fontSize='xl' $fontWeight='bold'>
          {t('models.api.active_key')}
        </Text>
        <Flex $alignItems='center' $gap='md'>
          <SmartText tkey='models.api.keyLimitation' vars={{ keyCount }} />
          <Button
            variant='small'
            backgroundColor={couldAddKey ? 'primary.500' : 'grayscale.300'}
            color={'grayscale.white'}
            leftAdornment={<Icons.PlusSignCircle $color='grayscale.white' $size='rg' />}
            onPress={open}
            isDisabled={!couldAddKey}
          >
            {t('models.api.createModalSuccessTitle')}
          </Button>
        </Flex>
      </Flex>
      <AdminClientList
        columns={columns}
        renderItem={(key) => <ApiItem item={key} />}
        requesterBuilder={client.api.getMany}
        hideTopNav
        searchable={false}
      />
    </>
  )
}
export default Component
