import { useChartCtx } from '@weenat/client/dist/core/charts'
import { useMemo } from 'react'
import { useTheme } from 'styled-components'

const Guidelines = () => {
  const { colors } = useTheme()

  const { width, marginLeft, marginRight, yScale, ticksCount } = useChartCtx()

  const gridStartOnX = marginLeft
  const gridEndOnX = (width ?? 0) - (marginLeft ?? 0) - (marginRight ?? 0)

  const ticks = useMemo(() => {
    const rawTicks = yScale?.ticks(ticksCount)

    return rawTicks?.map((value) => {
      return {
        value: value,
        yOffset: yScale?.(value)
      }
    })
  }, [yScale, ticksCount])

  return (
    <g transform={`translate(${gridStartOnX ?? 0}, 0)`}>
      {ticks?.map(({ value, yOffset }) => (
        <line
          key={value}
          strokeDasharray={4}
          x1={0}
          x2={gridEndOnX}
          y1={yOffset ?? 0}
          y2={yOffset ?? 0}
          stroke={colors.grayscale[300]}
        />
      ))}
    </g>
  )
}

export default Guidelines
