import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import Icons from 'app/src/kit/Icons'
import Text from 'app/src/kit/Text'
import { RecentlyOpenedElement } from 'app/state'
import { isNil } from 'lodash-es'
import { SearchSelectableElement } from './SearchSelectableElement'

export const SearchResult: FC<
  RecentlyOpenedElement & {
    onPress?: () => void
  }
> = ({ isFavorite, id, name, reverseGeocoding, organizationId, kind, onPress, technicalName }) => {
  const { t } = useIntl()
  const isAPlot = kind === 'plot'
  const isADevice = kind === 'device'
  const Icon = isAPlot ? Icons.PlotsFilled : Icons.SingleSensorFilled
  const orgId = useOrgContext().id
  return (
    <a
      href={
        isADevice
          ? `/weather-map/${id}`
          : `/farms/${organizationId ?? orgId}/${isAPlot ? 'plots' : 'stations'}/${id}`
      }
      onClick={onPress}
    >
      <SearchSelectableElement>
        <Flex $alignItems='center' $gap='md'>
          <Icon $size='md' $color={'grayscale.black'} />
          <Flex $gap='md' $alignItems='baseline'>
            <Text $fontWeight='bold' $color={'grayscale.black'}>
              {name}
            </Text>
            <Text $color={'grayscale.700'} $fontWeight='medium'>
              {reverseGeocoding}
            </Text>
            {!isNil(technicalName) ? (
              <Text $color={'grayscale.500'} $fontSize='sm' $fontVariantNumeric='tabular-nums'>
                {technicalName}
              </Text>
            ) : null}
          </Flex>
          {isFavorite ? (
            <Icons.Star $color={isFavorite ? 'primary.500' : 'grayscale.300'} $size='md' />
          ) : null}
        </Flex>
        <Flex $alignItems='center' $gap={'md'}>
          <Text $color='grayscale.700' $fontWeight='semiBold' $lineHeight={undefined}>
            {t('actions.open')}
          </Text>
          <Icons.ArrowRight $size='lg' $color='grayscale.700' />
        </Flex>
      </SearchSelectableElement>
    </a>
  )
}
