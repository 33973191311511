import { HTMLProps } from 'react'
import { css, styled } from 'styled-components'

const Pressable = styled.div<{ $isDisabled: boolean }>`
  user-select: none;

  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};

  ${({ $isDisabled }) =>
    $isDisabled
      ? css`
          opacity: 0.5;
        `
      : null};

  &:hover {
    background-color: ${(props) => props.theme.colors.primary[200]};
  }

  // without it content is shrink to 0 even if pressable content has big height
  // example in create plot step:"choose datasource" on small heights
  min-height: auto;
`

export interface WeenatPressableProps extends Omit<HTMLProps<HTMLDivElement>, 'onClick'> {
  onPress: HTMLProps<HTMLDivElement>['onClick']
  isDisabled?: boolean
}

const WeenatPressable: FC<WeenatPressableProps> = ({
  onPress,
  isDisabled = false,
  ...divProps
}) => {
  return (
    <Pressable
      role='button'
      $isDisabled={isDisabled}
      onClick={(e) => {
        e.stopPropagation()
        onPress?.(e)
      }}
      {...divProps}
    />
  )
}

export default WeenatPressable
