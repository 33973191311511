import { mergeQueryState, schemas, useClient, useMutation, useQuery } from '@weenat/client'
import {
  extractErrorsOnPhoneNumbersUpdate,
  useIsMemberOfExpertOrg
} from '@weenat/client/dist/core/me'
import { PhoneNumber } from '@weenat/client/dist/resources/me'
import { useIntl } from '@weenat/wintl'
import useDisclosure from 'app/src/hooks/useDisclosure'
import useToasts from 'app/src/hooks/useToasts'
import Button from 'app/src/kit/Button'
import ListEmpty from 'app/src/kit/ListEmpty'
import Modal from 'app/src/kit/Modal'
import Text from 'app/src/kit/Text'
import LoadingCircle from 'app/src/kit/loaders/LoadingCircle'
import { useUserContext } from 'app/userProvider'
import isArray from 'lodash-es/isArray'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import isString from 'lodash-es/isString'
import { transparentize } from 'polished'
import { useEffect, useState } from 'react'
import { styled, useTheme } from 'styled-components'
import Icons from '../kit/Icons'
import AccountSection from './AccountSection'
import AddPhoneNumberModal from './AddPhoneNumberModal'
import SecondaryPhoneNumberItem from './SecondaryPhoneNumberItem'

const MAX_NUMBER_OF_PHONES = 5

const SINGLE_PHONE_SCHEMA = schemas.me.newPhoneNumber
type SinglePhoneFormValues = typeof SINGLE_PHONE_SCHEMA.initialValues

const EmptyPrimaryPhoneContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: ${(p) => p.theme.radiuses.md}px;
  margin-top: ${(p) => p.theme.spacings.lg}px;
  padding: ${(p) => p.theme.spacings.lg}px;
  border: 1px solid ${(p) => p.theme.colors.grayscale[100]};
  background-color: ${(props) => transparentize(0.5, props.theme.colors.grayscale[100])};
`

function sortByPrimaryAttribute(phones: PhoneNumber[]) {
  return phones.sort((phoneA, phoneB) => (phoneA.isPrimary && !phoneB.isPrimary ? -1 : 1))
}

type PhoneType = 'primary' | 'secondary' | 'none'
const EMPTY_PHONE_NUMBERS: PhoneNumber[] = []

const EditMyPhoneNumbers = () => {
  const { t } = useIntl()
  const { colors } = useTheme()
  const { addSuccessToast, addErrorToast } = useToasts()
  const { me } = useUserContext()
  const {
    isOpen: isAddPhoneModalOpen,
    close: closeAddPhoneModal,
    open: openAddPhoneModal
  } = useDisclosure()

  const {
    isOpen: isSecondaryPhonesModalOpen,
    close: closeSecondaryPhonesModal,
    open: openSecondaryPhonesModal
  } = useDisclosure()
  const client = useClient()

  const { isMemberOfExpertOrg, myInfoRequest } = useIsMemberOfExpertOrg({ isEnabled: true })

  const allPhoneNumbersRequest = useQuery(client.me.phoneNumbers.getAll())
  const allPhones = allPhoneNumbersRequest.data ?? EMPTY_PHONE_NUMBERS

  const [localPhones, setLocalPhones] = useState<PhoneNumber[]>(allPhones)
  // Array of error message sorted in the same order as the localPhones
  const [phoneFieldsErrorMessages, setPhoneFieldsErrorMessages] = useState<(string | undefined)[]>(
    []
  )

  const [typeOfPhoneInCreation, setTypeOfPhoneInCreation] = useState<PhoneType>('none')

  const [updatePhones, updatePhonesRequest] = useMutation(client.me.phoneNumbers.updateAll(), {
    onSuccess: () => {
      if (isAddPhoneModalOpen) {
        setTypeOfPhoneInCreation('none')
        closeAddPhoneModal()
      } else {
        setPhoneFieldsErrorMessages([])
      }

      addSuccessToast(t('user_settings.phone_numbers.all_numbers_edition_success'))
    },
    onError: (e) => {
      const err = extractErrorsOnPhoneNumbersUpdate(e)

      if (isString(err)) {
        addErrorToast(err)
      } else if (isArray(err)) {
        setPhoneFieldsErrorMessages(err)
      }
    }
  })

  const sortAndSendUpdate = (newPhones: PhoneNumber[]) => {
    const sortedPhones = sortByPrimaryAttribute(newPhones)
    updatePhones(sortedPhones)
  }

  const handleMultiplePhonesSubmit = () => {
    sortAndSendUpdate(localPhones)
  }

  const handleCountryChange = (phoneId: number, newCountryCode: PhoneNumber['countryCode']) => {
    const newPhones = localPhones
    const index = localPhones.findIndex((p) => p.id === phoneId)
    newPhones[index].countryCode = newCountryCode

    setLocalPhones(newPhones)
  }

  const handleNumberChange = (phoneId: number, newNumber: PhoneNumber['number']) => {
    const newPhones = localPhones
    const index = localPhones.findIndex((p) => p.id === phoneId)
    newPhones[index].number = newNumber

    setLocalPhones(newPhones)
  }

  const handleAddPhoneFormSubmit = (newPhone: SinglePhoneFormValues) => {
    const toAdd: Omit<PhoneNumber, 'id'> = {
      countryCode: newPhone.telephoneCountryCode,
      isEnabled: newPhone.enabled,
      isPrimary: typeOfPhoneInCreation === 'primary',
      number: newPhone.telephoneNumber,
      userId: me.id
    }

    // not using localPhones to avoid error from ongoing edition form
    const newPhones = [...allPhones, toAdd]
    sortAndSendUpdate(newPhones as PhoneNumber[])
  }

  const { isLoading } = mergeQueryState(allPhoneNumbersRequest, myInfoRequest)

  useEffect(() => {
    const newPhones = sortByPrimaryAttribute(allPhones)
    setLocalPhones(newPhones)
  }, [allPhones])

  return (
    <>
      <Box>
        <AccountSection
          title={''}
          description={t('user_settings.phone_numbers.phones_usage_description')}
        >
          <Box $my={24}>
            <Text $fontWeight='bold' $lineHeight='md'>
              {t('user_settings.phone_numbers.section_label', { capitalize: true })}
            </Text>

            {isLoading ? (
              <LoadingCircle />
            ) : isEmpty(localPhones) ? (
              <EmptyPrimaryPhoneContainer>
                <Text $textAlign='center'>
                  {t('user_settings.phone_numbers.no_primary_phone', { capitalize: true })}
                </Text>
                <Box $mt='lg'>
                  <Button
                    IconLeft={Icons.PlusSignCircle}
                    onPress={() => {
                      setTypeOfPhoneInCreation('primary')
                      openAddPhoneModal()
                    }}
                  >
                    {t('user_settings.phone_numbers.add_primary_phone')}
                  </Button>
                </Box>
              </EmptyPrimaryPhoneContainer>
            ) : (
              localPhones.map((phone, index) => {
                return index === 0 ? (
                  <Box key={`${phone.id}-primary`}>
                    <SecondaryPhoneNumberItem
                      disableDeletion
                      disableActivation
                      label={t('user_settings.phone_numbers.primary_phone', { capitalize: true })}
                      phone={phone}
                      position={index}
                      onCountryChange={handleCountryChange}
                      onNumberChange={handleNumberChange}
                      error={phoneFieldsErrorMessages[index]}
                    />
                    {isMemberOfExpertOrg ? (
                      <Box>
                        <Flex $alignItems='center' $justifyContent='space-between' $mb='lg'>
                          <Flex $alignItems='center' $flex={1} $gap={'md'}>
                            <Text $fontWeight='bold'>
                              {t('user_settings.phone_numbers.secondary_phones', {
                                capitalize: true
                              })}
                            </Text>
                            <Icons.InfoCircle
                              $ml='sm'
                              $mr='md'
                              $size='md'
                              onPress={openSecondaryPhonesModal}
                            />
                          </Flex>
                          <Button
                            IconLeft={Icons.PlusSignCircle}
                            importance='sd'
                            color={colors.grayscale.black}
                            backgroundColor='transparent'
                            onPress={() => {
                              setTypeOfPhoneInCreation('secondary')
                              openAddPhoneModal()
                            }}
                            isDisabled={
                              !isNil(localPhones) && localPhones.length >= MAX_NUMBER_OF_PHONES
                            }
                          >
                            {t('user_settings.phone_numbers.add_secondary_phone', {
                              capitalize: true
                            })}
                          </Button>
                        </Flex>
                        {localPhones.length === 1 ? (
                          <ListEmpty
                            model='phoneNumber'
                            message={t('user_settings.phone_numbers.no_secondary_phones_entered')}
                          />
                        ) : null}
                      </Box>
                    ) : null}
                  </Box>
                ) : isMemberOfExpertOrg ? (
                  <SecondaryPhoneNumberItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${phone.id}-${index}`}
                    phone={phone}
                    position={index}
                    onCountryChange={handleCountryChange}
                    onNumberChange={handleNumberChange}
                    error={phoneFieldsErrorMessages[index]}
                  />
                ) : null
              })
            )}
          </Box>

          {!isEmpty(localPhones) ? (
            <Flex $width='100%' $alignItems='center' $justifyContent='flex-end'>
              <Button
                onPress={() => {
                  handleMultiplePhonesSubmit()
                }}
                state={updatePhonesRequest}
              >
                {t('actions.save_changes')}
              </Button>
            </Flex>
          ) : null}
        </AccountSection>
      </Box>

      <AddPhoneNumberModal
        isOpen={isAddPhoneModalOpen}
        close={closeAddPhoneModal}
        title={t(
          typeOfPhoneInCreation === 'primary'
            ? 'user_settings.phone_numbers.add_primary_phone'
            : 'user_settings.phone_numbers.add_secondary_phone',
          {
            capitalize: true
          }
        )}
        onSubmit={handleAddPhoneFormSubmit}
        buttonState={updatePhonesRequest}
        width={600}
      />

      <Modal
        isOpen={isSecondaryPhonesModalOpen}
        close={closeSecondaryPhonesModal}
        width={600}
        title={t('user_settings.phone_numbers.add_secondary_phone')}
      >
        <Text>
          {t('user_settings.phone_numbers.secondary_phones_explanation', {
            capitalize: true
          })}
        </Text>
      </Modal>
    </>
  )
}

export default EditMyPhoneNumbers
