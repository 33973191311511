import { Redirect, useSearchParams } from 'app/routx-router'
import { z } from 'zod'

export const settings = {
  search: z.object({
    network_id: z.coerce.number(),
    organization_id: z.coerce.number(),
    redirect: z.string()
  })
}

const PushNotification = () => {
  const [{ redirect }] = useSearchParams(settings.search)

  const redirectWithoutDomainName = redirect.replace(/http(s)*:\/\/app(-preprod)*\.weenat\.com/, '')

  // redirection from old urls to new ones will be done by redirect component on old url
  return <Redirect replace to={redirectWithoutDomainName} />
}

export const Component = PushNotification
export default PushNotification
