import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import isString from 'lodash-es/isString'
import { css, styled, useTheme } from 'styled-components'
import CountIndicator from './CountIndicator'
import Icon from './Icon'

const ChipsContainer = styled(Flex)<{ $isShadowed?: boolean }>`
  width: 100%;
  ${(p) =>
    !p.$isShadowed &&
    `
      overflow: hidden;
    `}
`

interface ChipTabsProps {
  $isActive: boolean
  $isRounded?: boolean
  $isShadowed?: boolean
  $isDisabled?: boolean
}

const ChipTab = styled(Box)<ChipTabsProps>`
  align-items: center;

  ${(p) =>
    p.$isShadowed &&
    css`
      box-shadow: ${(p) => p.theme.shadows.lg.boxShadow};
    `}

  padding: 4px 8px;
  border-radius: ${(p) => (p.$isRounded ? p.theme.radiuses.xl : p.theme.radiuses.md)}px;
  border: 1px solid
    ${(p) => (p.$isActive ? p.theme.colors.primary[800] : p.theme.colors.grayscale[300])};
  background-color: ${(p) =>
    p.$isActive ? p.theme.colors.primary[500] : p.theme.colors.grayscale.white};
  cursor: ${(p) => (p.$isDisabled ? 'inherit' : 'pointer')};
  height: 100%;

  &:hover {
    background-color: ${(p) =>
      p.$isDisabled ? p.theme.colors.grayscale.white : p.theme.colors.primary[500]};

    p {
      color: ${(p) =>
        p.$isDisabled ? p.theme.colors.grayscale[300] : p.theme.colors.grayscale.white};
    }

    svg {
      fill: ${(p) =>
        p.$isDisabled ? p.theme.colors.grayscale[300] : p.theme.colors.grayscale.white};
    }
  }

  transition: background-color 0.2s ease-in-out;
`

export interface ChipTabOption<V> {
  value: V
  label: string | React.ReactNode
  /** An indicator displayed to the right of the label */
  indicator?: string | number
  icon?: typeof Icon
  isDisabled?: boolean
}

interface ChipsTabsProps<Value extends string | number> {
  value: Value
  $isRounded?: boolean
  $isShadowed?: boolean
  onChange: (newValue: Value) => void
  options: ChipTabOption<Value>[]
  className?: string
  /** An indicator displayed to the right of the label */
  indicator?: string | number
}

function ChipsTabs<Value extends string | number>({
  onChange,
  options,
  value,
  className,
  $isRounded,
  $isShadowed
}: ChipsTabsProps<Value>) {
  const { colors } = useTheme()

  return (
    <ChipsContainer className={className} $isShadowed={$isShadowed}>
      {options.map((opt) => {
        const $isActive = value === opt.value
        const textColor = !!opt.isDisabled
          ? colors.grayscale[100]
          : $isActive
            ? colors.grayscale.white
            : colors.grayscale.black

        const BoldLabel = isString(opt.label) ? (
          <Text $fontWeight='semiBold' $ellipsis $textAlign='center' $color={textColor}>
            {opt.label.trim()}
          </Text>
        ) : (
          opt.label
        )

        const Icn = !isNil(opt.icon) ? opt.icon : null

        return (
          <Box key={`${opt.label}-${opt.value}`} $mr='md'>
            <ChipTab
              $isRounded={$isRounded}
              $isActive={$isActive}
              onClick={() => {
                if (!!!opt.isDisabled) {
                  onChange(opt.value)
                }
              }}
              $isShadowed={$isShadowed}
              $isDisabled={opt.isDisabled}
            >
              <Flex $alignItems='center' $gap='md'>
                {!isNil(Icn) ? <Icn $size='md' $color={textColor} /> : null}
                {BoldLabel}
                {!isNil(opt.indicator) ? (
                  <CountIndicator
                    count={opt.indicator}
                    backgroundColor={textColor}
                    color={
                      !!opt.isDisabled
                        ? colors.grayscale[100]
                        : !$isActive
                          ? colors.grayscale.white
                          : colors.primary[500]
                    }
                  />
                ) : null}
              </Flex>
            </ChipTab>
          </Box>
        )
      })}
    </ChipsContainer>
  )
}

export default ChipsTabs
