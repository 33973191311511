import { schemas, useClient, useMutation } from '@weenat/client'
import * as zodSchemas from '@weenat/client/dist/zod-schemas'
import { useIntl } from '@weenat/wintl'
import { useSearchParams } from 'app/routx-router'
import AuthCard from 'app/src/authentication/components/AuthCard'
import GoToLoginWithEmail from 'app/src/authentication/components/GoToLoginWithEmail'
import { useLogout } from 'app/src/authentication/components/Logout'
import useToasts from 'app/src/hooks/useToasts'
import Headline from 'app/src/kit/Headline'
import SubmitButton from 'app/src/kit/SubmitButton'
import SuperForm from 'app/src/kit/SuperForm'
import Text from 'app/src/kit/Text'
import EmailField from 'app/src/kit/fields/EmailField'
import { useToken } from 'app/state'
import isNil from 'lodash-es/isNil'
import { useEffect, useRef } from 'react'

export const settings = {
  public: 'always',
  search: zodSchemas.email
}
const SendVerificationEmail = () => {
  const { t } = useIntl()
  const client = useClient()
  const [token] = useToken()
  const logout = useLogout()
  const { addToast } = useToasts()
  const [{ email }] = useSearchParams(settings.search)

  const emailRef = useRef<string>('')

  const [sendVerificationEmail, sendVerificationEmailRequest] = useMutation(
    client.auth.sendVerificationEmail(),
    {
      onSuccess: () => {
        addToast(
          t('models.account.actions.verification_email_sent', {
            email: emailRef.current
          }),
          {
            appearance: 'success'
          }
        )
      },
      onError: (e) => {
        const [error] = e.nonFieldErrors || []
        if (!isNil(error)) {
          addToast(t('errors.unknown'))
        }
      }
    }
  )

  const title = t('actions.resend_verification_email')

  // TODO: check if really ok
  useEffect(() => {
    /** Otherwise we won't be able to resend a verification email */
    if (!isNil(token)) logout()
  }, [token])

  return (
    <AuthCard>
      <Flex $flexDirection='column' $alignItems='center'>
        <Headline>{title}</Headline>
        <Text $textAlign='center'>{t('models.account.actions.should_verify_email')}</Text>
        <SuperForm
          schema={schemas.shared.mailed}
          onSubmit={async (values) => {
            emailRef.current = values.email
            await sendVerificationEmail(values)
          }}
          initialValues={{ email: !isNil(email) ? email : '' }}
        >
          <EmailField
            $isDisabled={sendVerificationEmailRequest.isPending}
            errorState={sendVerificationEmailRequest.error}
          />
          <Flex $p='lg' $flexDirection='column' $alignItems='center'>
            <SubmitButton
              isLoading={sendVerificationEmailRequest.isPending}
              isError={sendVerificationEmailRequest.isError}
              isSuccess={sendVerificationEmailRequest.isSuccess}
            >
              {t('models.account.actions.send_email_verification')}
            </SubmitButton>
            <Box $pt='lg'>
              <GoToLoginWithEmail email={!isNil(emailRef.current) ? emailRef.current : email} />
            </Box>
          </Flex>
        </SuperForm>
      </Flex>
    </AuthCard>
  )
}
export const Component = SendVerificationEmail
export default SendVerificationEmail
