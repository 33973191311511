import { useChartCtx } from '@weenat/client/dist/core/charts'
import { DataPoint } from '@weenat/client/dist/core/charts/d3-charts.type'
import { WeenatColor, fromColorPathToColor } from '@weenat/theme'
import { isNil } from 'lodash-es'
import { SVGProps, useId } from 'react'

const CORNER_RADIUS = 4
const defaultNormalize = (x: number) => x

interface BarProps extends SVGProps<SVGRectElement> {
  data?: DataPoint[]
  $color?: WeenatColor | ((val: number, date: Date) => string)
  /** Horizontal space between bars on the same time point */
  offset?: number
  barWidth?: number
  normalize?: (val: number) => number
}

function Bar({
  data,
  $color,
  barWidth = 4,
  normalize = defaultNormalize,
  offset = 0,
  ...rectProps
}: BarProps) {
  const { xScale, yScale, marginBottom, height } = useChartCtx()
  const id = useId()

  const nonFunctionColor =
    typeof $color != 'function'
      ? !isNil($color)
        ? fromColorPathToColor($color)
        : 'currentColor'
      : undefined

  const onChartZero = yScale(normalize(0))

  return (
    <>
      <defs>
        {data?.map((d) => {
          if (d.value !== null) {
            const barHeight = onChartZero - yScale(normalize(d.value))

            return (
              <clipPath
                key={`round_${d.date.getTime()}_${id}`}
                id={`round_${d.date.getTime()}_${id}`}
              >
                <rect
                  x='0'
                  y='0'
                  width={barWidth}
                  height={barHeight + CORNER_RADIUS}
                  rx={CORNER_RADIUS}
                  ry={CORNER_RADIUS}
                />
              </clipPath>
            )
          } else {
            return null
          }
        })}
      </defs>
      <g fill='white' stroke='currentColor' strokeWidth='1.5'>
        {data?.map((d) => {
          if (d.value !== null) {
            const color = typeof $color === 'function' ? $color(d.value, d.date) : nonFunctionColor

            const barHeight = onChartZero - yScale(normalize(d.value))
            return (
              <rect
                key={d.date.getTime()}
                x={0}
                y={0}
                transform={`translate(${xScale(d.date) + offset - barWidth / 2} ${height - marginBottom - barHeight})`}
                style={{ borderRadius: `${CORNER_RADIUS}px ${CORNER_RADIUS}px 0px 0px` }}
                width={barWidth}
                height={barHeight}
                fill={color}
                strokeWidth={0}
                clipPath={d.value !== 0 ? `url(#round_${d.date.getTime()}_${id})` : undefined}
                {...rectProps}
              />
            )
          } else {
            return null
          }
        })}
      </g>
    </>
  )
}

export default Bar
