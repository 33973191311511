import { useClient, useQuery } from '@weenat/client'
import { useParams } from 'app/routx-router'
import NetworkPresentation from 'app/src/networks/NetworkPresentation'

const Network = () => {
  const client = useClient()
  const { networkId } = useParams()

  const networkRequest = useQuery(client.networks.get(networkId))
  const network = networkRequest.data

  return network ? <NetworkPresentation network={network} /> : null
}

export const Component = Network
export default Network
