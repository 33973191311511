import { setUser as setSentryUser } from '@sentry/react'
import { enums, useClient, useQuery } from '@weenat/client'
import { canUser, ops } from '@weenat/client/dist/core/access'
import { useHasPaymentIncident } from '@weenat/client/dist/core/billing'
import { Org } from '@weenat/client/dist/resources/orgs'
import { useIntl } from '@weenat/wintl'
import { CURRENT_ORG_KEY, OrgContextProvider, useOrgContext } from 'app/orgProvider'
import { useParams } from 'app/routx-router'
import { useLoginAs, useToken, useUserLanguage } from 'app/state'
import { UserContextProvider, useUserContext } from 'app/userProvider'
import { useInitTracking } from 'app/utils/analytics'
import { isNil, omitBy } from 'lodash-es'
import { default as momentTZ } from 'moment-timezone'
import { useEffect } from 'react'
import { useTheme } from 'styled-components'
import { clearAllStates } from './src/authentication/components/Logout'
import ShouldVerifyEmail from './src/authentication/components/ShouldVerifyEmail'
import useCloseRadarOnPageChange from './src/dashboard/components/DashboardMap/Radar/hooks/useCloseRadarOnPageChange'
import ExternalConnectionsErrorMessage from './src/dashboard/components/common/ExternalConnectionsErrorMessage'
import { useIsAppAccessRestricted } from './src/hooks/useIsAppAccessRestricted'
import useRefreshToken from './src/hooks/useRefreshToken'
import useTourStepper from './src/hooks/useTourStepper'
import Banner from './src/kit/Banner'
import DowngradeWarn from './src/kit/DowngradeWarn'
import InvitNotificationPresentation from './src/kit/InvitNotificationPresentation'
import ModalOverlay from './src/kit/ModalOverlay'
import SmartText from './src/kit/SmartText'
import Text from './src/kit/Text'
import { LogoSphereDark } from './src/kit/icons/logos'
import LoadingCircle from './src/kit/loaders/LoadingCircle'

const EMPTY_ORGS: Org[] = []

const useLocaleSync = () => {
  const { locales } = useIntl()
  const [, setUserLanguage] = useUserLanguage()

  useEffect(() => {
    momentTZ.locale(locales.current)
    setUserLanguage(locales.current)
  }, [locales.current])

  return locales.current
}

const OnUserLoginBehavior: FC = ({ children }) => {
  const org = useOrgContext()
  const user = useUserContext()
  const [token] = useToken()
  const { colors } = useTheme()

  const { me } = user

  useLocaleSync()
  useInitTracking()
  useCloseRadarOnPageChange()
  useRefreshToken()

  useEffect(() => {
    if (!isNil(user)) {
      setSentryUser(omitBy(user, (value) => isNil(value)))
    }
  }, [user])

  /** They are the same as for {@link useIsAppAccessRestricted} */
  const paymentIncidentArgs = {
    enabled: !isNil(token) && !isNil(org.id),
    orgId: org.id
  }

  const appRestriction = useIsAppAccessRestricted(paymentIncidentArgs)
  const { hasPaymentIncident, paymentIncidentRequest } = useHasPaymentIncident(paymentIncidentArgs)

  const hasDowngradeWarn =
    !isNil(org) &&
    canUser(ops.edit, 'billing', { organization: org }) &&
    appRestriction.isRestricted &&
    appRestriction.reason === 'downgrade' &&
    !isNil(paymentIncidentRequest.data)

  useTourStepper(me, hasDowngradeWarn)

  return (
    <>
      {/* Warning Modal */}
      {hasDowngradeWarn && (
        <DowngradeWarn
          orgId={org.id}
          paymentDueDate={paymentIncidentRequest.data?.paymentDueDate}
        />
      )}

      {/* Payment incident Modal */}
      {hasPaymentIncident && (
        <Banner $feedback='error'>
          <SmartText
            tkey='ecommerce.billing.payment_incident.web'
            vars={{ orgId: org.id }}
            $color={colors.grayscale.white}
          />
        </Banner>
      )}

      <ShouldVerifyEmail />
      <InvitNotificationPresentation />
      <ExternalConnectionsErrorMessage />

      {children}
    </>
  )
}

const AuthProviders: FC = ({ children }) => {
  const client = useClient()
  const { t } = useIntl()
  const [token] = useToken()
  const [loginAs] = useLoginAs()

  let orgId: number | undefined = undefined

  const { farmId: pathParamOrgId } = useParams()

  const config = {
    enabled: !isNil(token)
  }

  const meRequest = useQuery(client.me.get({ loginAsEmail: loginAs?.email }), config)
  const me = meRequest.data

  const myProfileRequest = useQuery(
    client.me.getMyProfile({ loginAsEmail: loginAs?.email }),
    config
  )
  const profile = myProfileRequest.data

  const allOrgsFromUserRequest = useQuery(client.orgs.getAllPages(), config)
  const allOrgsFromUser = allOrgsFromUserRequest.data ?? EMPTY_ORGS
  const firstAccessibleOrgId = allOrgsFromUser
    .sort((a) =>
      [enums.BillingStatus.plus, enums.BillingStatus.expert].includes(a.billingStatus) ? -1 : 1
    )
    .at(0)?.id

  if (isNil(pathParamOrgId)) {
    const storedCurrentOrgId = localStorage.getItem(CURRENT_ORG_KEY)
    if (!isNil(storedCurrentOrgId)) {
      orgId = parseInt(storedCurrentOrgId, 10)
    } else {
      const favoriteOrgId = myProfileRequest.data?.favoriteOrgId
      if (!isNil(favoriteOrgId)) {
        orgId = favoriteOrgId
      } else {
        orgId = firstAccessibleOrgId
      }
    }
  } else {
    orgId = pathParamOrgId
  }

  const orgRequest = useQuery(client.orgs.get(orgId), {
    enabled: !isNil(orgId) && !isNil(token)
  })

  /**
   * if previous org is no more accessible we defaulting on the first one accessible
   * case like i put a favorite than loose access to it or last org i was using is no more acessible
   */
  const alwaysAvailableOrgRequest = useQuery(client.orgs.get(firstAccessibleOrgId), {
    enabled: !isNil(firstAccessibleOrgId) && !isNil(token) && orgRequest.isError
  })

  const isLoading =
    allOrgsFromUserRequest.isLoading ||
    orgRequest.isLoading ||
    myProfileRequest.isLoading ||
    orgRequest.isLoading ||
    meRequest.isLoading ||
    alwaysAvailableOrgRequest.isLoading

  const unableToGetOwnIdentity =
    (meRequest.isError || myProfileRequest.isError) &&
    (meRequest.error?.status === 403 || myProfileRequest.error?.status === 403)
  if (unableToGetOwnIdentity) {
    // user is unauthorized to get is own identity or org so we should disconnect him
    clearAllStates(loginAs !== null)
  }

  const org = orgRequest.data ?? alwaysAvailableOrgRequest.data

  return isLoading ? (
    <ModalOverlay>
      <Flex $flexDirection='column' $alignItems='center' $justifyContent='center' $gap='md'>
        <Flex
          $flexDirection='column'
          $alignItems='center'
          $justifyContent='center'
          $position='relative'
          $width={32}
          $height={32}
        >
          <div style={{ position: 'absolute' }}>
            <LoadingCircle size='2xl' color={'grayscale.black'} />
          </div>
          <LogoSphereDark width={24} height={24} />
        </Flex>
        <Text $textAlign='center' $fontWeight='bold'>
          {t('status.loading', { capitalize: true })}
        </Text>
      </Flex>
    </ModalOverlay>
  ) : !isNil(profile) && !isNil(me) && !isNil(org) ? (
    <UserContextProvider profile={profile} me={me}>
      <OrgContextProvider org={org}>
        <OnUserLoginBehavior />
        {children}
      </OrgContextProvider>
    </UserContextProvider>
  ) : !unableToGetOwnIdentity ? (
    children
  ) : null
}

export default AuthProviders
