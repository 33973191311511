import { Universe } from '@weenat/client/dist/core/universes'
import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import useDisclosure from 'app/src/hooks/useDisclosure'
import { useHandleScreenQueryParamChange } from 'app/src/hooks/useScreenQueryParam'
import Icons from 'app/src/kit/Icons'
import Modal from 'app/src/kit/Modal'
import Pop from 'app/src/kit/Pop'
import TextTooltip from 'app/src/kit/tooltips/TextTooltip'
import { GoogleMapApi } from 'app/src/map/utils'
import { useUniverse } from 'app/state'
import { useTheme } from 'styled-components'
import logEvent from '../../../../../utils/analytics'
import ControlsContainer from '../ControlsContainer'
import {
  useBackgroundMapContext,
  useBackgroundMapDispatcher
} from '../contexts/BackgroundMapContext'
import useUniverseOptionsQuery from '../universes/useUniverseOptionsQuery'
import RadarLegend from './RadarLegend'
import RadarSlider from './RadarSlider'
import useIsRadarEnabled from './hooks/useIsRadarEnabled'

const Radar = ({ map, maps }: GoogleMapApi) => {
  const { t } = useIntl()
  const { colors } = useTheme()
  const org = useOrgContext()
  const currentOrgId = org.id

  const [, setUniverse] = useUniverse()
  const { universeOptions } = useUniverseOptionsQuery(currentOrgId)

  const [isRadarEnabled, setIsRadarEnabled] = useIsRadarEnabled()

  const { drawMarkers } = useBackgroundMapContext()
  const dispatch = useBackgroundMapDispatcher()

  const handleScreenQueryParamChange = useHandleScreenQueryParamChange()

  const { isOpen: isLegendOpen, close: closeLegend, open: openLegend } = useDisclosure()

  return isRadarEnabled ? (
    <>
      <Flex $flex={1} $alignItems='flex-end' $gap='lg'>
        <RadarSlider map={map} maps={maps} />
        <Flex $flexDirection='column' $gap='md'>
          <ControlsContainer>
            <Icons.DottedCircle
              $rounded
              $size={'lg'}
              onPress={() => {
                dispatch({ type: 'setDrawMarkers', newDrawMarkers: !drawMarkers })
              }}
            />
          </ControlsContainer>
          <ControlsContainer>
            <Icons.InfoCircle $rounded $size={'lg'} onPress={() => openLegend()} />
          </ControlsContainer>
          <ControlsContainer>
            <Icons.Close
              $rounded
              $size={'lg'}
              onPress={() => {
                logEvent('radar_close')
                setIsRadarEnabled(false)
              }}
            />
          </ControlsContainer>
        </Flex>
      </Flex>
      <Modal isOpen={isLegendOpen} close={closeLegend} title={t('help.legend')}>
        <RadarLegend />
      </Modal>
    </>
  ) : (
    <Pop
      tourName='discover_universes'
      stepName='custom_display'
      placement='top'
      onClose={() => {
        const hasFrost = universeOptions.some((x) => x.value === Universe.frost)
        if (hasFrost) {
          setUniverse(Universe.frost)
          handleScreenQueryParamChange('frost_universe', () => true)
        } else {
          handleScreenQueryParamChange('outro', () => true)
        }
      }}
    >
      <TextTooltip
        content={t('map_settings.view.options.radar', { capitalize: true })}
        placement='left'
      >
        <ControlsContainer>
          <Icons.Radar
            $size={'xl'}
            $m={'sm'}
            onPress={() => {
              logEvent('radar_open')
              setIsRadarEnabled(true)
            }}
            $color={isRadarEnabled ? 'primary.500' : colors.grayscale.black}
          />
        </ControlsContainer>
      </TextTooltip>
    </Pop>
  )
}

export default Radar
