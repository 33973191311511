import { QueryKey } from '@tanstack/react-query'
import { extractAvailableHorizonIdsSet, HorizonOpt } from '@weenat/client/dist/core/horizons'
import { hasHydricStressMetricData } from '@weenat/client/dist/core/summary'
import { Universe } from '@weenat/client/dist/core/universes'
import { isHydricStress } from '@weenat/client/dist/enums/MetricIds'
import { RawMyProfileUnits } from '@weenat/client/dist/resources/me'
import { PlotOrStationSummary } from '@weenat/client/dist/resources/measurements'
import {
  isPlotSummary,
  isStationSummary,
  PlotSummary,
  StationSummary
} from '@weenat/client/dist/resources/measurements.type'
import { useOrgContext } from 'app/orgProvider'
import Card from 'app/src/kit/Card'
import Link from 'app/src/kit/LinkComponent'
import { BoxProps } from 'app/src/kit/primitives/themeMappings/props'
import { useToggleFeature, useUniverse } from 'app/state'
import isNil from 'lodash-es/isNil'
import { Fragment, memo, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Code as ContentLoader } from 'react-content-loader'
import { FixedSizeList } from 'react-window'
import { styled } from 'styled-components'
import useResizeObserver from 'use-resize-observer'
import useDebounce from '../../../hooks/useDebounce'
import { elementIds } from './constants'
import {
  useBackgroundMapContext,
  useBackgroundMapDispatcher
} from './contexts/BackgroundMapContext'
import FetchSummariesError from './FetchSummariesError'
import NoSummaries from './NoSummaries'
import NoSummariesMatchingSearchTermContent from './NoSummariesMatchingSearchTermContent'
import PlotCard, { DEFAULT_PLOT_CARD_HEIGHT, HYDRIC_PLOT_CARD_HEIGHT } from './PlotCard'
import PlotListHeader from './PlotListHeader'
import { ListItemData } from './PlotOrStationList'
import StationCard from './StationCard'
import useUniverseBackgroundMapContext from './universes/useUniverseBackgroundMapContext'
/**
 * In pixels
 * @deprecated remove with redesign
 */
export const PLOT_LIST_WIDTH = 400

const ListContainer = styled(Box)`
  pointer-events: auto;

  background-color: ${(p) => p.theme.colors.grayscale.white};
  border-radius: 0 ${(p) => p.theme.radiuses.xl}px 0 0;
  width: ${PLOT_LIST_WIDTH}px;
  padding: 0;

  * {
    scroll-behavior: smooth;
  }
`

const RowCard = styled(Card)`
  overflow: hidden;
  height: 100%;
  transition: border-color 0.2s ease-in-out;
`

interface RowProps {
  index: number
  style: BoxProps['style']
  data: ListItemData
}
const emptyArray: HorizonOpt[] = []

const PlotRow: FC<
  { summary: PlotSummary; isNotRenderingSummary: boolean; itemId: number | null } & Pick<
    RowProps,
    'data'
  >
> = ({
  summary,
  data: {
    focusedMetric,
    focusedHorizon,
    availableHorizonOptions,
    onHorizonChange,
    summariesQueryCacheKey,
    isExpertOrg
  },
  isNotRenderingSummary,
  itemId
}) => {
  const plotId = summary.metadata.plot.id

  const hasNoDataForHorizon = useMemo(
    () =>
      isHydricStress(focusedMetric) &&
      !hasHydricStressMetricData({ metric: focusedMetric, horizonId: focusedHorizon, summary }),
    [focusedMetric, focusedHorizon, summary]
  )

  const availableHorizons = useMemo(() => {
    if (availableHorizonOptions === undefined) return []
    if (focusedMetric === 'HPOT') {
      const horizonIdsOnPlot = Array.from(extractAvailableHorizonIdsSet(summary))
      return availableHorizonOptions.filter((hOpt) => horizonIdsOnPlot.includes(hOpt.value))
    } else {
      return emptyArray
    }
  }, [focusedMetric, summary, availableHorizonOptions])
  return (
    <PlotCard
      summary={summary}
      summariesQueryCacheKey={summariesQueryCacheKey}
      onHorizonChange={onHorizonChange}
      availableHorizonOptions={availableHorizons}
      hasNoDataForHorizon={hasNoDataForHorizon}
      focusedHorizon={focusedHorizon}
      focusedMetric={focusedMetric}
      isSelected={!isNotRenderingSummary && itemId === plotId}
      isExpertOrg={isExpertOrg}
    />
  )
}

const StationRow: FC<
  { summary: StationSummary; isNotRenderingSummary: boolean; itemId: number | null } & Pick<
    RowProps,
    'data'
  >
> = ({
  summary,
  data: { focusedMetric, summariesQueryCacheKey, isExpertOrg },
  isNotRenderingSummary,
  itemId
}) => {
  const stationId = summary.metadata.station.id
  return (
    <StationCard
      summary={summary}
      summariesQueryCacheKey={summariesQueryCacheKey}
      focusedMetric={focusedMetric}
      isSelected={!isNotRenderingSummary && itemId === stationId}
      isExpertOrg={isExpertOrg}
    />
  )
}

const Row: FC<RowProps> = memo<RowProps>(({ index, style, data }) => {
  const {
    hoverIntent: { itemId }
  } = useBackgroundMapContext()
  const [universe] = useUniverse()
  const org = useOrgContext()
  const [{ redesign }] = useToggleFeature()
  const dispatch = useBackgroundMapDispatcher()

  const {
    currentItems,
    hasAlreadyFetchedAndHasNoItems,
    hasNoItemsMatchingSearchTerm,
    isError,
    isLoading
  } = data

  const summary = currentItems[index]

  const isNotRenderingSummary =
    isLoading || isError || hasAlreadyFetchedAndHasNoItems || hasNoItemsMatchingSearchTerm
  let itemContent

  if (isPlotSummary(summary)) {
    itemContent = (
      <PlotRow
        data={data}
        summary={summary}
        isNotRenderingSummary={isNotRenderingSummary}
        itemId={itemId}
      />
    )
  } else if (isStationSummary(summary)) {
    itemContent = (
      <StationRow
        data={data}
        summary={summary}
        isNotRenderingSummary={isNotRenderingSummary}
        itemId={itemId}
      />
    )
  }

  const content = isLoading ? (
    // Height is important otherwise not displayed
    <ContentLoader height='100%' uniqueKey='fetchSummariesLoader' width='100%' />
  ) : isError ? (
    <FetchSummariesError />
  ) : hasAlreadyFetchedAndHasNoItems ? (
    <NoSummaries />
  ) : hasNoItemsMatchingSearchTerm ? (
    <NoSummariesMatchingSearchTermContent />
  ) : (
    itemContent
  )

  const MaybeLink = isNotRenderingSummary ? Fragment : Link

  const currentItemId = isPlotSummary(summary)
    ? summary.metadata.plot.id
    : summary.metadata.station.id

  const maybeLinkProps = isNotRenderingSummary
    ? {}
    : {
        to: isPlotSummary(summary)
          ? `/farms/${org.id}/plots/${summary.metadata.plot.id}/${
              universe === Universe.irrelis ? ('irrelis' as const) : ''
            }`
          : `/farms/${org.id}/stations/${summary.metadata.station.id}`
      }

  const outerBoxStyle = useMemo(
    () => ({
      ...style,
      padding: redesign ? '0' : '8px',
      paddingTop: redesign ? '0' : '4px',
      paddingBottom: redesign ? '0' : '4px',
      height:
        hasNoItemsMatchingSearchTerm || hasAlreadyFetchedAndHasNoItems ? 'unset' : style?.height
    }),
    [style, redesign, hasNoItemsMatchingSearchTerm, hasAlreadyFetchedAndHasNoItems]
  )

  const handleMouseEnter = useCallback(() => {
    if (!isNotRenderingSummary) {
      dispatch({
        type: 'setHoverIntent',
        newHoverIntent: {
          itemId: currentItemId,
          source: 'list'
        }
      })
    }
  }, [isNotRenderingSummary, dispatch, currentItemId])

  return (
    // The outer box is needed otherwise card margin won't properly apply, and we need to pass style given by the list
    <Box style={outerBoxStyle}>
      <MaybeLink {...maybeLinkProps}>
        {isNotRenderingSummary ? (
          <RowCard onMouseEnter={handleMouseEnter}>{content}</RowCard>
        ) : (
          content
        )}
      </MaybeLink>
    </Box>
  )
})

const useListHeight = () => {
  const [height, setHeight] = useState<number | undefined>()
  const [elements, setElements] = useState<{
    overlayContainer: null | HTMLElement
    listHeader: null | HTMLElement
  }>({
    overlayContainer: null,
    listHeader: null
  })
  const overlayContainerSizes = useResizeObserver({
    ref: elements.overlayContainer
  })
  const listHeaderSizes = useResizeObserver({
    ref: elements.listHeader
  })

  useEffect(() => {
    if (!isNil(overlayContainerSizes.height) && !isNil(listHeaderSizes.height)) {
      // TODO: find a way to remove this paddingBottom, something is fucked up in the global layout
      const paddingBottomToAvoidScrollHeightGreaterThanScreenHeight = 32
      const newHeight =
        overlayContainerSizes.height -
        listHeaderSizes.height -
        paddingBottomToAvoidScrollHeightGreaterThanScreenHeight
      setHeight(newHeight)
    }
  }, [overlayContainerSizes.height, listHeaderSizes.height])

  // Only defined after mount
  useEffect(() => {
    setElements({
      overlayContainer: document.getElementById(elementIds.OverlayContainer),
      listHeader: document.getElementById(elementIds.ListHeader)
    })
  }, [])

  return useDebounce(height, 300)
}

interface PlotListProps {
  availableHorizonOptions: HorizonOpt[]
  currentItems: PlotOrStationSummary[]
  focusedHorizon: number | null
  hasAlreadyFetchedAndHasNoItems: boolean
  hasNoItemsMatchingSearchTerm: boolean
  isError: boolean
  isExpertOrg: boolean
  isLoading: boolean
  myPreferredUnits: RawMyProfileUnits | undefined
  onHorizonChange: (newHorizon: number | null) => void
  summariesQueryCacheKey: QueryKey
}

const List: FC<
  Pick<
    PlotListProps,
    | 'isError'
    | 'isExpertOrg'
    | 'isLoading'
    | 'currentItems'
    | 'hasAlreadyFetchedAndHasNoItems'
    | 'hasNoItemsMatchingSearchTerm'
    | 'summariesQueryCacheKey'
    | 'focusedHorizon'
    | 'myPreferredUnits'
    | 'availableHorizonOptions'
    | 'onHorizonChange'
  > & { listRef: RefObject<FixedSizeList<ListItemData>> }
> = ({
  isError,
  isExpertOrg,
  isLoading,
  currentItems,
  hasAlreadyFetchedAndHasNoItems,
  hasNoItemsMatchingSearchTerm,
  myPreferredUnits,
  summariesQueryCacheKey,
  availableHorizonOptions,
  focusedHorizon,
  onHorizonChange,
  listRef
}) => {
  const height = useListHeight()
  const { focusedMetricId } = useUniverseBackgroundMapContext()
  const itemData: ListItemData = {
    isLoading,
    isError,
    isExpertOrg,
    currentItems,
    hasAlreadyFetchedAndHasNoItems,
    hasNoItemsMatchingSearchTerm,
    myPreferredUnits,
    summariesQueryCacheKey,
    availableHorizonOptions,
    focusedHorizon,
    focusedMetric: focusedMetricId,
    onHorizonChange
  }

  const itemSize =
    (!isNil(focusedMetricId)
      ? isHydricStress(focusedMetricId) || focusedMetricId === 'HDEF'
        ? HYDRIC_PLOT_CARD_HEIGHT
        : DEFAULT_PLOT_CARD_HEIGHT
      : DEFAULT_PLOT_CARD_HEIGHT) + 8

  return !isNil(height) ? (
    <FixedSizeList
      height={height}
      ref={listRef}
      itemCount={currentItems.length}
      itemSize={itemSize}
      width='100%'
      itemData={itemData}
    >
      {Row}
    </FixedSizeList>
  ) : null
}

/** @deprecated remove with redesign */
const PlotList: FC<PlotListProps> = ({
  availableHorizonOptions,
  currentItems,
  focusedHorizon,
  hasAlreadyFetchedAndHasNoItems,
  hasNoItemsMatchingSearchTerm,
  isError,
  isExpertOrg,
  isLoading,
  myPreferredUnits,
  onHorizonChange,
  summariesQueryCacheKey
}) => {
  const ref = useRef<FixedSizeList<ListItemData>>(null)

  const { hoverIntent } = useBackgroundMapContext()

  useEffect(
    () => {
      if (!isNil(hoverIntent.itemId) && hoverIntent.source === 'marker' && !isNil(ref.current)) {
        const currentIdx = currentItems.findIndex((aSummary) =>
          isPlotSummary(aSummary)
            ? aSummary.metadata.plot.id === hoverIntent.itemId
            : aSummary.metadata.station.id === hoverIntent.itemId
        )
        if (currentIdx !== -1) {
          ref.current.scrollToItem(currentIdx, 'center')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hoverIntent.itemId, hoverIntent.source]
  )

  return (
    <ListContainer>
      <PlotListHeader />
      <List
        isError={isError}
        isExpertOrg={isExpertOrg}
        isLoading={isLoading}
        currentItems={currentItems}
        hasAlreadyFetchedAndHasNoItems={hasAlreadyFetchedAndHasNoItems}
        hasNoItemsMatchingSearchTerm={hasNoItemsMatchingSearchTerm}
        myPreferredUnits={myPreferredUnits}
        summariesQueryCacheKey={summariesQueryCacheKey}
        availableHorizonOptions={availableHorizonOptions}
        focusedHorizon={focusedHorizon}
        onHorizonChange={onHorizonChange}
        listRef={ref}
      />
    </ListContainer>
  )
}

export default memo(PlotList)
