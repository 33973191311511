import { enums, schemas, useClient, useQuery } from '@weenat/client'
import { PossibleError } from '@weenat/client/dist/errors'
import * as zodSchemas from '@weenat/client/dist/zod-schemas'
import { useIntl } from '@weenat/wintl'
import { useNavigate, useSearchParams } from 'app/routx-router'
import AuthCard from 'app/src/authentication/components/AuthCard'
import GoToLoginWithEmail from 'app/src/authentication/components/GoToLoginWithEmail'
import useToasts from 'app/src/hooks/useToasts'
import useWishedNetworkFromUrl from 'app/src/hooks/useWishedNetworkFromUrl'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'
import SubmitButton from 'app/src/kit/SubmitButton'
import SuperForm from 'app/src/kit/SuperForm'
import Text from 'app/src/kit/Text'
import CountryPickerField from 'app/src/kit/fields/CountryPickerField'
import EmailField from 'app/src/kit/fields/EmailField'
import FirstNameField from 'app/src/kit/fields/FirstNameField'
import HasAcceptedTermsOfServiceField from 'app/src/kit/fields/HasAcceptedTermsOfServiceField'
import IsSubscribedToNewsletterField from 'app/src/kit/fields/IsSubscribedToNewsletterField'
import LastNameField from 'app/src/kit/fields/LastNameField'
import PasswordField from 'app/src/kit/fields/PasswordField'
import TelephoneFields from 'app/src/kit/fields/TelephoneFields'
import { useToken } from 'app/state'
import { logNavigateOnRegistration } from 'app/utils/analytics'
import { FormikProps, useFormikContext } from 'formik'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useEffect, useMemo, useRef, useState } from 'react'
import { z } from 'zod'

export const settings = {
  search: zodSchemas.email
    .merge(zodSchemas.wishedNetwork)
    .merge(z.object({ emailInvitation: zodSchemas.emailInvitation.optional() }))
    // TODO: maybe the marketing want more
    .merge(zodSchemas.knownUtmParams),
  public: 'only'
}

const REGISTER_FORM_SCHEMA = schemas.auth.register
type RegisterFormValues = typeof REGISTER_FORM_SCHEMA.initialValues

const RegisterFields = ({
  isLoading,
  errorState
}: {
  isLoading: boolean
  errorState: PossibleError | null
}) => {
  const { values, setFieldValue } = useFormikContext<RegisterFormValues>()

  const sharedFieldsProps = { $isDisabled: isLoading, errorState }

  useEffect(() => {
    if (values.country) {
      setFieldValue('telephoneCountryCode', values.country)
    }
  }, [values.country])

  return (
    <>
      <Flex $gap='md'>
        <Box $flex={1}>
          <FirstNameField {...sharedFieldsProps} />
        </Box>
        <Box $flex={1}>
          <LastNameField {...sharedFieldsProps} />
        </Box>
      </Flex>
      <EmailField {...sharedFieldsProps} />
      <PasswordField {...sharedFieldsProps} />
      <CountryPickerField name='country' />
      <TelephoneFields {...sharedFieldsProps} errorPath='telephoneNumber' />
      <Box $width='100%' $mt='lg'>
        <HasAcceptedTermsOfServiceField {...sharedFieldsProps} />
        <IsSubscribedToNewsletterField {...sharedFieldsProps} />
      </Box>
    </>
  )
}

const Register = () => {
  const { t } = useIntl()
  const client = useClient()
  const [searchParams] = useSearchParams(settings.search)
  const { addToast } = useToasts()
  const [, setToken] = useToken()
  const nav = useNavigate()
  const [registerState, setRegisterState] = useState<'loading' | 'success' | 'error' | 'idle'>(
    'idle'
  )
  const [registerError, setRegisterError] = useState<PossibleError | null>(null)

  const { emailInvitation } = searchParams
  const hasEmailInvitation = !isNil(emailInvitation)

  const formRef = useRef<FormikProps<RegisterFormValues>>(null)

  const wishedNetwork = useWishedNetworkFromUrl() || searchParams?.wishedNetwork

  const networkRequest = useQuery(client.networks.get(wishedNetwork?.networkId), {
    enabled: !isNil(wishedNetwork?.networkId)
  })

  const networkName = !isNil(networkRequest.data) ? networkRequest.data.name : undefined

  const startOnboardingWithWishedNetwork = (orgId: number) =>
    nav(`/farms/${orgId}/onboarding/welcome`, { search: { networkName } })

  const startOnboardingWithAcceptedInvitation = (
    orgId: number,
    invite: NonNullable<typeof emailInvitation>
  ) => nav(`/farms/${orgId}/onboarding/welcome`, { search: { invite } })

  const startClassicOnboarding = (orgId: number) => nav(`/farms/${orgId}/onboarding/welcome`)

  /** We want the email from the state (to handle login/register switch with same email), not from the emailInvitation */
  const email = !isNil(searchParams?.email) ? searchParams?.email : ''

  const initialValues = useMemo(
    () => ({
      email,
      firstName: isNil(emailInvitation?.firstName) ? '' : emailInvitation?.firstName,
      lastName: isNil(emailInvitation?.lastName) ? '' : emailInvitation?.lastName,
      country: isNil(emailInvitation?.country) ? 'FR' : emailInvitation?.country,
      telephoneCountryCode: isNil(emailInvitation?.phone?.country)
        ? 'FR'
        : emailInvitation?.phone?.country,
      telephoneNumber: isNil(emailInvitation?.phone?.number) ? '' : emailInvitation?.phone?.number
    }),
    [email, emailInvitation]
  )

  const onSubmit = async (values: RegisterFormValues) => {
    const { telephoneCountryCode, telephoneNumber, ...valuesWithoutPhone } = values

    try {
      setRegisterState('loading')
      const registerResult = await client.auth
        .register({
          utm_campaign: searchParams.utm_campaign,
          utm_source: searchParams.utm_source,
          utm_medium: searchParams.utm_medium,
          utm_term: searchParams.utm_term,
          utm_content: searchParams.utm_content
        })
        .request(!isEmpty(telephoneNumber.trim()) ? values : valuesWithoutPhone)

      setToken(registerResult.token)

      try {
        const ownedOrgs = await client.orgs.getAllPages({ myRole: enums.OrgRole.owner }).request()
        const firstOwnedOrg = ownedOrgs.at(0)

        if (!isNil(firstOwnedOrg) && !isNil(firstOwnedOrg.id)) {
          if (hasEmailInvitation) {
            setRegisterState('success')
            startOnboardingWithAcceptedInvitation(firstOwnedOrg.id, emailInvitation)
          } else if (wishedNetwork) {
            await client.invitations.beg.networkMembership(wishedNetwork?.networkId).request({
              organizationId: firstOwnedOrg.id,
              trial: wishedNetwork.trial
            })

            setRegisterState('success')
            startOnboardingWithWishedNetwork(firstOwnedOrg.id)
          } else {
            setRegisterState('success')
            startClassicOnboarding(firstOwnedOrg.id)
          }
        }
      } catch (error) {
        setRegisterState('error')
      }
    } catch (e) {
      setRegisterState('error')
      setRegisterError(e)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const emailError: string | undefined = e.fieldErrors?.email?.[0]?.message
      if (!isNil(emailError)) addToast(emailError, { appearance: 'error' })
    }
  }
  useEffect(() => {
    logNavigateOnRegistration()
  }, [])

  return (
    <AuthCard>
      <Box>
        <Text $fontWeight='bold' $fontSize='md' $textAlign='center'>
          {t('models.account.actions.register')}
        </Text>
        <SuperForm
          schema={REGISTER_FORM_SCHEMA}
          onSubmit={onSubmit}
          initialValues={initialValues}
          formRef={formRef}
        >
          <RegisterFields isLoading={registerState === 'loading'} errorState={registerError} />
          <SubmitButton
            style={{ width: '100%' }}
            isDisabled={registerState === 'loading'}
            isLoading={registerState === 'loading'}
            isError={registerState === 'error'}
            isSuccess={registerState === 'success'}
          >
            {t('models.account.actions.register')}
          </SubmitButton>
        </SuperForm>

        <DelimitedFlex
          $isDelimitedOnTop
          $alignItems='stretch'
          $justifyContent='stretch'
          $pt='lg'
          $mt='lg'
        >
          <GoToLoginWithEmail
            style={{ width: '100%' }}
            isDisabled={registerState === 'loading'}
            email={
              !isNil(formRef) && !isNil(formRef.current) ? formRef.current.values.email : undefined
            }
            wishedNetwork={
              !isNil(wishedNetwork)
                ? {
                    ...wishedNetwork,
                    networkName: networkName as string
                  }
                : undefined
            }
          />
        </DelimitedFlex>
      </Box>
    </AuthCard>
  )
}

export const Component = Register
export default Register
