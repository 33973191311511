import { Slot } from 'app/routx-router'
import AuthenticatedLayout from 'app/src/layouts/AuthenticatedLayout'
import MenuLayout from 'app/src/layouts/MenuLayout'
import ProtectedRoute from 'app/src/layouts/ProtectedRoute'
import PublicLayout from 'app/src/layouts/PublicLayout'
import { useToggleFeature } from 'app/state'

const HelpLayout: FC = () => {
  const [{ redesign }] = useToggleFeature()

  return (
    <ProtectedRoute>
      <AuthenticatedLayout>
        {redesign ? (
          <MenuLayout>
            <Slot />
          </MenuLayout>
        ) : (
          <PublicLayout>
            <Slot />
          </PublicLayout>
        )}
      </AuthenticatedLayout>
    </ProtectedRoute>
  )
}
export const Component = HelpLayout
export default HelpLayout
