import { getDeviceCommonName } from '@weenat/client/dist/core/devices'
import { Device } from '@weenat/client/dist/resources/devices'
import { Head } from 'app/routx-router'
import DeviceSummary from 'app/src/dashboard/components/weatherMap/summary/DeviceSummary'
import withClientDevice from 'app/src/hocs/withClientDevice'
import logEvent from 'app/utils/analytics'
import { useEffect } from 'react'

const DeviceScreen: FC<{ device: Device }> = ({ device }) => {
  const commonName = getDeviceCommonName(device)

  useEffect(() => {
    logEvent('device_summary_page')
  }, [])

  return (
    <>
      <Head title={commonName} />
      <DeviceSummary device={device} />
    </>
  )
}

export const Component = withClientDevice()(DeviceScreen)
export default Component
