import type { SVGProps } from 'react'
const SvgDiscretePlotMarker = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={36} height={36} {...props}>
    <rect
      width={34}
      height={34}
      x={1}
      y={1}
      stroke={props.color ?? '#fff'}
      strokeLinecap='round'
      strokeWidth={2}
      rx={17}
      ry={17}
      style={{
        paintOrder: 'fill markers stroke'
      }}
    />
    <path
      fill='#fff'
      d='M17.999 8.789c-1.532 0-2.878.557-4.042 1.67-1.164 1.114-1.746 2.61-1.746 4.488 0 1.202.434 2.494 1.303 3.877.868 1.383 2.182 2.882 3.943 4.497a.857.857 0 0 0 .252.149.8.8 0 0 0 .29.057.79.79 0 0 0 .29-.057.868.868 0 0 0 .255-.15c1.76-1.614 3.074-3.114 3.943-4.496.868-1.383 1.303-2.675 1.303-3.877 0-1.877-.583-3.374-1.747-4.487C20.88 9.346 19.531 8.789 18 8.789zm0 3.157A2.106 2.106 0 1 1 18 16.157a2.106 2.106 0 0 1-.001-4.21zm-6.314 7.896c-.4 0-.764.225-.943.581l-2.63 5.265a1.054 1.054 0 0 0 .94 1.523h17.895a1.053 1.053 0 0 0 .942-1.523l-2.63-5.265a1.053 1.053 0 0 0-.943-.581h-1.052a22.355 22.355 0 0 1-1.822 2.105h2.224l1.578 3.16H10.756l1.579-3.16h2.224a22.346 22.346 0 0 1-1.822-2.105z'
    />
  </svg>
)
export default SvgDiscretePlotMarker
