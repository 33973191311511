import Text from '../Text'

const PanelSectionTitle: FC<{ className?: string }> = ({ children, className }) => {
  return (
    <Text
      $fontSize='sm'
      $fontWeight='medium'
      $color={'grayscale.700'}
      $lineHeight='none'
      className={className}
    >
      {children}
    </Text>
  )
}

export default PanelSectionTitle
