import { metricsWithGradient, YDomain } from '@weenat/client/dist/core/charts/charts'
import { getColorScalePerMetric } from '@weenat/client/dist/core/measurements'
import { scaleLinear } from 'd3-scale'
import isEmpty from 'lodash-es/isEmpty'
import isEqual from 'lodash-es/isEqual'
import isNil from 'lodash-es/isNil'
import { memo, useMemo } from 'react'
import { useTheme } from 'styled-components'
import type { VictoryLineProps, VictoryScatterProps } from 'victory'
import { VictoryLine, VictoryScatter } from 'victory'

interface MetricLineProps extends VictoryScatterProps {
  absoluteYDomain: YDomain
  metric: 'T' | 'T_SOIL' | 'T_DRY' | 'T_WET' | 'T_CAPA' | 'T_CAL' | 'U' | 'FF' | 'HPOT' | 'SSI'
  color?: string
  useGradient?: boolean
  getGradient?: () => { gradientStart: number; gradientEnd: number }
  scatterComponent?: VictoryScatterProps['dataComponent']
}

const stops = [0, 50, 60, 100]
const Label = <></>

/**
 * Displays lines and extremums area for temperature related metrics
 * @deprecated remove with chartWithD3 flag
 */
const MetricLine: FC<MetricLineProps> & { role: string } = memo<MetricLineProps>(
  ({ metric, absoluteYDomain, color, getGradient, scatterComponent, data, ...props }) => {
    const useGradient = metricsWithGradient.includes(metric) && isNil(color)
    const { colors } = useTheme()
    const linearGradientId = `${metric}-gradient`
    const finalColor = useGradient
      ? `url(#${linearGradientId})`
      : !isNil(color)
        ? color
        : colors.metrics[metric]['500']

    const lineStyle: VictoryLineProps['style'] = useMemo(
      () => ({
        data: {
          strokeLinejoin: 'round',
          strokeLinecap: 'round',
          stroke: finalColor,
          strokeWidth: 3,
          fill: 'none'
        }
      }),
      [finalColor]
    )

    const scatterStyle: VictoryScatterProps['style'] = useMemo(
      () => ({
        data: {
          fill: finalColor
        }
      }),
      [finalColor]
    )

    const colorScale = getColorScalePerMetric(metric)

    const scaleOffsetToTemp = useMemo(
      () => scaleLinear().domain([100, 0]).range(absoluteYDomain),
      [absoluteYDomain]
    )
    const { gradientEnd, gradientStart } = useGradient
      ? getGradient?.() || { gradientEnd: 0, gradientStart: 0 }
      : { gradientEnd: 0, gradientStart: 0 }

    const scatterData = useMemo(() => {
      return !isNil(scatterComponent)
        ? data
        : data?.filter((dataPoint, index) => {
            let keepValue = false

            if (!isNil(dataPoint.value) && index !== 0 && index !== data.length - 1) {
              keepValue = isNil(data[index - 1]?.value) && isNil(data[index + 1])
            }

            return keepValue
          })
    }, [data, scatterComponent])

    return (
      <>
        {useGradient && (
          <defs>
            <linearGradient
              id={linearGradientId}
              x1='0%'
              y1={`${gradientStart}%`}
              x2='0%'
              y2={`${gradientEnd}%`}
              gradientUnits='userSpaceOnUse'
            >
              {stops.map((offset) => (
                <stop
                  key={`${linearGradientId}-${offset}`}
                  offset={`${offset}%`}
                  stopColor={colorScale(absoluteYDomain)(scaleOffsetToTemp(offset))}
                />
              ))}
            </linearGradient>
          </defs>
        )}

        <VictoryLine {...props} data={data} style={lineStyle} labelComponent={Label} />
        {!isEmpty(scatterData) ? (
          <VictoryScatter
            {...props}
            data={scatterData}
            dataComponent={scatterComponent}
            style={scatterStyle}
            size={3}
          />
        ) : null}
      </>
    )
  },
  (pp, np) => {
    const { scale: _2, ...ppNoScale } = pp
    const { scale: _1, ...npNoScale } = np
    return isEqual(ppNoScale, npNoScale)
  }
)

//this role is use inside victory to check for child that can handle events
MetricLine.role = 'group'

export default MetricLine
