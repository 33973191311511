import { useChartCtx } from '@weenat/client/dist/core/charts'
import { SURFACE_POWER_THRESHOLD } from '@weenat/client/dist/core/history'
import { useIntl } from '@weenat/wintl'
import * as d3 from 'd3'

const RECT_HEIGHT = 16
const RECT_PADDING = 4
const RECT_PADDING_X = 6

const FONT_SIZE = 12

interface SolarRadiationThresholdLineProps {
  normalizeFct: ((point: number | null) => number | null) | ((pt: d3.NumberValue) => d3.NumberValue)
  denormalizeFct:
    | ((point: number | null) => number | null)
    | ((pt: d3.NumberValue) => d3.NumberValue)
}

/**
 * Displays lines and area for Solar Radiation
 */
const SolarRadiationThresholdLine = ({
  normalizeFct,
  denormalizeFct
}: SolarRadiationThresholdLineProps) => {
  const { t } = useIntl()
  const { yScale, xScale } = useChartCtx()

  const label = `${SURFACE_POWER_THRESHOLD} ${t('units.watts_per_square_meter_abbr')}`

  const thresholdYPos = yScale(normalizeFct(SURFACE_POWER_THRESHOLD)!)
  const [firstPointXPos, lastPointXPos] = xScale.range()
  const maxNumberValue = denormalizeFct(yScale.domain()[1])
  const max = typeof maxNumberValue === 'number' ? maxNumberValue : maxNumberValue?.valueOf()

  return (max ?? 0) > SURFACE_POWER_THRESHOLD ? (
    <>
      <defs>
        <filter x='-10%' y='-10%' width='120%' height='120%' id='solid'>
          <feFlood floodColor='#E05252' result='bg' />
          <feMerge>
            <feMergeNode in='bg' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
      </defs>

      <line
        strokeWidth={2}
        stroke={'#E05252'}
        x1={firstPointXPos}
        x2={lastPointXPos}
        y1={thresholdYPos}
        y2={thresholdYPos}
      />
      <g
        transform={`translate(${lastPointXPos - RECT_PADDING_X},${thresholdYPos + RECT_HEIGHT + RECT_PADDING})`}
      >
        <text
          textAnchor='end'
          style={{ fontFamily: 'Inter', fontSize: FONT_SIZE, fontWeight: 'bold', fill: 'white' }}
          filter='url(#solid)'
        >
          {label}
        </text>
      </g>
    </>
  ) : null
}

export default SolarRadiationThresholdLine
