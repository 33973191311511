import { useExternalConnectionsError } from '@weenat/client/dist/core/externalConnectionsError'
import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import useDisclosure from 'app/src/hooks/useDisclosure'
import Button from 'app/src/kit/Button'
import Modal from 'app/src/kit/Modal'
import Text from 'app/src/kit/Text'
import Flex from 'app/src/kit/primitives/Flex'
import { useAcquittedExternalErrors } from 'app/state'
import isNil from 'lodash-es/isNil'
import React from 'react'

const ExternalConnectionsErrorMessage: React.FC = () => {
  const { t } = useIntl()
  const [acquittedExternalErrors, setAcquittedExternalErrors] = useAcquittedExternalErrors()
  const org = useOrgContext()
  const currentOrgId = org.id

  const { close, isOpen } = useDisclosure(true)
  const { isLoading, currentError } = useExternalConnectionsError(
    currentOrgId,
    acquittedExternalErrors
  )

  return !isLoading && !isNil(currentError) && isOpen ? (
    <Modal isOpen={isOpen} close={close} title={''}>
      <Text>{currentError.errorMessage}</Text>
      <Flex $mt='lg' $justifyContent='end'>
        <Button
          onPress={() => {
            setAcquittedExternalErrors([...acquittedExternalErrors, currentError._id])
          }}
        >
          {t('actions.understand')}
        </Button>
      </Flex>
    </Modal>
  ) : null
}

export default ExternalConnectionsErrorMessage
