import { HorizonOpt } from '@weenat/client/dist/core/horizons'
import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import { useCurrentUnivMetric, useUniverseHorizon } from 'app/state'
import logEvent from 'app/utils/analytics'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { memo, useEffect } from 'react'
import { styled, useTheme } from 'styled-components'
import ControlsContainer from '../ControlsContainer'
import UniverseHorizonOptions from './UniverseHorizonOptions'

const RULER_WIDTH = 48

const Ruler = styled(ControlsContainer)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${(p) => p.theme.radiuses.md}px;
  background-color: ${(p) => p.theme.colors.grayscale[50]};
  width: ${RULER_WIDTH}px;
  padding: 0;
  overflow: hidden;
`

const DepthLabel = styled(Text)`
  position: absolute;
  -webkit-text-stroke: 1px ${(p) => p.theme.colors.grayscale.black};
  bottom: 0;
  left: -24px;
  transform-origin: top left;
  transform: rotate(-90deg);
`

/** @deprecated remove with redesign */
const UniverseHorizonSelector: FC<{ availableHorizonOptions: HorizonOpt[] }> = ({
  availableHorizonOptions
}) => {
  const { t } = useIntl()
  const { colors } = useTheme()

  const currentMetric = useCurrentUnivMetric()
  const [currentHorizonId, setCurrentHorizonId] = useUniverseHorizon()

  const depthUnit = t('units.centimeters_abbr')

  // initializing horizon if value is null
  useEffect(() => {
    if (isNil(currentHorizonId) && !isEmpty(availableHorizonOptions)) {
      logEvent('horizon_changed')
      const [firstOpt] = availableHorizonOptions
      setCurrentHorizonId(firstOpt?.value)
    }
  }, [availableHorizonOptions])

  return currentMetric === 'HPOT' ? (
    <Flex $position='relative' $alignItems='flex-end'>
      <DepthLabel $textAlign='left' $fontWeight='black' $fontSize='rg' $color='grayscale.50'>
        {t('models.device.fields.horizon.label', { capitalize: true })}
      </DepthLabel>
      <Ruler>
        <UniverseHorizonOptions
          currentHorizon={currentHorizonId}
          availableHorizonOptions={availableHorizonOptions}
          onHorizonChange={(value) => setCurrentHorizonId(value)}
        />
        <Box $py='sm' $px='md' $backgroundColor={colors.grayscale.black} $width='100%'>
          <Text
            $fontWeight='bold'
            $fontSize='sm'
            $color={colors.grayscale.white}
            $textAlign='center'
          >
            {depthUnit}
          </Text>
        </Box>
      </Ruler>
    </Flex>
  ) : null
}

export default memo(UniverseHorizonSelector)
