import { useClient, useMutation } from '@weenat/client'
import useDisplayParams from '@weenat/client/dist/hooks/useDisplayParams'
import { DisplayPreferences } from '@weenat/client/dist/resources/preferences'
import { WeenatColor } from '@weenat/theme'
import { useIntl } from '@weenat/wintl'
import { useSearchParams } from 'app/routx-router'
import useDisclosure from 'app/src/hooks/useDisclosure'
import Icons from 'app/src/kit/Icons'
import Link from 'app/src/kit/LinkComponent'
import PillTabs from 'app/src/kit/PillTabs'
import Text from 'app/src/kit/Text'
import {
  Dropdown,
  DropdownContent,
  DropdownTrigger
} from 'app/src/kit/fields/Select/SelectDropdown'
import { TogglePrimitive } from 'app/src/kit/fields/ToggleField'
import PanelSection from 'app/src/kit/panels/PanelSection'
import { displaySettingsSearchParamsSchema } from 'app/state'
import { styled } from 'styled-components'
const SelectedFilterBox = styled(Box)`
  border: 0px solid ${(p) => p.theme.colors.grayscale[300]};
  border-left-width: 1px;
  padding: ${(p) => p.theme.spacings.md}px;
`

const DisplaySettings: FC = () => {
  const { t } = useIntl()
  const client = useClient()
  const { isOpen, toggle, close } = useDisclosure()
  const [searchParams, setSearchParams] = useSearchParams(displaySettingsSearchParamsSchema)
  const selectedColor: WeenatColor = 'grayscale.white'
  const selectedBackgroundColor: WeenatColor = 'primary.500'

  const displayPreferences = useDisplayParams()
  const { onlyFavorites, hideEmptyDataPOD, kind } = displayPreferences

  const hasFilterActive = kind !== 'all' || onlyFavorites || hideEmptyDataPOD

  const [updatePreferences] = useMutation(client.preferences.update())

  const handleDispatch = (displayParam: Partial<DisplayPreferences>) => {
    updatePreferences({
      display: { ...displayPreferences, ...displayParam }
    })
  }

  return (
    <Dropdown isVisible={isOpen} onVisibleChange={() => (isOpen ? close() : open())}>
      <DropdownTrigger>
        <Flex
          $alignItems='center'
          $gap='md'
          $backgroundColor='grayscale.white'
          $color='grayscale.black'
          $borderColor='grayscale.300'
          $borderRadius='sm'
          $pl={hasFilterActive ? 'md' : undefined}
          $px={!hasFilterActive ? 'md' : undefined}
          $borderWidth={1}
          onClick={toggle}
          $cursor='pointer'
        >
          <Flex $flex={1} $alignItems='center' $gap='md' $py='sm' $my='xs'>
            <Icons.Tune $size='lg' />
            <Text $fontWeight='bold'>{t('map_settings.view.title')}</Text>
          </Flex>
          {hasFilterActive ? (
            <Flex $alignItems='center' $ml='md'>
              {kind === 'plots' ? (
                <SelectedFilterBox>
                  <Icons.PlotsFilled $size='md' className='display-settings-filter-icon' />
                </SelectedFilterBox>
              ) : null}
              {kind === 'stations' ? (
                <SelectedFilterBox>
                  <Icons.SingleSensorFilled $size='md' className='display-settings-filter-icon' />
                </SelectedFilterBox>
              ) : null}
              {onlyFavorites ? (
                <SelectedFilterBox>
                  <Icons.Star $size='md' className='display-settings-filter-icon' />
                </SelectedFilterBox>
              ) : null}
              {hideEmptyDataPOD ? (
                <SelectedFilterBox>
                  <Icons.ExploreNearbyOff $size='md' className='display-settings-filter-icon' />
                </SelectedFilterBox>
              ) : null}
            </Flex>
          ) : null}
        </Flex>
      </DropdownTrigger>
      <DropdownContent>
        <PanelSection title={t('map.views.label')}>
          <PillTabs
            options={[
              { label: t('map.views.map'), value: 'map' },
              { label: t('map.views.list'), value: 'list' }
            ]}
            value={searchParams.view ?? 'map'}
            onChange={(newView) => setSearchParams({ view: newView })}
            $selectedColor={'grayscale.black'}
            $isCentered={false}
          />
        </PanelSection>
        <PanelSection title={t('map.views.elements_title')}>
          <Flex $flexDirection='column' $mt='md' $gap='sm'>
            <Flex
              $alignItems='center'
              $justifyContent='space-between'
              $borderRadius='md'
              $borderWidth={1}
              $borderColor='grayscale.100'
              $p='md'
              $backgroundColor={kind === 'all' ? selectedBackgroundColor : undefined}
              onClick={() => handleDispatch({ kind: 'all' })}
              $cursor='pointer'
            >
              <Text $fontWeight='semiBold' $color={kind === 'all' ? selectedColor : undefined}>
                {t('lists.filters_all_male')}
              </Text>
              <Flex $alignItems='center' $gap='sm'>
                <Icons.PlotsFilled $color={kind === 'all' ? selectedColor : undefined} $size='md' />
                <Icons.SingleSensorFilled
                  $color={kind === 'all' ? selectedColor : undefined}
                  $size='md'
                />
              </Flex>
            </Flex>
            <Flex
              $alignItems='center'
              $justifyContent='space-between'
              $borderRadius='md'
              $borderWidth={1}
              $borderColor='grayscale.100'
              $p='md'
              $backgroundColor={kind === 'plots' ? selectedBackgroundColor : undefined}
              onClick={() => handleDispatch({ kind: 'plots' })}
              $cursor='pointer'
            >
              <Text $fontWeight='semiBold' $color={kind === 'plots' ? selectedColor : undefined}>
                {t('models.plot.model.name')}
              </Text>
              <Icons.PlotsFilled $color={kind === 'plots' ? selectedColor : undefined} $size='md' />
            </Flex>
            <Flex
              $alignItems='center'
              $justifyContent='space-between'
              $borderRadius='md'
              $borderWidth={1}
              $borderColor='grayscale.100'
              $p='md'
              $backgroundColor={kind === 'stations' ? selectedBackgroundColor : undefined}
              onClick={() => handleDispatch({ kind: 'stations' })}
              $cursor='pointer'
            >
              <Text $fontWeight='semiBold' $color={kind === 'stations' ? selectedColor : undefined}>
                {t('models.agro_weather_device.model.name')}
              </Text>
              <Icons.SingleSensorFilled
                $color={kind === 'stations' ? selectedColor : undefined}
                $size='md'
              />
            </Flex>
          </Flex>
        </PanelSection>
        <PanelSection title={t('map.views.options_title')}>
          <Flex $flexDirection='column' $gap='lg'>
            <Flex $alignItems='center' $justifyContent='space-between' $gap='md'>
              <Flex $alignItems='center' $gap='md'>
                <Icons.ExploreNearbyOff $size='md' />
                <Text>{t('map.hide_empty_plots')}</Text>
              </Flex>
              <TogglePrimitive
                value={hideEmptyDataPOD}
                onToggle={() => handleDispatch({ hideEmptyDataPOD: !hideEmptyDataPOD })}
              />
            </Flex>
            <Flex $alignItems='center' $justifyContent='space-between' $gap='md'>
              <Flex $alignItems='center' $gap='md'>
                <Icons.Star $size='md' />
                <Text>{t('map.only_favorite')}</Text>
              </Flex>
              <TogglePrimitive
                value={onlyFavorites}
                onToggle={() => handleDispatch({ onlyFavorites: !onlyFavorites })}
              />
            </Flex>
          </Flex>
        </PanelSection>

        <Flex $justifyContent='center' $p='lg'>
          <Link $underlined to='plots/archived'>
            {t('map.display_archived_plot')}
          </Link>
        </Flex>
      </DropdownContent>
    </Dropdown>
  )
}

export default DisplaySettings
