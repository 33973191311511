import { Placement } from '@floating-ui/react'
import { useIntl } from '@weenat/wintl'
import { isNil } from 'lodash-es'
import styled, { useTheme } from 'styled-components'
import useDisclosure from '../hooks/useDisclosure'
import Button from '../kit/Button'
import { IconProps } from '../kit/Icon'
import Icons from '../kit/Icons'
import Modal from '../kit/Modal'
import Text from '../kit/Text'
import TextTooltip from '../kit/tooltips/TextTooltip'

interface Props {
  tooltipPlacement?: Placement
  variant?: 'button' | 'icon'
  label?: string
  iconProps?: IconProps
}

interface MarkerModalProps {
  isOpen: boolean
  close: () => void
}

interface LegendMarkerProps {
  value?: number
  icon?: React.ReactNode
  shape: 'square' | 'circle'
  bgColor?: string
}

interface LegendSectionProps {
  title: string
  description: string
}

const LegendContainer = styled(Flex)<{
  $isEmpty: boolean
  $backgroundColor?: string
  $shape: LegendMarkerProps['shape']
}>`
  background-color: ${(p) =>
    !isNil(p.$backgroundColor) ? p.$backgroundColor : p.theme.colors.grayscale['100']};
  border-width: ${(p) => p.theme.borderWidths.rg}px;
  border-color: ${(p) => p.theme.colors.grayscale.white};
  border-style: solid;
  box-shadow: 0px 2px 8px #00223510;
  border-radius: ${(p) =>
    p.$shape === 'square' ? p.theme.radiuses.md : p.theme.radiuses.rounded}px;
  height: ${(p) => p.theme.spacings['3xl']}px;
  width: ${(p) => p.theme.spacings['3xl']}px;
  padding: ${(p) => p.theme.spacings.md}px;
  justify-content: center;
  align-items: center;
`

const LegendMarker: FC<LegendMarkerProps> = ({ value, shape, icon, bgColor }) => {
  const { colors } = useTheme()
  const { t } = useIntl()
  const markerShapeLegend =
    shape === 'square'
      ? t('models.device.model.singular_name', { capitalize: true })
      : t('models.plot.model.singular_name', { capitalize: true })
  return (
    <Flex $flexDirection='column' $gap='sm'>
      <LegendContainer $isEmpty={isNil(value)} $shape={shape} $backgroundColor={bgColor}>
        {isNil(icon) ? (
          <Text
            $fontWeight='bold'
            $color={isNil(value) ? colors.grayscale.black : colors.grayscale.white}
          >
            {isNil(value) ? '-' : value}
          </Text>
        ) : (
          <>{icon}</>
        )}
      </LegendContainer>
      <Text $fontSize='sm' $color={colors.grayscale['700']} $textAlign='center'>
        {markerShapeLegend}
      </Text>
    </Flex>
  )
}

const LegendSection: FC<LegendSectionProps> = ({ title, description, children }) => {
  return (
    <Flex $flexDirection='column' $p='xl' $gap='md' $flex={1} $flexBasis='50%'>
      <Flex $gap='lg'>{children}</Flex>
      <Flex $flexDirection='column' $gap='sm'>
        <Text $fontWeight='regular'>{title}</Text>
        <Text>{description}</Text>
      </Flex>
    </Flex>
  )
}

const MarkerModal: FC<MarkerModalProps> = ({ isOpen, close }) => {
  const { t } = useIntl()
  const { colors } = useTheme()

  return (
    <Modal
      isOpen={isOpen}
      title={t('map.legend_modal.title')}
      hasCloseIcon={true}
      close={close}
      width={720}
      $noInnerPadding
    >
      <Flex $flexWrap='wrap'>
        <LegendSection
          title={t('map.legend_modal.markers_section.no_data.title')}
          description={t('map.legend_modal.markers_section.no_data.description')}
        >
          <LegendMarker
            icon={<Icons.SingleSensorFilled $color={colors.grayscale.white} $size='lg' />}
            shape='square'
            bgColor={colors.grayscale.black}
          />
          <LegendMarker
            icon={<Icons.PlotsFilled $color={colors.grayscale.white} $size='lg' />}
            shape='circle'
            bgColor={colors.grayscale.black}
          />
        </LegendSection>
        <LegendSection
          title={t('map.legend_modal.markers_section.with_data.title')}
          description={t('map.legend_modal.markers_section.with_data.description')}
        >
          <LegendMarker value={67} shape='square' bgColor={colors.metrics.U[500]} />
          <LegendMarker value={67} shape='circle' bgColor={colors.metrics.U[500]} />
        </LegendSection>
        <LegendSection
          title={t('map.legend_modal.markers_section.empty.title')}
          description={t('map.legend_modal.markers_section.empty.description')}
        >
          <LegendMarker shape='square' />
          <LegendMarker shape='circle' />
        </LegendSection>
        <LegendSection
          title={t('map.legend_modal.markers_section.needs_config.title')}
          description={t('map.legend_modal.markers_section.needs_config.description')}
        >
          <LegendMarker icon={<Icons.Wrench $size='lg' />} shape='circle' />
        </LegendSection>
      </Flex>
    </Modal>
  )
}

const MarkerLegend = ({ tooltipPlacement, variant = 'icon', label, iconProps }: Props) => {
  const { t } = useIntl()
  const { isOpen, open, close } = useDisclosure()

  const finalLabel = t('map.show_legend') ?? label
  return (
    <>
      <MarkerModal isOpen={isOpen} close={close} />
      {variant === 'icon' && (
        <TextTooltip content={finalLabel} placement={tooltipPlacement}>
          <Icons.NotListedLocation onPress={open} $size={'xl'} $m='sm' $p='xs' {...iconProps} />
        </TextTooltip>
      )}
      {variant === 'button' && (
        <Button importance='sd' IconRight={Icons.NotListedLocation}>
          {finalLabel}
        </Button>
      )}
    </>
  )
}

export default MarkerLegend
