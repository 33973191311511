import { TKey, useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import { useNavigate } from 'app/routx-router'
import useDisclosure from 'app/src/hooks/useDisclosure'
import { useTheme } from 'styled-components'
import { RestrictionReason } from '../hooks/useIsAppAccessRestricted'
import useLogAndNavToUpgradePlans from '../hooks/useLogAndNavToUpgradePlans'
import Button from './Button'
import IconTextCard from './IconTextCard'
import Icons from './Icons'
import Link from './LinkComponent'
import { ControlledModalOverlay } from './ModalOverlay'
import Text from './Text'

interface LockedFeatProps {
  title: TKey
  titleOptions?: Record<string, string>
  body: TKey
  showCTA?: boolean
  reason?: RestrictionReason
}

const LockedFeat: FC<LockedFeatProps> = ({
  title,
  body,
  showCTA = false,
  children,
  reason,
  titleOptions
}) => {
  const { t } = useIntl()
  const logAndNav = useLogAndNavToUpgradePlans()
  const nav = useNavigate()
  const { colors } = useTheme()
  const isFreemium = reason === 'freemium'
  const org = useOrgContext()
  const currentOrgId = org.id

  const contentColor = isFreemium ? colors.grayscale.white : undefined
  return (
    <IconTextCard
      Icon={Icons.Lock}
      titleMsgKey={title}
      titleOptions={titleOptions}
      bodyMsgKey={body}
      backgroundColor={isFreemium ? colors.primary[500] : undefined}
      borderColor={isFreemium ? colors.primary[800] : undefined}
      contentColor={contentColor}
    >
      {showCTA ? (
        <Flex $mt='lg' width='100%' $alignItems='center' $justifyContent='center'>
          <Button
            IconRight={Icons.ArrowRight}
            onPress={isFreemium ? logAndNav : () => nav(`/farms/${currentOrgId}/admin/billing`)}
            backgroundColor={isFreemium ? 'grayscale.white' : undefined}
            color={isFreemium ? 'grayscale.black' : undefined}
          >
            {t(isFreemium ? 'actions.i_subscribe' : 'actions.go_to_billing')}
          </Button>
        </Flex>
      ) : null}
      {children}
    </IconTextCard>
  )
}

export const LockedCarouselValueModal: FC<
  { reason: RestrictionReason } & Pick<ReturnType<typeof useDisclosure>, 'isOpen' | 'close'>
> = ({ reason, isOpen, close }) => {
  const { colors } = useTheme()
  const { t } = useIntl()
  const nav = useNavigate()

  return (
    <ControlledModalOverlay isOpen={isOpen} close={close}>
      {reason === 'freemium' ? (
        <LockedFeat
          title='ecommerce.plans.restrictions.carousel_value_freemium_title'
          body='ecommerce.plans.restrictions.carousel_value_freemium_message'
          reason={reason}
        >
          <Flex $flexDirection='column' $gap='lg' $alignItems='center' $mt='lg' $width='100%'>
            <Button
              backgroundColor={colors.grayscale.white}
              onPress={() => {
                nav('/discover/sensors')
              }}
            >
              <Flex $alignItems='center'>
                <Text $color={colors.grayscale.black} $fontWeight='semiBold'>
                  {t('ecommerce.plans.restrictions.carousel_value_freemium_cta')}
                </Text>
                <Icons.ArrowRight $size='lg' />
              </Flex>
            </Button>
            <Link
              $underlined
              to='/discover'
              $color={colors.grayscale.white}
              $fontWeight='semiBold'
              $textAlign='center'
            >
              {t('ecommerce.plans.restrictions.carousel_value_freemium_link')}
            </Link>
          </Flex>
        </LockedFeat>
      ) : (
        <LockedFeat
          title='ecommerce.plans.restrictions.carousel_value_downgrade_title'
          body='ecommerce.plans.restrictions.carousel_value_downgrade_message'
          showCTA={true}
          reason={reason}
        />
      )}
    </ControlledModalOverlay>
  )
}

export default LockedFeat
