import { useOrgContext } from 'app/orgProvider'
import { Redirect, useParams } from 'app/routx-router'
import React from 'react'

export const Component: React.FC = () => {
  const { plotId } = useParams()
  const org = useOrgContext()
  return <Redirect to={`/farms/${org.id}/plots/${plotId}`} replace />
}

export default Component
