import { FormattedDataPoint } from '@weenat/client/dist/core/charts/charts'
import find from 'lodash-es/find'
import isNil from 'lodash-es/isNil'
import { styled } from 'styled-components'
import WindDirectionIcon from '../../icons/WindDirectionIcon'

const iconDimension = 24

const IconDirectionContainer = styled.foreignObject`
  position: relative;
  z-index: -1;
`

interface ChartWindDirectionIconProps {
  x?: number
  y?: number
  datum?: { value?: number; timestamp: number }
  windDirectionData: FormattedDataPoint[]
}

/** @deprecated remove with chartWithD3 flag */
const ChartWindDirectionIcon: FC<ChartWindDirectionIconProps> = ({
  x = 0,
  y = 0,
  datum: { timestamp } = {},
  windDirectionData
}) => {
  const windDirectionPoint = find(windDirectionData, ({ timestamp: ts }) => ts === timestamp)

  if (windDirectionPoint && !isNil(windDirectionPoint.value)) {
    return (
      <IconDirectionContainer
        width={iconDimension}
        height={iconDimension}
        transform={`translate(${x - iconDimension / 2}, ${y - iconDimension / 2})`}
      >
        <WindDirectionIcon
          windOrigin={windDirectionPoint.value}
          $color={'metrics.FF.500'}
          $size='sm'
        />
      </IconDirectionContainer>
    )
  }
  return null
}

export default ChartWindDirectionIcon
