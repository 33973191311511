import { colorByUniv, Universe } from '@weenat/client/dist/core/universes'
import { useIntl } from '@weenat/wintl'
import { labelByUniv } from '../myFarm/tools/UniverseSelector'
import { IconProps } from './Icon'
import Icons from './Icons'
import UniverseIcons from './icons/UniverseIcons'
import TextTooltip from './tooltips/TextTooltip'

const StackedUniverses: FC<{ universes: Universe[]; withTooltipLabel?: boolean }> = ({
  universes,
  withTooltipLabel = false
}) => {
  const { t } = useIntl()
  return (
    <Flex>
      {universes.map((universe, idx) => {
        const Icon: FC<IconProps> = UniverseIcons[universe] ?? Icons.Bug
        const universeColor = colorByUniv[universe]
        const universeLabel = t(labelByUniv[universe])

        const universeBadge = (
          <Box
            $borderRadius={'md'}
            $boxShadow='sm'
            key={universe}
            $zIndex={universes.length - idx}
            $ml={-8}
            style={{ borderRadius: '100%' }}
          >
            <Icon
              $color={'grayscale.white'}
              $size='md'
              $backgroundColor={universeColor.backgroundColor}
              $borderColor={universeColor.borderColor}
              $p='sm'
              style={{ borderRadius: '100%' }}
            />
          </Box>
        )

        if (withTooltipLabel) {
          return (
            <TextTooltip key={universe} content={universeLabel}>
              {universeBadge}
            </TextTooltip>
          )
        } else {
          return <>{universeBadge}</>
        }
      })}
    </Flex>
  )
}

export default StackedUniverses
