import { MutationRequestState } from '@weenat/client/dist/mergeState'
import { WeenatColor, fromColorPathToColor } from '@weenat/theme'
import isNil from 'lodash-es/isNil'
import { darken, transparentize } from 'polished'
import { CSSProperties, MouseEventHandler, ReactNode, forwardRef, useEffect, useState } from 'react'
import { css, styled } from 'styled-components'
import { IconProps } from './Icon'
import Icons from './Icons'
import LoadingCircle from './loaders/LoadingCircle'

const BORDER_WIDTH = 1

type Importance = 'pm' | 'sd' | 'tt'

export type ButtonProps = {
  backgroundColor?: WeenatColor
  $border?: string
  className?: string
  color?: WeenatColor
  IconRight?: FC<IconProps>
  IconLeft?: FC<IconProps>
  importance?: Importance
  isDanger?: boolean
  isDisabled?: boolean
  /** use state props for client related feedback */
  isError?: boolean
  /** use state props for client related feedback */
  isLoading?: boolean
  /** use state props for client related feedback */
  isSuccess?: boolean
  /** To add an Icon please use the IconLeft props */
  leftAdornment?: React.ReactElement | null
  onPress?: MouseEventHandler
  /** To add an Icon please use IconRight props */
  rightAdornment?: React.ReactElement | null
  /** the state of a request for client related feedback */
  state?: MutationRequestState & { reset?: () => void }
  type?: 'submit' | 'reset' | 'button'
  variant?: 'default' | 'small'
  children?: ReactNode
  style?: CSSProperties
}

type StyledButtonProps = Omit<ButtonProps, 'color' | 'backgroundColor' | 'variant'> & {
  $color?: string
  $outlined: boolean
  $backgroundColor: string
  $variant?: 'default' | 'small'
}

const StyledButton = styled('button').withConfig({
  shouldForwardProp: (prop) => !['backgroundColor', '$color', '$variant'].includes(prop)
})<StyledButtonProps>`
  display: inline-block;
  border: none;
  border-radius: ${({ theme, $variant }) =>
    $variant === 'small' ? theme.radiuses.md : theme.radiuses.xl}px;
  padding: ${({ $variant }) => ($variant === 'small' ? '8px' : '0.5rem 1.5rem')};
  text-decoration: none;
  user-select: none;
  text-align: center;
  line-height: normal;
  font-family: ${(p) => p.theme.typography.families.pm};
  font-size: ${(p) => p.theme.typography.sizes.rg}px;
  font-weight: ${(p) => p.theme.typography.weights.regular};
  cursor: pointer;
  white-space: nowrap;
  background-color: ${(p) => p.$backgroundColor};
  min-height: ${({ $variant }) => ($variant === 'small' ? '24px' : '42px')};
  ${({ $outlined, $color }) =>
    $outlined &&
    css`
      color: ${$color};
      -webkit-box-shadow: inset 0px 0px 0px ${BORDER_WIDTH}px ${$color};
      -moz-box-shadow: inset 0px 0px 0px ${BORDER_WIDTH}px ${$color};
      box-shadow: inset 0px 0px 0px ${BORDER_WIDTH}px ${$color};
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
  ${({ $color }) =>
    !isNil($color) &&
    css`
      color: ${$color};
    `}
    ${({ disabled, $color, $backgroundColor, $outlined }) =>
    !disabled &&
    // TODO: maybe we should remove this check & removhave a default color
    !isNil($color) &&
    css`
      &:hover {
        background-color: ${$outlined ? transparentize(0.9, $color) : $backgroundColor};
        -webkit-box-shadow:
          inset 0px 0px 0px ${BORDER_WIDTH * 2}px
            ${darken(0.1, $outlined ? $color : $backgroundColor)},
          0 2px 2px 0 rgb(0 0 0 / 12%);
        -moz-box-shadow:
          inset 0px 0px 0px ${BORDER_WIDTH * 2}px
            ${darken(0.1, $outlined ? $color : $backgroundColor)},
          0 2px 2px 0 rgb(0 0 0 / 12%);
        box-shadow:
          inset 0px 0px 0px ${BORDER_WIDTH * 2}px
            ${darken(0.1, $outlined ? $color : $backgroundColor)},
          0 2px 2px 0 rgb(0 0 0 / 12%);
      }
    `}
  will-change: background-color, box-shadow;
  transition:
    background-color 0.25s ease-in-out,
    box-shadow 0.25s ease-in-out;
`

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      backgroundColor: $backgroundColor,
      $border,
      children,
      className,
      color,
      IconLeft,
      IconRight,
      importance = 'pm',
      isDanger = false,
      isDisabled = false,
      isError = false,
      isLoading = false,
      isSuccess = false,
      leftAdornment,
      onPress,
      rightAdornment,
      state,
      type = 'button',
      variant = 'default',
      ...props
    },
    forwardedRef
  ) => {
    const [shouldShowStateFromProps, setShouldShowStateFromProps] = useState(true)

    const isErrorFinal = shouldShowStateFromProps && (isError || state?.isError)
    const isLoadingFinal = isLoading || state?.isPending
    const isDisabledFinal = isDisabled || isLoadingFinal
    const isSuccessFinal = shouldShowStateFromProps && (isSuccess || state?.isSuccess)

    const hasLeftAdornment =
      !isNil(IconLeft) || !isNil(leftAdornment) || isLoadingFinal || isSuccessFinal
    const hasRightAdornment = !isNil(IconRight) || !isNil(rightAdornment)

    const initialBackground: WeenatColor =
      isErrorFinal || isDanger
        ? 'feedback.error.500'
        : isSuccessFinal
          ? 'feedback.success.500'
          : isDisabledFinal
            ? 'grayscale.300'
            : 'primary.500'

    const finalBackgroundColor = fromColorPathToColor(
      !isNil($backgroundColor)
        ? $backgroundColor
        : importance === 'sd'
          ? 'transparent'
          : initialBackground
    )

    const contentColor = fromColorPathToColor(
      !isNil(color)
        ? color
        : importance === 'sd'
          ? isErrorFinal || isDanger
            ? 'feedback.error.500'
            : isSuccessFinal
              ? 'feedback.success.500'
              : isDisabledFinal
                ? 'grayscale.300'
                : 'primary.500'
          : 'grayscale.white'
    )

    const iconProps: Partial<IconProps> = {
      $size: 'md',
      $color: contentColor
    }

    useEffect(() => {
      if (state) {
        setShouldShowStateFromProps(true)
        const timer = setTimeout(() => setShouldShowStateFromProps(false), 1500)
        return () => {
          if (!isNil(timer)) {
            clearTimeout(timer)
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.isError, state?.isSuccess])

    return (
      <StyledButton
        $backgroundColor={finalBackgroundColor}
        $color={contentColor}
        $border={$border}
        disabled={isDisabledFinal}
        onClick={onPress}
        type={type}
        className={className}
        $outlined={importance === 'sd'}
        $variant={variant}
        ref={forwardedRef}
        {...props}
      >
        <Flex $width='100%' $alignItems='center' $justifyContent='center' $gap={'sm'}>
          {hasLeftAdornment && (
            <Box>
              {isLoadingFinal ? (
                <LoadingCircle color={fromColorPathToColor(contentColor)} />
              ) : isSuccessFinal ? (
                <Icons.CheckCircle {...iconProps} />
              ) : !isNil(IconLeft) ? (
                <IconLeft {...iconProps} />
              ) : (
                <>{leftAdornment}</>
              )}
            </Box>
          )}
          {children}
          {hasRightAdornment ? (
            <Box>{!isNil(IconRight) ? <IconRight {...iconProps} /> : <>{rightAdornment}</>}</Box>
          ) : null}
        </Flex>
      </StyledButton>
    )
  }
)

export default Button
