import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import noop from 'lodash-es/noop'
import { LegacyRef, createContext, useContext, useEffect, useState } from 'react'
import { useMeasure } from 'react-use'
import { css, styled, useTheme } from 'styled-components'
import { RestrictionReason } from '../../hooks/useIsAppAccessRestricted'
import Icons from '../Icons'
import Link from '../LinkComponent'
import LoadingChart from '../LoadingChart'
import LockedFeat from '../LockedFeat'

const ChartTitle = styled(Text)`
  padding: 8px 16px;
  align-self: flex-start;
  font-weight: ${(p) => p.theme.typography.weights.medium};
`

const ChartFlexContainer = styled(Flex)<{ $height?: number | string }>`
  height: ${(props) =>
    !isNil(props.$height)
      ? Number.isFinite(props.$height)
        ? `${props.$height}px`
        : props.$height
      : '50vh'};
  padding: 0 8px;
  flex-direction: column;
`

const EmptyChartContainer = styled(Box)<{ $isEmpty: boolean }>`
  height: 100%;
  width: 100%;

  ${(p) =>
    p.$isEmpty &&
    css`
      border: 1px solid ${p.theme.colors.grayscale[300]};
      border-radius: ${p.theme.radiuses.md}px;
      background-color: ${p.theme.colors.grayscale[100]};
    `}
`

interface ChartWrapperProps {
  /** Forces the chart height */
  height?: number | string
  /** Whether or not the data set is empty */
  isEmpty: boolean
  /** whether or not the chart data is loading */
  isLoading: boolean
  /** chart title */
  title?: string
  /** is app access restricted because of plan or unpaid invoice */
  isRestricted?: boolean
  /** reason of restriction */
  reason?: RestrictionReason
}

export const ChartWrapperContext = createContext<{
  dimension?: { width: number; height: number }
  setDimension: (newDimension: { width: number; height: number }) => void
}>({ dimension: undefined, setDimension: noop })

const ChartWrapperCtxProvider: FC = ({ children }) => {
  const [dimension, setDimension] = useState<{ width: number; height: number } | undefined>()
  return (
    <ChartWrapperContext.Provider value={{ dimension, setDimension }}>
      {children}
    </ChartWrapperContext.Provider>
  )
}

const divStyle = { width: '100%', height: '100%' }

const ChartContainer: FC<Pick<ChartWrapperProps, 'isLoading' | 'title' | 'height'>> = ({
  children,
  title,
  isLoading,
  height: heightFromProps
}) => {
  const { setDimension } = useContext(ChartWrapperContext)
  const [containerRef, { width, height: measuredHeight }] = useMeasure()
  const height =
    isNil(heightFromProps) || typeof heightFromProps === 'string' ? measuredHeight : heightFromProps
  useEffect(() => {
    setDimension({ height, width })
  }, [height, width])
  return (
    <ChartFlexContainer $height={heightFromProps}>
      <div style={divStyle} ref={containerRef as LegacyRef<HTMLDivElement>}>
        {/* Showing title if its not in fullscreen and if titling isn't handle by Expandable */}
        {!isNil(title) && <ChartTitle>{title}</ChartTitle>}
        {!isNil(height) && !isLoading ? (
          children
        ) : (
          <LoadingChart height='100%' uniqueKey='genericChart' />
        )}
      </div>
    </ChartFlexContainer>
  )
}

/**
 * Component wrapping Charts and handling common logic such as loading, empty states
 * @deprecated remove with chartWithD3 flag
 */
const ChartWrapper: FC<ChartWrapperProps> = ({
  children,
  title,
  isEmpty,
  isLoading,
  height,
  isRestricted,
  reason
}) => {
  const { t } = useIntl()
  const { colors } = useTheme()
  return (
    <ChartWrapperCtxProvider>
      <EmptyChartContainer $isEmpty={isEmpty && !isLoading && !isRestricted}>
        <ChartContainer isLoading={isLoading} title={title} height={height}>
          {!isEmpty ? (
            children
          ) : (
            <>
              {isRestricted ? (
                <Box $mt={16}>
                  <LockedFeat
                    title={
                      reason === 'freemium'
                        ? 'ecommerce.plans.restrictions.history_freemium_title'
                        : 'ecommerce.plans.restrictions.history_downgrade_title'
                    }
                    body={
                      reason === 'freemium'
                        ? 'ecommerce.plans.restrictions.history_freemium_message'
                        : 'ecommerce.plans.restrictions.carousel_value_downgrade_message'
                    }
                    reason={reason}
                    showCTA
                  >
                    {reason === 'freemium' && (
                      <Flex $flex={1} $justifyContent='center' $mt={12}>
                        <Link
                          to='/discover/application/history'
                          $color={colors.grayscale.white}
                          $underlined
                        >
                          {t('ecommerce.plans.restrictions.history_freemium_link')}
                        </Link>
                      </Flex>
                    )}
                  </LockedFeat>
                </Box>
              ) : (
                <Flex
                  $height='100%'
                  $width='100%'
                  $flexDirection='column'
                  $alignItems='center'
                  $justifyContent='center'
                >
                  <Icons.BarChart $size='2xl' />
                  <Text $textAlign='center'>{t('charts.no_data')}</Text>
                </Flex>
              )}
            </>
          )}
        </ChartContainer>
      </EmptyChartContainer>
    </ChartWrapperCtxProvider>
  )
}

export default ChartWrapper
