import { useMatch, usePathname } from 'app/routx-router'

const usePlotsOrDevicesFromPathname = () => {
  const pathname = usePathname()
  const matchFarms = useMatch('/farms/*')

  return matchFarms
    ? ('plots' as const)
    : pathname.startsWith('/weather-map')
      ? ('devices' as const)
      : undefined
}

export default usePlotsOrDevicesFromPathname
