import { invalidateQueriesContainingPattern, useClient, useQuery } from '@weenat/client'
import isIrrigationDevice from '@weenat/client/dist/core/devices/isIrrigationDevice'
import { Device } from '@weenat/client/dist/resources/devices'
import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import Button from 'app/src/kit/Button'
import Icons from 'app/src/kit/Icons'
import Modal from 'app/src/kit/Modal'
import SmartText from 'app/src/kit/SmartText'
import Text from 'app/src/kit/Text'
import Flex from 'app/src/kit/primitives/Flex'
import { usePaymentCtx } from 'app/state'
import { useUserContext } from 'app/userProvider'
import isNil from 'lodash-es/isNil'
import { useEffect } from 'react'
import { styled } from 'styled-components'

const SUCCESS_CIRCLE_SIZE = 64
const EMPTY_DEVICES: Device[] = []

const SuccessCircle = styled(Flex)`
  align-items: center;
  justify-content: center;

  width: ${SUCCESS_CIRCLE_SIZE}px;
  height: ${SUCCESS_CIRCLE_SIZE}px;

  border-radius: ${({ theme }) => theme.radiuses.rounded}px;
  background-color: ${({ theme }) => theme.colors.feedback.success['500']};
`

const Success = () => {
  const client = useClient()
  const { t } = useIntl()
  const nav = useNavigate()
  const { me } = useUserContext()

  const [paymentContext, setPaymentContext] = usePaymentCtx()

  const devicesRequest = useQuery(
    client.devices.getMany({
      ownedByOrganizationId: paymentContext.orgId as number,
      sortBy: 'created_at',
      sortOrder: 'desc',
      limit: 1
    }),
    { enabled: paymentContext.from === 'onboarding' && typeof paymentContext.orgId === 'number' }
  )

  const [firstDevice] = devicesRequest.data?.results ?? EMPTY_DEVICES

  const fromOnboardingWithIrrigationDevice =
    paymentContext.from === 'onboarding' && !isNil(firstDevice) && isIrrigationDevice(firstDevice)

  const orgHome = !isNil(paymentContext.orgId)
    ? (`/farms/${paymentContext.orgId}` as const)
    : undefined

  useEffect(() => {
    invalidateQueriesContainingPattern('')

    return () => {
      setPaymentContext({})
    }
  }, [])

  return (
    <Modal
      isOpen
      close={!isNil(orgHome) ? () => nav(orgHome, { replace: true }) : undefined}
      maxWidth={480}
    >
      <Flex
        $flexDirection='column'
        $alignItems='center'
        $justifyContent='space-between'
        $height='100%'
        $gap={48}
      >
        {
          <>
            <Flex $flexDirection='column' $alignItems='center' $gap='lg'>
              <SuccessCircle>
                <Icons.Checkmark $color={'grayscale.white'} $size='lg' />
              </SuccessCircle>

              <Text $fontSize='md' $fontWeight='bold' $textAlign='center'>
                {t('ecommerce.plans.order_confirmed')}
              </Text>
              {!isNil(me) && (
                <SmartText
                  tkey='ecommerce.plans.subscription_order_success_message'
                  vars={{ email: me.email }}
                  $textAlign='center'
                />
              )}
              {paymentContext.from === 'onboarding' ? (
                <>
                  {!isNil(firstDevice) && isIrrigationDevice(firstDevice) ? (
                    <Text $textAlign='center'>{t('onboardings.start_off_with_plot')}</Text>
                  ) : (
                    <Text $textAlign='center'>{t('onboardings.start_off_with_sensor_data')}</Text>
                  )}
                </>
              ) : null}
            </Flex>
            <Flex $alignItems='center' $justifyContent='flex-end' $gap='lg' $width='100%'>
              {fromOnboardingWithIrrigationDevice ? (
                <Button
                  onPress={
                    !isNil(orgHome)
                      ? () => nav(`${orgHome}/plots/create`, { replace: true })
                      : undefined
                  }
                >
                  {t('models.plot.actions.create')}
                </Button>
              ) : (
                <Button
                  onPress={!isNil(orgHome) ? () => nav(orgHome, { replace: true }) : undefined}
                >
                  {t('my_farm.actions.view')}
                </Button>
              )}
            </Flex>
          </>
        }
      </Flex>
    </Modal>
  )
}

export const Component = Success
export default Success
