import { Me, MyProfile } from '@weenat/client/dist/resources/me'
import { createContext, useContext, useMemo } from 'react'

interface IUserContext {
  me: Me
  profile: MyProfile
}

const UserContext = createContext<IUserContext | null>(null)

export function useUserContext() {
  const context = useContext<IUserContext | null>(UserContext)

  if (!context) {
    throw new Error(
      'You may have forgot to render <UserContextProvider /> because UserContext is not defined'
    )
  }

  return context
}

export const UserContextProvider: FC<{ profile: MyProfile; me: Me }> = ({
  profile,
  me,
  children
}) => {
  const value = useMemo(() => {
    return {
      me,
      profile
    }
  }, [me, profile])

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
