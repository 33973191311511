import { ResourceAction } from '@weenat/client/dist/core/actions'
import { getDeviceCommonName } from '@weenat/client/dist/core/devices'
import {
  colorByUniv,
  getAvailableUniversesFromMetrics,
  getMetricsFromSummary,
  Universe
} from '@weenat/client/dist/core/universes'
import {
  DeviceSummary,
  isPlotSummary,
  isStationSummary,
  PlotSummary,
  StationSummary
} from '@weenat/client/dist/resources/measurements.type'
import { Href } from '@weenat/client/dist/routx/runtime-core'
import { TKey, useIntl } from '@weenat/wintl'
import { PlotCardHeader } from 'app/src/dashboard/components/DashboardMap/PlotCard'
import useUniverseOptions from 'app/src/dashboard/components/DashboardMap/universes/useUniverseOptions'
import ActionItem from 'app/src/kit/ActionItem'
import DropdownSelect from 'app/src/kit/DropdownSelect'
import Icons from 'app/src/kit/Icons'
import UniverseIcons from 'app/src/kit/icons/UniverseIcons'
import Modal from 'app/src/kit/Modal'
import Text from 'app/src/kit/Text'
import { isNil } from 'lodash-es'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const NO_BORDER_BOTTOM = { borderBottomWidth: 0 }

export const labelByUniv: Record<Universe, TKey> = {
  [Universe.overview]: 'map.universes.all.overview',
  [Universe.weather]: 'map.universes.all.weather',
  [Universe.irrigation]: 'map.universes.all.irrigation',
  [Universe.frost]: 'map.universes.all.frost',
  [Universe.irrelis]: 'map.universes.all.irrelis'
}

interface UniverseItemProps {
  universe: Universe
  summaryLink: Href
  handleClose: () => void
}

interface UniverseCardSelectorTooltipProps {
  summary: PlotSummary | StationSummary | DeviceSummary
  summaryLink: Href
  handleClick: () => void
  handleClose: () => void
}

interface UniverseCardSelectorModalProps {
  isOpen: boolean
  summary: PlotSummary | StationSummary | DeviceSummary
  summaryLink: Href
  handleClose: () => void
}

const UniverseMenuItem: FC<UniverseItemProps> = ({ universe, summaryLink, handleClose }) => {
  const { t } = useIntl()
  const nav = useNavigate()

  const universeLabel = t(`map.universes.all.${Universe[universe]}`)
  const universeDestination = summaryLink + Universe[universe]
  const universeAction: ResourceAction<string> = {
    id: universeLabel,
    label: universeLabel,
    iconNode: UniverseIcons[universe]({}),
    onPress: () => {
      handleClose()
      nav(universeDestination, { replace: false })
    }
  }

  return <ActionItem action={universeAction} withRightArrow />
}

export const UniverseCardSelectorTooltip: FC<UniverseCardSelectorTooltipProps> = ({
  summary,
  summaryLink,
  handleClick,
  handleClose
}) => {
  const measures = getMetricsFromSummary(summary)
  const availableUniverses = getAvailableUniversesFromMetrics(measures)

  return (
    <Box
      $backgroundColor={'grayscale.white'}
      $borderRadius='lg'
      $borderWidth={1}
      $borderColor='grayscale.300'
      $width={328}
      onClick={!isPlotSummary(summary) ? handleClick : () => null}
    >
      <PlotCardHeader
        name={
          isStationSummary(summary)
            ? summary.metadata.station.name
            : isPlotSummary(summary)
              ? summary.metadata.plot.name
              : getDeviceCommonName(summary.metadata.device)
        }
        reverseGeocoding={
          isStationSummary(summary)
            ? summary.metadata.station.reverseGeocoding
            : isPlotSummary(summary)
              ? summary.metadata.plot.reverseGeocoding
              : summary.metadata.device.reverseGeocoding
        }
        Icon={
          isPlotSummary(summary) ? (
            <Icons.PlotsFilled $size='md' />
          ) : (
            <Icons.SingleSensorFilled $size='md' />
          )
        }
        onClose={handleClose}
        style={!isPlotSummary(summary) ? NO_BORDER_BOTTOM : {}}
      />
      {isPlotSummary(summary) && (
        <Flex
          $py='md'
          $px='sm'
          $flexDirection='row'
          $flexWrap='wrap'
          $gap='md'
          $flex={1}
          style={{ position: 'relative', zIndex: 1 }}
        >
          {!isNil(availableUniverses) &&
            availableUniverses.map((universe) => (
              <UniverseMenuItem
                key={universe}
                universe={universe}
                summaryLink={summaryLink}
                handleClose={handleClose}
              />
            ))}
        </Flex>
      )}
    </Box>
  )
}

export const UniverseCardSelectorModal: FC<UniverseCardSelectorModalProps> = ({
  isOpen,
  summary,
  summaryLink,
  handleClose
}) => {
  const measures = getMetricsFromSummary(summary)
  const availableUniverses = getAvailableUniversesFromMetrics(measures)

  const resourceName = isStationSummary(summary)
    ? summary.metadata.station.name
    : isPlotSummary(summary)
      ? summary.metadata.plot.name
      : getDeviceCommonName(summary.metadata.device)

  const reverseGeocoding = isStationSummary(summary)
    ? summary.metadata.station.reverseGeocoding
    : isPlotSummary(summary)
      ? summary.metadata.plot.reverseGeocoding
      : summary.metadata.device.reverseGeocoding

  const resourceIcon = isPlotSummary(summary) ? (
    <Icons.PlotsFilled $size='md' />
  ) : (
    <Icons.SingleSensorFilled $size='md' />
  )

  const modalTitle = (
    <Flex $gap='md' $p='sm'>
      {resourceIcon}
      <Flex $flexDirection='column' $gap='sm'>
        <Text $fontWeight='bold'>{resourceName}</Text>
        <Text $fontSize='sm' $color={'grayscale.700'} $ellipsis>
          {reverseGeocoding}
        </Text>
      </Flex>
    </Flex>
  )
  return (
    <Modal isOpen={isOpen} title={modalTitle} close={handleClose} $noInnerPadding>
      {isPlotSummary(summary) && (
        <Flex
          $py='md'
          $px='sm'
          $flexDirection='row'
          $flexWrap='wrap'
          $gap='md'
          $flex={1}
          style={{ position: 'relative', zIndex: 1 }}
        >
          {!isNil(availableUniverses) &&
            availableUniverses.map((universe) => (
              <UniverseMenuItem
                key={universe}
                universe={universe}
                summaryLink={summaryLink}
                handleClose={handleClose}
              />
            ))}
        </Flex>
      )}
    </Modal>
  )
}

export interface UniverseSelectorProps {
  universe: Universe
  onChange: (newUniverse: Universe) => void
  availableUniverses: ReturnType<typeof useUniverseOptions>
}

const UniverseSelector: React.FC<UniverseSelectorProps> = ({
  universe,
  onChange,
  availableUniverses
}) => {
  const { t } = useIntl()
  return (
    <DropdownSelect
      options={availableUniverses.map((univ) => {
        const aUniverse = univ.value
        return {
          ...univ,
          ...colorByUniv[univ.value],
          color: 'grayscale.white',
          icon: UniverseIcons[univ.value],
          label: t(labelByUniv[aUniverse] as 'map.universes.all.irrelis')
        }
      })}
      onChange={onChange}
      value={universe}
    />
  )
}

export default UniverseSelector
