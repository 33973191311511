import { useClient, useItemContext, useQuery } from '@weenat/client'
import { ItemAvailableMeasures } from '@weenat/client/dist/ItemContext'
import {
  getLastFullPeriod,
  getMetricsToFetch,
  getPeriodByPeriodId
} from '@weenat/client/dist/core/history'
import { preferredMetricsOrder } from '@weenat/client/dist/core/metrics'
import { useMetricSummaryForecastsData } from '@weenat/client/dist/core/metrics/useMetricSummary'
import { UseMetricSummaryChartInterface } from '@weenat/client/dist/core/metrics/useMetricSummaryChart'
import { getAggregateValue } from '@weenat/client/dist/core/summary'
import TimeSteps from '@weenat/client/dist/enums/TimeSteps'
import { Device } from '@weenat/client/dist/resources/devices'
import { Plot } from '@weenat/client/dist/resources/plots'
import { Station } from '@weenat/client/dist/resources/stations'
import { useParams } from 'app/routx-router'
import isNil from 'lodash-es/isNil'
import moment from 'moment-timezone'
import { memo, useMemo } from 'react'
import ContentLoader from 'react-content-loader'
import MetricSummary from './dailyWeather/MetricSummary'

const timeStep = TimeSteps.perHours

interface MetricsDetailsProps {
  metricsToDisplay?: ItemAvailableMeasures
  chartsToDisplay?: ItemAvailableMeasures
  etpValue?: number | null
  /** Whether or not the current metric is not available in the user current plan */
  restrictedMetrics?: ItemAvailableMeasures
  resource: Plot | Device | Station
  isExpertOrg: boolean
}

const MetricsDetails: FC<MetricsDetailsProps> = ({
  chartsToDisplay = [],
  metricsToDisplay = [],
  etpValue,
  restrictedMetrics = [],
  resource,
  isExpertOrg
}) => {
  const client = useClient()
  const { stationId } = useParams()
  const { kind, id, timezone } = useItemContext()

  const isPlot = kind === 'plot'
  const isDevice = kind === 'device'
  const isStation = kind === 'station'

  const measurementPeriod = getPeriodByPeriodId({ periodId: 'current_day', timezone })

  const metricToPrefetch = getMetricsToFetch(
    chartsToDisplay.includes('T_WET') ? [...chartsToDisplay, 'T_DRY'] : chartsToDisplay
  ).sort()

  const plotMeasurementsRequest = useQuery(
    client.measurements.plots.histories.get1_1({
      plotId: id,
      ...measurementPeriod,
      span: timeStep,
      metrics: metricToPrefetch
    }),
    {
      enabled:
        isPlot && !isNil(measurementPeriod) && measurementPeriod.before !== measurementPeriod.since
    }
  )

  const deviceMeasurementsRequest = useQuery(
    client.measurements.devices.histories.get1_1({
      deviceId: id,
      ...measurementPeriod,
      span: timeStep,
      metrics: metricToPrefetch
    }),
    {
      enabled:
        !isPlot && !isNil(measurementPeriod) && measurementPeriod.before !== measurementPeriod.since
    }
  )

  const prefetch: UseMetricSummaryChartInterface['prefetch'] = {
    isLoading: plotMeasurementsRequest.isLoading || deviceMeasurementsRequest.isLoading,
    measurements: plotMeasurementsRequest.data || deviceMeasurementsRequest.data
  }

  // fetch data on mount, item id change, period change and refresh trigger timestamps change
  const period = {
    span: timeStep,
    ...getLastFullPeriod('day', moment(moment().tz(timezone)).add(1, 'days'), timezone)
  }
  const { since: forecastsSince } = getLastFullPeriod('hour', moment().add(1, 'hours'), timezone)
  const { before: forecastsBefore } = period

  const forecastsPeriod = {
    span: timeStep,
    since: forecastsSince,
    before: forecastsBefore
  }

  const plotSummaryRequest = useQuery(client.measurements.plots.summaries.getOne({ plotId: id }), {
    enabled: isPlot
  })
  const deviceSummaryRequest = useQuery(
    client.measurements.devices.summaries.getOne({ deviceId: id }),
    {
      enabled: isDevice
    }
  )
  const stationSummaryRequest = useQuery(
    client.measurements.stations.summaries.getOne({ stationId }),
    {
      enabled: isStation && !isNil(stationId)
    }
  )

  const isLoading =
    plotSummaryRequest.isLoading ||
    deviceSummaryRequest.isLoading ||
    stationSummaryRequest.isLoading

  const deviceSummary = deviceSummaryRequest.data?.data
  const plotSummary = plotSummaryRequest.data?.data
  const stationSummary = stationSummaryRequest.data?.data
  const summary = plotSummary || deviceSummary || stationSummary

  const metricsByPreferences = useMemo(
    () =>
      metricsToDisplay.sort(
        (a, b) => preferredMetricsOrder.indexOf(a) - preferredMetricsOrder.indexOf(b)
      ),
    [metricsToDisplay]
  )

  const summaryForecastsData = useMetricSummaryForecastsData({
    forecastsPeriod,
    metrics: metricsToDisplay
  })

  const loaders = useMemo(
    () => Array.from({ length: metricsToDisplay.length }, (_, d) => d),
    [metricsToDisplay.length]
  )

  return summary && !isLoading ? (
    <>
      {metricsByPreferences.map((metric) => {
        const latestTimestamp = getAggregateValue(metric, 'latest', summary).timestamp

        return (
          <MetricSummary
            key={metric}
            isRestrictedMetric={restrictedMetrics.includes(metric)}
            summary={summary}
            metric={metric}
            isShowingChart={chartsToDisplay.includes(metric)}
            latestTimestamp={!isNil(latestTimestamp) ? parseInt(latestTimestamp, 10) : undefined}
            resource={resource}
            etpValue={etpValue}
            forecastsResults={summaryForecastsData}
            prefetch={prefetch}
            isExpertOrg={isExpertOrg}
          />
        )
      })}
    </>
  ) : (
    <Box $width='100%'>
      {loaders.map((i) => {
        const key = `metricsDetailsLoading-${i}`
        return <ContentLoader width='100%' height={128} id={key} key={key} uniqueKey={key} />
      })}
    </Box>
  )
}

export default memo(MetricsDetails)
