import { enums, useClient, useMutation, useQuery } from '@weenat/client'
import { Network } from '@weenat/client/dist/resources/networks'
import { Id } from '@weenat/client/dist/resources/types'
import { useIntl } from '@weenat/wintl'
import { useNavigate, usePathname } from 'app/routx-router'
import useDisclosure from 'app/src/hooks/useDisclosure'
import useToasts from 'app/src/hooks/useToasts'
import Button, { ButtonProps } from '../kit/Button'
import RequestNetworkMembershipModal from './RequestNetworkMembershipModal'

interface RequestNetworkMembershipProps {
  network: Network
  buttonProps?: Omit<ButtonProps, 'children' | 'onPress'>
}

const RequestNetworkMembership = ({ network, buttonProps }: RequestNetworkMembershipProps) => {
  const { t } = useIntl()
  const client = useClient()
  const { addSuccessToast, addErrorToast } = useToasts()
  const pathname = usePathname()
  const nav = useNavigate()

  const orgs = useQuery(
    client.orgs.getAllPages({ myRole: [enums.OrgRole.owner, enums.OrgRole.admin] })
  ).data

  const [requestNetworkMembershipMutation, request] = useMutation(
    client.invitations.beg.networkMembership(network.id),
    {
      onSuccess: () => {
        addSuccessToast(
          t('models.network.actions.request_membership_success', {
            network: network.name
          })
        )
        if (pathname.includes('onboarding')) {
          nav('/farms')
        }
      },
      onError: () =>
        addErrorToast(t('models.network.actions.request_membership_error'), {
          appearance: 'error'
        })
    }
  )
  const { open: openModal, close: closeModal, isOpen: isModalOpen } = useDisclosure(false)

  const requestNetworkMembership = (orgId: Id) =>
    requestNetworkMembershipMutation({
      organizationId: orgId,
      // All request are considered as a trial request
      trial: true
    })

  const handleRequestMembership = () => {
    if (orgs?.length === 1) {
      const [firstOrg] = orgs
      requestNetworkMembership(firstOrg.id)
    } else {
      openModal()
    }
  }

  return (
    <>
      <Button onPress={handleRequestMembership} state={request} {...buttonProps}>
        {t('models.network.actions.ask_to_join', { capitalize: true })}
      </Button>
      {isModalOpen && (
        <RequestNetworkMembershipModal
          network={network}
          onSubmit={(orgId) => requestNetworkMembership(orgId)}
          requestStatus={request}
          isOpen={isModalOpen}
          close={closeModal}
        />
      )}
    </>
  )
}

export default RequestNetworkMembership
