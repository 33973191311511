import { useClient } from '@weenat/client'
import { useMatch, usePathname } from 'app/routx-router'
import { useToggleFeature, useToken } from 'app/state'
import { useUserContext } from 'app/userProvider'
import { isNil } from 'lodash-es'
import styled from 'styled-components'
import ExternalConnectionsErrorMessage from '../dashboard/components/common/ExternalConnectionsErrorMessage'
import NavPanel from '../dashboard/components/common/NavPanel'
import BackgroundMap from '../dashboard/components/DashboardMap/BackgroundMap'
import InvitNotificationPresentation from '../kit/InvitNotificationPresentation'
import MapBackgroundImage from '../kit/MapBackgroundImage'
import NavBar from './NavBar'

const ContentContainer = styled(Flex)`
  position: relative;
  align-items: stretch;
  flex: 1;
  overflow: hidden;
  height: 100%;
`

const AuthenticatedLayout: FC = ({ children }) => {
  const [token] = useToken()
  const [{ redesign }] = useToggleFeature()
  const { profile } = useUserContext()
  const client = useClient()

  const pathname = usePathname()

  const authMatch = useMatch('/auth/*')
  const plotCreation = useMatch('/farms/*/plots/create')
  const plotEdition = useMatch('/farms/*/plots/edit')
  const headquarterEdit = useMatch('/farms/*/admin/edit/headquarter')
  const onboarding = useMatch('/farms/*/onboarding/*')

  const farmsMatch = useMatch('/farms/*')
  const adminMatch = useMatch('/farms/*/admin/*')
  const myFarmPathsMatch = !isNil(adminMatch) ? null : farmsMatch
  const weatherMap = useMatch('/weather-map')

  /**
   * There are 5 different cases in which we want to have a background map
   * My farm map (/plots), the weather map (/weather-map), headquarters edition (/administration/{id}/edit/headquarter), plot creation and edition (/create-plot & /plot-edition)
   */
  const isMatchingAMapRoute =
    !isNil(myFarmPathsMatch) ||
    !isNil(weatherMap) ||
    !isNil(plotCreation) ||
    !isNil(plotEdition) ||
    !isNil(headquarterEdit)

  const isAcceptTOS = pathname === '/accept-tos'

  const showNav =
    !isNil(token) &&
    !authMatch &&
    !isAcceptTOS &&
    !plotCreation &&
    !plotEdition &&
    !headquarterEdit &&
    !onboarding

  const getBoundingBoxRequester = !isNil(weatherMap)
    ? client.boundingBoxes.devices.getBoundingBox
    : client.boundingBoxes.plotsAndStations.getBoundingBox

  return (
    <>
      <InvitNotificationPresentation />
      <ExternalConnectionsErrorMessage />
      {redesign ? (
        <Flex $height={'100vh'} $width={'100vw'}>
          {showNav ? <NavPanel /> : null}
          <ContentContainer $pointerEvents='auto'>{children}</ContentContainer>
        </Flex>
      ) : (
        <>
          {!isMatchingAMapRoute ? (
            <MapBackgroundImage />
          ) : (
            <BackgroundMap isPlotsMap={false} getBoundingBoxRequester={getBoundingBoxRequester} />
          )}
          {showNav ? <NavBar key={`navbar_${profile.language}`} /> : null}
          <ContentContainer $pointerEvents={isMatchingAMapRoute ? 'none' : 'auto'}>
            {children}
          </ContentContainer>
        </>
      )}
    </>
  )
}

export default AuthenticatedLayout
