import { ReactNode } from 'react'
import Icon, { IconProps } from '../Icon'

export interface GenericIconProps extends IconProps {
  svg: ReactNode
}

/** Wrapper to generate icons on Records */
const GenericIcon: FC<GenericIconProps> = ({ svg, ...props }) => <Icon {...props}>{svg}</Icon>

export default GenericIcon
