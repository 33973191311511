import { useIntl } from '@weenat/wintl'
import { usePathname } from 'app/routx-router'
import Banner from 'app/src/kit/Banner'
import Text from 'app/src/kit/Text'
import { useUserContext } from 'app/userProvider'

const ShouldVerifyEmail: FC = () => {
  const { t } = useIntl()
  const { me } = useUserContext()
  const pathname = usePathname()

  //check on auth is here to avoid rendering header while begin to join network
  return me.isEmailVerified || pathname.startsWith('/auth') ? null : (
    <Banner $feedback='warn'>
      <Text>{t('auth.email_verification_warning', { email: me.email })}</Text>
    </Banner>
  )
}

export default ShouldVerifyEmail
