import {
  ArticleSlug,
  DiscoverMenuSection,
  getSectionArticlesSlugs,
  getSlugsByCategories,
  sensorUsages
} from '@weenat/client/dist/core/discover-menu'
import { useIntl } from '@weenat/wintl'
import { useNavigate, useParams } from 'app/routx-router'
import DiscoverSectionItem from 'app/src/discover-menu/DiscoverSectionItem'
import useSetKeyToBreadcrumbsCtx from 'app/src/kit/breadcrumbs/useSetKeyToBreadcrumbsCtx'
import Grid from 'app/src/kit/Grid'
import ListEmpty from 'app/src/kit/ListEmpty'
import Scrollable from 'app/src/kit/Scrollable'
import Text from 'app/src/kit/Text'
import logEvent from 'app/utils/analytics'
import isEmpty from 'lodash-es/isEmpty'
import { useCallback } from 'react'

const ArticleGrid: FC = ({ children }) => {
  return (
    <Grid $templateColumns='1fr 1fr 1fr' $templateRows='1fr' $width={'100%'} $gap={'lg'} $mb={'xl'}>
      {children}
    </Grid>
  )
}

const DiscoverSectionScreen: FC = () => {
  const nav = useNavigate()
  const { t, locales } = useIntl()
  const { sectionSlug } = useParams()

  const section = sectionSlug as DiscoverMenuSection

  const slugs = getSectionArticlesSlugs(section, locales.current)
  const wintlSectionPath = `discover_menu.sections.${section}` as const

  const renderSlugs = useCallback(
    (slug: ArticleSlug) => {
      const articlePath = `${wintlSectionPath}.articles.${slug}` as const

      return (
        <DiscoverSectionItem
          key={slug}
          itemType='article'
          cover={t(`${articlePath}.cover_url`)}
          overview={t(`${articlePath}.overview`)}
          title={t(`${articlePath}.title`)}
          onPress={() => {
            logEvent('discover_menu_article', { slug })
            nav(`/discover/${section}/${slug}`)
          }}
          $objectFit={section === 'sensors' ? 'cover' : 'contain'}
        />
      )
    },
    // do not add nav to the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wintlSectionPath, section]
  )

  useSetKeyToBreadcrumbsCtx('section', t(`${wintlSectionPath}.title`))

  return (
    <Scrollable>
      {section !== 'sensors' ? (
        <ArticleGrid>{slugs.map(renderSlugs)}</ArticleGrid>
      ) : (
        sensorUsages.map((usage) => {
          const title = t(`map.universes.all.${usage}`, { capitalize: true })
          const slugsByUsage = getSlugsByCategories(usage)

          return (
            <Box key={usage}>
              <Text $fontWeight='bold' $fontSize='md' $lineHeight='lg'>
                {title}
              </Text>
              {!isEmpty(slugsByUsage) ? (
                <ArticleGrid>{slugsByUsage.map(renderSlugs)}</ArticleGrid>
              ) : (
                <ListEmpty model='article' />
              )}
            </Box>
          )
        })
      )}
    </Scrollable>
  )
}
export const Component = DiscoverSectionScreen
export default DiscoverSectionScreen
