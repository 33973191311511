import TimeSteps from '@weenat/client/dist/enums/TimeSteps'
import { ComponentType, memo } from 'react'
import { useTheme } from 'styled-components'
import {
  VictoryLabelProps,
  VictoryTooltip,
  VictoryTooltipProps,
  VictoryVoronoiContainerProps
} from 'victory'

type TooltipVariant = 'history' | 'summary'

export interface ChartTooltipProps extends VictoryTooltipProps {
  scale?: VictoryVoronoiContainerProps['scale']
  x?: number
  yMin: number
  text?: string[]
  chartTooltipWidth: number
  TooltipContentComponent: ComponentType<{
    width: number
    height: number
  }>
  timeStep: TimeSteps
  timezone: string
  variant: TooltipVariant
}

interface LabelComponentProps extends VictoryLabelProps {
  TooltipContentComponent: ChartTooltipProps['TooltipContentComponent']
  width: number
  height: number
  // props passed by victory so in reallity it's always set but had to make it optionnal because it's set at runtime
  x?: number
  timeStep: TimeSteps
  variant: TooltipVariant
  timezone: string
}

/** @deprecated remove with chartWithD3 flag  */
const LabelComponent: React.FC<LabelComponentProps> = ({
  TooltipContentComponent,
  height,
  width,
  x = 0,
  ...rest
}) => {
  return (
    <foreignObject x={x - width / 2} y={0} width={width} height={height}>
      <TooltipContentComponent {...rest} width={width} height={height} />
    </foreignObject>
  )
}

const TOOLTIP_Y_POSITION = 0
const TOOLTIP_PADDING = 16
const METRIC_LINE_HEIGHT = 36
const DATE_ROW_HEIGHT = METRIC_LINE_HEIGHT + TOOLTIP_PADDING
const BORDER = 4

const getChartTooltipHeight = (nbLineInTooltip: number) => {
  return nbLineInTooltip * METRIC_LINE_HEIGHT + 2 * TOOLTIP_PADDING + DATE_ROW_HEIGHT + BORDER
}

const Line: FC<{
  x?: number
  scale?: { y?: (val: number) => number }
  yMin: number
  nbLineIntTooltip: number
}> = ({ x, scale, yMin, nbLineIntTooltip }) => {
  const { colors } = useTheme()
  const chartTooltipHeight = getChartTooltipHeight(nbLineIntTooltip)
  const bottomTooltip = TOOLTIP_Y_POSITION + chartTooltipHeight
  return (
    <line
      x1={x}
      x2={x}
      y={bottomTooltip}
      stroke={colors.grayscale[700]}
      y1={0}
      y2={scale?.y?.(yMin) ?? 0}
    />
  )
}

/** @deprecated remove with chartWithD3 flag  */
const ChartTooltip: FC<ChartTooltipProps> = memo(
  ({
    scale,
    yMin,
    chartTooltipWidth,
    TooltipContentComponent,
    timeStep,
    variant = 'history',
    timezone,
    activePoints,
    ...props
  }) => {
    const nbLineIntTooltip = props.text?.length ?? 0
    const chartTooltipHeight = getChartTooltipHeight(nbLineIntTooltip)
    let modifier = 1
    if (props.x !== undefined && props.width !== undefined) {
      modifier = props.x >= props.width / 2 ? -1 : 1
    }
    return (
      <VictoryTooltip
        {...props}
        centerOffset={{ x: modifier * (chartTooltipWidth / 2 + 32) }}
        constrainToVisibleArea
        flyoutWidth={chartTooltipWidth}
        flyoutHeight={chartTooltipHeight}
        flyoutComponent={
          <Line nbLineIntTooltip={nbLineIntTooltip} x={props.x} yMin={yMin} scale={scale} />
        }
        labelComponent={
          <LabelComponent
            TooltipContentComponent={TooltipContentComponent}
            width={chartTooltipWidth}
            height={chartTooltipHeight}
            timeStep={timeStep}
            variant={variant}
            timezone={timezone}
          />
        }
      />
    )
  },
  (prev: ChartTooltipProps, next: ChartTooltipProps) => prev.x === next.x
)

export default ChartTooltip
