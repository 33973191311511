import { getUniverseAggs, Universe, UniverseAgg } from '@weenat/client/dist/core/universes'
import { isFrost } from '@weenat/client/dist/enums/MetricIds'
import Text from 'app/src/kit/Text'

import { useIntl } from '@weenat/wintl'
import usePlotsOrDevicesFromPathname from 'app/src/hooks/usePlotsOrDevicesFromPathname'
import { useHandleScreenQueryParamChange } from 'app/src/hooks/useScreenQueryParam'
import Button from 'app/src/kit/Button'
import Pop from 'app/src/kit/Pop'
import { useCurrentUnivMetric, useUniverse, useUniverseAggregate } from 'app/state'
import { logSwitchUnivAgg } from 'app/utils/analytics'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useEffect } from 'react'
import { styled, useTheme } from 'styled-components'
import { controlsContainerStyle } from '../ControlsContainer'

const AggregatesContainer = styled(Flex)`
  ${controlsContainerStyle}
  border-color: ${(p) => p.theme.colors.grayscale.white};
  flex-direction: row;
  border-radius: ${(p) => p.theme.radiuses.md}px;
  justify-content: stretch;
  box-shadow: ${(p) => p.theme.shadows.lg.boxShadow};
  min-width: 420px;
  overflow: hidden;
`

const AggregateTouchable = styled(Button)`
  padding: ${(p) => p.theme.spacings.sm}px ${(p) => p.theme.spacings.md}px;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-radius: ${(p) => p.theme.radiuses.md - 2}px;
`

const styleButton = { borderRadius: 4 } as const

const LastValuesButton: FC<{
  isActive: boolean
  onPress?: () => void
  label: string
  color: string
}> = ({ isActive, onPress, label, color }) => {
  const { colors } = useTheme()
  const backgroundColor = isActive ? color : colors.grayscale[100]
  return (
    <Box>
      <Button
        isDisabled={!isActive}
        backgroundColor={backgroundColor}
        onPress={onPress}
        style={styleButton}
        color={isActive ? colors.grayscale.white : colors.grayscale[700]}
      >
        {label}
      </Button>
    </Box>
  )
}
const measureCircleSize = 8

const UniverseAggregate = () => {
  const [univAgg, setUnivAgg] = useUniverseAggregate()
  const [univ] = useUniverse()
  const currentUnivMetric = useCurrentUnivMetric()
  const plotsOrDevices = usePlotsOrDevicesFromPathname()
  const { t } = useIntl()
  const { colors } = useTheme()

  const aggregates =
    !isNil(univ) && currentUnivMetric ? getUniverseAggs(univ, currentUnivMetric) : []

  const frostAggregates =
    !isNil(currentUnivMetric) && isFrost(currentUnivMetric) && !isNil(aggregates)
      ? [...aggregates].reverse()
      : []

  const areAggregatesEmpty = aggregates && aggregates.length === 0

  const switchAggregate = (agg: UniverseAgg) => {
    if (!isNil(plotsOrDevices)) {
      logSwitchUnivAgg({ action: `${plotsOrDevices}_switch_aggregate`, selected: agg })
    }
    setUnivAgg(agg)
  }

  const resetAggregates = () => {
    if (!isNil(aggregates) && !isEmpty(aggregates)) {
      if (univ === Universe.irrigation) {
        if (currentUnivMetric === 'HPOT' || currentUnivMetric === 'U_CAPA') {
          const [last] = aggregates.slice(-1)
          if (!isNil(last)) setUnivAgg(last)
        } else {
          const [first] = aggregates
          if (!isNil(first)) setUnivAgg(first)
        }
      } else {
        const [first] = aggregates
        if (!isNil(first)) setUnivAgg(first)
      }
    }
  }

  const handleScreenQueryParamChange = useHandleScreenQueryParamChange()

  useEffect(() => {
    !univAgg && resetAggregates()
  }, [univAgg])

  useEffect(() => {
    resetAggregates()
  }, [univ, currentUnivMetric])

  if (!univAgg || !aggregates || areAggregatesEmpty || !currentUnivMetric) return null

  const activeColor = colors.metrics[currentUnivMetric]['500']

  let currentIdx = frostAggregates.indexOf(univAgg)
  const incrementDecrementAgg = (isIncrement: boolean) => {
    if (isIncrement) {
      switchAggregate(frostAggregates[++currentIdx])
    } else {
      switchAggregate(frostAggregates[--currentIdx])
    }
  }

  const couldIncrement = currentIdx + 1 < frostAggregates.length
  const couldDecrement = currentIdx > 0

  return (
    <Pop tourName='discover_universes' stepName='more_data' placement='top'>
      <Pop
        tourName='discover_universes'
        stepName='consult_last_frames'
        placement='top'
        onNext={() => handleScreenQueryParamChange('outro', () => true)}
      >
        {isFrost(currentUnivMetric) ? (
          <AggregatesContainer>
            <Flex $flexDirection='row' $justifyContent={'space-between'} $width='100%'>
              <LastValuesButton
                color={activeColor}
                isActive={couldDecrement}
                onPress={couldDecrement ? () => incrementDecrementAgg(false) : undefined}
                label={t('map.universes.frost.previous_measure')}
              />
              <Flex $justifyContent='space-between' $alignItems='center' $px='md'>
                {frostAggregates.map((a) => (
                  <Box
                    key={a}
                    $width={measureCircleSize}
                    $height={measureCircleSize}
                    $mx='sm'
                    $borderRadius={'rounded'}
                    $backgroundColor={a === univAgg ? activeColor : colors.grayscale[300]}
                  />
                ))}
              </Flex>
              <LastValuesButton
                isActive={couldIncrement}
                onPress={couldIncrement ? () => incrementDecrementAgg(true) : undefined}
                color={activeColor}
                label={t('map.universes.frost.next_measure')}
              />
            </Flex>
          </AggregatesContainer>
        ) : (
          <AggregatesContainer>
            <Flex $flexDirection='row' $justifyContent={'space-between'} $width='100%'>
              {aggregates.map((a) => {
                const isActive = a === univAgg
                const textColor = isActive ? colors.grayscale.white : colors.grayscale.black

                return (
                  <AggregateTouchable
                    key={a}
                    backgroundColor={isActive ? activeColor : colors.grayscale.white}
                    onPress={() => switchAggregate(a)}
                  >
                    <Text
                      $fontSize='rg'
                      $fontWeight='regular'
                      $color={textColor}
                      $textAlign='center'
                    >
                      {t(`map.universes.aggregate.${a}`)}
                    </Text>
                  </AggregateTouchable>
                )
              })}
            </Flex>
          </AggregatesContainer>
        )}
      </Pop>
    </Pop>
  )
}

export default UniverseAggregate
