import { useClient, useQuery } from '@weenat/client'
import { useArvalisClientIdFromOrg } from '@weenat/client/dist/core/dss/waterBalance'
import { Universe } from '@weenat/client/dist/core/universes'
import { BillingStatus } from '@weenat/client/dist/enums'
import useFilteredSummaries from '@weenat/client/dist/hooks/useFilteredSummaries'
import { PlotOrStationSummary } from '@weenat/client/dist/resources/measurements'
import { useOrgContext } from 'app/orgProvider'
import { useSearchParams } from 'app/routx-router'
import { useBackgroundMapContext } from 'app/src/dashboard/components/DashboardMap/contexts/BackgroundMapContext'
import usePODClusters from 'app/src/dashboard/components/DashboardMap/hooks/usePODClusters'
import PlotOrStationList from 'app/src/dashboard/components/DashboardMap/PlotOrStationList'
import PlotsMapOverlay from 'app/src/dashboard/components/DashboardMap/PlotsMapOverlay'
import useUniverseBackgroundMapContext from 'app/src/dashboard/components/DashboardMap/universes/useUniverseBackgroundMapContext'
import FeatGuarded from 'app/src/kit/FeatGuarded'
import DisplaySettings from 'app/src/myFarm/tools/DisplaySettings'
import MyFarmTools from 'app/src/myFarm/tools/MyFarmTools'
import { useToggleFeature, useUniverse, useUniverseHorizon } from 'app/state'
import logEvent from 'app/utils/analytics'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useCallback } from 'react'

// 10 Minutes
// Might need to change when frost universe is active
const REFETCH_INTERVAL = 10 * 60 * 1000
const EMPTY_SUMMARIES: PlotOrStationSummary[] = []

const RedesignedIndex = () => {
  const client = useClient()
  const [universe] = useUniverse()
  const { focusedMetricId } = useUniverseBackgroundMapContext()
  const org = useOrgContext()
  const currentOrgId = org.id

  const isExpertOrg = !isNil(org) ? org.billingStatus === BillingStatus.expert : false
  const [focusedHorizon, setHorizon] = useUniverseHorizon()
  const [searchParams] = useSearchParams()

  const { search } = useBackgroundMapContext()

  const summariesQueryParams = {
    organizationId: currentOrgId,
    universe: universe !== Universe.overview ? universe : undefined
  }

  const clientCode = useArvalisClientIdFromOrg(org)

  const summariesRequester =
    universe === Universe.irrelis
      ? client.waterBalanceArvalis.getSummaries({ orgId: currentOrgId, clientCode })
      : client.measurements.plotsAndStations.summaries.getAllPages(summariesQueryParams)

  const summariesRequest = useQuery(summariesRequester, {
    enabled: !isNil(currentOrgId),
    // Every 10 minutes
    refetchInterval: REFETCH_INTERVAL
  })

  const onHorizonChange = useCallback(
    (newHorizon: number | null): void => {
      logEvent('horizon_changed')
      setHorizon(newHorizon)
    },
    [setHorizon]
  )

  const summaries = summariesRequest.data ?? EMPTY_SUMMARIES

  const hasAlreadyFetchedAndHasNoItems = summariesRequest.isFetched && isEmpty(summaries)

  const { isLoading: isLoading, isError, isFetching } = summariesRequest

  const isListView = 'view' in searchParams && searchParams.view === 'list'
  /**
   * Computing the visible summaries depending on the context
   * If search is active we're filtering the non-matching summaries
   * If the current metric is an irrigation metric we're showing only the current metric summaries
   */

  const { areResultsEmpty, hiddenElementsCount, filteredResults } = useFilteredSummaries({
    universe,
    focusedMetricId,
    summaries,
    search
  })

  usePODClusters({ summaries: filteredResults, isPlotsMap: true, isFetching: isFetching ?? false })

  return (
    <PlotsMapOverlay
      allSummaries={summaries}
      currentItems={filteredResults}
      focusedHorizon={focusedHorizon}
      hasAlreadyFetchedAndHasNoItems={hasAlreadyFetchedAndHasNoItems}
      hasNoItemsMatchingSearchTerm={areResultsEmpty}
      isError={isError}
      isExpertOrg={isExpertOrg}
      isLoading={isLoading}
      onHorizonChange={onHorizonChange}
      summariesQueryCacheKey={summariesRequester.key}
    >
      <FeatGuarded org={org} feat='FEAT-WEATHERMAP-PLOT' mapFallback>
        {isListView ? (
          <>
            <MyFarmTools
              summaries={summaries}
              filteredResults={filteredResults}
              displaySettingsElement={<DisplaySettings />}
              isPlotsMap
            />
            <PlotOrStationList
              allSummaries={summaries}
              currentItems={filteredResults}
              focusedHorizon={focusedHorizon}
              hasAlreadyFetchedAndHasNoItems={hasAlreadyFetchedAndHasNoItems}
              hiddenElementsCount={hiddenElementsCount}
              isError={isError}
              isExpertOrg={isExpertOrg}
              isLoading={isLoading}
              summariesQueryCacheKey={summariesRequester.key}
            />
          </>
        ) : null}
      </FeatGuarded>
    </PlotsMapOverlay>
  )
}

export const Component = () => {
  const [{ redesign }] = useToggleFeature()

  return redesign ? <RedesignedIndex /> : null
}

export default Component
