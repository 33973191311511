import { useIntl } from '@weenat/wintl'
import { ButtonProps } from 'app/src/kit/Button'
import ButtonCreate from 'app/src/kit/ButtonCreate'

const CreateAlertButton: FC<ButtonProps> = ({ onPress, ...props }) => {
  const { t } = useIntl()

  return (
    <ButtonCreate onPress={onPress} {...props}>
      {t('alerts.create')}
    </ButtonCreate>
  )
}

export default CreateAlertButton
