import { Head } from 'app/routx-router'
import Text from 'app/src/kit/Text'
import { Breadcrumb, useToggleFeature } from 'app/state'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { Fragment } from 'react'
import { styled } from 'styled-components'
import Icons from '../Icons'
import Link, { LinkProps } from '../LinkComponent'
import useBreadcrumbs from './useBreadcrumbs'

type BreadcrumbsItemProps = Omit<LinkProps, 'children'> & {
  width: string
}

const BreadcrumbLink = styled(Link)<{ width: string }>`
  max-width: ${({ width }) => `${width}`};

  /* css tricks from https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size this enforce compo to shrink to fit inside parent */
  min-width: 0;
`

const BreadcrumbsItem: FC<BreadcrumbsItemProps> = ({ children, ...props }) => (
  <BreadcrumbLink {...props}>
    <Text $fontSize='sm' $ellipsis>
      {children}
    </Text>
  </BreadcrumbLink>
)

const Breadcrumbs = ({ crumbs }: { crumbs: Breadcrumb[] }) => {
  const [{ redesign }] = useToggleFeature()

  const subtitleCrumbs = !isEmpty(crumbs) && crumbs.length > 1 ? [...crumbs].slice(0, -1) : null

  const titleCrumb = !isEmpty(crumbs) && !isNil(crumbs) ? [...crumbs].pop() : null

  return !isNil(crumbs) && !isEmpty(crumbs) ? (
    <Box $minWidth={0} $flex={1}>
      {!isNil(subtitleCrumbs) ? (
        <Flex
          $alignItems='center'
          $justifyContent='flex-start'
          $width='100%'
          // To align with the left menu title
          $pt={redesign ? undefined : 'md'}
          $gap='sm'
        >
          {subtitleCrumbs.map((crumb) => (
            <Fragment key={crumb.path}>
              <BreadcrumbsItem to={crumb.path} width={`${(1 / subtitleCrumbs.length) * 100}%`}>
                {crumb.label}
              </BreadcrumbsItem>
              <Icons.ChevronLeft $rotate={180} />
            </Fragment>
          ))}
        </Flex>
      ) : null}
      {!isNil(titleCrumb) && !redesign ? (
        <Text $fontSize='lg' $lineHeight='lg' $fontWeight='medium' $ellipsis>
          {titleCrumb.label}
        </Text>
      ) : null}
    </Box>
  ) : null
}

/**
 *
 * this component sync breadcrumbs with html title
 */
export const HTMLPageTitle: FC = () => {
  const breadcrumbs = useBreadcrumbs()

  const screenTitle = !isEmpty(breadcrumbs) && !isNil(breadcrumbs) ? [...breadcrumbs].pop() : null
  return !isNil(screenTitle?.label) ? <Head title={screenTitle?.label} /> : undefined
}

export default Breadcrumbs
