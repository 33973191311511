import { AbsoluteHref } from '@weenat/client/dist/routx/runtime-core'
import useDisclosure from 'app/src/hooks/useDisclosure'
import { isNil } from 'lodash-es'
import { transparentize } from 'polished'
import { ReactNode } from 'react'
import styled from 'styled-components'
import Collapsible from '../Collapsible'
import Icons from '../Icons'
import SidePanelLink, { SidePanelLinkProps } from '../SidePanelLink'
import SidePanelLinkGroup from '../SidePanelLinkGroup'
import PanelSectionTitle from './PanelSectionTitle'

export interface PanelLinkSectionItem extends SidePanelLinkProps {
  to: AbsoluteHref
  id: string
}

interface PanelLinkSectionItemGroup {
  id: string
  mainLink: PanelLinkSectionItem
  subLinks: PanelLinkSectionItem[]
}

export function isPanelLinkSectionItemGroup(
  v: PanelLinkSectionItem | PanelLinkSectionItemGroup
): v is PanelLinkSectionItemGroup {
  return 'mainLink' in v
}

/**
 * Used to render an item - should generally be used unless the content of PanelLinkSection needs to be specific
 */
export function renderPanelLinkSectionItem(
  item: PanelLinkSectionItem | PanelLinkSectionItemGroup,
  onLinkActivation?: ({
    to,
    label,
    description
  }: Pick<PanelLinkSectionItem, 'to' | 'label' | 'description'>) => void
): ReactNode {
  return isPanelLinkSectionItemGroup(item) ? (
    <SidePanelLinkGroup key={item.id} {...item} />
  ) : (
    <SidePanelLink
      key={item.id}
      onActive={
        !isNil(onLinkActivation)
          ? () =>
              onLinkActivation({
                to: item.to,
                label: item.label,
                description: item.description
              })
          : undefined
      }
      {...item}
      LeftIcon={item.LeftIcon}
    />
  )
}

const SectionTitleContainer = styled(Flex)`
  position: relative;

  align-items: center;
  gap: ${({ theme }) => theme.spacings.sm}px;
  margin-bottom: ${({ theme }) => theme.spacings.sm}px;

  cursor: pointer;

  &:hover::after {
    content: ' ';
    position: absolute;
    left: -4px;
    right: -4px;
    top: -4px;
    bottom: -4px;

    border-radius: 4px;

    background: ${({ theme }) => transparentize(0.9, theme.colors.grayscale.black)};
  }
`

export interface IPanelLinkSection {
  id: string
  title?: string
  items: (PanelLinkSectionItem | PanelLinkSectionItemGroup)[]
}

interface PanelLinkSectionProps {
  title?: string
}

const PanelLinkSection: FC<PanelLinkSectionProps> = ({ title, children }) => {
  const { isOpen, toggle } = useDisclosure(true)

  return (
    <Flex $flexDirection='column' $pb={'md'}>
      {!isNil(title) ? (
        <SectionTitleContainer onClick={toggle}>
          <PanelSectionTitle>{title}</PanelSectionTitle>
          <Icons.ArrowDropDown $rotate={isOpen ? 0 : -90} />
        </SectionTitleContainer>
      ) : null}
      <Collapsible isOpen={isOpen} direction='vertical'>
        {/**
         * Did not use the content of the render function above since we may need to declare children specifically (see NavPanel)
         */}
        {children}
      </Collapsible>
    </Flex>
  )
}

export default PanelLinkSection
