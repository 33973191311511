import type { Placement } from '@floating-ui/react'
import {
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole
} from '@floating-ui/react'
import {
  HTMLProps,
  ReactNode,
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  useContext,
  useMemo,
  useState
} from 'react'
import { useTheme } from 'styled-components'

const PADDING_FROM_TRIGGER = 8

export interface TooltipOptions {
  initialOpen?: boolean
  placement?: Placement
  open?: boolean
  disabled?: boolean
  onOpenChange?: (open: boolean) => void
  enableSafePolygon?: boolean
  restMs?: number | undefined
  openDelay?: number
  closeDelay?: number
}

function useTooltip({
  initialOpen = false,
  placement = 'top',
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  disabled = false,
  enableSafePolygon = false,
  restMs = undefined,
  openDelay = 0,
  closeDelay = 0
}: TooltipOptions = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen)

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(PADDING_FROM_TRIGGER),
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'start',
        padding: PADDING_FROM_TRIGGER
      }),
      shift({ padding: PADDING_FROM_TRIGGER })
    ]
  })

  const { context } = data

  const hover = useHover(context, {
    restMs,
    delay: {
      open: openDelay,
      close: closeDelay
    },
    handleClose: enableSafePolygon ? safePolygon({ blockPointerEvents: true }) : undefined,
    move: false,
    enabled: !disabled && controlledOpen == null
  })

  const dismiss = useDismiss(context)

  const role = useRole(context, { role: 'tooltip' })

  const interactions = useInteractions([hover, dismiss, role])

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data
    }),
    [open, setOpen, interactions, data]
  )
}

type ContextType = ReturnType<typeof useTooltip> | null

const TooltipContext = createContext<ContextType>(null)

const useTooltipContext = () => {
  const context = useContext(TooltipContext)

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />')
  }

  return context
}

export function Tooltip({ children, ...options }: { children: ReactNode } & TooltipOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip(options)

  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>
}

export const TooltipTrigger = forwardRef<
  HTMLElement,
  HTMLProps<HTMLElement> & { asChild?: boolean }
>(function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
  const context = useTooltipContext()
  const childrenRef = children?.ref

  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && isValidElement(children)) {
    return cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed'
      })
    )
  }

  return (
    <div
      ref={ref}
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
    >
      {children}
    </div>
  )
})

export const TooltipContent = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
  function TooltipContent({ style, className, ...props }, propRef) {
    const { colors } = useTheme()
    const context = useTooltipContext()
    const ref = useMergeRefs([context.refs.setFloating, propRef])

    const mergedStyle = useMemo(
      () => ({
        ...context.floatingStyles,
        padding: 8,
        backgroundColor: colors.grayscale['900'],
        borderColor: colors.grayscale.black,
        borderRadius: 8,
        boxShadow: '0 2px 4px rgb(0 0 0 / 25%)',
        zIndex: 99999,
        ...style
      }),
      [context.floatingStyles, style, colors]
    )

    if (!context.open) return null

    return (
      <FloatingPortal>
        <div
          ref={ref}
          style={mergedStyle}
          className={className}
          {...context.getFloatingProps(props)}
        />
      </FloatingPortal>
    )
  }
)
