import { getMetricNameForSelector } from '@weenat/client/dist/core/metrics'
import { UniverseMetric } from '@weenat/client/dist/core/universes'
import { fromColorPathToColor } from '@weenat/theme'
import DropdownSelect from 'app/src/kit/DropdownSelect'
import MetricIcons from 'app/src/kit/icons/MetricIcons'
import React from 'react'

export interface MetricSelectorProps {
  metric: UniverseMetric | undefined
  onChange: (newMetric: UniverseMetric) => void
  availableMetrics: UniverseMetric[]
}

const MetricSelector: React.FC<MetricSelectorProps> = ({ metric, onChange, availableMetrics }) => {
  return (
    <DropdownSelect
      options={availableMetrics.map((aMetric) => {
        return {
          backgroundColor: fromColorPathToColor(`metrics.${aMetric}.500`),
          icon: MetricIcons[aMetric],
          borderColor: fromColorPathToColor(`metrics.${aMetric}.800`),
          label: getMetricNameForSelector(aMetric),
          color: fromColorPathToColor('grayscale.white'),
          value: aMetric
        }
      })}
      onChange={onChange}
      value={metric}
    />
  )
}

export default MetricSelector
