import isEmpty from 'lodash-es/isEmpty'
import moment from 'moment-timezone'
import { useMemo } from 'react'
import { useTheme } from 'styled-components'
import { VictoryArea, VictoryAreaProps, VictoryBar } from 'victory'

interface FutureAreaProps extends VictoryAreaProps {
  timezone: string
}

/**
 * Victory Area highlighting the past on a Chart
 * @deprecated remove with chartWithD3 flag
 */
const PastArea: FC<FutureAreaProps> = ({ data, domain, timezone, ...props }) => {
  const { colors } = useTheme()

  const now = moment(new Date(Date.now())).tz(timezone)
  const showArea =
    domain?.x && (now.isBetween(domain?.x[0], domain?.x[1]) || now.isBefore(domain?.x[0]))

  // Creating an array of data (null or maxes)
  const areaData = useMemo(() => {
    const area = data?.map((dataPoint) => {
      const momentOnX = moment(dataPoint.x).tz(timezone)
      const value = momentOnX.isSameOrBefore(now) ? domain?.y[1] : null

      return {
        x: dataPoint.x,
        y: value,
        value: value,
        y0: domain?.y[0]
      }
    })

    // Completing area to so it doesn't stop to the last round x value
    if (!isEmpty(area))
      area?.push({
        x: now,
        y: domain?.y[1],
        value: domain?.y[1],
        y0: domain?.y[0]
      })

    return area
  }, [data, domain?.y, timezone, now])

  const areaStyle = useMemo(
    () => ({
      data: {
        stroke: 'none',
        fill: colors.grayscale[300],
        fillOpacity: 0.15
      }
    }),
    [colors]
  )

  const barStyle = useMemo(
    () => ({
      data: {
        width: 0.25,
        stroke: colors.grayscale[700]
      }
    }),
    [colors]
  )

  return showArea ? (
    <>
      <VictoryArea {...props} data={areaData} domain={domain} style={areaStyle} />
      <VictoryBar
        {...props}
        domain={domain}
        data={[{ x: now, y: domain?.y[1] }]}
        style={barStyle}
      />
    </>
  ) : null
}

export default PastArea
