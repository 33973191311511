import { queryClient } from '@weenat/client'
import { ConcreteSearchParams, Href } from '@weenat/client/dist/routx/runtime-core'
import { useIntl } from '@weenat/wintl'
import { CURRENT_ORG_KEY } from 'app/orgProvider'
import { useNavigate } from 'app/routx-router'
import Button from 'app/src/kit/Button'
import Icons from 'app/src/kit/Icons'
import { str, useLoginAs } from 'app/state'

export const clearAllStates = async (isDebugMode?: boolean) => {
  const keysToNotFlush = isDebugMode ? ['token'] : []

  await str.flush({ except: ['lightTour', 'clusterSettings', 'features', ...keysToNotFlush] })
  localStorage.removeItem(CURRENT_ORG_KEY)
  if (isDebugMode) {
    queryClient.refetchQueries()
  } else {
    queryClient.clear()
  }
}

export const useLogout = () => {
  const [loginAs, setLoginAs] = useLoginAs()
  const isDebugMode = loginAs != null
  const nav = useNavigate()

  return async (redirectTo?: Href, search?: ConcreteSearchParams) => {
    setLoginAs(null)
    await clearAllStates(isDebugMode)

    nav(isDebugMode ? `/farms` : redirectTo ?? '/auth/login', { replace: true, search })
  }
}

const Logout: FC = () => {
  const { t } = useIntl()
  const handleLogout = useLogout()

  return (
    <Button isDanger IconLeft={Icons.Logout} onPress={() => handleLogout()}>
      {t('models.account.actions.logout')}
    </Button>
  )
}

export default Logout
