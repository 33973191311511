import {
  getTimeFormatForTicks,
  getTimeInterval,
  useChartCtx
} from '@weenat/client/dist/core/charts'
import { isNil } from 'lodash-es'
import moment from 'moment-timezone'
import { FC, useMemo } from 'react'

const TEXT_CHARACTER_WIDTH = 8

const tickLabelStyle = {
  fontSize: '10px',
  textAnchor: 'middle',
  transform: 'translateY(16px)',
  fill: '#002635'
}

interface TimeAxisProps {
  tickFormat?: string
  tickCount?: number
  color?: string | ((date: Date) => string)
  timezone: string
  period: [Date, Date]
  isFourHourTimeStep?: boolean
}

const TimeAxis: FC<TimeAxisProps> = ({
  tickFormat: formatFromProps,
  tickCount,
  timezone,
  period,
  isFourHourTimeStep = false
}) => {
  const { height, marginBottom, xScale } = useChartCtx()

  const timeInterval = useMemo(() => {
    return isNil(period) ? undefined : getTimeInterval(period, timezone, isFourHourTimeStep)
  }, [period, timezone, isFourHourTimeStep])

  const tickFormat = !isNil(formatFromProps)
    ? formatFromProps
    : isNil(period)
      ? undefined
      : getTimeFormatForTicks(period, timezone)

  const ticks = useMemo(() => {
    const ticksArray = !isNil(timeInterval)
      ? xScale?.ticks(timeInterval).filter((_a, idx, vals) => {
          const ratio = !isNil(tickCount) ? Math.floor(vals.length / tickCount) : undefined
          return isNil(ratio) || idx % ratio === 0
        })
      : xScale?.ticks(tickCount)

    const distanceBtwTicks = xScale?.(ticksArray[1]) - xScale?.(ticksArray[0])

    let overlappedLabels = 0

    return ticksArray?.reduce(
      (acc, value) => {
        const formattedValue = moment(value).format(tickFormat)

        const labelWidth = formattedValue.length * TEXT_CHARACTER_WIDTH

        const ticksOverlappedByLabel = Math.ceil(labelWidth / distanceBtwTicks)

        if (overlappedLabels === 0) {
          acc.push({
            id: value.getTime(),
            value: formattedValue,
            xOffset: xScale(value)
          })
        }
        if (overlappedLabels !== 0) {
          overlappedLabels = overlappedLabels - 1
        } else if (overlappedLabels === 0 && ticksOverlappedByLabel > 1) {
          overlappedLabels = ticksOverlappedByLabel
        }

        return acc
      },
      [] as {
        id: number
        value: string
        xOffset: number
      }[]
    )
  }, [timeInterval, xScale, tickCount, tickFormat])

  return (
    <g transform={`translate(0,${height - marginBottom})`}>
      {/* <path d={`M ${marginLeft} 0 H ${width - marginRight}`} stroke='currentColor' /> */}
      {ticks?.map(({ value, xOffset }) => (
        <g key={value} transform={`translate(${xOffset}, 0)`}>
          <line y2='4' stroke={'black'} />
          <text key={value} style={tickLabelStyle}>
            {value}
          </text>
        </g>
      ))}
    </g>
  )
}

export default TimeAxis
