import { Slot } from 'app/routx-router'
import ProtectedRoute from 'app/src/layouts/ProtectedRoute'

export const Component = () => {
  return (
    <ProtectedRoute>
      <Slot />
    </ProtectedRoute>
  )
}
export default Component
