import {
  Universe,
  UniverseMetric,
  getUniverseAggs,
  getUniverseMetrics
} from '@weenat/client/dist/core/universes'
import { isMetricThatNeedsToBeFromDeviceToBeDisplayedOnMap } from '@weenat/client/dist/enums/MetricIds'
import { DeviceAvailableMeasure } from '@weenat/client/dist/resources'
import {
  Summary,
  isDeviceSummary,
  isPlotSummary,
  isStationSummary
} from '@weenat/client/dist/resources/measurements.type'
import { useOrgContext } from 'app/orgProvider'
import { useSearchParams } from 'app/routx-router'
import {
  useBackgroundMapContext,
  useBackgroundMapDispatcher
} from 'app/src/dashboard/components/DashboardMap/contexts/BackgroundMapContext'
import UniverseAggregate from 'app/src/dashboard/components/DashboardMap/universes/UniverseAggregate'
import useUniverseOptionsQuery from 'app/src/dashboard/components/DashboardMap/universes/useUniverseOptionsQuery'
import usePlotsOrDevicesFromPathname from 'app/src/hooks/usePlotsOrDevicesFromPathname'
import {
  displaySettingsSearchParamsSchema,
  useActiveMetricByUniverse,
  useUniverse,
  useUniverseAggregate,
  useUniverseHorizon
} from 'app/state'
import { logSwitchUnivMetric } from 'app/utils/analytics'
import { intersection, isEmpty, isNil } from 'lodash-es'
import { ReactElement, useEffect, useMemo, useRef } from 'react'
import { css, styled } from 'styled-components'
import HorizonSelector from './HorizonSelector'
import MetricSelector from './MetricSelector'
import Search from './Search'
import UniverseSelector from './UniverseSelector'

const MainContainer = styled(Box)<{ $variant: 'map' | 'list' }>`
  background-color: ${(p) => p.theme.colors.grayscale.white};
  border-radius: ${(p) => (p.$variant === 'map' ? p.theme.radiuses.md : 0)}px;
  margin-bottom: ${(p) => (p.$variant === 'map' ? p.theme.spacings.md : 0)}px;
  flex: 1;

  ${(p) =>
    p.$variant === 'map'
      ? css`
          border: 1px solid ${p.theme.colors.grayscale[500]};
          box-shadow: ${p.theme.shadows.md.boxShadow};
        `
      : css`
          border-bottom: 1px solid ${p.theme.colors.grayscale[300]};
        `}
`

export interface MyFarmToolsProps {
  summaries: Summary[]
  filteredResults: Summary[] | undefined
  children?: ReactElement
  displaySettingsElement?: ReactElement
  isPlotsMap?: boolean
}

const MyFarmTools = ({
  summaries,
  filteredResults,
  displaySettingsElement,
  isPlotsMap = false
}: MyFarmToolsProps) => {
  const [currentUniv, setUniv] = useUniverse()
  const org = useOrgContext()
  const [searchParams] = useSearchParams<typeof displaySettingsSearchParamsSchema>()
  const dispatch = useBackgroundMapDispatcher()
  const { search } = useBackgroundMapContext()
  const [, setAggregate] = useUniverseAggregate()

  const isListView = 'view' in searchParams && searchParams.view === 'list'
  const currentOrgId = org.id

  const universeOptionsQuery = useUniverseOptionsQuery(currentOrgId)
  const { universesRequest } = universeOptionsQuery
  const universeOptions = useMemo(
    () =>
      (isPlotsMap
        ? universeOptionsQuery.universeOptions
        : universeOptionsQuery.universeOptions.filter(
            (universe) => universe.value !== Universe.overview
          )
      ).sort((univA, univB) =>
        univB.value === Universe.overview ? -1 : univA.value - univB.value
      ),
    [isPlotsMap, universeOptionsQuery]
  )
  // const debouncedValue = useDebounce(search, 200)

  const [selectedMetricByUniverse, setSelectedMetricByUniverse] = useActiveMetricByUniverse()
  const [selectedHorizon, setSelectedHorizon] = useUniverseHorizon()

  const metricsFromConfigs = useMemo(() => {
    const keysFromData: Set<string> = new Set()

    if (currentUniv === Universe.irrelis) keysFromData.add('HDEF')

    summaries.forEach((sum) => {
      if (isPlotSummary(sum)) {
        const { currentMeasurementConfigs } = sum.metadata.plot

        currentMeasurementConfigs.forEach((conf) => {
          if (
            // we first check if we can add based on metric then if it's a metric that need to be from device we check that device id is not null
            !isMetricThatNeedsToBeFromDeviceToBeDisplayedOnMap(conf.metric) ||
            !isNil(conf.deviceId)
          ) {
            keysFromData.add(conf.metric)
          }
        })
      } else {
        let measures: DeviceAvailableMeasure[] = []

        if (isDeviceSummary(sum)) {
          const { availableMeasures } = sum.metadata.device
          measures = availableMeasures
        }
        if (isStationSummary(sum)) {
          const { availableMeasures } = sum.metadata.station
          measures = availableMeasures
        }

        measures.forEach((m) => keysFromData.add(m))
      }
    })

    return Array.from(keysFromData)
  }, [summaries, currentUniv])

  const allUniverseMetrics = useMemo(() => getUniverseMetrics(currentUniv), [currentUniv])

  // metrics that are in both configs and possible universe metrics
  const enabledMetrics: UniverseMetric[] = useMemo(() => {
    return intersection(allUniverseMetrics, metricsFromConfigs as UniverseMetric[])
  }, [allUniverseMetrics, metricsFromConfigs])

  const currentMetric = selectedMetricByUniverse?.[currentUniv]

  const plotsOrDevices = usePlotsOrDevicesFromPathname()

  useEffect(() => {
    const setUnivMetricAndLog = (aMetric: UniverseMetric) => {
      !isNil(plotsOrDevices) &&
        logSwitchUnivMetric({ action: `${plotsOrDevices}_switch_metrics`, selected: aMetric })
      if (selectedMetricByUniverse?.[currentUniv] !== aMetric) {
        setSelectedMetricByUniverse((previousState) => ({
          ...(previousState || {}),
          [currentUniv]: aMetric
        }))
        const universeAggregate = getUniverseAggs(currentUniv, aMetric)
        if (!isNil(universeAggregate)) {
          setAggregate(universeAggregate[0])
        }
      }
    }
    if (isNil(currentMetric) || !enabledMetrics.includes(currentMetric)) {
      setUnivMetricAndLog(enabledMetrics[0])
    }
  }, [
    currentMetric,
    currentUniv,
    enabledMetrics,
    plotsOrDevices,
    selectedMetricByUniverse,
    setAggregate,
    setSelectedMetricByUniverse
  ])

  // Resetting the current universe to weather if the org ID is defined and the current universe is not inside the available options
  useEffect(() => {
    const currentOptionAvailable =
      !isEmpty(universeOptions) &&
      universeOptions.some((opt) => opt.value === currentUniv && !opt.isDisabled)

    if (
      !isNil(currentOrgId) &&
      universesRequest.isSuccess &&
      !currentOptionAvailable &&
      !isEmpty(universeOptions)
    ) {
      setUniv(universeOptions[0].value)
    }
  }, [currentOrgId, currentUniv, setUniv, universeOptions, universesRequest.isSuccess])

  const mainLayoutRef = useRef<HTMLDivElement>(null)
  const universeSelector =
    universeOptions.length > 0 ? (
      <UniverseSelector
        universe={currentUniv}
        onChange={setUniv}
        availableUniverses={universeOptions}
      />
    ) : null
  const metricSelector = (
    <>
      {currentUniv !== Universe.overview ? (
        <MetricSelector
          metric={selectedMetricByUniverse?.[currentUniv]}
          onChange={(metric) =>
            setSelectedMetricByUniverse((previousState) => ({
              ...(previousState || {}),
              [currentUniv]: metric
            }))
          }
          availableMetrics={enabledMetrics}
        />
      ) : null}
      {currentUniv === Universe.irrigation && selectedMetricByUniverse?.[currentUniv] === 'HPOT' ? (
        <HorizonSelector
          value={selectedHorizon}
          onChange={(horizon) => setSelectedHorizon(horizon)}
          summaries={summaries}
        />
      ) : null}
    </>
  )

  return (
    <Box
      $p={isListView ? 0 : 'md'}
      $pointerEvents='auto'
      $width='100%'
      $height='fit-content'
      $display={isListView ? 'flex' : 'block'}
      $zIndex={2}
    >
      <MainContainer $variant={isListView ? 'list' : 'map'} ref={mainLayoutRef}>
        <Search
          value={search}
          onSearch={(newVal) => {
            dispatch({ type: 'setSearch', newSearch: newVal ?? '' })
          }}
          width={mainLayoutRef.current?.clientWidth}
          displaySettingsElement={displaySettingsElement}
          filteredResults={filteredResults}
          enabledMetrics={enabledMetrics}
          universeSelector={universeSelector}
          metricSelector={metricSelector}
        />
      </MainContainer>
      {isListView ? null : (
        <Box $width={'fit-content'}>
          <UniverseAggregate />
        </Box>
      )}
    </Box>
  )
}

export default MyFarmTools
