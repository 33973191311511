import { getLabels, splitLabels } from '@weenat/client/dist/core/charts/charts'
import { capitalize } from '@weenat/client/dist/core/dss/decitrait'
import { getDateFormatPerTimeSteps } from '@weenat/client/dist/core/history'
import { fromWindAngleToDirection } from '@weenat/client/dist/core/metrics'
import TimeSteps from '@weenat/client/dist/enums/TimeSteps'
import { isMetricWithUnit } from '@weenat/client/dist/enums/UnitChoices'
import { useConvertedValue } from '@weenat/client/dist/hooks'
import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import { isNil } from 'lodash-es'
import isEmpty from 'lodash-es/isEmpty'
import moment from 'moment-timezone'
import { memo, useContext } from 'react'
import { styled } from 'styled-components'
import { VictoryChart, VictoryChartProps, VictoryVoronoiContainer } from 'victory'
import WindDirectionIcon from '../icons/WindDirectionIcon'
import ChartTooltip, { ChartTooltipProps } from './ChartTooltip'
import { ChartWrapperContext } from './ChartWrapper'

type ChartProps = VictoryChartProps & {
  timezone: string
  tooltipVariant?: ChartTooltipProps['variant']
  timeStep?: TimeSteps
}

const DataSourceLabel = styled(Text)`
  color: ${(props) => props.theme.colors.grayscale[700]};
  font-size: ${(p) => p.theme.typography.sizes.sm}px;
`

const EMPTY_VALUE_PLACEHOLDER = '-'
const TOOLTIP_ROW_HEIGHT = 32

/** @deprecated remove with chartWithD3 flag  */
const ToolTipContent = memo(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ datum, text, width, timeStep: selectedTimeStep, variant, timezone }: any) => {
    const { t } = useIntl()
    const { getUnit } = useConvertedValue()

    const { formatConvertedValue } = useConvertedValue()

    const isHistoryVariant = variant === 'history'

    const dateTextSize = isHistoryVariant ? 'rg' : 'sm'
    const valueTextSize = isHistoryVariant ? 'lg' : 'rg'
    const valueTextWeight = isHistoryVariant ? 'regular' : 'bold'
    const tooltipRowHeight = isHistoryVariant ? 32 : 16
    const format = getDateFormatPerTimeSteps(selectedTimeStep as TimeSteps)

    return (
      <Box
        $borderColor='grayscale.300'
        $backgroundColor='grayscale.white'
        $borderRadius={'md'}
        $borderWidth={'rg'}
        $p='md'
        $pb='sm'
        $width={width}
      >
        <Text $fontSize={dateTextSize} $lineHeight='md'>
          {moment(datum.x).tz(timezone).format(format)}
        </Text>
        {text?.map((tooltipLine: string) => {
          const { color, label, value, metric, source } = splitLabels(tooltipLine)
          const unit = isMetricWithUnit(metric) ? getUnit(metric) : undefined
          const isAngle = unit === 'degrees'
          const valueTextColor = isHistoryVariant ? undefined : color
          const formattedValue = isEmpty(value)
            ? EMPTY_VALUE_PLACEHOLDER
            : isMetricWithUnit(metric)
              ? `${formatConvertedValue({
                  metric,
                  value: parseFloat(value)
                })}`
              : `${parseFloat(value)}`

          const renderedValue = !isAngle ? (
            <Flex $alignItems='baseline' $height={TOOLTIP_ROW_HEIGHT} $gap='sm'>
              <Text $fontSize={valueTextSize} $fontWeight={valueTextWeight} $color={valueTextColor}>
                {formattedValue}
              </Text>
              {unit && isMetricWithUnit(metric) && (
                <Text $color={valueTextColor}>{t(`units.${unit}_abbr`)}</Text>
              )}
            </Flex>
          ) : (
            <>
              {!isEmpty(value) ? (
                <Flex $alignItems='center' $height={TOOLTIP_ROW_HEIGHT}>
                  <WindDirectionIcon windOrigin={parseInt(value, 10)} $color={color} $size='rg' />
                  <Text $fontSize='sm'>
                    {t(`windDirections.${fromWindAngleToDirection(parseInt(value, 10))}`)}
                  </Text>
                </Flex>
              ) : (
                <Text $fontSize={valueTextSize} $fontWeight={valueTextWeight}>
                  {EMPTY_VALUE_PLACEHOLDER}
                </Text>
              )}
            </>
          )

          return isHistoryVariant ? (
            <Flex
              key={`${label}_${datum.x}`}
              $alignItems='center'
              $justifyContent='space-between'
              $mb='sm'
            >
              <Flex
                $minWidth={0}
                $flex={1}
                $alignItems='center'
                $height={tooltipRowHeight}
                $mr='sm'
              >
                <Box
                  $borderRadius='sm'
                  $backgroundColor={color}
                  $height='100%'
                  $width={4}
                  $mr='sm'
                />
                <Flex $minWidth={0} $flex={1} $flexDirection='column' $height='100%'>
                  <Text $fontWeight='bold' $ellipsis>
                    {capitalize(label)}
                  </Text>
                  <DataSourceLabel>{`${t('dataSources.label_short', {
                    capitalize: true
                  })}: ${source}`}</DataSourceLabel>
                </Flex>
              </Flex>
              {renderedValue}
            </Flex>
          ) : (
            <Flex
              key={`${label}_${datum.x}`}
              $alignItems='center'
              $justifyContent='space-between'
              $mb='sm'
            >
              {renderedValue}
            </Flex>
          )
        })}
      </Box>
    )
  },
  (a, b) => a.x === b.x
)

const blacklist = ['blacklisted']

/** @deprecated remove with chartWithD3 flag  */
const Chart: React.FC<ChartProps> = ({
  domain,
  children,
  timeStep,
  tooltipVariant = 'history',
  containerComponent,
  timezone,
  ...props
}) => {
  const { dimension } = useContext(ChartWrapperContext)

  return dimension ? (
    <VictoryChart
      {...props}
      domain={domain}
      height={dimension.height}
      width={dimension.width}
      standalone
      containerComponent={
        containerComponent || (
          <VictoryVoronoiContainer
            voronoiDimension='x'
            voronoiBlacklist={blacklist}
            labels={getLabels}
            labelComponent={
              <ChartTooltip
                yMin={
                  !isNil(domain) &&
                  'y' in domain &&
                  !isNil(domain.y) &&
                  typeof domain.y[0] === 'number'
                    ? domain.y[0]
                    : 0
                }
                chartTooltipWidth={tooltipVariant === 'history' ? 300 : 112}
                TooltipContentComponent={ToolTipContent}
                timeStep={timeStep ?? TimeSteps.perHours}
                timezone={timezone}
                variant={tooltipVariant}
              />
            }
          />
        )
      }
    >
      {children}
    </VictoryChart>
  ) : null
}

export default Chart
