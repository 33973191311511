import { useItemContext } from '@weenat/client'
import { getDomains, toDataPoint } from '@weenat/client/dist/core/charts'
import { DataPoint } from '@weenat/client/dist/core/charts/d3-charts.type'
import { useFrostMetricsSummaryChart } from '@weenat/client/dist/core/metrics'
import { UseMetricSummaryChartInterface } from '@weenat/client/dist/core/metrics/useMetricSummaryChart'
import TimeSteps from '@weenat/client/dist/enums/TimeSteps'
import { useConvertedValue } from '@weenat/client/dist/hooks'
import D3Axis from 'app/src/kit/d3-charts/Axis'
import CursorChart from 'app/src/kit/d3-charts/CursorChart'
import D3Line from 'app/src/kit/d3-charts/Line'
import D3TimeAxis from 'app/src/kit/d3-charts/TimeAxis'
import { useToggleFeature } from 'app/state'
import { groupBy, isNil } from 'lodash-es'
import isEmpty from 'lodash-es/isEmpty'
import moment from 'moment-timezone'
import { useCallback, useMemo } from 'react'
import { styled, useTheme } from 'styled-components'
import { VictoryAxis, VictoryChartProps } from 'victory'
import Text from '../../Text'
import FutureArea from '../../d3-charts/FutureArea'
import Chart from '../Chart'
import ChartWrapper from '../ChartWrapper'
import PastArea from '../PastArea'
import TimeAxis from '../TimeAxis'
import victoryTheme from '../victoryTheme'
import MetricLine from './MetricLine'

const CHART_HEIGHT = 148

const tickValues = [0, 0.5, 1]
const chartPadding = { top: 16, left: 24, right: 16, bottom: 40 }

const AbsolutelyPostionedContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: -16px;
  height: ${CHART_HEIGHT}px;
  width: 100%;
`

/**
 * Chart used inside summaries
 */
const FrostMetricsSummaryChart: FC<
  VictoryChartProps & {
    prefetch?: UseMetricSummaryChartInterface['prefetch']
  }
> = ({ prefetch, ...victoryChartProps }) => {
  const [{ chartWithD3 }] = useToggleFeature()
  const { colors } = useTheme()
  const { timezone } = useItemContext()
  const { convertValue } = useConvertedValue()

  const {
    chartDomain,
    denormalizeFct,
    getTDryGradient,
    getTWetGradient,
    isLoading,
    period,
    tDryAbsoluteYDomain,
    tDryData,
    tWetAbsoluteYDomain,
    tWetData
  } = useFrostMetricsSummaryChart(prefetch)

  const chartStyle = useMemo(
    () => ({
      axis: {
        fill: 'transparent',
        strokeWidth: 0
      },
      ticks: { size: 0 },
      tickLabels: {
        textAlign: 'right',
        fontWeight: 'bold',
        fill: colors.metrics.T_DRY['500'] || colors.grayscale.black
      }
    }),
    [colors]
  )

  const dataPointsResults = useMemo(() => {
    if (!isEmpty(tWetData) || !isEmpty(tDryData)) {
      const tDryD3Data = tDryData.map(toDataPoint)
      const tWetD3Data = tWetData.map(toDataPoint)
      const tWetAndDryD3Data = tWetD3Data.concat(tDryD3Data)
      const domain = getDomains(tWetAndDryD3Data)
      const tWetAndDryByTimestamp = groupBy(tWetAndDryD3Data, (dataPoint) =>
        dataPoint.date.getTime()
      )
      return { domain, tDryD3Data, tWetD3Data, tWetAndDryD3Data, tWetAndDryByTimestamp }
    } else {
      return undefined
    }
  }, [tDryData, tWetData])

  const tWetAndDryByTimestamp = dataPointsResults?.tWetAndDryByTimestamp

  const renderTooltip = useCallback(
    ({ date }: DataPoint) => {
      return (
        <Box
          $backgroundColor='grayscale.white'
          $borderColor='grayscale.200'
          $borderRadius='sm'
          $borderWidth='rg'
          $p='md'
          $pointerEvents='none'
        >
          <Text>{moment(date).format('DD/MM HH:mm')}</Text>
          {!isNil(tWetAndDryByTimestamp) ? (
            <>
              <Text $color={`metrics.T_WET.500`}>
                {convertValue({
                  metric: 'T_WET',
                  value: denormalizeFct(tWetAndDryByTimestamp[date.getTime()][0].value)
                })}
              </Text>
              <Text $color={`metrics.T_DRY.500`}>
                {convertValue({
                  metric: 'T_WET',
                  value: denormalizeFct(tWetAndDryByTimestamp[date.getTime()][1].value)
                })}
              </Text>
            </>
          ) : null}
        </Box>
      )
    },
    [convertValue, denormalizeFct, tWetAndDryByTimestamp]
  )

  return (
    <Box $position='relative' $height={CHART_HEIGHT}>
      <AbsolutelyPostionedContainer>
        {chartWithD3 ? (
          <>
            {!isNil(dataPointsResults) ? (
              <CursorChart
                domain={dataPointsResults.domain}
                width='100%'
                height={CHART_HEIGHT}
                data={dataPointsResults.tWetAndDryD3Data}
                renderTooltip={renderTooltip}
                domainPadding={{ bottom: 0, left: 16, right: 16, top: 16 }}
                ticksCount={3}
                timeStep={TimeSteps.perHours}
              >
                <FutureArea />
                <D3Line
                  data={dataPointsResults.tDryD3Data}
                  metric='T_DRY'
                  timeStep={TimeSteps.perHours}
                  denormalize={denormalizeFct}
                />
                <D3Line
                  data={dataPointsResults.tWetD3Data}
                  metric='T_WET'
                  timeStep={TimeSteps.perHours}
                  denormalize={denormalizeFct}
                />
                <D3TimeAxis
                  period={dataPointsResults.domain.x}
                  timezone={timezone}
                  tickFormat='HH'
                />
                <D3Axis denormalize={denormalizeFct} metric='T_DRY' />
              </CursorChart>
            ) : null}
          </>
        ) : (
          <ChartWrapper
            height={CHART_HEIGHT}
            isEmpty={isEmpty(tWetData) && isEmpty(tDryData)}
            isLoading={isLoading}
          >
            {tWetData && tDryData && (
              <Chart
                {...victoryChartProps}
                height={CHART_HEIGHT}
                domain={chartDomain}
                padding={chartPadding}
                timeStep={TimeSteps.perHours}
                tooltipVariant='summary'
                theme={victoryTheme}
                timezone={timezone}
              >
                <VictoryAxis
                  dependentAxis
                  standalone
                  style={chartStyle}
                  tickValues={tickValues}
                  tickFormat={(tick) => Math.ceil(denormalizeFct(tick) as number)}
                />

                <MetricLine
                  data={tWetData}
                  metric={'T_WET'}
                  absoluteYDomain={tWetAbsoluteYDomain}
                  getGradient={getTWetGradient}
                />

                <MetricLine
                  data={tDryData}
                  metric={'T_DRY'}
                  absoluteYDomain={tDryAbsoluteYDomain}
                  getGradient={getTDryGradient}
                />

                <PastArea
                  data={!isEmpty(tWetData) ? tWetData : !isEmpty(tDryData) ? tDryData : []}
                  timezone={timezone}
                />

                <TimeAxis
                  timeStep={TimeSteps.perHours}
                  period={period}
                  timezone={timezone}
                  orientation={'bottom'}
                  style={{ axis: { strokeWidth: 0 } }}
                />
              </Chart>
            )}
          </ChartWrapper>
        )}
      </AbsolutelyPostionedContainer>
    </Box>
  )
}

export default FrostMetricsSummaryChart
