import { useIntl } from '@weenat/wintl'
import isNil from 'lodash-es/isNil'
import { Simplify } from 'type-fest'
import AdvancedClientList, {
  AdvancedClientListProps,
  PaginatedAndSearchableParams
} from './AdvancedClientList'

type CleanedClientListProps<T, Q extends PaginatedAndSearchableParams, E> = Omit<
  AdvancedClientListProps<T, Q, E>,
  'variant' | 'hideBottomNav' | 'columns' | 'ListEmptyComponent' | 'withSkeleton'
>

type NewlyRequiredProps<T, Q extends PaginatedAndSearchableParams, E> = Pick<
  AdvancedClientListProps<T, Q, E>,
  'columns'
>

type AdminClientListProps<T, Q extends PaginatedAndSearchableParams, E> = Simplify<
  CleanedClientListProps<T, Q, E> & NewlyRequiredProps<T, Q, E>
> &
  Pick<
    AdvancedClientListProps<T, Q, E>,
    'filterCategories' | 'sortOptionsCategories' | 'initialQueryParams'
  >

function AdminClientList<T, Q extends PaginatedAndSearchableParams, E>({
  searchable = true,
  model,
  searchLabel,
  filterCategories,
  sortOptionsCategories,
  initialQueryParams,
  ...clientListProps
}: AdminClientListProps<T, Q, E>) {
  const { t } = useIntl()

  const msgKey = !isNil(model) ? (`models.${model}.model.search_item` as const) : null

  const finalSearchLabel = !isNil(searchLabel)
    ? searchLabel
    : !isNil(msgKey)
      ? t(msgKey, { capitalize: true })
      : undefined

  return (
    <AdvancedClientList
      hideBottomNav
      variant='table'
      searchLabel={finalSearchLabel}
      searchable={searchable}
      model={model}
      ListEmptyComponent={null}
      filterCategories={filterCategories}
      sortOptionsCategories={sortOptionsCategories}
      initialQueryParams={initialQueryParams}
      {...clientListProps}
    />
  )
}

export default AdminClientList
