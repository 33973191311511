import { WeenatColor, fromColorPathToColor } from '@weenat/theme'
import Text from 'app/src/kit/Text'
import { useField } from 'formik'
import isNil from 'lodash-es/isNil'
import { useCallback } from 'react'
import { styled } from 'styled-components'
import SvgCheckmark from '../icons/generic-icons/Checkmark'
import SvgClose from '../icons/generic-icons/Close'

type TogglePosition = 'left' | 'right'

const TOGGLE_WIDTH = 48
const TOGGLE_HEIGHT = 24
const ICON_SIZE = 12
const CIRCLE_SIZE = 16

const Container = styled(Flex)<{ $isDisabled?: boolean; $togglePosition: TogglePosition }>`
  cursor: ${(p) => (p.$isDisabled ? 'not-allowed' : 'pointer')};
  align-items: center;
  flex-direction: ${(p) => (p.$togglePosition === 'right' ? ' row-reverse' : 'row')};
`

interface ContainerProps {
  $isChecked: boolean
  $backgroundColor: WeenatColor
  $isDisabled: boolean
}

const ToggleContainer = styled.span<ContainerProps>`
  position: relative;
  border-radius: ${(props) => props.theme.radiuses.rounded}px;
  width: ${TOGGLE_WIDTH}px;
  height: ${TOGGLE_HEIGHT}px;
  background-color: ${({ $backgroundColor }) => fromColorPathToColor($backgroundColor)};
  opacity: ${(props) => (props.$isDisabled ? 0.5 : 1)};
  will-change: opacity;
  min-width: 50px;
  margin-right: 8px;
  transition: opacity 0.175s ease-in-out;

  &:hover {
    cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};
  }
`

const ToggleLabel = styled(Text)<{ $togglePosition: TogglePosition }>`
  flex: ${(p) => (p.$togglePosition === 'right' ? 1 : 'auto')};
`

const Circle = styled(Flex)<{ $isChecked: boolean }>`
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 4px;
  left: ${(props) => (props.$isChecked ? '28px' : '4px')};
  width: ${CIRCLE_SIZE}px;
  height: ${CIRCLE_SIZE}px;
  border-radius: ${(props) => props.theme.radiuses.rounded}px;
  background-color: ${(props) => props.theme.colors.grayscale.white};
  will-change: left;
  transition: left 0.2s ease-in-out;
`

interface TogglePrimitiveProps {
  $backgroundColor?: WeenatColor
  $isDisabled?: boolean
  label?: React.ReactElement | string
  onToggle: (value: boolean) => void
  showIcons?: boolean
  value: boolean
  className?: string
  togglePosition?: 'left' | 'right'
}

export const TogglePrimitive: FC<TogglePrimitiveProps> = ({
  $backgroundColor,
  $isDisabled = false,
  label,
  onToggle,
  showIcons = false,
  value,
  className,
  togglePosition = 'left'
}) => {
  const finalColor: WeenatColor = value
    ? !isNil($backgroundColor)
      ? $backgroundColor
      : 'primary.500'
    : 'grayscale.300'

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation()
      if (!$isDisabled) onToggle(value)
    },
    [$isDisabled, onToggle, value]
  )

  const Icn = !value ? SvgClose : SvgCheckmark

  return (
    <Container
      onClick={handleClick}
      $isDisabled={$isDisabled}
      $togglePosition={togglePosition}
      className={className}
    >
      <ToggleContainer
        onClick={handleClick}
        $isChecked={value}
        $backgroundColor={finalColor}
        $isDisabled={$isDisabled}
      >
        <Circle $isChecked={value}>
          {showIcons ? (
            <Icn height={ICON_SIZE} width={ICON_SIZE} fill={fromColorPathToColor(finalColor)} />
          ) : null}
        </Circle>
      </ToggleContainer>
      {!isNil(label) ? (
        typeof label === 'string' ? (
          <ToggleLabel $togglePosition={togglePosition}>{label}</ToggleLabel>
        ) : (
          label
        )
      ) : null}
    </Container>
  )
}

interface ToggleFieldProps extends Omit<TogglePrimitiveProps, 'value' | 'onToggle'> {
  name: string
}

const ToggleField: FC<ToggleFieldProps> = ({
  name,
  label,
  $isDisabled: isDisabled,
  $backgroundColor: backgroundColor,
  ...props
}) => {
  const [field, , helpers] = useField<boolean>(name)
  // const displayableError = meta.touched ? meta.error : null

  const handleChange = useCallback(
    (value: boolean) => {
      if (!isDisabled) {
        helpers.setTouched(true)
        helpers.setValue(!value)
      }
    },
    [helpers, isDisabled]
  )

  return (
    <TogglePrimitive
      value={field.value}
      onToggle={handleChange}
      $isDisabled={isDisabled}
      $backgroundColor={backgroundColor}
      label={label}
      {...props}
    />
  )
}

export default ToggleField
