import { DeviceAvailableMeasure } from '@weenat/client/dist/resources'
import { useIntl } from '@weenat/wintl'
import { IconProps } from './Icon'
import Icons from './Icons'
import MetricIcons from './icons/MetricIcons'
import TextTooltip from './tooltips/TextTooltip'

/**
 * list of metric that have the same pictogram as another metric
 * we exclude them from stacked icons to avoid displaying same pictogram twice
 */
const metricWithSamePictogram: Readonly<DeviceAvailableMeasure[]> = [
  'FXY',
  'DXY',
  'LW_D',
  'SSI_MIN',
  'SSI_MAX',
  'PPFD_MAX',
  'PPFD_MIN'
] as const

const StackedMetrics: FC<{ metrics: DeviceAvailableMeasure[]; withTooltipLabel?: boolean }> = ({
  metrics,
  withTooltipLabel = false
}) => {
  const { t } = useIntl()
  return (
    <Flex>
      {metrics.map((metric, idx) => {
        const Icon: FC<IconProps> = MetricIcons[metric] ?? Icons.Bug
        const metricColor = (`metrics.${metric}.500` as const) || 'grayscale.black'
        const metricLabel = t(`metrics.${metric as 'T'}`, { capitalize: true })
        if (metricWithSamePictogram.includes(metric)) return null

        const metricBadge = (
          <Box
            $borderRadius={'md'}
            $boxShadow='sm'
            key={metric}
            $zIndex={metrics.length - idx}
            $ml={-8}
          >
            <Icon
              $color={'grayscale.white'}
              $size='md'
              $backgroundColor={metricColor}
              $borderColor={`metrics.${metric}.800`}
              $p='sm'
            />
          </Box>
        )

        if (withTooltipLabel) {
          return (
            <TextTooltip key={metric} content={metricLabel}>
              {metricBadge}
            </TextTooltip>
          )
        } else {
          return <>{metricBadge}</>
        }
      })}
    </Flex>
  )
}

export default StackedMetrics
