import { QueryKey } from '@tanstack/react-query'
import { useClient, useMutation } from '@weenat/client'
import { extractAvailableHorizonIdsSet, HorizonOpt } from '@weenat/client/dist/core/horizons'
import { hasHydricStressMetricData } from '@weenat/client/dist/core/summary'
import {
  BackgroundMapUniverseMetric,
  getAvailableUniversesFromMetrics,
  getMetricsFromSummary,
  Universe
} from '@weenat/client/dist/core/universes'
import {
  isHydricStress,
  metricsAndLabelKeyDisplayedByMetric
} from '@weenat/client/dist/enums/MetricIds'
import { RawMyProfileUnits } from '@weenat/client/dist/resources/me'
import { PlotOrStationSummary } from '@weenat/client/dist/resources/measurements'
import { isPlotSummary, isStationSummary } from '@weenat/client/dist/resources/measurements.type'
import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import { useNavigate } from 'app/routx-router'
import useUniverseBackgroundMapContext from 'app/src/dashboard/components/DashboardMap/universes/useUniverseBackgroundMapContext'
import useDisclosure from 'app/src/hooks/useDisclosure'
import Card from 'app/src/kit/Card'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'
import Link from 'app/src/kit/LinkComponent'
import ListEmpty from 'app/src/kit/ListEmpty'
import { BoxProps } from 'app/src/kit/primitives/themeMappings/props'
import Text from 'app/src/kit/Text'
import ViewKindSelector from 'app/src/kit/ViewKindSelector'
import { useToggleFeature, useUniverse } from 'app/state'
import isNil from 'lodash-es/isNil'
import { Fragment, memo, useCallback, useMemo, useRef } from 'react'
import { Code as ContentLoader } from 'react-content-loader'
import AutoSizer, { Size } from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import { styled, useTheme } from 'styled-components'
import { UniverseCardSelectorModal } from '../../../myFarm/tools/UniverseSelector'
import { useBackgroundMapDispatcher } from './contexts/BackgroundMapContext'
import FetchSummariesError from './FetchSummariesError'
import NoSummaries from './NoSummaries'
import NoSummariesMatchingSearchTermContent from './NoSummariesMatchingSearchTermContent'
import PlotOrStationCard, {
  PLOT_OR_STATION_CARD_BORDER_WIDTH,
  PLOT_OR_STATION_CARD_HEIGHT
} from './PlotOrStationCard'
// Adding plus one for the borders to display
const ROW_HEIGHT = PLOT_OR_STATION_CARD_HEIGHT + PLOT_OR_STATION_CARD_BORDER_WIDTH
const emptyArray: HorizonOpt[] = []

const HEADER_RIGHT_MARGIN = 96
export const METRIC_COLUMN_WIDTH = 80
export const METRIC_COLUMN_GAP = 8

const ListHeaderContainer = styled(Flex)`
  border-color: ${(p) => p.theme.colors.grayscale['100']};
  border-width: 0 0 1px 0;
  border-style: solid;
  padding-right: ${HEADER_RIGHT_MARGIN}px;
`

const RowCard = styled(Card)`
  overflow: hidden;
  height: 100%;
  transition: border-color 0.2s ease-in-out;
`

export interface ListItemData {
  isLoading: boolean
  isError: boolean
  isExpertOrg: boolean
  currentItems: PlotOrStationSummary[]
  hiddenElementsCount?: number
  hasAlreadyFetchedAndHasNoItems: boolean
  hasNoItemsMatchingSearchTerm: boolean
  summariesQueryCacheKey: QueryKey
  focusedHorizon: number | null
  focusedMetric: BackgroundMapUniverseMetric | undefined
}

interface PlotListProps {
  availableHorizonOptions: HorizonOpt[]
  allSummaries: PlotOrStationSummary[]
  currentItems: PlotOrStationSummary[]
  focusedHorizon: number | null
  hasAlreadyFetchedAndHasNoItems: boolean
  hiddenElementsCount: number
  isError: boolean
  isExpertOrg: boolean
  isLoading: boolean
  myPreferredUnits: RawMyProfileUnits | undefined
  onHorizonChange: (newHorizon: number | null) => void
  summariesQueryCacheKey: QueryKey
}

/** Leaves space for FAB at the end of the list */
const listStyle = {
  paddingBottom: 64
}

interface PlotOrStationListProps {
  allSummaries: PlotOrStationSummary[]
  currentItems: PlotOrStationSummary[]
  focusedHorizon: number | null
  hasAlreadyFetchedAndHasNoItems: boolean
  hiddenElementsCount: number
  isError: boolean
  isExpertOrg: boolean
  isLoading: boolean
  summariesQueryCacheKey: QueryKey
}

interface RowProps {
  index: number
  style: BoxProps['style']
  data: ListItemData
}

const ShowFilteredElementsLink: FC<Pick<RowProps, 'style' | 'data'>> = ({ style, data }) => {
  const { t } = useIntl()
  const client = useClient()
  const [updatePreferences] = useMutation(client.preferences.update())

  if (isNil(data.hiddenElementsCount) || data.hiddenElementsCount === 0) return <></>
  return (
    <Flex $justifyContent='center' $alignItems='center' $p='lg' style={style}>
      <Text>
        {data.hiddenElementsCount === 1
          ? t('models.plot.misc.singular_item_count_hidden', { count: data.hiddenElementsCount })
          : t('models.plot.misc.items_count_hidden', { count: data.hiddenElementsCount })}
        {'. '}
        <Link
          $underlined
          onPress={() => {
            updatePreferences({
              display: {
                onlyFavorites: false,
                hideEmptyDataPOD: false,
                kind: 'all'
              }
            })
          }}
        >
          {t('models.plot.actions.show_hidden_elements')}
        </Link>
      </Text>
    </Flex>
  )
}

const Row: FC<RowProps> = memo<RowProps>(({ index, style, data }) => {
  const [universe] = useUniverse()
  const org = useOrgContext()
  const [{ redesign }] = useToggleFeature()
  const dispatch = useBackgroundMapDispatcher()
  const { isOpen, toggle, close } = useDisclosure()

  const {
    currentItems,
    hasAlreadyFetchedAndHasNoItems,
    hasNoItemsMatchingSearchTerm,
    isError,
    isLoading,
    focusedMetric,
    focusedHorizon,
    availableHorizonOptions,
    onHorizonChange,
    summariesQueryCacheKey,
    isExpertOrg
  } = data

  const nav = useNavigate()
  const [, setUniverse] = useUniverse()
  const summary = currentItems[index]

  const isNotRenderingSummary =
    isLoading || isError || hasAlreadyFetchedAndHasNoItems || hasNoItemsMatchingSearchTerm

  const hasNoDataForHorizon = useMemo(
    () =>
      isHydricStress(focusedMetric) &&
      !hasHydricStressMetricData({ metric: focusedMetric, horizonId: focusedHorizon, summary }),
    [focusedMetric, focusedHorizon, summary]
  )

  const availableUniverses = useMemo(() => {
    const measures = getMetricsFromSummary(summary)
    return getAvailableUniversesFromMetrics(measures)
  }, [summary])

  const availableHorizons = useMemo(() => {
    if (availableHorizonOptions === undefined) return []
    if (focusedMetric === 'HPOT') {
      const horizonIdsOnPlot = Array.from(extractAvailableHorizonIdsSet(summary))
      return availableHorizonOptions.filter((hOpt) => horizonIdsOnPlot.includes(hOpt.value))
    } else {
      return emptyArray
    }
  }, [focusedMetric, summary, availableHorizonOptions])

  const itemContent = (
    <PlotOrStationCard
      summary={summary}
      summariesQueryCacheKey={summariesQueryCacheKey}
      onHorizonChange={onHorizonChange}
      availableHorizonOptions={availableHorizons}
      hasNoDataForHorizon={hasNoDataForHorizon}
      focusedHorizon={focusedHorizon}
      focusedMetric={focusedMetric}
      isExpertOrg={isExpertOrg}
    />
  )

  const content = isLoading ? (
    // Height is important otherwise not displayed
    <ContentLoader height='100%' uniqueKey='fetchSummariesLoader' width='100%' />
  ) : isError ? (
    <FetchSummariesError />
  ) : hasAlreadyFetchedAndHasNoItems ? (
    <NoSummaries />
  ) : hasNoItemsMatchingSearchTerm ? (
    <NoSummariesMatchingSearchTermContent />
  ) : (
    itemContent
  )

  const MaybeLink = isNotRenderingSummary ? Fragment : Link

  const currentItemId = isPlotSummary(summary)
    ? summary.metadata.plot.id
    : summary.metadata.station.id

  const maybeLinkProps = isNotRenderingSummary
    ? {}
    : {
        to: isPlotSummary(summary)
          ? `/farms/${org.id}/plots/${summary.metadata.plot.id}/${
              universe === Universe.irrelis ? ('irrelis' as const) : ''
            }`
          : `/farms/${org.id}/stations/${summary.metadata.station.id}`
      }

  const outerBoxStyle = useMemo(
    () => ({
      ...style,
      padding: redesign ? '0' : '8px',
      paddingTop: redesign ? '0' : '4px',
      paddingBottom: redesign ? '0' : '4px',
      height:
        hasNoItemsMatchingSearchTerm || hasAlreadyFetchedAndHasNoItems ? 'unset' : style?.height
    }),
    [style, redesign, hasNoItemsMatchingSearchTerm, hasAlreadyFetchedAndHasNoItems]
  )

  const handleMouseEnter = useCallback(() => {
    if (!isNotRenderingSummary) {
      dispatch({
        type: 'setHoverIntent',
        newHoverIntent: {
          itemId: currentItemId,
          source: 'list'
        }
      })
    }
  }, [isNotRenderingSummary, dispatch, currentItemId])

  const handleClickOnOverviewElement = useCallback(() => {
    if (availableUniverses.length > 1) {
      return toggle()
    } else if (availableUniverses.length === 1 && !isNil(maybeLinkProps.to)) {
      setUniverse(availableUniverses[0])
      return nav(maybeLinkProps.to)
    }
  }, [availableUniverses, maybeLinkProps.to, toggle])
  return (
    // The outer box is needed otherwise card margin won't properly apply, and we need to pass style given by the list
    <Box style={outerBoxStyle}>
      {(universe !== Universe.overview || isStationSummary(summary)) && (
        <MaybeLink {...maybeLinkProps}>
          {isNotRenderingSummary ? (
            <RowCard onMouseEnter={handleMouseEnter}>{content}</RowCard>
          ) : (
            content
          )}
        </MaybeLink>
      )}
      {universe === Universe.overview && isPlotSummary(summary) && (
        <>
          <Flex onClick={handleClickOnOverviewElement}>
            {isNotRenderingSummary ? (
              <RowCard onMouseEnter={handleMouseEnter}>{content}</RowCard>
            ) : (
              content
            )}
          </Flex>
          {availableUniverses.length > 1 && (
            <UniverseCardSelectorModal
              isOpen={isOpen}
              summary={summary}
              summaryLink={maybeLinkProps.to!}
              handleClose={close}
            />
          )}
        </>
      )}
    </Box>
  )
})

const RowWrapper: FC<RowProps> = memo<RowProps>(({ index, style, data }) => {
  if (index >= data.currentItems.length)
    return <ShowFilteredElementsLink data={data} style={style} />
  return <Row index={index} style={style} data={data} />
})

const PlotOrStationAutosizeList: FC<
  Pick<
    PlotListProps,
    | 'allSummaries'
    | 'currentItems'
    | 'isError'
    | 'isExpertOrg'
    | 'isLoading'
    | 'hasAlreadyFetchedAndHasNoItems'
    | 'hiddenElementsCount'
    | 'summariesQueryCacheKey'
    | 'focusedHorizon'
  >
> = ({
  isError,
  isExpertOrg,
  isLoading,
  allSummaries,
  currentItems,
  hasAlreadyFetchedAndHasNoItems,
  hiddenElementsCount,
  summariesQueryCacheKey,
  focusedHorizon
}) => {
  const { focusedMetricId } = useUniverseBackgroundMapContext()
  const listRef = useRef<FixedSizeList<ListItemData>>(null)

  const itemData: ListItemData = {
    isLoading,
    isError,
    isExpertOrg,
    currentItems,
    hiddenElementsCount,
    hasAlreadyFetchedAndHasNoItems,
    hasNoItemsMatchingSearchTerm: currentItems.length === 0,
    summariesQueryCacheKey,
    focusedHorizon,
    focusedMetric: focusedMetricId
  }

  const listItemCount =
    allSummaries.length === currentItems.length ? currentItems.length : currentItems.length + 1

  return (
    <>
      {currentItems.length === 0 && (
        <DelimitedFlex $justifyContent='center' $backgroundColor={'grayscale.white'}>
          <ListEmpty isFetchPending={isLoading} />
        </DelimitedFlex>
      )}

      <AutoSizer>
        {({ height, width }: Size) => (
          <FixedSizeList
            height={height}
            ref={listRef}
            itemCount={listItemCount}
            itemSize={ROW_HEIGHT}
            width={width}
            itemData={itemData}
            style={listStyle}
          >
            {RowWrapper}
          </FixedSizeList>
        )}
      </AutoSizer>
    </>
  )
}

const PlotOrStationListHeader: FC<{
  focusedMetric: BackgroundMapUniverseMetric | undefined
}> = ({ focusedMetric }) => {
  const { t } = useIntl()
  const { colors } = useTheme()

  return (
    <ListHeaderContainer $flexDirection='row' $justifyContent='flex-end' $py='lg' $px='md' $mr={0}>
      {Object.values(metricsAndLabelKeyDisplayedByMetric(focusedMetric))?.map((labelKey) => (
        <Flex
          $width={METRIC_COLUMN_WIDTH + METRIC_COLUMN_GAP}
          key={labelKey}
          $justifyContent='flex-end'
        >
          <Text
            $lineHeight='none'
            $fontWeight='bold'
            $color={colors.grayscale.black}
            $textAlign='end'
          >
            {t(`${labelKey}`, { capitalize: true })}
          </Text>
        </Flex>
      ))}
    </ListHeaderContainer>
  )
}

const AbsolutelyPositionedSwitch = styled(ViewKindSelector)`
  position: absolute;
  bottom: 16px;
`

const PlotOrStationList = ({
  allSummaries,
  currentItems,
  focusedHorizon,
  hasAlreadyFetchedAndHasNoItems,
  hiddenElementsCount,
  isError,
  isExpertOrg,
  isLoading,
  summariesQueryCacheKey
}: PlotOrStationListProps) => {
  const { focusedMetricId } = useUniverseBackgroundMapContext()
  const [universe] = useUniverse()

  return (
    <Flex $flex={1} $flexDirection='column' $backgroundColor={'grayscale.50'} $pointerEvents='auto'>
      {universe !== Universe.overview && (
        <PlotOrStationListHeader focusedMetric={focusedMetricId} />
      )}
      <Flex $flex={1} $flexDirection='column'>
        <PlotOrStationAutosizeList
          allSummaries={allSummaries}
          currentItems={currentItems}
          focusedHorizon={focusedHorizon}
          hasAlreadyFetchedAndHasNoItems={hasAlreadyFetchedAndHasNoItems}
          hiddenElementsCount={hiddenElementsCount}
          isError={isError}
          isExpertOrg={isExpertOrg}
          isLoading={isLoading}
          summariesQueryCacheKey={summariesQueryCacheKey}
        />
        <AbsolutelyPositionedSwitch />
      </Flex>
    </Flex>
  )
}

export default PlotOrStationList
