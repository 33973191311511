import { useClient, useMutation, useQuery } from '@weenat/client'
import * as zodSchemas from '@weenat/client/dist/zod-schemas'
import { useIntl } from '@weenat/wintl'
import { useNavigate, useSearchParams } from 'app/routx-router'
import useToasts from 'app/src/hooks/useToasts'
import MapBackgroundImage from 'app/src/kit/MapBackgroundImage'
import { useToken } from 'app/state'
import isNil from 'lodash-es/isNil'
import { useEffect } from 'react'

export const settings = {
  public: 'only',
  search: zodSchemas.tokenWithRefresh
}

function Smag() {
  const client = useClient()
  const nav = useNavigate()
  const { addToast } = useToasts()
  const { t } = useIntl()
  const [weenatToken, setWeenatToken] = useToken()
  const [searchParams] = useSearchParams(settings.search)

  const [associate] = useMutation(client.smag.associate(), {
    onSuccess: () => {
      nav('/me/account')
    },
    onError: () => {
      addToast(t('errors.unknown'), {
        appearance: 'error',
        id: `smag-unknown-error`
      })
    }
  })

  const [loginFromSmag] = useMutation(client.smag.login(), {
    onSuccess: ({ token }) => {
      setWeenatToken(token)
    }
  })

  const infos = useQuery(client.smag.infos(), { enabled: !isNil(weenatToken) })

  useEffect(() => {
    const { token, refresh_token: refreshToken } = searchParams
    const isAssociated = infos.data?.isAssociated

    if (!isNil(token) && !isNil(refreshToken)) {
      if (isNil(weenatToken)) {
        loginFromSmag()
      } else if (!isAssociated) {
        associate({ token, refreshToken })
      } else {
        nav('/farms')
      }
    }
  }, [infos.isSuccess, weenatToken])

  return <MapBackgroundImage />
}
export const Component = Smag
export default Smag
