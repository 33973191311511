import type { SVGProps } from 'react'
const SvgExploreNearbyOff = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 24 24' fill='none' {...props}>
    <defs>
      <mask id='explore-nearby-off_svg__a' maskUnits='userSpaceOnUse'>
        <path fill='#d9d9d9' d='M0 0h24v24H0z' />
      </mask>
    </defs>
    <path
      fill='#000'
      d='M12.336 2a9.74 9.74 0 0 0-3.9.787c-.612.264-1.184.575-1.715.934l3.428 3.427c.642-.431 1.37-.648 2.187-.648 1.1 0 2.04.392 2.824 1.176.783.783 1.176 1.724 1.176 2.824 0 .753-.185 1.513-.555 2.281l4.834 4.834c.359-.531.67-1.103.934-1.715.525-1.216.787-2.517.787-3.9s-.262-2.684-.787-3.9-1.239-2.274-2.139-3.174-1.957-1.614-3.174-2.139S13.72 2 12.336 2zM3 3.164a1 1 0 0 0-.707 1.707l1.664 1.664c-.317.489-.595 1.01-.834 1.565-.525 1.216-.787 2.517-.787 3.9s.262 2.684.787 3.9 1.239 2.274 2.139 3.174c.9.9 1.957 1.614 3.174 2.14 1.216.524 2.517.786 3.9.786s2.684-.262 3.9-.787c.555-.24 1.076-.517 1.565-.834l1.463 1.463a1 1 0 1 0 1.414-1.414L3.707 3.457A.997.997 0 0 0 3 3.164zm5.356 7.772 5.216 5.214c-.383.459-.795.909-1.236 1.35a19.738 19.738 0 0 1-2-2.324c-.5-.684-.958-1.434-1.375-2.25-.341-.67-.544-1.333-.605-1.99z'
      mask='url(#explore-nearby-off_svg__a)'
    />
  </svg>
)
export default SvgExploreNearbyOff
