import { useIntl } from '@weenat/wintl'
import { resolvePathPattern, useMatches } from 'app/routx-router'
import { useBreadcrumbsCtx } from 'app/state'
import { isEmpty } from 'lodash-es'

function useBreadcrumbs() {
  const matches = useMatches()
  const { t } = useIntl()
  const [breadcrumbsCtx] = useBreadcrumbsCtx()

  return matches
    .map((match) => {
      const pp = resolvePathPattern(match.pathname)

      const label = t(
        // @ts-expect-error this is fine
        `breadcrumbs.${pp}` as const,
        breadcrumbsCtx
      )

      return { label, path: match.pathname }
    })
    .filter((c) => {
      return !isEmpty(c.label) && !c.label.includes('{') && !c.path.endsWith('/')
    })
}

export default useBreadcrumbs
