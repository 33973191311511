import { useChartCtx } from '@weenat/client/dist/core/charts'
import { DataPoint } from '@weenat/client/dist/core/charts/d3-charts.type'
import { isNil } from 'lodash-es'
import { ReactNode } from 'react'

interface ChartGutterProps {
  data?: DataPoint[]
  renderItem: (args: { date: Date; value: number; y: number; x: number }) => ReactNode
  height?: number
}

const ChartGutter: FC<ChartGutterProps> = ({ data, height = 16, renderItem }) => {
  const { xScale, marginTop } = useChartCtx()

  if (isNil(data)) return null

  const transform = `translate(0, ${marginTop - height})`

  return (
    <g transform={transform}>
      {data.map((dp) => {
        return renderItem({
          date: dp.date,
          value: dp.value,
          x: xScale(dp.date),
          y: height / 2
        })
      })}
    </g>
  )
}

export default ChartGutter
