import { FormattedDataPoint, YDomain } from '@weenat/client/dist/core/charts/charts'
import { getColorScalePerMetric } from '@weenat/client/dist/core/measurements'
import isEqual from 'lodash-es/isEqual'
import isNil from 'lodash-es/isNil'
import { darken } from 'polished'
import { memo, useCallback, useMemo } from 'react'
import { VictoryBar, VictoryBarProps } from 'victory'

interface RainfallBarsProps extends VictoryBarProps {
  metric?: 'RR' | 'etp' | 'DRN'
  absoluteYDomain: YDomain
  includeNull?: boolean
  customFill?: ({ datum }: { datum?: unknown }) => string
  barRatio?: number
}

const Label = <></>

/** @deprecated remove with chartWithD3 flag  */
const RainfallBars: FC<RainfallBarsProps> & { role: string } = memo<RainfallBarsProps>(
  ({ metric = 'RR', absoluteYDomain, data, includeNull, customFill, barRatio, ...props }) => {
    const colorScale = getColorScalePerMetric(metric)

    const notNullData = useMemo(() => {
      return (data as FormattedDataPoint[])?.filter((point) => point.y !== null && point.y !== 0)
    }, [data])

    const getFill = useCallback(
      ({ datum }: { datum?: unknown }) => {
        return !isNil(customFill)
          ? customFill({ datum })
          : colorScale(absoluteYDomain)(datum?.value as number)
      },
      [customFill, colorScale, absoluteYDomain]
    )

    const getStrokeColor = useCallback(
      ({ datum }: { datum?: unknown }) => {
        const fill = getFill({ datum })

        return darken(0.25, fill)
      },
      [getFill]
    )

    const style = useMemo(
      () => ({
        data: {
          fill: getFill,
          stroke: getStrokeColor,
          strokeWidth: 0.5
        }
      }),
      [getFill, getStrokeColor]
    )

    return (
      <VictoryBar
        {...props}
        barRatio={barRatio}
        data={includeNull ? data : notNullData}
        style={style}
        labelComponent={Label}
      />
    )
  },
  (pp, np) => {
    const { scale: _2, ...ppNoScale } = pp
    const { scale: _1, ...npNoScale } = np
    return isEqual(ppNoScale, npNoScale)
  }
)

//this role is use inside victory to check for child that can handle events
RainfallBars.role = 'group'

export default RainfallBars
