import { WeenatColor } from '@weenat/theme'
import Text from 'app/src/kit/Text'
import Badge from './Badge'

interface CountBadgeProps {
  total: number
  count?: number
  backgroundColor?: WeenatColor
  color?: WeenatColor
}

const CountBadge = ({
  total,
  count,
  backgroundColor = 'primary.500',
  color = 'grayscale.white'
}: CountBadgeProps) => {
  return (
    <Badge $px='md' $py='xs' $backgroundColor={backgroundColor}>
      <Text $fontSize='sm' $fontWeight='bold' $color={color}>{`${count} / ${total}`}</Text>
    </Badge>
  )
}

export default CountBadge
