import Text, { TextProps } from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { ReactNode } from 'react'
import { styled } from 'styled-components'

interface ErrorMessageProps extends TextProps {
  $error?: ReactNode
}

const StyledText = styled(Text)<ErrorMessageProps>`
  color: ${(p) => (!isNil(p.$error) ? p.theme.colors.feedback.error['500'] : 'transparent')};
  transition: color 0.2s ease-in-out;
`

const ErrorMessage: FC<ErrorMessageProps> = ({ $error: error, ...textProps }) => {
  return (
    <StyledText $fontSize='sm' $error={error} {...textProps}>
      {error}
    </StyledText>
  )
}

export default ErrorMessage
