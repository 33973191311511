import isNil from 'lodash-es/isNil'
import styled from 'styled-components'

const PADDING = 16

/** remove interface with redesign flag */
interface MapControlsOverlayProps {
  $left?: number
  $redesign: boolean
}

const MapControlsOverlay = styled(Flex)<MapControlsOverlayProps>`
  position: absolute;
  z-index: -1;

  top: 0px;
  left: ${(p) => (!isNil(p.$left) ? p.$left : 0) + PADDING}px;
  right: ${PADDING}px;
  bottom: ${PADDING}px;

  flex: 1;
  flex-direction: column;
`

export default MapControlsOverlay
