import { Devices } from '@weenat/client/dist/enums'
import { DeviceAvailableMeasure } from '@weenat/client/dist/resources/devices'
import { MeasurementConfig } from '@weenat/client/dist/resources/measurementConfigs'
import { PlotAvailableMeasure } from '@weenat/client/dist/resources/plots'
import { useIntl } from '@weenat/wintl'
import DeviceDistanceFromPlot from 'app/src/kit/DeviceDistanceFromPlot'
import Text from 'app/src/kit/Text'
import UpdateInformation from 'app/src/kit/UpdateInformation'
import WindHeightExplanation from 'app/src/kit/WindHeightExplanation'
import MetricIcons from 'app/src/kit/icons/MetricIcons'
import isNil from 'lodash-es/isNil'
import { useTheme } from 'styled-components'

interface MetricSummaryHeaderProps {
  /** Timestamp at which the latest data was received */
  latestUpdate?: number
  metric: DeviceAvailableMeasure | PlotAvailableMeasure
  isShrunk?: boolean
  provider?: string
  warningThreshold?: Devices.UpdateWarningThresholds
  hasVirtualDevice?: boolean
  measurementConfig?: MeasurementConfig
  deviceId?: number
}

const MetricSummaryHeader: FC<MetricSummaryHeaderProps> = ({
  isShrunk,
  metric,
  provider,
  latestUpdate,
  warningThreshold,
  hasVirtualDevice,
  measurementConfig,
  deviceId
}) => {
  const { t } = useIntl()
  const { colors } = useTheme()

  const metricColor = colors.metrics[metric]?.['500'] || colors.grayscale.black
  const MetricIcon = MetricIcons[metric as 'RR']

  return (
    <Flex $alignItems='center' $mb='md'>
      {!isShrunk && !isNil(MetricIcon) && (
        <MetricIcon
          $backgroundColor={metricColor}
          $size='xl'
          $mr='md'
          $color={'grayscale.white'}
          $p='sm'
        />
      )}
      <Box>
        <Flex $flexWrap='wrap' $alignItems='center'>
          <Text $fontWeight='bold' $color={metricColor} $lineHeight='none'>
            {t(`metrics.${metric as 'RR'}`, { capitalize: true })}
          </Text>

          {metric === 'FF' && !isShrunk && (
            <Box $ml='sm'>
              <WindHeightExplanation />
            </Box>
          )}
        </Flex>
        <Flex $flexWrap='wrap' $alignItems='center' $mt='sm'>
          <UpdateInformation
            warningThreshold={warningThreshold}
            timestamp={latestUpdate}
            isVirtualMetric={hasVirtualDevice}
            name={provider}
            deviceId={deviceId}
          />
          {!isNil(measurementConfig) && (
            <Box $pl='sm'>
              <DeviceDistanceFromPlot measurementConfig={measurementConfig} />
            </Box>
          )}
        </Flex>
      </Box>
    </Flex>
  )
}

export default MetricSummaryHeader
