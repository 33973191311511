import { Popover, PopoverPlacement, PopoverProps } from '@idui/react-popover'
import {
  CurrentTour,
  LightTourKey,
  TourName,
  TourStepName,
  getStepItems,
  getStepKey,
  getStepTitleKey,
  useTourStepPop
} from '@weenat/client/dist/core/tours'
import * as zodSchemas from '@weenat/client/dist/zod-schemas'
import { TKey, useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import { useNavigate, useSearchParams } from 'app/routx-router'
import useDisclosure from 'app/src/hooks/useDisclosure'
import Text from 'app/src/kit/Text'
import { useCurrentTour, useLightTours } from 'app/state'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { ReactNode, useCallback } from 'react'
import { styled, useTheme } from 'styled-components'
import Badge from './Badge'
import Button from './Button'
import Icons from './Icons'

const StyledPopover = styled(Popover)<{ $bgColor: string }>`
  /* Otherwise override */
  background-color: ${(p) => p.$bgColor || p.theme.colors.primary[500]} !important;
  border-radius: ${(p) => p.theme.radiuses.md}px !important;
  max-width: 800px !important;
  padding: 0;
`

const Header = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px;
  overflow: hidden;
  border-radius: ${(p) => p.theme.radiuses.md}px ${(p) => p.theme.radiuses.md}px 0 0;
  background-color: ${({ theme }) => theme.colors.primary[500]};
`
type StepKey = `tours.${TourName}.steps.${TourStepName}`
type PopPrimitiveProps = {
  /** Used to inject dynamic component translations */
  postTranslateItems?: (translatedStepItems: string[], stepKey: StepKey) => (string | ReactNode)[]
  onClose: () => void
  stepKey: StepKey
  isOpen: boolean
  hasPrev?: boolean
  title?: string
  onIgnore?: () => void
  onNext?: () => void
  onPrev?: () => void
  placement?: `${PopoverPlacement}`
  nextKey?: TKey
  items?: string[]
  /** Content that will be placed over a blue gradient background */
  headerContent?: ReactNode
} & Omit<PopoverProps, 'placement'>

const PopOverPrimitive: FC<PopPrimitiveProps> = ({
  onClose,
  title,
  stepKey,
  onIgnore,
  hasPrev = false,
  onNext = onClose,
  onPrev = onClose,
  placement: maybePlacement,
  isOpen,
  items,
  children,
  nextKey,
  headerContent,
  postTranslateItems,
  ...popOverProps
}) => {
  const { colors } = useTheme()
  const { t } = useIntl()

  const placement = PopoverPlacement[!isNil(maybePlacement) ? maybePlacement : 'bottom']
  const itemsOrStepKeyAsItems =
    !isNil(items) && !isEmpty(items)
      ? items
      : [
          //@ts-expect-error We assume no translation arguments
          t(stepKey)
        ]

  const finalItems = postTranslateItems
    ? postTranslateItems(itemsOrStepKeyAsItems, stepKey)
    : itemsOrStepKeyAsItems

  const bgColor = colors.plans.expert['500']
  const reverseColor = 'grayscale.white'
  const textColor = 'grayscale.white'

  return (
    <StyledPopover
      {...popOverProps}
      considerContentResizing
      zIndex={100}
      placement={placement}
      arrowSize={16}
      guessBetterPosition
      arrowColor={bgColor}
      $bgColor={bgColor}
      content={
        <Flex
          $flexDirection='column'
          $flex={1}
          // Otherwise the Pop is always dismissed
          onClick={(e) => e.preventDefault()}
        >
          {!isNil(headerContent) ? <Header>{headerContent}</Header> : null}

          {/* POP CONTENT */}
          <Flex $flexDirection='column' $p='lg'>
            {/* NEW BADGE */}
            {!isNil(headerContent) ? (
              <Box $mb='md'>
                <Badge $backgroundColor={'primary.500'} $p='md' $py='sm'>
                  <Text $fontSize='sm' $fontWeight='bold' $color={'grayscale.white'}>
                    {t('tours.new').toLocaleUpperCase()}
                  </Text>
                </Badge>
              </Box>
            ) : null}

            {/* TITLE */}
            {!isEmpty(title) ? (
              <Box $mt={!isNil(headerContent) ? undefined : 'lg'} $mr='md'>
                <Text $fontSize='md' $fontWeight='bold' $color={textColor}>
                  {title}
                </Text>
              </Box>
            ) : null}

            {/* TEXT & EXPLANATIONS */}
            <Flex $flexDirection='column' $mt='lg' $mb='md'>
              {finalItems.map((item, i) =>
                typeof item === 'string' ? (
                  // eslint-disable-next-line react/no-array-index-key
                  <Box $mb='sm' key={i}>
                    <Text $color={textColor}>{item}</Text>
                  </Box>
                ) : (
                  // eslint-disable-next-line react/no-array-index-key
                  <Box $mb='sm' key={i}>
                    {item}
                  </Box>
                )
              )}
            </Flex>

            {/* BUTTONS */}
            <Flex $justifyContent='space-between' $alignItems='center' $mt='lg'>
              {onIgnore ? (
                <Box $mr='md'>
                  <Button
                    backgroundColor='transparent'
                    color={reverseColor}
                    onPress={(e) => {
                      e.preventDefault()
                      onIgnore()
                    }}
                  >
                    {t('actions.ignore')}
                  </Button>
                </Box>
              ) : null}
              <Flex $alignItems='center' $justifyContent='center'>
                {hasPrev && (
                  <Box $mr='md'>
                    <Button
                      backgroundColor='transparent'
                      color={reverseColor}
                      onPress={(e) => {
                        e.preventDefault()
                        onPrev()
                        // Important otherwise navigation is broken
                        setTimeout(onClose, 0)
                      }}
                    >
                      {t('actions.previous')}
                    </Button>
                  </Box>
                )}
                {/* There is always a next button */}
                <Button
                  onPress={(e) => {
                    e.preventDefault()
                    onNext()
                    // Important otherwise navigation is broken
                    setTimeout(onClose, 0)
                  }}
                  IconRight={Icons.ArrowRight}
                  backgroundColor={reverseColor}
                  color={bgColor}
                >
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    t(nextKey || 'actions.next')
                  }
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      }
      isOpen={isOpen}
    >
      {children}
    </StyledPopover>
  )
}

type PopProps = Partial<
  Pick<
    PopPrimitiveProps,
    'postTranslateItems' | 'placement' | 'headerContent' | 'onNext' | 'onClose'
  >
> & {
  tourName: TourName
  stepName: TourStepName
  firstDeviceId?: number
}

const ActiveTourPop: FC<
  PopProps & {
    currentTour: CurrentTour
    setCurrentTour: React.Dispatch<React.SetStateAction<CurrentTour>>
  }
> = ({
  stepName,
  tourName,
  children,
  firstDeviceId,
  headerContent,
  placement,
  postTranslateItems,
  currentTour,
  setCurrentTour,
  ...props
}) => {
  const { t } = useIntl()
  const nav = useNavigate()
  const { id: orgId } = useOrgContext()
  // We do not want to run the validation, only get the correct type
  const [searchParams] = useSearchParams<typeof zodSchemas.popScreen>()

  const items = getStepItems(tourName, stepName)

  const { isOpen, onNext, onClose, onIgnore, onPrev, hasPrev, currentStep } = useTourStepPop({
    orgId,
    queryParams: searchParams,
    nav,
    tourName,
    stepName,
    setCurrentTour,
    currentTour,
    deviceIdToGoOnMyFarm: firstDeviceId,
    isNative: false
  })

  const stepKey = getStepKey(tourName, stepName)

  const title = t(
    getStepTitleKey(tourName, stepName) as 'tours.discover_alerts.titles.confirm_alert_type'
  )

  return (
    <PopOverPrimitive
      title={title}
      nextKey={!isNil(currentStep) && 'next' in currentStep ? currentStep.next : undefined}
      hasPrev={hasPrev}
      isOpen={isOpen}
      onClose={() => {
        onClose()
        props.onClose?.()
      }}
      onIgnore={onIgnore}
      onNext={() => {
        onNext()
        props.onNext?.()
      }}
      onPrev={onPrev}
      placement={placement}
      items={items}
      stepKey={stepKey}
      headerContent={headerContent}
      postTranslateItems={postTranslateItems}
    >
      {children}
    </PopOverPrimitive>
  )
}

const Pop: FC<PopProps> = ({
  children,
  tourName,
  stepName,
  placement,
  headerContent,
  postTranslateItems,
  firstDeviceId,
  ...props
}) => {
  const [currentTour, setCurrentTour] = useCurrentTour()

  return !isNil(currentTour) && currentTour.name === tourName ? (
    <ActiveTourPop
      stepName={stepName}
      tourName={tourName}
      firstDeviceId={firstDeviceId}
      headerContent={headerContent}
      onClose={props.onClose}
      onNext={props.onNext}
      placement={placement}
      currentTour={currentTour}
      setCurrentTour={setCurrentTour}
    >
      {children}
    </ActiveTourPop>
  ) : (
    <>{children}</>
  )
}

type LightPopProps = Pick<PopPrimitiveProps, 'placement' | 'title' | 'items'> & {
  lightTourKey: LightTourKey
  textKey?: TKey
}

export const LightPop: FC<LightPopProps> = ({
  lightTourKey,
  placement,
  textKey,
  children,
  ...popOverProps
}) => {
  const [currentTour] = useCurrentTour()
  const [alreadySeenLightTours, setLightTours] = useLightTours()
  const isOpenByDefault = currentTour === undefined && !alreadySeenLightTours.includes(lightTourKey)
  const { close, isOpen } = useDisclosure(isOpenByDefault)
  const handleClose = useCallback(() => {
    close()
    if (!alreadySeenLightTours.includes(lightTourKey)) {
      setLightTours([...alreadySeenLightTours, lightTourKey])
    }
  }, [close, setLightTours, alreadySeenLightTours, lightTourKey])
  return (
    <PopOverPrimitive
      {...popOverProps}
      isOpen={isOpen && currentTour == null}
      onClose={handleClose}
      stepKey={textKey}
      nextKey='actions.understand'
      placement={placement}
    >
      {children}
    </PopOverPrimitive>
  )
}

export default Pop
