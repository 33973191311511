import { enums, schemas, useClient, useQuery } from '@weenat/client'
import ErrCode from '@weenat/client/dist/ErrCode'
import { PossibleError } from '@weenat/client/dist/errors'
import { Org } from '@weenat/client/dist/resources/orgs'
import * as zodSchemas from '@weenat/client/dist/zod-schemas'
import { useIntl } from '@weenat/wintl'
import { useNavigate, useSearchParams } from 'app/routx-router'
import AuthCard from 'app/src/authentication/components/AuthCard'
import { useLogout } from 'app/src/authentication/components/Logout'
import useToasts from 'app/src/hooks/useToasts'
import useWishedNetworkFromUrl from 'app/src/hooks/useWishedNetworkFromUrl'
import Button from 'app/src/kit/Button'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'
import Link from 'app/src/kit/LinkComponent'
import SubmitButton from 'app/src/kit/SubmitButton'
import SuperForm from 'app/src/kit/SuperForm'
import Text from 'app/src/kit/Text'
import EmailField from 'app/src/kit/fields/EmailField'
import PasswordField from 'app/src/kit/fields/PasswordField'
import { useToken } from 'app/state'
import logEvent from 'app/utils/analytics'
import { FormikProps } from 'formik'
import { isEmpty } from 'lodash-es'
import isNil from 'lodash-es/isNil'
import { useRef, useState } from 'react'

const EMPTY_ORGS: Org[] = []

export const settings = {
  search: zodSchemas.email.merge(zodSchemas.wishedNetwork)
}

/**
 * After 10 days we redirect users with an unverified email toward email validation form
 */
const useHandleUnverifiedUserEmail = () => {
  const logout = useLogout()
  const nav = useNavigate()
  return async (e: PossibleError) => {
    if (e.status === 401) {
      await logout()
      nav('/auth/login', { replace: true })
    }
    if (Array.isArray(e.nonFieldErrors)) {
      const [firstNonFieldError] = e.nonFieldErrors
      if (firstNonFieldError?.code === ErrCode.userUnverifiedEmail) {
        // TODO: see https://linear.app/weenat/issue/APP-403#comment-b7abea8c
        return logout('/auth/send-verification-email')
      }
    }
  }
}

const LOGIN_FORM_SCHEMA = schemas.auth.login
type LoginFormValues = typeof LOGIN_FORM_SCHEMA.initialValues

const Login = () => {
  const nav = useNavigate()
  const { t } = useIntl()
  const client = useClient()
  const [searchParams] = useSearchParams(settings.search)
  const { addErrorToast } = useToasts()
  const [, setToken] = useToken()

  const [loginState, setLoginState] = useState<'loading' | 'success' | 'error' | 'idle'>('idle')

  const formRef = useRef<FormikProps<Partial<LoginFormValues>>>(null)

  const wishedNetwork = useWishedNetworkFromUrl() || searchParams.wishedNetwork

  const networkName = useQuery(client.networks.get(wishedNetwork?.networkId), {
    enabled: !isNil(wishedNetwork?.networkId)
  }).data?.name

  const handleUnverifiedUserEmail = useHandleUnverifiedUserEmail()

  const sharedProps = { isDisabled: loginState === 'loading' }

  const onLoginFormSubmission = async (values: LoginFormValues) => {
    try {
      setLoginState('loading')
      const loginResult = await client.auth.login().request(values)
      setToken(loginResult.token)

      try {
        const ownedOrgs = await client.orgs.getAllPages({ myRole: enums.OrgRole.owner }).request()
        const firstOwnedOrg = ownedOrgs.at(0)
        if (!isNil(wishedNetwork?.networkId) && !isNil(firstOwnedOrg)) {
          client.invitations.beg
            .networkMembership(wishedNetwork?.networkId)
            .request({ organizationId: firstOwnedOrg.id, trial: wishedNetwork.trial })
        }
      } finally {
        setLoginState('success')
        nav(`/farms`, { replace: true })
      }
    } catch (e) {
      setLoginState('error')
      const castedE = e as PossibleError
      await handleUnverifiedUserEmail(castedE)
      if ('nonFieldErrors' in castedE) {
        const [firstGlobalError] = castedE.nonFieldErrors ?? []
        if (!isNil(firstGlobalError) && firstGlobalError.code != ErrCode.userUnverifiedEmail) {
          let message: string

          if (!isEmpty(t(`error_codes.${firstGlobalError.code as '501'}`))) {
            message = t(`error_codes.${firstGlobalError.code as '501'}`)
          } else {
            // eslint-disable-next-line prefer-destructuring
            message = firstGlobalError.message
          }

          addErrorToast(message)
        }
      }
    }
  }

  const initialValues = { email: searchParams?.email ?? '' }

  return (
    <AuthCard>
      <Flex $flexDirection='column' $gap='lg'>
        <Text $fontSize='md' $fontWeight='bold' $textAlign='center'>
          {t('models.account.actions.login')}
        </Text>

        <SuperForm
          schema={LOGIN_FORM_SCHEMA}
          onSubmit={onLoginFormSubmission}
          initialValues={initialValues}
          formRef={formRef}
        >
          <Flex $flexDirection='column' width={'100%'} $gap='xl'>
            <Flex $flexDirection='column'>
              <EmailField {...sharedProps} />
              <PasswordField {...sharedProps} withStrengthMeter={false} />
              <Link
                $underlined
                onPress={() => {
                  logEvent('reset_password')
                  // could not be a to props because formRef is a ref and is not updated at each render but value is correct on click
                  nav(`/auth/reset-password?email=${formRef.current?.values.email}`)
                }}
                $textAlign='right'
              >
                {t('auth.forgot_password')}
              </Link>
            </Flex>
            <SubmitButton
              isLoading={loginState === 'loading'}
              isError={loginState === 'error'}
              isSuccess={loginState === 'success'}
            >
              {t('models.account.actions.login')}
            </SubmitButton>
          </Flex>
        </SuperForm>
        <Link
          $underlined
          onPress={() => {
            logEvent('login_magic')
            // could not be a props because formRef is a ref and is not updated at each render but value is correct on click
            nav(`/auth/login/magic?email=${formRef.current?.values.email}`)
          }}
          $textAlign='center'
        >
          {t('auth.login_magic')}
        </Link>

        <DelimitedFlex
          $isDelimitedOnTop
          $pt='lg'
          $mt='lg'
          $alignSelf='stretch'
          $alignItems='stretch'
        >
          <Button
            importance='sd'
            onPress={() =>
              nav('/auth/register', {
                search: {
                  // could not be a props because formRef is a ref and is not updated at each render but value is correct on click
                  email: formRef.current?.values.email,
                  wishedNetwork: wishedNetwork
                    ? {
                        ...wishedNetwork,
                        networkName: networkName as string
                      }
                    : undefined
                }
              })
            }
            isDisabled={loginState === 'loading'}
            style={{ width: '100%' }}
          >
            {t('models.account.actions.register')}
          </Button>
        </DelimitedFlex>
      </Flex>
    </AuthCard>
  )
}

export const Component = Login
export default Login
