import { WeenatColor, fromColorPathToColor } from '@weenat/theme'
import Text from 'app/src/kit/Text'
import { FlexProps } from 'app/src/kit/primitives/themeMappings/props'
import isNil from 'lodash-es/isNil'
import { styled, useTheme } from 'styled-components'
import CountIndicator from './CountIndicator'

const minWidth = { minWidth: 'max-content' }

const PillTab = styled(Flex)<{ $isActive: boolean; $backgroundColor: WeenatColor }>`
  align-items: center;
  justify-content: center;
  border-radius: ${(p) => p.theme.radiuses.md}px;
  border: 2px solid;
  background-color: ${(p) =>
    p.$isActive ? fromColorPathToColor(p.$backgroundColor) : 'transparent'};
  border-color: ${(p) => (p.$isActive ? fromColorPathToColor(p.$backgroundColor) : 'transparent')};
  width: 100%;

  padding: ${(p) => `${p.theme.spacings.md}px ${p.theme.spacings.lg}px`};
  cursor: pointer;
  gap: ${(p) => `${p.theme.spacings.md}px`};

  &:hover {
    border-color: ${(p) => p.theme.colors.grayscale[300]};
  }

  transition:
    background-color 0.2s ease-out,
    border-color 0.2s ease-out;
  will-change: border-color, background-color;
`

export interface PillTabsProps<Value extends string | number | boolean>
  extends Omit<FlexProps, 'value' | 'onChange'> {
  $isCentered?: boolean
  value: Value
  onChange: (newValue: Value) => void
  options: { value: Value; label: string; indicator?: number }[]
  $backgroundColor?: WeenatColor
  $selectedColor?: WeenatColor
}

function PillTabs<Value extends string | number | boolean>({
  onChange,
  options,
  value,
  $isCentered = true,
  $backgroundColor = 'grayscale.50',
  $selectedColor = 'primary.500',
  ...flexProps
}: PillTabsProps<Value>) {
  const { colors } = useTheme()

  const content = (
    <Flex
      $width='100%'
      $backgroundColor={$backgroundColor}
      $borderWidth={1}
      $borderColor={'grayscale.300'}
      $borderRadius={'md'}
      {...flexProps}
    >
      {options.map((opt) => {
        const isActive = value === opt.value
        return (
          <Box key={`${opt.label}-${opt.value}`} $flex={1}>
            <PillTab
              $isActive={isActive}
              onClick={() => onChange(opt.value)}
              $backgroundColor={$selectedColor}
            >
              <Text
                $textAlign='center'
                $fontWeight='medium'
                $color={isActive ? colors.grayscale.white : colors.grayscale.black}
                style={minWidth}
              >
                {opt.label.trim()}
              </Text>
              {!isNil(opt.indicator) ? (
                <CountIndicator
                  count={opt.indicator}
                  color={
                    isActive && !isNil($backgroundColor) ? $backgroundColor : colors.grayscale.black
                  }
                  backgroundColor={isActive ? colors.grayscale.white : colors.grayscale[300]}
                />
              ) : null}
            </PillTab>
          </Box>
        )
      })}
    </Flex>
  )

  return $isCentered ? (
    <Flex $alignItems='center' $justifyContent='center'>
      {content}
    </Flex>
  ) : (
    content
  )
}

export default PillTabs
