import { IconProps } from './Icon'
import DecitraitLeaf from './icons/DecitraitLeaf'
import * as genericIconsMap from './icons/generic-icons'
import GenericIcon from './icons/GenericIcon'

const iconMap = {
  ...genericIconsMap,
  DecitraitLeaf
}

/** Bake svg icons with GenericIcon */
function generify() {
  return Object.entries(iconMap).reduce(
    (acc, [key, SvgComp]) => {
      const Compo: FC<IconProps> = (props) => <GenericIcon svg={<SvgComp />} {...props} />
      const compoName = key as keyof typeof iconMap
      Compo.displayName = compoName
      acc[compoName] = Compo
      return acc
    },
    {} as { [k in keyof typeof iconMap]: FC<IconProps> }
  )
}

const Icons = generify()

export default Icons
