import { PossibleError } from '@weenat/client/dist/errors'
import ErrorScreen from 'app/src/errors/ErrorScreen'
import isNil from 'lodash-es/isNil'
import { ReactNode } from 'react'
import LoadingCircle from '../kit/loaders/LoadingCircle'

interface WithClientResourceWrapperProps {
  isLoading: boolean
  isError: boolean
  error: PossibleError | null
  predicate: boolean
  WrappedComponent: ReactNode
  isResourceDefined: boolean
}

/**
 * Wrapping component to display loading and errors depending on a request state
 */
const WithClientResourceWrapper: FC<WithClientResourceWrapperProps> = ({
  isError,
  isLoading,
  error,
  predicate,
  isResourceDefined,
  WrappedComponent
}) => {
  let result: ReactNode = null

  if (isLoading) {
    result = <LoadingCircle />
  } else if (isError) {
    if (!isNil(error)) {
      return <ErrorScreen status={error.status} />
    }
  } else {
    if (isResourceDefined) {
      if (predicate) {
        result = WrappedComponent
      } else {
        result = <ErrorScreen status={403} />
      }
    }
  }

  return result
}

export default WithClientResourceWrapper
