import { useClient, useQuery } from '@weenat/client'
import { getDeviceCommonName } from '@weenat/client/dist/core/devices'
import { useIntl } from '@weenat/wintl'
import { Head, useSearchParams } from 'app/routx-router'
import CreateNewAlert from 'app/src/alerts/CreateNewAlert'
import { isNil } from 'lodash-es'
import { z } from 'zod'

const searchParams = z.object({
  stationId: z.optional(z.number()),
  deviceId: z.optional(z.number()),
  plotId: z.optional(z.number()),
  origin: z.optional(z.string())
})

const CreateAlertPage: FC = () => {
  const { t } = useIntl()
  const client = useClient()

  const [{ deviceId, plotId, stationId, origin }] = useSearchParams<typeof searchParams>()

  const hasParams = deviceId !== undefined || plotId !== undefined || stationId !== undefined

  const plot = useQuery(client.plots.get(plotId), { enabled: plotId !== undefined }).data
  const device = useQuery(client.devices.get(deviceId ?? 0), {
    enabled: deviceId !== undefined
  }).data
  const station = useQuery(client.stations.get(stationId), {
    enabled: stationId !== undefined
  }).data

  const target = plot ?? device ?? station
  const title =
    plot?.name ?? (!isNil(device) ? getDeviceCommonName(device) : station?.name ?? undefined)

  return (
    <>
      {hasParams && <Head title={title ?? t('status.loading')} />}
      <CreateNewAlert initialTarget={target} origin={origin} />
    </>
  )
}

export const Component = CreateAlertPage
export default CreateAlertPage
