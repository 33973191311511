import { QueryKey } from '@tanstack/react-query'
import { useMetricProvider } from '@weenat/client/dist/core/metrics'
import { useToggleIsFavorite } from '@weenat/client/dist/core/plots'
import { getMetricLastUpdateFromSummary } from '@weenat/client/dist/core/summary'
import { UniverseMetric } from '@weenat/client/dist/core/universes'
import { isFrost } from '@weenat/client/dist/enums/MetricIds'
import { StationSummary } from '@weenat/client/dist/resources/measurements.type'
import { useIntl } from '@weenat/wintl'
import { useIsAppAccessRestricted } from 'app/src/hooks/useIsAppAccessRestricted'
import useToasts from 'app/src/hooks/useToasts'
import Icons from 'app/src/kit/Icons'
import { useToggleFeature } from 'app/state'
import isNil from 'lodash-es/isNil'
import { MouseEvent, memo, useCallback } from 'react'
import { PlotCardFooter, PlotCardHeader, StyledCard } from './PlotCard'
import PlotListItemMetricSummary from './plotListItem/PlotListItemMetricSummary'

interface StationCardProps {
  focusedMetric: UniverseMetric
  isSelected: boolean
  summariesQueryCacheKey: QueryKey
  summary: StationSummary
  hasNoDataForHorizon: boolean
  isExpertOrg: boolean
  onPress?: () => void
  onClose?: () => void
}

const StationCard: FC<StationCardProps> = ({
  focusedMetric,
  isSelected = false,
  summariesQueryCacheKey,
  summary,
  hasNoDataForHorizon = false,
  isExpertOrg,
  onPress,
  onClose
}) => {
  const { t } = useIntl()
  const { addSuccessToast } = useToasts()
  const [{ redesign }] = useToggleFeature()

  const { name, isFavorite } = summary.metadata.station

  const toggleIsFavorite = useToggleIsFavorite({
    summary,
    summariesQueryCacheKey,
    onSuccess: (data) => {
      addSuccessToast(
        t(
          data.isFavorite
            ? 'actions.add_station_to_favorites_success'
            : 'actions.remove_station_from_favorites_success',
          { stationName: name }
        )
      )
    }
  })

  if (toggleIsFavorite.type !== 'isStation') {
    throw new Error('Wrong hook used to add station to favorites')
  }

  const isFavoriteRequest = toggleIsFavorite.request

  const onFavoritePress = useCallback(
    (e: MouseEvent<Element>) => {
      // Otherwise we open the summary
      e.preventDefault()
      toggleIsFavorite.mutation({ isFavorite: !summary.metadata.station.isFavorite })
    },
    [toggleIsFavorite, summary.metadata.station.isFavorite]
  )

  const lastUpdate = getMetricLastUpdateFromSummary({
    summary,
    metric: focusedMetric,
    horizonId: null
  })

  const {
    isVirtualDevice,
    warningThreshold,
    name: providerName
  } = useMetricProvider({
    resource: summary.metadata.station,
    metric: focusedMetric,
    isExpertOrg
  })

  const { isRestricted, reason } = useIsAppAccessRestricted()

  return (
    <StyledCard $isSelected={isSelected} $isRedesign={redesign} onPress={onPress}>
      <Flex $flexDirection='column' $height='100%'>
        <PlotCardHeader
          isFavorite={isFavorite}
          isFavoriteRequestLoading={isFavoriteRequest.isPending}
          name={name}
          reverseGeocoding={summary.metadata.station?.reverseGeocoding}
          onFavoritePress={onFavoritePress}
          Icon={<Icons.SingleSensorFilled $size='md' />}
          onClose={onClose}
        />

        {!isNil(focusedMetric) ? (
          <Flex
            $flexDirection='column'
            $width='100%'
            $alignItems='center'
            $justifyContent='center'
            $flex={1}
            $px='lg'
            $py='md'
          >
            <PlotListItemMetricSummary
              metric={focusedMetric}
              data={summary.data}
              focusedHorizon={null}
              isRestricted={isRestricted}
              reason={reason}
              isVirtualDevice={isVirtualDevice}
              provider={providerName}
            />
          </Flex>
        ) : null}

        {hasNoDataForHorizon || isFrost(focusedMetric) ? null : (
          <PlotCardFooter
            timestamp={lastUpdate}
            isVirtualMetric={isVirtualDevice}
            warningThreshold={warningThreshold}
            name={providerName}
          />
        )}
      </Flex>
    </StyledCard>
  )
}

export default memo(StationCard)
