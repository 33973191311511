import { thresholdColorScale } from '@weenat/client/dist/core/dss/irrigation'
import { timestampToMoment } from '@weenat/client/dist/core/history'
import { useHorizonOption } from '@weenat/client/dist/core/horizons'
import { HydricStressMetric } from '@weenat/client/dist/enums/MetricIds'
import { useConvertedValue } from '@weenat/client/dist/hooks'
import { PlotSummary } from '@weenat/client/dist/resources/measurements.type'
import { ThresholdType } from '@weenat/client/dist/resources/thresholds.types'
import { useIntl } from '@weenat/wintl'
import { fontFamily } from 'app/src/kit/charts/victoryTheme'
import { useToggleFeature, useUniverseAggregate } from 'app/state'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import moment from 'moment-timezone'
import { lighten } from 'polished'
import React, { useMemo } from 'react'
import { useTheme } from 'styled-components'
import PlotListItemValue from './PlotListItemValue'
import { PlotListItemsVariants } from './PlotListItemMetricSummary'

const SVG_HEIGHT = 80
const SVG_WIDTH = 352

const NB_MEASURE = 4

const FONT_SIZE = 12
const TITLE_FONT_SIZE = 10

const HORIZON_FONT_SIZE = 16
const HORIZON_WIDTH = 40

const STROKE_WIDTH = 2

const MEASURE_RECT_HEIGHT = 80 - 2 * STROKE_WIDTH
const MEASURE_RECT_PADDING = 8

const CAPA_MEASURE_RECT_WIDTH =
  (SVG_WIDTH - (NB_MEASURE - 1) * MEASURE_RECT_PADDING - NB_MEASURE * (2 * STROKE_WIDTH)) /
  NB_MEASURE

const HPOT_MEASURE_RECT_WIDTH =
  (SVG_WIDTH -
    HORIZON_WIDTH -
    NB_MEASURE * MEASURE_RECT_PADDING -
    NB_MEASURE * (2 * STROKE_WIDTH)) /
  NB_MEASURE

const VALUE_HEADER_PADDING_TOP = 10
const VALUE_HEADER_HEIGHT = 3 * VALUE_HEADER_PADDING_TOP + 2 * TITLE_FONT_SIZE
const VALUE_HEIGHT = MEASURE_RECT_HEIGHT - VALUE_HEADER_HEIGHT - FONT_SIZE
const CAPTION_OFFSET = 20
/** below this number text is displayed above point under it's the opposite */
const BREAKING_LINE = -20

const getLeftOffset = (isCapa: boolean) => (isCapa ? 0 : HORIZON_WIDTH + MEASURE_RECT_PADDING)

const getXPositionFromIdx = (idx: number, isCapa: boolean) => {
  const leftOffset = getLeftOffset(isCapa)
  const measureRectWidth = isCapa ? CAPA_MEASURE_RECT_WIDTH : HPOT_MEASURE_RECT_WIDTH
  return (
    STROKE_WIDTH +
    idx * 2 * STROKE_WIDTH +
    idx * (measureRectWidth + MEASURE_RECT_PADDING) +
    leftOffset
  )
}

const getMinMax = (values: { value: number | null | undefined }[]) => {
  const nonNullValues = values.reduce((acc, v) => {
    if (!isNil(v.value)) {
      acc.push(v.value)
    }
    return acc
  }, [] as number[])
  const minValue = isEmpty(nonNullValues) ? 0 : Math.min(...nonNullValues)
  const maxValue = isEmpty(nonNullValues) ? 0 : Math.max(...nonNullValues)
  return [minValue, maxValue]
}

// The lower the sensitivity the more it will variate for a given delta
// The tensiometers scales and amplitudes between values being higher we set a high sensitivity
// On the opposite capacitive only variates between 10 and 30 percent so we set a low sensitivity
const TENSIOMETERS_Y_SENSITIVITY = 10
const CAPACITIVE_Y_SENSITIVITY = 1

const getYPositionFromValue = (
  min: number,
  max: number,
  value?: number | null,
  offsetY = 0,
  isCapa = false
) => {
  const sensitivity = isCapa ? CAPACITIVE_Y_SENSITIVITY : TENSIOMETERS_Y_SENSITIVITY
  if (isNil(value)) {
    return 0
  } else {
    let delta = Math.ceil((max - min) / sensitivity)
    if (delta === 0) {
      delta = 1
    }
    const ratio = VALUE_HEIGHT / delta
    return ((value - min) / sensitivity) * -ratio + offsetY
  }
}

const getCaptionYPositionFromValue = (
  min: number,
  max: number,
  value?: number,
  offsetY = 0,
  isCapa = false
) => {
  const circleCenterPosition = getYPositionFromValue(min, max, value, offsetY, isCapa)
  return circleCenterPosition - offsetY < BREAKING_LINE
    ? circleCenterPosition + CAPTION_OFFSET
    : circleCenterPosition - CAPTION_OFFSET / 2
}

interface IrrigationValueProps {
  value?: number | null
  isCapa: boolean
  idx: number
  min: number
  max: number
  nextValue?: number | null
  threshold?: ThresholdType
  id: string
  timestamp?: string | null
}

const IrrigationValue: React.FC<IrrigationValueProps> = ({
  value,
  isCapa,
  idx,
  min,
  max,
  nextValue,
  threshold,
  id,
  timestamp
}) => {
  const { colors } = useTheme()
  const { t } = useIntl()
  const { convertValue } = useConvertedValue()

  const thresholdColor = !isNil(threshold) ? thresholdColorScale(threshold) : colors.grayscale.black

  const measureRectWidth = isCapa ? CAPA_MEASURE_RECT_WIDTH : HPOT_MEASURE_RECT_WIDTH
  const halfWidth = measureRectWidth / 2

  const xPosition = getXPositionFromIdx(idx, isCapa)
  const yPosition = getYPositionFromValue(
    min,
    max,
    value,
    VALUE_HEADER_HEIGHT + VALUE_HEIGHT,
    isCapa
  )
  const nextYPosition = !isNil(nextValue)
    ? getYPositionFromValue(min, max, nextValue, VALUE_HEADER_HEIGHT + VALUE_HEIGHT, isCapa)
    : undefined

  const circleXPosition = xPosition + halfWidth
  const nextCircleXPosition = getXPositionFromIdx(idx + 1, isCapa) + halfWidth

  let dateLabels: string[] | undefined = undefined

  const now = moment()
  const midnight = now.clone().set('hour', 0).set('minute', 0).format('HH:mm')

  // const today = now.clone().format(t('formats.date_short_chart'))
  const today = t('map.universes.aggregate.d-0-measure')

  switch (id) {
    case '7D':
      // const sevenDaysAgo = now.clone().add('day', -7).format(t('formats.date_short_chart'))
      const sevenDaysAgo = t('map.universes.aggregate.d-7-measure')
      dateLabels = [sevenDaysAgo, midnight]
      break
    case 'DD':
      // const yesterday = now.clone().add('day', -1).format(t('formats.date_short_chart'))
      const yesterday = t('map.universes.aggregate.d-1-measure')
      dateLabels = [yesterday, midnight]
      break
    case 'D':
      dateLabels = [today, midnight]
      break
    case 'L':
      // const labels: string[] = [today]
      const labels: string[] = [t('map.universes.aggregate.real-time')]
      if (!isNil(timestamp)) {
        labels.push(timestampToMoment(timestamp).format('HH:mm'))
      }
      dateLabels = labels
      break
  }

  return (
    <>
      {/* LINK */}
      {!isNil(nextValue) && !isNil(value) && (
        <line
          x1={circleXPosition}
          x2={nextCircleXPosition}
          y1={yPosition}
          y2={nextYPosition}
          stroke={colors.grayscale.black}
          strokeWidth={1}
        />
      )}
      {/* DATE */}
      {isNil(dateLabels)
        ? null
        : dateLabels.map((label, labelIdx) => (
            <text
              key={label}
              fill={colors.grayscale.black}
              x={circleXPosition}
              y={(TITLE_FONT_SIZE + 1) * (labelIdx + 1) + TITLE_FONT_SIZE / 2}
              fontSize={TITLE_FONT_SIZE}
              fontFamily={fontFamily}
              fontWeight={labelIdx === 0 ? 700 : 400}
              textAnchor='middle'
            >
              {label}
            </text>
          ))}
      {!isNil(value) && (
        <>
          <circle cx={circleXPosition} cy={yPosition} r='10' fill={colors.grayscale[50]} />
          <circle
            cx={circleXPosition}
            cy={yPosition}
            r='6'
            fill={lighten(0.2, thresholdColor)}
            fillOpacity='0.2'
            stroke={thresholdColor}
          />
          <text
            fill={colors.grayscale.black}
            x={circleXPosition}
            y={getCaptionYPositionFromValue(
              min,
              max,
              value,
              VALUE_HEADER_HEIGHT + VALUE_HEIGHT,
              isCapa
            )}
            fontSize={FONT_SIZE}
            fontFamily={fontFamily}
            fontWeight={500}
            textAnchor='middle'
          >
            {`${convertValue({ metric: isCapa ? 'U_CAPA' : 'HPOT', value })}`}
          </text>
        </>
      )}
    </>
  )
}

const Rect: FC<{ aKey: string; idx: number; isCapa: boolean; metric: HydricStressMetric }> = ({
  aKey,
  idx,
  isCapa,
  metric
}) => {
  const [universeAggregate] = useUniverseAggregate()
  const { colors } = useTheme()
  const measureRectWidth = isCapa ? CAPA_MEASURE_RECT_WIDTH : HPOT_MEASURE_RECT_WIDTH
  return (
    <rect
      width={measureRectWidth}
      height={MEASURE_RECT_HEIGHT}
      rx={8}
      y={1}
      x={getXPositionFromIdx(idx, isCapa)}
      fill={colors.grayscale[50]}
      strokeWidth={STROKE_WIDTH}
      stroke={
        universeAggregate === aKey ? colors.metrics[metric as 'HPOT'][500] : colors.grayscale[300]
      }
    />
  )
}

interface IrrigationPlotListItemValuesProps {
  metric: HydricStressMetric
  /** Plot summary data */
  summaryData: PlotSummary['data']
  focusedHorizon: number | null
  variant: PlotListItemsVariants
}

const IrrigationPlotListItemValues: React.FC<IrrigationPlotListItemValuesProps> = ({
  summaryData,
  focusedHorizon,
  metric,
  variant
}) => {
  const { colors } = useTheme()
  const { t } = useIntl()

  //easier than handle null in code
  const nonNullHorizonId = focusedHorizon === null ? -1 : focusedHorizon

  const isCapa = metric === 'U_CAPA'
  const [{ redesign }] = useToggleFeature()
  const isRedesignList = redesign && variant === 'list'

  const data = useMemo(
    () =>
      isCapa
        ? [
            {
              id: '7D',
              value: summaryData.U_CAPA_7D?.[0],
              key: 'd-7-measure',
              threshold: summaryData.U_CAPA_7D?.[1]
            },
            {
              id: 'DD',
              value: summaryData.U_CAPA_DD?.[0],
              key: 'd-1-measure',
              threshold: summaryData.U_CAPA_DD?.[1]
            },
            {
              id: 'D',
              value: summaryData.U_CAPA_D?.[0],
              key: 'd-0-measure',
              threshold: summaryData.U_CAPA_D?.[1]
            },
            {
              id: 'L',
              value: summaryData.U_CAPA_L?.[0],
              key: 'real-time',
              threshold: summaryData.U_CAPA_L?.[1],
              timestamp: summaryData.U_CAPA_Li
            }
          ]
        : [
            {
              id: '7D',
              value: summaryData.HPOT_7D?.[nonNullHorizonId]?.[0],
              key: 'd-7-measure',
              threshold: summaryData.HPOT_7D?.[nonNullHorizonId]?.[1]
            },
            {
              id: 'DD',
              value: summaryData.HPOT_DD?.[nonNullHorizonId]?.[0],
              key: 'd-1-measure',
              threshold: summaryData.HPOT_DD?.[nonNullHorizonId]?.[1]
            },
            {
              id: 'D',
              value: summaryData.HPOT_D?.[nonNullHorizonId]?.[0],
              key: 'd-0-measure',
              threshold: summaryData.HPOT_D?.[nonNullHorizonId]?.[1]
            },
            {
              id: 'L',
              value: summaryData.HPOT_L?.[nonNullHorizonId]?.[0],
              key: 'real-time',
              threshold: summaryData.HPOT_L?.[nonNullHorizonId]?.[1],
              timestamp: summaryData.HPOT_Li?.[nonNullHorizonId]
            }
          ],
    [summaryData, nonNullHorizonId, isCapa]
  )

  const [min, max] = useMemo(() => getMinMax(data), [data])

  const depthUnit = t('units.centimeters_abbr')
  const horizonOpt = useHorizonOption(nonNullHorizonId, true)

  return (
    <>
      {isRedesignList ? (
        <Flex>
          {data.map(({ value, key }) => (
            <PlotListItemValue
              key={value}
              name={t(`map.universes.aggregate[${key}]`)}
              metric={metric}
              variant={variant}
              value={value}
            />
          ))}
        </Flex>
      ) : (
        <Box $width='100%'>
          <svg viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`} width='100%' height={SVG_HEIGHT}>
            {!isCapa && (
              <>
                <rect
                  width={HORIZON_WIDTH}
                  height={MEASURE_RECT_HEIGHT}
                  rx={8}
                  x={0}
                  fill={colors.metrics.HPOT['500']}
                />
                <text
                  fill={colors.grayscale.white}
                  x={HORIZON_WIDTH / 2}
                  y={MEASURE_RECT_HEIGHT / 2}
                  fontSize={HORIZON_FONT_SIZE}
                  fontFamily={fontFamily}
                  fontWeight='bold'
                  textAnchor='middle'
                >
                  {horizonOpt?.label}
                </text>
                <text
                  fill={colors.grayscale.white}
                  x={HORIZON_WIDTH / 2}
                  y={MEASURE_RECT_HEIGHT / 2 + HORIZON_FONT_SIZE}
                  fontSize={HORIZON_FONT_SIZE}
                  fontFamily={fontFamily}
                  fontWeight='bold'
                  textAnchor='middle'
                >
                  {depthUnit}
                </text>
              </>
            )}
            {data.map(({ key }, idx) => (
              <Rect key={key} aKey={key} idx={idx} isCapa={isCapa} metric={metric} />
            ))}
            {data.map(({ value, id, threshold, timestamp, key }, idx) => (
              <IrrigationValue
                key={key}
                id={id}
                idx={idx}
                isCapa={isCapa}
                max={max}
                min={min}
                value={value}
                nextValue={data[idx + 1]?.value}
                threshold={threshold}
                timestamp={timestamp}
              />
            ))}
          </svg>
        </Box>
      )}
    </>
  )
}

export default IrrigationPlotListItemValues
