import { FontSizeToken, fromColorPathToColor, type WeenatColor } from '@weenat/theme'
import { SpacingProps, boxable } from 'app/src/kit/primitives/themeMappings/props'
import isNil from 'lodash-es/isNil'
import { transparentize } from 'polished'
import {
  MouseEvent,
  MouseEventHandler,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useState
} from 'react'
import { css, styled, useTheme } from 'styled-components'
import LoadingCircle from './loaders/LoadingCircle'

export interface IconProps extends SpacingProps {
  $color?: WeenatColor
  $backgroundColor?: WeenatColor
  $borderColor?: WeenatColor
  $size?: Exclude<FontSizeToken, 'none'>
  $isDisabled?: boolean
  $isLoading?: boolean
  $rotate?: number
  $rounded?: boolean
  onPress?: MouseEventHandler
  className?: string
  /** Whether of not the icon should show a fake loading animation when pressed */
  $useFakeLoading?: boolean
  children?: ReactNode
}

/**
 * Helper function to pick the correct color
 */
const getColor = (path?: IconProps['$color']) => {
  return !isNil(path) ? fromColorPathToColor(path) : 'transparent'
}

type IconWrapperProps = {
  $touchable?: boolean
  $backgroundColor: string
  $borderColor: string
} & Pick<IconProps, '$isDisabled' | '$rounded'>

const IconWrapper = styled(Box)<IconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${boxable}

  ${(props) => css`
    opacity: ${props.$isDisabled ? 0.5 : 1};
    background-color: ${props.$backgroundColor};
    border: 1px solid ${!isNil(props.$borderColor) ? props.$borderColor : 'transparent'};
    border-radius: ${props.$rounded ? props.theme.radiuses.rounded : props.theme.radiuses.md}px;
    cursor: ${props.$touchable ? 'pointer' : 'inherit'};
    ${props.$isDisabled && 'pointer-events: none;'}
    &:hover {
      ${props.$touchable && !props.$isDisabled
        ? css`
            opacity: 1;
            border-color: ${props.theme.colors.grayscale[300]};
          `
        : ''}
      ${props.$touchable && props.$backgroundColor === 'transparent' && !props.$isDisabled
        ? `background-color: ${transparentize(0.85, props.theme.colors.grayscale.black)};`
        : ''}
    }
  `}
  overflow: hidden;
  transition: all 0.2s ease-in-out;
`

type IconContainerProps = { $color?: string; $finalSize: number } & Pick<
  IconProps,
  '$rotate' | '$isLoading'
>

const IconContainer = styled(Box)<IconContainerProps>`
  line-height: 1;
  transition: inherit;

  ${({ $color, $finalSize, $isLoading, $rotate }) => css`
    color: ${$color ?? 'inherit'};
    transform: rotate(${$rotate}deg);
    font-size: ${$finalSize}px;
    width: ${$finalSize}px;
    height: ${$finalSize}px;

    ${$isLoading &&
    `
      cursor: wait !important;
    `}

    svg {
      fill: ${$color ?? 'inherit'};
      width: ${$finalSize}px;
      height: ${$finalSize}px;
    }
  `}
`

const FAKE_LOADING_DURATION = 2000

/**
 * Container for icons imported with svgr
 */
const Icon = forwardRef<HTMLDivElement, IconProps>(
  (
    {
      $color,
      $backgroundColor,
      $borderColor,
      $size = 'rg',
      $isDisabled = false,
      children = null,
      $isLoading = false,
      $rounded = false,
      $rotate = 0,
      onPress,
      className,
      $p,
      $useFakeLoading: useFakeLoading = false,
      ...boxProps
    },
    ref
  ) => {
    const { typography } = useTheme()

    const [isFakeLoading, setFakeLoading] = useState(false)
    const [triggerFakeLoading, setTriggerFakeLoading] = useState(false)

    const touchable = !isNil(onPress) && !$isDisabled
    const finalColor = !isNil($color) ? getColor($color) : undefined

    const finalSize = typography.sizes[$size]

    const defaultPadding = (!isNil(onPress) || !isNil($backgroundColor)) && isNil($p) ? 2 : $p

    const handlePress = useCallback(
      (e: MouseEvent) => {
        if (!isNil(onPress)) {
          e.stopPropagation()
          onPress(e)

          if (useFakeLoading) {
            setTriggerFakeLoading(true)
          }
        }
      },
      [useFakeLoading, onPress, setTriggerFakeLoading]
    )

    useEffect(() => {
      if (triggerFakeLoading) {
        setFakeLoading(true)
        setTimeout(() => {
          setFakeLoading(false)
          setTriggerFakeLoading(false)
        }, FAKE_LOADING_DURATION)
      }
    }, [triggerFakeLoading])

    const finalLoading = isFakeLoading || $isLoading

    return (
      <IconWrapper
        ref={ref}
        $rounded={$rounded}
        $touchable={touchable}
        $isDisabled={$isDisabled}
        $backgroundColor={getColor($backgroundColor)}
        $borderColor={getColor($borderColor)}
        onClick={handlePress}
        className={className}
        $p={defaultPadding}
        {...boxProps}
      >
        <IconContainer
          $color={finalColor}
          $finalSize={finalSize}
          $rotate={$rotate}
          $isLoading={finalLoading}
        >
          {!finalLoading ? children : <LoadingCircle size={$size} />}
        </IconContainer>
      </IconWrapper>
    )
  }
)

export default Icon
