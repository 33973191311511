import { extractValueFromSummaryData } from '@weenat/client/dist/core/measurements'
import { Aggregations } from '@weenat/client/dist/enums'
import { PlotSummary } from '@weenat/client/dist/resources/measurements.type'
import { WeenatColor } from '@weenat/theme'
import { useIntl } from '@weenat/wintl'
import { RestrictionReason } from 'app/src/hooks/useIsAppAccessRestricted'
import UpdateInformation from 'app/src/kit/UpdateInformation'
import MetricIcons from 'app/src/kit/icons/MetricIcons'
import { useToggleFeature, useUniverseAggregate } from 'app/state'
import isNil from 'lodash-es/isNil'
import { memo } from 'react'
import { styled } from 'styled-components'
import { PlotListItemsVariants } from './PlotListItemMetricSummary'
import PlotListItemValue, { PlotSummaryValue } from './PlotListItemValue'

const FrostTemperatureValueContainer = styled(Flex)`
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`

interface FrostMetricValueProps {
  metric: 'T_WET' | 'T_DRY'
  value: PlotSummaryValue
  variant: PlotListItemsVariants
  isRestricted: boolean
  reason: RestrictionReason
}

const PADDING_0 = { padding: 0 }
const FrostMetricValue = memo<FrostMetricValueProps>(
  ({ metric, value, variant, isRestricted, reason }) => {
    const { t } = useIntl()

    const [{ redesign }] = useToggleFeature()
    const Icn = MetricIcons[metric]
    const color: WeenatColor = `metrics.${metric}.500` as const

    const isDryTemp = metric === 'T_DRY'
    const isRedesignList = redesign && variant === 'list'
    return (
      <FrostTemperatureValueContainer>
        {!isNil(Icn) && !isRedesignList ? (
          <Icn $size='lg' $mr='md' $p='md' $backgroundColor={color} $color={'grayscale.white'} />
        ) : null}
        <PlotListItemValue
          metric={metric}
          name={t(`map.universes.frost.${isDryTemp ? 'dry' : 'wet'}_temperature_abbr`)}
          value={value}
          variant={variant}
          isMainValue={!isRedesignList}
          isRestricted={isRestricted}
          reason={reason}
        />
      </FrostTemperatureValueContainer>
    )
  }
)

const MyUpdateInformation = styled(UpdateInformation)`
  justify-content: center;
  margin-top: 8px;
`

interface FrostPlotListItemValuesProps {
  /** Plot summary data */
  summaryData: PlotSummary['data']
  provider?: string
  variant: PlotListItemsVariants
  isRestricted: boolean
  reason: RestrictionReason
}

/** Carousel values for Frost */
const FrostPlotListItemValues: FC<FrostPlotListItemValuesProps> = ({
  summaryData,
  provider,
  variant,
  isRestricted,
  reason
}) => {
  const [universeAggregate] = useUniverseAggregate()
  let wetTemperature: number | null = null
  let dryTemperature: number | null = null
  let timestamp: string | null = null

  const [{ redesign }] = useToggleFeature()
  const isRedesignList = redesign && variant === 'list'

  if (universeAggregate != null) {
    const { value, timestamp: extractedTimestamp } = extractValueFromSummaryData({
      data: summaryData,
      metricId: 'T_WET',
      aggregate: Aggregations.lastValues,
      universeAggregate
    })
    wetTemperature = value
    timestamp = extractedTimestamp

    dryTemperature = extractValueFromSummaryData({
      data: summaryData,
      metricId: 'T_DRY',
      aggregate: Aggregations.lastValues,
      universeAggregate
    })?.value
  }

  return (
    <Box $width='100%'>
      <Flex $width='100%' $gap='md'>
        <FrostMetricValue
          metric='T_WET'
          value={wetTemperature}
          variant={variant}
          isRestricted={isRestricted}
          reason={reason}
        />
        <FrostMetricValue
          metric='T_DRY'
          value={dryTemperature}
          variant={variant}
          isRestricted={isRestricted}
          reason={reason}
        />
      </Flex>
      {!isNil(timestamp) && !isRedesignList ? (
        <MyUpdateInformation timestamp={parseInt(timestamp, 10)} name={provider} />
      ) : null}
    </Box>
  )
}

export default memo(FrostPlotListItemValues)
