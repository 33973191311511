import { UniverseMetric } from '@weenat/client/dist/core/universes'
import { Aggregations } from '@weenat/client/dist/enums'
import { useIntl } from '@weenat/wintl'
import { RestrictionReason } from 'app/src/hooks/useIsAppAccessRestricted'
import { memo } from 'react'
import useUniverseBackgroundMapContext from '../universes/useUniverseBackgroundMapContext'
import PlotListItemValue, { PlotSummaryValue } from './PlotListItemValue'
import PlotListItemValuesContainer from './PlotListItemValuesContainer'
import { PlotListItemsVariants } from './PlotListItemMetricSummary'

interface NowMinMaxPlotListItemValuesProps {
  now: PlotSummaryValue
  max: PlotSummaryValue
  min?: PlotSummaryValue
  metric: UniverseMetric
  isVirtualDevice: boolean
  isRestricted: boolean
  reason: RestrictionReason
  variant: PlotListItemsVariants
}

/** PlotListItem values for Humidities */
const NowMinMaxPlotListItemValues: FC<NowMinMaxPlotListItemValuesProps> = ({
  now,
  min,
  max,
  metric,
  isVirtualDevice,
  isRestricted: isRestrictedFromProps,
  reason,
  variant
}) => {
  const { t } = useIntl()
  const { aggregate } = useUniverseBackgroundMapContext()
  const isRestricted = isRestrictedFromProps && !isVirtualDevice

  return (
    <PlotListItemValuesContainer $isRestricted={isRestricted}>
      <PlotListItemValue
        isMainValue
        value={now}
        name={t('map.universes.aggregate.real-time')}
        metric={metric}
        selected={aggregate === Aggregations.latest}
        isRestricted={isRestricted && !isVirtualDevice}
        reason={reason}
        variant={variant}
      />
      <PlotListItemValue
        value={min}
        name={t('map.universes.aggregate.min-measured-today')}
        metric={metric}
        selected={aggregate === Aggregations.minimum}
        isRestricted={isRestricted && !isVirtualDevice}
        reason={reason}
        variant={variant}
      />
      <PlotListItemValue
        value={max}
        name={t('map.universes.aggregate.max-measured-today')}
        metric={metric}
        selected={aggregate === Aggregations.maximum}
        isRestricted={isRestricted && !isVirtualDevice}
        reason={reason}
        variant={variant}
      />
    </PlotListItemValuesContainer>
  )
}

export default memo(NowMinMaxPlotListItemValues)
