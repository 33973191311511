import { useClient, useMutation } from '@weenat/client'
import paths from '@weenat/client/dist/core/appPaths'
import { NotificationStatus } from '@weenat/client/dist/resources/notifications'
import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import Button from 'app/src/kit/Button'
import CloseButton from 'app/src/kit/CloseButton'
import Tabs from 'app/src/kit/Tabs'
import Text from 'app/src/kit/Text'
import { useToggleFeature } from 'app/state'
import { styled } from 'styled-components'
import DelimitedFlex from '../kit/DelimitedFlex'
import Icons from '../kit/Icons'
import { DropdownContent } from '../kit/fields/Select/SelectDropdown'
import TextTooltip from '../kit/tooltips/TextTooltip'
import NotificationsList from './NotificationsList'

const Header = styled(Box)`
  background-color: ${(p) => p.theme.colors.grayscale.white};
  border-bottom: 1px solid ${(p) => p.theme.colors.grayscale[300]};
  box-shadow: ${(p) => p.theme.shadows.md.boxShadow};
`

interface NotificationsDropdownProps {
  totalNumberOfUnreadNotifications: number
  onClose: () => void
  /** mandatory when betterNotification is activated */
  currentStatus: NotificationStatus
  /** mandatory when betterNotification is activated */
  onStatusChange: (newStatus: NotificationStatus) => void
}

const NotificationsDropdown: FC<NotificationsDropdownProps> = ({
  totalNumberOfUnreadNotifications,
  onClose,
  onStatusChange,
  currentStatus
}) => {
  const client = useClient()
  const nav = useNavigate()
  const { t } = useIntl()
  const [{ redesign }] = useToggleFeature()

  const isShowingUnread = currentStatus === NotificationStatus.unread

  const [markAsRead, markAsReadState] = useMutation(client.notifications.markAllAsRead())

  const options = [
    {
      label: t('models.notification.status.unread', { capitalize: true }),
      value: NotificationStatus.unread,
      indicator: totalNumberOfUnreadNotifications > 0 ? totalNumberOfUnreadNotifications : undefined
    },
    {
      label: t('models.notification.status.read', { capitalize: true }),
      value: NotificationStatus.read
    }
  ]

  return (
    <DropdownContent
      width={600}
      Header={
        <Header>
          <Flex $alignItems='center' $p='lg' $gap='md'>
            <Box $flex={1}>
              <Text $fontWeight='bold' $fontSize='lg' $ellipsis>
                {t('models.notification.model.name', { capitalize: true })}
              </Text>
            </Box>

            {isShowingUnread ? (
              <Button
                IconLeft={Icons.DoubleCheckmark}
                onPress={() => markAsRead()}
                isLoading={markAsReadState.isPending}
                isDisabled={markAsReadState.isPending}
                backgroundColor={'transparent'}
                color={'primary.500'}
              >
                {t('models.notification.actions.mark_all_as_read')}
              </Button>
            ) : null}

            <TextTooltip content={t('titles.notification_preferences')}>
              <Icons.SettingsCog
                onPress={() => {
                  nav(paths.me.notificationPreferences)
                  onClose()
                }}
                $size='lg'
                $p='md'
                $backgroundColor={'grayscale.100'}
              />
            </TextTooltip>

            <CloseButton $ml={0} onPress={onClose} />
          </Flex>
          {redesign ? (
            <DelimitedFlex $alignItems='center' $width='100%' $px='lg' $py={'sm'}>
              <Tabs currentTab={currentStatus} tabs={options} onTabChange={onStatusChange} />
            </DelimitedFlex>
          ) : (
            <Flex $alignItems='center' $width='100%' $px='lg'>
              <Tabs currentTab={currentStatus} tabs={options} onTabChange={onStatusChange} />
            </Flex>
          )}
        </Header>
      }
    >
      <NotificationsList isShowingUnread={isShowingUnread} onNotificationPress={onClose} />
    </DropdownContent>
  )
}

export default NotificationsDropdown
