import { useIntl } from '@weenat/wintl'
import useDisclosure from 'app/src/hooks/useDisclosure'
import Text from 'app/src/kit/Text'
import Icons from './Icons'
import Modal from './Modal'

const WindHeightExplanation: FC = () => {
  const { t } = useIntl()
  const { open, close, isOpen } = useDisclosure()

  return (
    <>
      <Icons.InfoCircle onPress={open} $size='rg' $color={'metrics.FF.500'} />
      <Modal title={t('help.wind_reading_aid_title')} close={close} isOpen={isOpen}>
        <Flex $flexDirection='column'>
          <Box $pb='md'>
            <Text>{t('help.wind_reading_aid_content')}</Text>
          </Box>
          <Text $fontWeight='bold'>{t('metrics.FF', { capitalize: true })}</Text>
          <Box $pt='lg'>
            <Text>{t('help.wind_height_explanation')}</Text>
          </Box>
        </Flex>
      </Modal>
    </>
  )
}

export default WindHeightExplanation
