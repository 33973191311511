import { Slot } from 'app/routx-router'
import AuthenticatedLayout from 'app/src/layouts/AuthenticatedLayout'
import ProtectedRoute from 'app/src/layouts/ProtectedRoute'
import PublicLayout from 'app/src/layouts/PublicLayout'

/**
 * This page is both public and can also be access while logged in
 */
const NetworksLayout: FC = () => {
  return (
    <ProtectedRoute>
      <AuthenticatedLayout>
        <PublicLayout>
          <Slot />
        </PublicLayout>
      </AuthenticatedLayout>
    </ProtectedRoute>
  )
}

export const Component = NetworksLayout
export default NetworksLayout
