import {
  getAlreadyWatchedStepsFromNewScreen,
  ScreenQueryParam
} from '@weenat/client/dist/core/tours'
import { useOrgContext } from 'app/orgProvider'
import { useNavigate, usePathname, useSearchParams } from 'app/routx-router'
import { useCurrentTour } from 'app/state'
import { useEffect } from 'react'

export const useHandleScreenQueryParamChange = () => {
  const hookPn = usePathname()
  const nav = useNavigate()
  const [, setCurrentTour] = useCurrentTour()
  // We'd rather have the id coming from the context than the params, since we may tour on a page without an id
  const { id: farmId } = useOrgContext()
  const [searchParams] = useSearchParams()

  return (tourScreen: ScreenQueryParam, predicate?: () => boolean, pathname = hookPn) => {
    if (!predicate || predicate()) {
      nav(pathname, { replace: true, search: { ...searchParams, tourScreen } })

      // @ts-expect-error currentTour.name may not be defined
      setCurrentTour((currentTour) => {
        return {
          ...(currentTour || {}),
          stepIndex: 0,
          isWaitingNextScreen: false,
          ...(tourScreen === 'intro' || !currentTour?.name
            ? {}
            : {
                alreadyWatchedSteps: getAlreadyWatchedStepsFromNewScreen(
                  farmId,
                  currentTour,
                  tourScreen
                )
              })
        }
      })
    }
  }
}

/** Sync tourScreen query param with a useEffect */
const useScreenQueryParam = (tourScreen: ScreenQueryParam, predicate?: () => boolean) => {
  const handle = useHandleScreenQueryParamChange()

  useEffect(() => {
    handle(tourScreen, predicate)
  }, [tourScreen])
}

export default useScreenQueryParam
