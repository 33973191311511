import { colorByUniv, Universe } from '@weenat/client/dist/core/universes'
import { Device } from '@weenat/client/dist/resources/devices'
import { Plot } from '@weenat/client/dist/resources/plots'
import { Station } from '@weenat/client/dist/resources/stations'
import { AbsoluteHref } from '@weenat/client/dist/routx/runtime-core'
import { WeenatColor } from '@weenat/theme'
import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import { useNavigate, usePathname } from 'app/routx-router'
import { isNil } from 'lodash-es'
import { useMemo } from 'react'
import styled from 'styled-components'
import { IconProps } from '../kit/Icon'
import Icons from '../kit/Icons'
import UniverseIcons from '../kit/icons/UniverseIcons'
import Tab from '../kit/Tab'
import Text from '../kit/Text'

interface Tab {
  Icon?: FC<IconProps>
  color?: WeenatColor
  borderColor?: WeenatColor
  label: string
  to: AbsoluteHref
  matchExactPath?: boolean
}

interface OptionItemProps extends Omit<Tab, 'to'> {}

const OptionItem = ({ Icon, label, color }: OptionItemProps) => {
  const Icn = !isNil(Icon) ? Icon : Icons.Circle

  return (
    <Flex $flex={1} $minWidth={0} $py={'sm'} $px={'md'} $gap={'sm'} $alignItems='center'>
      <Icn $size='lg' $color={color ?? undefined} />
      <Text $ellipsis $color={color} unselectable='on' $lineHeight={undefined}>
        {label}
      </Text>
    </Flex>
  )
}

const Bar = styled(Flex)`
  align-items: center;

  gap: ${(p) => p.theme.spacings.md}px;

  padding: ${(p) => `${p.theme.spacings.md}px ${p.theme.spacings.lg}px`};
  border-bottom: 1px solid ${(p) => p.theme.colors.grayscale[100]};

  height: 48px;
`

interface ResourceNavigatorProps {
  resource: Station | Device | Plot
}

const ResourceNavigator: FC<ResourceNavigatorProps> = ({ resource }) => {
  const { t } = useIntl()
  const nav = useNavigate()
  const org = useOrgContext()
  const pathname = usePathname()

  const { kind, id } = resource

  let tabs: Tab[] = []

  const rootPath =
    kind === 'device'
      ? (`/weather-map/${id}` as const)
      : (`/farms/${org.id}/${kind === 'plot' ? 'plots' : 'stations'}/${id}` as const)

  const commonTabs: Tab[] = useMemo(
    () => [
      {
        Icon: Icons.Forecasts,
        label: t(`titles.forecasts`),
        to: `${rootPath}/forecasts` as const
      },
      {
        Icon: Icons.History,
        label: t(`titles.history`),
        to: `${rootPath}/history` as const
      }
    ],
    [rootPath, t]
  )

  if (kind === 'plot') {
    let universeTabs: Tab[] = []

    if (!isNil(resource.availableUniverses)) {
      universeTabs = resource.availableUniverses.map((u) => {
        const currentSlug = Universe[u]

        return {
          Icon: UniverseIcons[u],
          label: t(`map.universes.all.${currentSlug}` as const),
          to: `${rootPath}/${currentSlug}` as const,
          color: colorByUniv[u].backgroundColor,
          borderColor: colorByUniv[u].borderColor
        }
      })
    }

    tabs = [...universeTabs, ...commonTabs]
  } else {
    tabs = [
      {
        Icon: Icons.GridView,
        label: t(`titles.summary`),
        to: rootPath,
        matchExactPath: true
      },
      ...commonTabs
    ]
  }

  return (
    <Bar>
      {tabs.map((opt) => {
        const isActive = opt.matchExactPath ? pathname === opt.to : pathname.startsWith(opt.to)

        return (
          <Tab
            key={opt.to}
            focusBorderColor={opt.borderColor}
            focusColor={opt.color}
            isFocused={isActive}
            onPress={() => nav(opt.to, { replace: true })}
            p={0}
          >
            <OptionItem
              Icon={opt.Icon}
              label={opt.label}
              color={!isNil(opt.color) && isActive ? 'grayscale.white' : 'grayscale.black'}
            />
          </Tab>
        )
      })}
    </Bar>
  )
}

export default ResourceNavigator
