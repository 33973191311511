import { Redirect } from 'app/routx-router'
import { useToken } from 'app/state'
import { isNil } from 'lodash-es'

const ProtectedRoute: FC = ({ children }) => {
  const [token] = useToken()
  return !isNil(token) ? children : <Redirect to='/auth/login' replace />
}

export default ProtectedRoute
