import { WeenatColor } from '@weenat/theme'
import Text from 'app/src/kit/Text'
import Badge from './Badge'

interface CountIndicatorProps {
  count: string | number
  color: WeenatColor
  backgroundColor: WeenatColor
}

const CountIndicator: FC<CountIndicatorProps> = ({ color, backgroundColor, count }) => {
  return (
    <Badge $p='sm' $backgroundColor={backgroundColor}>
      <Text
        $fontVariantNumeric='tabular-nums'
        $fontSize='sm'
        $fontWeight='medium'
        $textAlign='center'
        $color={color}
      >
        {count}
      </Text>
    </Badge>
  )
}

export default CountIndicator
