import {
  ScreenQueryParam,
  stepsByScreenByTour,
  useShowableTour
} from '@weenat/client/dist/core/tours'
import { Me } from '@weenat/client/dist/resources/me'
import { useOrgContext } from 'app/orgProvider'
import { usePathname } from 'app/routx-router'
import useIrrigationUniverseTourSetup from 'app/src/dashboard/components/DashboardMap/universes/useIrrigationUniverseTourSetup'
import { useCurrentTour, useToken } from 'app/state'
import isNil from 'lodash-es/isNil'
import { useHandleScreenQueryParamChange } from './useScreenQueryParam'

const useTourStepper = (me: Me | undefined, hasDowngradeWarn: boolean) => {
  const [, setCurrentTour] = useCurrentTour()
  const [token] = useToken()
  const pathname = usePathname()
  const { id } = useOrgContext()

  const irrigationUniverseTourSetup = useIrrigationUniverseTourSetup()

  // Location.pathname guard is important otherwise the tour will be enabled too early on the incorrect path.
  const isEnabled =
    !isNil(token) &&
    !hasDowngradeWarn &&
    pathname === `/farms/${id}` &&
    irrigationUniverseTourSetup.isEnabled

  const handleScreenQueryParamChange = useHandleScreenQueryParamChange()

  useShowableTour({
    me,
    isEnabled,
    onShowableTour: (showableTour) => {
      if (irrigationUniverseTourSetup.handleTourSetup(showableTour)) {
        const { name } = showableTour

        setCurrentTour({
          name,
          stepIndex: 0,
          isWaitingNextScreen: false,
          isWaitingNextPop: false
        })
        const [firstScreenName] = Object.keys(stepsByScreenByTour(id)[name])
        handleScreenQueryParamChange(firstScreenName as ScreenQueryParam)
      }
    }
  })

  return null
}

export default useTourStepper
