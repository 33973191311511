import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { MouseEventHandler, ReactNode } from 'react'
import { styled } from 'styled-components'
import { IconProps } from './Icon'
import Icons from './Icons'

interface SelectChipContainerProps {
  $hasIcon?: boolean
  $isDisabled?: boolean
  $isSelected: boolean
}

const SelectChipContainer = styled(Box)<SelectChipContainerProps>`
  display: flex;
  justify-content: center;
  padding: 4px 12px;

  height: 36px;
  min-height: 36px;

  background-color: ${(p) =>
    p.$isSelected
      ? p.theme.colors.primary['200']
      : p.$isDisabled
        ? p.theme.colors.grayscale[100]
        : 'transparent'};

  border-radius: ${(p) => p.theme.radiuses.rounded}px;
  border: 1px solid
    ${(p) => (p.$isSelected ? p.theme.colors.primary['500'] : p.theme.colors.grayscale[300])};

  opacity: ${(p) => (p.$isDisabled ? 0.5 : 1)};
  cursor: ${(p) => (p.$isDisabled ? 'not-allowed' : 'pointer')};

  user-select: none;
  transition: border-color 0.2s ease-in-out;
`

interface SelectChipProps {
  children: ReactNode
  className?: string
  canBeRemoved?: boolean
  isSelected: boolean
  isDisabled?: boolean
  Icon?: FC<IconProps> | null
  iconProps?: Omit<IconProps, '_color'>
  onPress: MouseEventHandler<HTMLDivElement>
  onRemove?: MouseEventHandler<HTMLDivElement>
}

const SelectChip: FC<SelectChipProps> = ({
  className,
  children,
  isSelected = false,
  Icon,
  onPress,
  canBeRemoved = false,
  isDisabled = false,
  onRemove,
  iconProps
}) => {
  const getColor = () => (isSelected && !isDisabled ? 'primary.500' : 'grayscale.black')

  const handleClick: MouseEventHandler<HTMLDivElement> = (e) =>
    !isDisabled && canBeRemoved ? onRemove?.(e) : !isDisabled ? onPress(e) : undefined

  return (
    <SelectChipContainer
      className={className}
      $isDisabled={isDisabled}
      $isSelected={isSelected && !isDisabled}
      $hasIcon={!isNil(Icon)}
      onClick={handleClick}
    >
      <Flex $alignItems='center' $justifyContent='center' $gap='sm'>
        {Icon ? <Icon $size='md' {...iconProps} $color={getColor()} /> : null}
        {typeof children === 'string' ? (
          <Text $color={getColor()} $fontWeight='medium'>
            {children}
          </Text>
        ) : (
          children
        )}
        {canBeRemoved && !isDisabled ? (
          <Icons.Close $size='md' $color='primary.500' $p={0} />
        ) : null}
      </Flex>
    </SelectChipContainer>
  )
}

export default SelectChip
