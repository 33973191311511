interface useMapIntentProps {
  lat: number | undefined
  lng: number | undefined
}

/**
 * Hook providing a url to open maps at the given location
 */
const useMapIntent = ({ lng, lat }: useMapIntentProps) => {
  return `https://maps.google.com/maps?z=12&t=m&q=loc:${lat}+${lng}`
}

export default useMapIntent
