import Text from 'app/src/kit/Text'
import { useToggleFeature } from 'app/state'
import isNil from 'lodash-es/isNil'
import { useTheme } from 'styled-components'
import CountIndicator from './CountIndicator'
import Tab from './Tab'

interface TabsProps<V> {
  currentTab: V
  tabs: {
    label: string
    value: V
    onPress?: () => void
    indicator?: number
  }[]
  onTabChange: (value: V) => void
  flexTabs?: boolean
  className?: string
}

function Tabs<V>({ className, currentTab, tabs, onTabChange, flexTabs = false }: TabsProps<V>) {
  const { colors } = useTheme()
  const [{ redesign }] = useToggleFeature()

  return (
    <Flex $gap={redesign ? 'sm' : undefined} className={className}>
      {tabs.map((tab) => {
        const isFocused = currentTab === tab.value
        const color = isFocused ? colors.primary[500] : colors.grayscale.black

        return (
          <Tab
            key={tab.value.toString()}
            flex={flexTabs ? 1 : undefined}
            isFocused={isFocused}
            onPress={() => {
              tab.onPress?.()
              onTabChange(tab.value)
            }}
            p={redesign ? 'md' : undefined}
          >
            <Text $fontWeight='bold' $textAlign='center' $color={color} $ellipsis>
              {tab.label}
            </Text>
            {!isNil(tab.indicator) ? (
              <CountIndicator
                count={tab.indicator}
                backgroundColor={color}
                color={colors.grayscale.white}
              />
            ) : null}
          </Tab>
        )
      })}
    </Flex>
  )
}

export default Tabs
