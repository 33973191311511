import { css, styled } from 'styled-components'
import { IconProps } from '../kit/Icon'
import Icons from '../kit/Icons'

const sharedProps: Partial<IconProps> = {
  $size: 'md'
}

interface BoxWithActiveStatusProps {
  $active?: boolean
}

const BoxWithActiveStatus = styled(Box)<BoxWithActiveStatusProps>`
  ${(p) =>
    !p.$active &&
    css`
      opacity: 0.4;
    `}
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  margin: ${(p) => p.theme.spacings.xs}px;
`

type MapControlIconProps = BoxWithActiveStatusProps & IconProps

const Trash: FC<MapControlIconProps> = ({ $active, ...props }) => {
  return (
    <BoxWithActiveStatus $active={$active}>
      <Icons.Bin
        {...sharedProps}
        $color={'grayscale.white'}
        $backgroundColor={'feedback.error.500'}
        $rounded
        {...props}
      />
    </BoxWithActiveStatus>
  )
}

const grab: FC<MapControlIconProps> = ({ $active, ...props }) => (
  <BoxWithActiveStatus $active={$active}>
    <Icons.PanTool {...sharedProps} {...props} />
  </BoxWithActiveStatus>
)

const drawPolygon: FC<MapControlIconProps> = ({ $active, ...props }) => (
  <BoxWithActiveStatus $active={$active}>
    <Icons.DrawPolygon {...sharedProps} {...props} />
  </BoxWithActiveStatus>
)

const zoomIn: FC<MapControlIconProps> = ({ $active, ...props }) => (
  <BoxWithActiveStatus $active={$active}>
    <Icons.PlusSign {...sharedProps} {...props} />
  </BoxWithActiveStatus>
)

const zoomOut: FC<MapControlIconProps> = ({ $active, ...props }) => (
  <BoxWithActiveStatus $active={$active}>
    <Icons.MinusSign {...sharedProps} {...props} />
  </BoxWithActiveStatus>
)

const fullscreen: FC<MapControlIconProps> = ({ $active, ...props }) => (
  <BoxWithActiveStatus $active={$active}>
    {$active ? (
      <Icons.Expand {...sharedProps} {...props} />
    ) : (
      <Icons.Collapse {...sharedProps} {...props} />
    )}
  </BoxWithActiveStatus>
)

export default {
  trash: Trash,
  grab,
  drawPolygon,
  zoomIn,
  zoomOut,
  fullscreen
}
