// Generated code. DO NOT EDIT WILL BE OVERRIDE.
// eslint-disable-next-line no-restricted-imports
import { RouteObject } from 'react-router-dom'
import Layout from './routes/_layout'
import AcceptPp from './routes/accept-pp'
import AcceptTos from './routes/accept-tos'
import Actura from './routes/actura'
import AlertsLayout from './routes/alerts/_layout'
import AlertsAlertId from './routes/alerts/[alertId]'
import AlertsCreate from './routes/alerts/create'
import Alerts, { ErrorBoundary as AlertsErrorBoundary } from './routes/alerts/index'
import AuthLayout from './routes/auth/_layout'
import Auth from './routes/auth/index'
import AuthLogin from './routes/auth/login/index'
import AuthRegister from './routes/auth/register'
import AuthResetPassword from './routes/auth/reset-password/index'
import AuthSendVerificationEmail from './routes/auth/send-verification-email'
import DiscoverLayout from './routes/discover/_layout'
import DiscoverSectionSlug from './routes/discover/[sectionSlug]/index'
import Discover, { ErrorBoundary as DiscoverErrorBoundary } from './routes/discover/index'
import Export from './routes/export'
import FarmsLayout from './routes/farms/_layout'
import FarmsFarmId from './routes/farms/[farmId]/index'
import Farms from './routes/farms/index'
import HelpLayout from './routes/help/_layout'
import Help from './routes/help/index'
import Root from './routes/index'
import MeLayout from './routes/me/_layout'
import MeAccount from './routes/me/account'
import MeApi from './routes/me/api'
import MeClusterSettings from './routes/me/cluster-settings'
import MeContactInformation from './routes/me/contact-information'
import MeDebug from './routes/me/debug'
import MeExternalConnections from './routes/me/external-connections'
import MeFeatureToggle from './routes/me/feature-toggle'
import Me from './routes/me/index'
import MeInvitations from './routes/me/invitations/index'
import MeMobileDevices from './routes/me/mobile-devices'
import MePreferences from './routes/me/preferences/index'
import MeProfile from './routes/me/profile'
import NetworksLayout from './routes/networks/_layout'
import NetworksNetworkId from './routes/networks/[networkId]/index'
import Networks from './routes/networks/index'
import PaymentLayout from './routes/payment/_layout'
import PaymentFailed from './routes/payment/failed'
import PaymentSuccess from './routes/payment/success'
import PlotsLayout from './routes/plots/_layout'
import PlotsPlotId from './routes/plots/[plotId]/index'
import Plots from './routes/plots/index'
import PushNotification from './routes/push-notification'
import Smag from './routes/smag'
import UnsubscribeEmails from './routes/unsubscribe-emails'
import WeatherMapLayout from './routes/weather-map/_layout'
import WeatherMapDeviceId from './routes/weather-map/[deviceId]/index'
import Unmatched from './routes/[...unmatched]'

export const routesConfig: RouteObject[] = [
  {
    path: '/',

    element: <Layout />,
    children: [
      {
        path: 'accept-pp',

        element: <AcceptPp />
      },
      {
        path: 'accept-tos',

        element: <AcceptTos />
      },
      {
        path: 'actura',

        element: <Actura />
      },
      {
        path: 'administration',

        children: [
          {
            path: ':orgId',

            children: [
              {
                path: 'billing',

                lazy: () => import('./routes/administration/[orgId]/billing')
              }
            ]
          },
          {
            path: 'organizations',

            children: [
              {
                path: ':orgId',

                lazy: () => import('./routes/administration/organizations/[orgId]/_layout'),
                children: [
                  {
                    path: 'devices',

                    lazy: () =>
                      import('./routes/administration/organizations/[orgId]/devices/_layout'),
                    children: [
                      {
                        path: ':deviceId',

                        lazy: () =>
                          import(
                            './routes/administration/organizations/[orgId]/devices/[deviceId]/_layout'
                          ),
                        children: [
                          {
                            path: 'events',

                            lazy: () =>
                              import(
                                './routes/administration/organizations/[orgId]/devices/[deviceId]/events'
                              )
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'alerts',

        element: <AlertsLayout />,
        children: [
          {
            path: ':alertId',

            element: <AlertsAlertId />
          },
          {
            path: 'create',

            element: <AlertsCreate />
          },
          {
            errorElement: <AlertsErrorBoundary />,
            index: true,
            element: <Alerts />
          }
        ]
      },
      {
        path: 'auth',

        element: <AuthLayout />,
        children: [
          {
            index: true,
            element: <Auth />
          },
          {
            path: 'login',

            lazy: () => import('./routes/auth/login/_layout'),
            children: [
              {
                index: true,
                element: <AuthLogin />
              },
              {
                path: 'magic',

                lazy: () => import('./routes/auth/login/magic/_layout'),
                children: [
                  {
                    path: 'confirm',

                    lazy: () => import('./routes/auth/login/magic/confirm'),
                    children: [
                      {
                        path: ':loginMagicToken',

                        lazy: () => import('./routes/auth/login/magic/confirm/[loginMagicToken]')
                      }
                    ]
                  },
                  {
                    index: true,
                    lazy: () => import('./routes/auth/login/magic/index')
                  }
                ]
              }
            ]
          },
          {
            path: 'register',

            element: <AuthRegister />
          },
          {
            path: 'reset-password',

            children: [
              {
                path: ':resetPasswordUid',

                lazy: () => import('./routes/auth/reset-password/[resetPasswordUid]'),
                children: [
                  {
                    path: ':resetPasswordToken',

                    lazy: () =>
                      import('./routes/auth/reset-password/[resetPasswordUid]/[resetPasswordToken]')
                  }
                ]
              },
              {
                index: true,
                element: <AuthResetPassword />
              }
            ]
          },
          {
            path: 'send-verification-email',

            element: <AuthSendVerificationEmail />
          },
          {
            path: 'verify-email',

            children: [
              {
                path: ':verifyEmailKey',

                lazy: () => import('./routes/auth/verify-email/[verifyEmailKey]')
              }
            ]
          }
        ]
      },
      {
        path: 'discover',

        element: <DiscoverLayout />,
        children: [
          {
            path: ':sectionSlug',

            children: [
              {
                path: ':articleSlug',

                lazy: () => import('./routes/discover/[sectionSlug]/[articleSlug]')
              },
              {
                index: true,
                element: <DiscoverSectionSlug />
              }
            ]
          },
          {
            errorElement: <DiscoverErrorBoundary />,
            index: true,
            element: <Discover />
          }
        ]
      },
      {
        path: 'export',

        element: <Export />
      },
      {
        path: 'farms',

        element: <FarmsLayout />,
        children: [
          {
            path: ':farmId',

            lazy: () => import('./routes/farms/[farmId]/_layout'),
            children: [
              {
                path: 'admin',

                lazy: () => import('./routes/farms/[farmId]/admin/_layout'),
                children: [
                  {
                    path: 'billing',

                    lazy: () => import('./routes/farms/[farmId]/admin/billing/_layout'),
                    children: [
                      {
                        path: 'history',

                        lazy: () => import('./routes/farms/[farmId]/admin/billing/history')
                      },
                      {
                        index: true,
                        lazy: () => import('./routes/farms/[farmId]/admin/billing/index')
                      },
                      {
                        path: 'my-subscription-detail',

                        lazy: () =>
                          import('./routes/farms/[farmId]/admin/billing/my-subscription-detail')
                      },
                      {
                        path: 'next',

                        lazy: () => import('./routes/farms/[farmId]/admin/billing/next')
                      }
                    ]
                  },
                  {
                    path: 'devices',

                    lazy: () => import('./routes/farms/[farmId]/admin/devices/_layout'),
                    children: [
                      {
                        path: ':deviceId',

                        lazy: () =>
                          import('./routes/farms/[farmId]/admin/devices/[deviceId]/_layout'),
                        children: [
                          {
                            path: 'events',

                            lazy: () =>
                              import('./routes/farms/[farmId]/admin/devices/[deviceId]/events')
                          },
                          {
                            index: true,
                            lazy: () =>
                              import('./routes/farms/[farmId]/admin/devices/[deviceId]/index')
                          },
                          {
                            path: 'linked-plots',

                            lazy: () =>
                              import(
                                './routes/farms/[farmId]/admin/devices/[deviceId]/linked-plots'
                              )
                          }
                        ]
                      },
                      {
                        path: 'add',

                        lazy: () => import('./routes/farms/[farmId]/admin/devices/add')
                      },
                      {
                        path: 'agro-weather',

                        lazy: () => import('./routes/farms/[farmId]/admin/devices/agro-weather')
                      },
                      {
                        index: true,
                        lazy: () => import('./routes/farms/[farmId]/admin/devices/index')
                      },
                      {
                        path: 'irrigation',

                        lazy: () => import('./routes/farms/[farmId]/admin/devices/irrigation')
                      }
                    ]
                  },
                  {
                    path: 'edit',

                    lazy: () => import('./routes/farms/[farmId]/admin/edit/_layout'),
                    children: [
                      {
                        path: 'headquarter',

                        lazy: () => import('./routes/farms/[farmId]/admin/edit/headquarter')
                      },
                      {
                        index: true,
                        lazy: () => import('./routes/farms/[farmId]/admin/edit/index')
                      }
                    ]
                  },
                  {
                    index: true,
                    lazy: () => import('./routes/farms/[farmId]/admin/index')
                  },
                  {
                    path: 'info',

                    lazy: () => import('./routes/farms/[farmId]/admin/info')
                  },
                  {
                    path: 'members',

                    lazy: () => import('./routes/farms/[farmId]/admin/members/_layout'),
                    children: [
                      {
                        path: ':memberId',

                        lazy: () =>
                          import('./routes/farms/[farmId]/admin/members/[memberId]/_layout'),
                        children: [
                          {
                            index: true,
                            lazy: () =>
                              import('./routes/farms/[farmId]/admin/members/[memberId]/index')
                          }
                        ]
                      },
                      {
                        index: true,
                        lazy: () => import('./routes/farms/[farmId]/admin/members/index')
                      },
                      {
                        path: 'invite',

                        lazy: () => import('./routes/farms/[farmId]/admin/members/invite')
                      }
                    ]
                  },
                  {
                    path: 'members-invitations',

                    lazy: () => import('./routes/farms/[farmId]/admin/members-invitations/_layout'),
                    children: [
                      {
                        path: ':invitationId',

                        lazy: () =>
                          import(
                            './routes/farms/[farmId]/admin/members-invitations/[invitationId]/_layout'
                          ),
                        children: [
                          {
                            index: true,
                            lazy: () =>
                              import(
                                './routes/farms/[farmId]/admin/members-invitations/[invitationId]/index'
                              )
                          }
                        ]
                      },
                      {
                        index: true,
                        lazy: () =>
                          import('./routes/farms/[farmId]/admin/members-invitations/index')
                      }
                    ]
                  },
                  {
                    path: 'my-farm',

                    lazy: () => import('./routes/farms/[farmId]/admin/my-farm')
                  },
                  {
                    path: 'network',

                    lazy: () => import('./routes/farms/[farmId]/admin/network/_layout'),
                    children: [
                      {
                        index: true,
                        lazy: () => import('./routes/farms/[farmId]/admin/network/index')
                      },
                      {
                        path: 'invitations',

                        lazy: () =>
                          import('./routes/farms/[farmId]/admin/network/invitations/_layout'),
                        children: [
                          {
                            path: ':invitationId',

                            lazy: () =>
                              import(
                                './routes/farms/[farmId]/admin/network/invitations/[invitationId]/_layout'
                              ),
                            children: [
                              {
                                index: true,
                                lazy: () =>
                                  import(
                                    './routes/farms/[farmId]/admin/network/invitations/[invitationId]/index'
                                  )
                              }
                            ]
                          },
                          {
                            index: true,
                            lazy: () =>
                              import('./routes/farms/[farmId]/admin/network/invitations/index')
                          }
                        ]
                      },
                      {
                        path: 'invite',

                        lazy: () => import('./routes/farms/[farmId]/admin/network/invite')
                      },
                      {
                        path: 'members',

                        lazy: () => import('./routes/farms/[farmId]/admin/network/members/_layout'),
                        children: [
                          {
                            path: ':networkOrgId',

                            lazy: () =>
                              import(
                                './routes/farms/[farmId]/admin/network/members/[networkOrgId]/_layout'
                              ),
                            children: [
                              {
                                index: true,
                                lazy: () =>
                                  import(
                                    './routes/farms/[farmId]/admin/network/members/[networkOrgId]/index'
                                  )
                              },
                              {
                                path: 'upgrade',

                                lazy: () =>
                                  import(
                                    './routes/farms/[farmId]/admin/network/members/[networkOrgId]/upgrade'
                                  )
                              }
                            ]
                          },
                          {
                            index: true,
                            lazy: () =>
                              import('./routes/farms/[farmId]/admin/network/members/index')
                          }
                        ]
                      },
                      {
                        path: 'members-trials',

                        lazy: () =>
                          import('./routes/farms/[farmId]/admin/network/members-trials/_layout'),
                        children: [
                          {
                            path: ':networkOrgId',

                            lazy: () =>
                              import(
                                './routes/farms/[farmId]/admin/network/members-trials/[networkOrgId]/_layout'
                              ),
                            children: [
                              {
                                index: true,
                                lazy: () =>
                                  import(
                                    './routes/farms/[farmId]/admin/network/members-trials/[networkOrgId]/index'
                                  )
                              }
                            ]
                          },
                          {
                            index: true,
                            lazy: () =>
                              import('./routes/farms/[farmId]/admin/network/members-trials/index')
                          }
                        ]
                      },
                      {
                        path: 'membership-requests',

                        lazy: () =>
                          import(
                            './routes/farms/[farmId]/admin/network/membership-requests/_layout'
                          ),
                        children: [
                          {
                            path: ':membershipRequestId',

                            lazy: () =>
                              import(
                                './routes/farms/[farmId]/admin/network/membership-requests/[membershipRequestId]/_layout'
                              ),
                            children: [
                              {
                                index: true,
                                lazy: () =>
                                  import(
                                    './routes/farms/[farmId]/admin/network/membership-requests/[membershipRequestId]/index'
                                  )
                              }
                            ]
                          },
                          {
                            index: true,
                            lazy: () =>
                              import(
                                './routes/farms/[farmId]/admin/network/membership-requests/index'
                              )
                          }
                        ]
                      },
                      {
                        path: 'option-requests',

                        lazy: () =>
                          import('./routes/farms/[farmId]/admin/network/option-requests/_layout'),
                        children: [
                          {
                            path: ':invitationId',

                            lazy: () =>
                              import(
                                './routes/farms/[farmId]/admin/network/option-requests/[invitationId]/_layout'
                              ),
                            children: [
                              {
                                index: true,
                                lazy: () =>
                                  import(
                                    './routes/farms/[farmId]/admin/network/option-requests/[invitationId]/index'
                                  )
                              }
                            ]
                          },
                          {
                            index: true,
                            lazy: () =>
                              import('./routes/farms/[farmId]/admin/network/option-requests/index')
                          }
                        ]
                      }
                    ]
                  },
                  {
                    path: 'options',

                    lazy: () => import('./routes/farms/[farmId]/admin/options/_layout'),
                    children: [
                      {
                        path: ':optionCode',

                        lazy: () =>
                          import('./routes/farms/[farmId]/admin/options/[optionCode]/_layout'),
                        children: [
                          {
                            index: true,
                            lazy: () =>
                              import('./routes/farms/[farmId]/admin/options/[optionCode]/index')
                          },
                          {
                            path: 'plots-activations',

                            lazy: () =>
                              import(
                                './routes/farms/[farmId]/admin/options/[optionCode]/plots-activations'
                              )
                          },
                          {
                            path: 'settings',

                            lazy: () =>
                              import('./routes/farms/[farmId]/admin/options/[optionCode]/settings')
                          },
                          {
                            path: 'stations-activations',

                            lazy: () =>
                              import(
                                './routes/farms/[farmId]/admin/options/[optionCode]/stations-activations'
                              )
                          }
                        ]
                      },
                      {
                        index: true,
                        lazy: () => import('./routes/farms/[farmId]/admin/options/index')
                      }
                    ]
                  },
                  {
                    path: 'org-plots',

                    lazy: () => import('./routes/farms/[farmId]/admin/org-plots'),
                    children: [
                      {
                        path: ':plotId',

                        lazy: () => import('./routes/farms/[farmId]/admin/org-plots/[plotId]')
                      }
                    ]
                  },
                  {
                    path: 'plots',

                    lazy: () => import('./routes/farms/[farmId]/admin/plots/_layout'),
                    children: [
                      {
                        path: ':plotId',

                        lazy: () => import('./routes/farms/[farmId]/admin/plots/[plotId]/_layout'),
                        children: [
                          {
                            path: 'devices',

                            lazy: () =>
                              import(
                                './routes/farms/[farmId]/admin/plots/[plotId]/devices/_layout'
                              ),
                            children: [
                              {
                                index: true,
                                lazy: () =>
                                  import(
                                    './routes/farms/[farmId]/admin/plots/[plotId]/devices/index'
                                  )
                              }
                            ]
                          },
                          {
                            index: true,
                            lazy: () => import('./routes/farms/[farmId]/admin/plots/[plotId]/index')
                          },
                          {
                            path: 'option-activations',

                            lazy: () =>
                              import(
                                './routes/farms/[farmId]/admin/plots/[plotId]/option-activations'
                              )
                          }
                        ]
                      },
                      {
                        index: true,
                        lazy: () => import('./routes/farms/[farmId]/admin/plots/index')
                      }
                    ]
                  },
                  {
                    path: 'stations',

                    lazy: () => import('./routes/farms/[farmId]/admin/stations/_layout'),
                    children: [
                      {
                        path: ':stationId',

                        lazy: () =>
                          import('./routes/farms/[farmId]/admin/stations/[stationId]/_layout'),
                        children: [
                          {
                            index: true,
                            lazy: () =>
                              import('./routes/farms/[farmId]/admin/stations/[stationId]/index')
                          },
                          {
                            path: 'option-activations',

                            lazy: () =>
                              import(
                                './routes/farms/[farmId]/admin/stations/[stationId]/option-activations'
                              )
                          }
                        ]
                      },
                      {
                        index: true,
                        lazy: () => import('./routes/farms/[farmId]/admin/stations/index')
                      }
                    ]
                  },
                  {
                    path: 'upgrade-to-premium',

                    lazy: () => import('./routes/farms/[farmId]/admin/upgrade-to-premium')
                  }
                ]
              },
              {
                index: true,
                element: <FarmsFarmId />
              },
              {
                path: 'onboarding',

                lazy: () => import('./routes/farms/[farmId]/onboarding/_layout'),
                children: [
                  {
                    index: true,
                    lazy: () => import('./routes/farms/[farmId]/onboarding/index')
                  },
                  {
                    path: 'members-tips',

                    lazy: () => import('./routes/farms/[farmId]/onboarding/members-tips')
                  },
                  {
                    path: 'my-farm',

                    lazy: () => import('./routes/farms/[farmId]/onboarding/my-farm')
                  },
                  {
                    path: 'networks',

                    lazy: () => import('./routes/farms/[farmId]/onboarding/networks/_layout'),
                    children: [
                      {
                        path: ':networkId',

                        lazy: () =>
                          import('./routes/farms/[farmId]/onboarding/networks/[networkId]')
                      },
                      {
                        path: 'find',

                        lazy: () => import('./routes/farms/[farmId]/onboarding/networks/find')
                      },
                      {
                        index: true,
                        lazy: () => import('./routes/farms/[farmId]/onboarding/networks/index')
                      }
                    ]
                  },
                  {
                    path: 'sensors',

                    lazy: () => import('./routes/farms/[farmId]/onboarding/sensors/_layout'),
                    children: [
                      {
                        path: 'add',

                        lazy: () => import('./routes/farms/[farmId]/onboarding/sensors/add')
                      },
                      {
                        index: true,
                        lazy: () => import('./routes/farms/[farmId]/onboarding/sensors/index')
                      },
                      {
                        path: 'success',

                        lazy: () => import('./routes/farms/[farmId]/onboarding/sensors/success')
                      }
                    ]
                  },
                  {
                    path: 'welcome',

                    lazy: () => import('./routes/farms/[farmId]/onboarding/welcome')
                  }
                ]
              },
              {
                path: 'plots',

                lazy: () => import('./routes/farms/[farmId]/plots/_layout'),
                children: [
                  {
                    path: ':plotId',

                    lazy: () => import('./routes/farms/[farmId]/plots/[plotId]/_layout'),
                    children: [
                      {
                        path: 'alerts',

                        lazy: () => import('./routes/farms/[farmId]/plots/[plotId]/alerts')
                      },
                      {
                        path: 'crop-tracking',

                        lazy: () => import('./routes/farms/[farmId]/plots/[plotId]/crop-tracking')
                      },
                      {
                        path: 'decitrait',

                        lazy: () =>
                          import('./routes/farms/[farmId]/plots/[plotId]/decitrait/_layout'),
                        children: [
                          {
                            index: true,
                            lazy: () =>
                              import('./routes/farms/[farmId]/plots/[plotId]/decitrait/index')
                          },
                          {
                            path: 'sanitary-state-notes',

                            lazy: () =>
                              import(
                                './routes/farms/[farmId]/plots/[plotId]/decitrait/sanitary-state-notes'
                              )
                          },
                          {
                            path: 'settings',

                            lazy: () =>
                              import('./routes/farms/[farmId]/plots/[plotId]/decitrait/settings')
                          },
                          {
                            path: 'treatments',

                            lazy: () =>
                              import(
                                './routes/farms/[farmId]/plots/[plotId]/decitrait/treatments/_layout'
                              ),
                            children: [
                              {
                                path: 'add',

                                lazy: () =>
                                  import(
                                    './routes/farms/[farmId]/plots/[plotId]/decitrait/treatments/add'
                                  )
                              },
                              {
                                index: true,
                                lazy: () =>
                                  import(
                                    './routes/farms/[farmId]/plots/[plotId]/decitrait/treatments/index'
                                  )
                              }
                            ]
                          }
                        ]
                      },
                      {
                        path: 'forecasts',

                        lazy: () => import('./routes/farms/[farmId]/plots/[plotId]/forecasts')
                      },
                      {
                        path: 'frost',

                        lazy: () => import('./routes/farms/[farmId]/plots/[plotId]/frost')
                      },
                      {
                        path: 'history',

                        lazy: () => import('./routes/farms/[farmId]/plots/[plotId]/history')
                      },
                      {
                        index: true,
                        lazy: () => import('./routes/farms/[farmId]/plots/[plotId]/index')
                      },
                      {
                        path: 'irrelis',

                        lazy: () =>
                          import('./routes/farms/[farmId]/plots/[plotId]/irrelis/_layout'),
                        children: [
                          {
                            path: 'create-plot',

                            lazy: () =>
                              import('./routes/farms/[farmId]/plots/[plotId]/irrelis/create-plot')
                          },
                          {
                            index: true,
                            lazy: () =>
                              import('./routes/farms/[farmId]/plots/[plotId]/irrelis/index')
                          },
                          {
                            path: 'settings',

                            lazy: () =>
                              import('./routes/farms/[farmId]/plots/[plotId]/irrelis/settings')
                          }
                        ]
                      },
                      {
                        path: 'irrigation',

                        lazy: () =>
                          import('./routes/farms/[farmId]/plots/[plotId]/irrigation/_layout'),
                        children: [
                          {
                            index: true,
                            lazy: () =>
                              import('./routes/farms/[farmId]/plots/[plotId]/irrigation/index')
                          },
                          {
                            path: 'reports',

                            lazy: () =>
                              import('./routes/farms/[farmId]/plots/[plotId]/irrigation/reports')
                          },
                          {
                            path: 'settings',

                            lazy: () =>
                              import(
                                './routes/farms/[farmId]/plots/[plotId]/irrigation/settings/_layout'
                              ),
                            children: [
                              {
                                path: 'thresholds',

                                lazy: () =>
                                  import(
                                    './routes/farms/[farmId]/plots/[plotId]/irrigation/settings/thresholds/_layout'
                                  ),
                                children: [
                                  {
                                    path: ':thresholdId',

                                    lazy: () =>
                                      import(
                                        './routes/farms/[farmId]/plots/[plotId]/irrigation/settings/thresholds/[thresholdId]'
                                      )
                                  },
                                  {
                                    path: 'create',

                                    lazy: () =>
                                      import(
                                        './routes/farms/[farmId]/plots/[plotId]/irrigation/settings/thresholds/create'
                                      )
                                  },
                                  {
                                    index: true,
                                    lazy: () =>
                                      import(
                                        './routes/farms/[farmId]/plots/[plotId]/irrigation/settings/thresholds/index'
                                      )
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        path: 'maizy',

                        lazy: () => import('./routes/farms/[farmId]/plots/[plotId]/maizy')
                      },
                      {
                        path: 'options',

                        lazy: () => import('./routes/farms/[farmId]/plots/[plotId]/options')
                      },
                      {
                        path: 'overview',

                        lazy: () => import('./routes/farms/[farmId]/plots/[plotId]/overview')
                      },
                      {
                        path: 'summary',

                        lazy: () => import('./routes/farms/[farmId]/plots/[plotId]/summary')
                      },
                      {
                        path: 'tavmills',

                        lazy: () => import('./routes/farms/[farmId]/plots/[plotId]/tavmills')
                      },
                      {
                        path: 'water-balance',

                        lazy: () =>
                          import('./routes/farms/[farmId]/plots/[plotId]/water-balance/_layout'),
                        children: [
                          {
                            index: true,
                            lazy: () =>
                              import('./routes/farms/[farmId]/plots/[plotId]/water-balance/index')
                          },
                          {
                            path: 'reports',

                            lazy: () =>
                              import('./routes/farms/[farmId]/plots/[plotId]/water-balance/reports')
                          },
                          {
                            path: 'settings',

                            lazy: () =>
                              import(
                                './routes/farms/[farmId]/plots/[plotId]/water-balance/settings'
                              )
                          }
                        ]
                      },
                      {
                        path: 'water-balance-ri',

                        lazy: () =>
                          import('./routes/farms/[farmId]/plots/[plotId]/water-balance-ri/_layout'),
                        children: [
                          {
                            index: true,
                            lazy: () =>
                              import(
                                './routes/farms/[farmId]/plots/[plotId]/water-balance-ri/index'
                              )
                          },
                          {
                            path: 'reports',

                            lazy: () =>
                              import(
                                './routes/farms/[farmId]/plots/[plotId]/water-balance-ri/reports'
                              )
                          },
                          {
                            path: 'settings',

                            lazy: () =>
                              import(
                                './routes/farms/[farmId]/plots/[plotId]/water-balance-ri/settings'
                              )
                          }
                        ]
                      },
                      {
                        path: 'weather',

                        lazy: () => import('./routes/farms/[farmId]/plots/[plotId]/weather')
                      },
                      {
                        path: 'weephyt',

                        lazy: () => import('./routes/farms/[farmId]/plots/[plotId]/weephyt')
                      }
                    ]
                  },
                  {
                    path: 'archived',

                    lazy: () => import('./routes/farms/[farmId]/plots/archived')
                  },
                  {
                    path: 'create',

                    lazy: () => import('./routes/farms/[farmId]/plots/create')
                  },
                  {
                    path: 'edit',

                    lazy: () => import('./routes/farms/[farmId]/plots/edit')
                  },
                  {
                    index: true,
                    lazy: () => import('./routes/farms/[farmId]/plots/index')
                  }
                ]
              },
              {
                path: 'stations',

                lazy: () => import('./routes/farms/[farmId]/stations/_layout'),
                children: [
                  {
                    path: ':stationId',

                    lazy: () => import('./routes/farms/[farmId]/stations/[stationId]/_layout'),
                    children: [
                      {
                        path: 'alerts',

                        lazy: () => import('./routes/farms/[farmId]/stations/[stationId]/alerts')
                      },
                      {
                        path: 'forecasts',

                        lazy: () => import('./routes/farms/[farmId]/stations/[stationId]/forecasts')
                      },
                      {
                        path: 'history',

                        lazy: () => import('./routes/farms/[farmId]/stations/[stationId]/history')
                      },
                      {
                        index: true,
                        lazy: () => import('./routes/farms/[farmId]/stations/[stationId]/index')
                      },
                      {
                        path: 'options',

                        lazy: () => import('./routes/farms/[farmId]/stations/[stationId]/options')
                      },
                      {
                        path: 'summary',

                        lazy: () => import('./routes/farms/[farmId]/stations/[stationId]/summary')
                      },
                      {
                        path: 'weather',

                        lazy: () => import('./routes/farms/[farmId]/stations/[stationId]/weather')
                      },
                      {
                        path: 'weephyt',

                        lazy: () => import('./routes/farms/[farmId]/stations/[stationId]/weephyt')
                      }
                    ]
                  },
                  {
                    index: true,
                    lazy: () => import('./routes/farms/[farmId]/stations/index')
                  }
                ]
              }
            ]
          },
          {
            index: true,
            element: <Farms />
          }
        ]
      },
      {
        path: 'help',

        element: <HelpLayout />,
        children: [
          {
            index: true,
            element: <Help />
          }
        ]
      },
      {
        index: true,
        element: <Root />
      },
      {
        path: 'me',

        element: <MeLayout />,
        children: [
          {
            path: 'account',

            element: <MeAccount />
          },
          {
            path: 'api',

            element: <MeApi />
          },
          {
            path: 'cluster-settings',

            element: <MeClusterSettings />
          },
          {
            path: 'contact-information',

            element: <MeContactInformation />
          },
          {
            path: 'debug',

            element: <MeDebug />
          },
          {
            path: 'external-connections',

            element: <MeExternalConnections />
          },
          {
            path: 'feature-toggle',

            element: <MeFeatureToggle />
          },
          {
            index: true,
            element: <Me />
          },
          {
            path: 'invitations',

            lazy: () => import('./routes/me/invitations/_layout'),
            children: [
              {
                path: 'accept',

                lazy: () => import('./routes/me/invitations/accept/_layout'),
                children: [
                  {
                    path: ':invitationBase64',

                    lazy: () => import('./routes/me/invitations/accept/[invitationBase64]')
                  },
                  {
                    index: true,
                    lazy: () => import('./routes/me/invitations/accept/index')
                  }
                ]
              },
              {
                index: true,
                element: <MeInvitations />
              }
            ]
          },
          {
            path: 'mobile-devices',

            element: <MeMobileDevices />
          },
          {
            path: 'preferences',

            children: [
              {
                index: true,
                element: <MePreferences />
              },
              {
                path: 'notifications',

                lazy: () => import('./routes/me/preferences/notifications')
              }
            ]
          },
          {
            path: 'profile',

            element: <MeProfile />
          }
        ]
      },
      {
        path: 'networks',

        element: <NetworksLayout />,
        children: [
          {
            path: ':networkId',

            children: [
              {
                index: true,
                element: <NetworksNetworkId />
              }
            ]
          },
          {
            index: true,
            element: <Networks />
          }
        ]
      },
      {
        path: 'payment',

        element: <PaymentLayout />,
        children: [
          {
            path: 'failed',

            element: <PaymentFailed />
          },
          {
            path: 'success',

            element: <PaymentSuccess />
          }
        ]
      },
      {
        path: 'plots',

        element: <PlotsLayout />,
        children: [
          {
            path: ':plotId',

            lazy: () => import('./routes/plots/[plotId]/_layout'),
            children: [
              {
                path: 'forecasts',

                lazy: () => import('./routes/plots/[plotId]/forecasts')
              },
              {
                path: 'history',

                lazy: () => import('./routes/plots/[plotId]/history')
              },
              {
                index: true,
                element: <PlotsPlotId />
              },
              {
                path: 'summary',

                lazy: () => import('./routes/plots/[plotId]/summary')
              }
            ]
          },
          {
            index: true,
            element: <Plots />
          }
        ]
      },
      {
        path: 'push-notification',

        element: <PushNotification />
      },
      {
        path: 'smag',

        element: <Smag />
      },
      {
        path: 'unsubscribe-emails',

        element: <UnsubscribeEmails />
      },
      {
        path: 'weather-map',

        element: <WeatherMapLayout />,
        children: [
          {
            path: ':deviceId',

            lazy: () => import('./routes/weather-map/[deviceId]/_layout'),
            children: [
              {
                path: 'alerts',

                lazy: () => import('./routes/weather-map/[deviceId]/alerts')
              },
              {
                path: 'forecasts',

                lazy: () => import('./routes/weather-map/[deviceId]/forecasts')
              },
              {
                path: 'history',

                lazy: () => import('./routes/weather-map/[deviceId]/history')
              },
              {
                index: true,
                element: <WeatherMapDeviceId />
              },
              {
                path: 'summary',

                lazy: () => import('./routes/weather-map/[deviceId]/summary')
              },
              {
                path: 'weather',

                lazy: () => import('./routes/weather-map/[deviceId]/weather')
              }
            ]
          }
        ]
      },
      {
        path: '*',

        element: <Unmatched />
      }
    ]
  }
]
