import {
  AlertValidationForm,
  stepsWithoutBreadcrumbs
} from '@weenat/client/dist/core/alerts/alerts'
import useAlertCreation from '@weenat/client/dist/core/alerts/useAlertCreation'
import { Alert, Predicate, Source } from '@weenat/client/dist/resources/alerts.types'
import { Device } from '@weenat/client/dist/resources/devices'
import { Plot } from '@weenat/client/dist/resources/plots'
import { Station } from '@weenat/client/dist/resources/stations'
import { Href, HrefWithoutGoBack } from '@weenat/client/dist/routx/runtime-core'
import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import useScreenQueryParam, {
  useHandleScreenQueryParamChange
} from 'app/src/hooks/useScreenQueryParam'
import useToasts from 'app/src/hooks/useToasts'
import StepProgressIndicator from 'app/src/kit/StepProgressIndicator'
import Text from 'app/src/kit/Text'
import FullScreen from 'app/src/kit/fullscreens/FullScreen'
import { useCurrentTour } from 'app/state'
import isNil from 'lodash-es/isNil'
import { useCallback } from 'react'
import { styled } from 'styled-components'
import AlertBreadcrumbs from './AlertBreadcrumbs'
import PickAlertCreationType from './PickAlertCreationType'
import PickAlertNotificationMethod from './PickAlertNotificationMethod'
import PickAlertTargets from './PickAlertTargets'
import PickPredicateOperation from './PickPredicateOperation'
import PickPredicateSource from './PickPredicateSource'
import SetupPredicateParams from './SetupPredicateParams'
import ValidateAlert from './ValidateAlert'
import ValidateMultiplePredicates from './ValidateMultiplePredicates'

const APPROXIMATE_MODAL_HEADER_HEIGHT = 64
const MAX_SCREEN_WIDTH = 980

const ContentContainer = styled(Flex)`
  flex-direction: column;
  height: ${`calc(100vh - ${APPROXIMATE_MODAL_HEADER_HEIGHT}px)`};
  width: 100%;
  max-width: ${`${MAX_SCREEN_WIDTH}px`};
  margin: 0 auto;
  padding: 16px 48px;

  /** Hiding scrollbar but keeping scroll */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /** Chromium based */
  &::-webkit-scrollbar {
    display: none;
  }
`

interface CreateNewInterface {
  initialTarget?: Plot | Device | Station
  origin?: string
}

const CreateNewAlert: FC<CreateNewInterface> = ({ initialTarget, origin }) => {
  const { t } = useIntl()
  const { addToast } = useToasts()
  const nav = useNavigate()
  //here to copy behaviour of native close cb
  // the hook will display toast on close callback
  const onClose = (cb?: () => void) => {
    if (cb) {
      cb()
    }
  }

  const {
    currentAlertCreationType,
    currentPredicate,
    currentStep,
    currentStepNumber,
    currentSteps,
    disabledSources,
    goToStep,
    goToPreviousStep,
    handleAddPredicateForMultiParams,
    handleEditPredicateForMultiParams,
    handleMultiplePredicatesValidation,
    handleOperationSelection,
    handleParamsSelection,
    handleSourceSelection,
    handleTargetsSelection,
    handleTypeSelection,
    handleNotificationMethodChoice,
    handleValidation,
    handlePreviousOnValidateMultiplePredicates,
    stepTitle,
    currentPredicates,
    alertValidationForm,
    targetsWithTZ,
    setTargetsWithTZ,
    handlePredicateDeletion,
    createAlertRequest
  } = useAlertCreation({ initialTarget, addToast, onClose })

  const handleScreenQueryParamChange = useHandleScreenQueryParamChange()
  const [currentTour] = useCurrentTour()

  const submitAlert = useCallback(
    async (alertValidationFormSubmitted?: AlertValidationForm | undefined) => {
      await handleValidation(alertValidationFormSubmitted)
      if (currentTour?.name === 'discover_alerts') {
        handleScreenQueryParamChange('outro', () => true, '/alerts')
      } else if (!isNil(origin)) {
        nav(origin as HrefWithoutGoBack)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleValidation, currentTour?.name]
  )

  useScreenQueryParam(currentStep)

  return (
    <FullScreen
      headerHasBorder={true}
      title={
        <Text $fontSize='lg' $fontWeight='bold'>
          {t(`alerts.types.${currentAlertCreationType}.name`)}
        </Text>
      }
      onClose={() => {
        if (isNil(origin)) {
          nav('/alerts')
        } else {
          nav(origin as Href)
        }
      }}
    >
      <ContentContainer>
        {/* HEADER */}
        <Box $mb='lg'>
          <StepProgressIndicator
            numberOfSteps={currentSteps.length}
            currentStepIndex={currentStepNumber - 1}
          />
          <Text $fontWeight='medium'>{stepTitle}</Text>
        </Box>

        {/* BREADCRUMBS */}
        {!stepsWithoutBreadcrumbs.includes(currentStep) && currentPredicate && (
          <AlertBreadcrumbs
            predicatesLength={currentPredicates.length}
            creationType={currentAlertCreationType}
            displayedPredicate={currentPredicate}
            navigateToOp={() => goToStep('pickPredicateOperation')}
            navigateToParams={() => goToStep('setupPredicate')}
            navigateToSource={() => goToStep('pickPredicateSource')}
          />
        )}

        {/* STEP CONTENT */}
        {currentStep === 'pickAlertCreationType' ? (
          <PickAlertCreationType
            initialType={currentAlertCreationType}
            currentStepNumber={currentStepNumber}
            onPressNext={handleTypeSelection}
          />
        ) : isNil(currentPredicate) ? (
          <></>
        ) : currentStep === 'pickPredicateSource' ? (
          <PickPredicateSource
            initialSource={currentPredicate.source}
            disabledSources={disabledSources}
            currentStepNumber={currentStepNumber}
            onPressPrevious={goToPreviousStep}
            onPressNext={handleSourceSelection}
          />
        ) : currentStep === 'pickPredicateOperation' && currentPredicate.source ? (
          <PickPredicateOperation
            currentAlertCreationType={currentAlertCreationType}
            initialOperation={currentPredicate.op}
            source={currentPredicate.source}
            currentStepNumber={currentStepNumber}
            onPressPrevious={goToPreviousStep}
            onPressNext={handleOperationSelection}
          />
        ) : currentStep === 'setupPredicate' && currentPredicate.source && currentPredicate.op ? (
          <SetupPredicateParams
            predicateToSetup={currentPredicate as Predicate}
            currentStepNumber={currentStepNumber}
            onPressPrevious={goToPreviousStep}
            onPressNext={handleParamsSelection}
          />
        ) : currentStep === 'validateMultiplePredicates' ? (
          <ValidateMultiplePredicates
            initialPredicates={currentPredicates as Alert['predicates']}
            currentStepNumber={currentStepNumber}
            onPressPrevious={handlePreviousOnValidateMultiplePredicates}
            onPressAddPredicate={handleAddPredicateForMultiParams}
            onPressEditPredicate={handleEditPredicateForMultiParams}
            onPressDeletePredicate={handlePredicateDeletion}
            onPressNext={handleMultiplePredicatesValidation}
          />
        ) : currentStep === 'pickTargets' ? (
          <PickAlertTargets
            initialTargets={targetsWithTZ}
            setTargets={setTargetsWithTZ}
            currentStepNumber={currentStepNumber}
            sources={
              currentPredicates.map((p) => p.source).filter((s) => s !== undefined) as Source[]
            }
            onPressPrevious={goToPreviousStep}
            onPressNext={handleTargetsSelection}
          />
        ) : currentStep === 'pickNotificationMethod' ? (
          <PickAlertNotificationMethod
            currentAlertCreationType={currentAlertCreationType}
            isVoiceCallEnabled={!!alertValidationForm.isVoiceCallEnabled}
            currentStepNumber={currentStepNumber}
            onPressPrevious={goToPreviousStep}
            onPressNext={handleNotificationMethodChoice}
          />
        ) : currentStep === 'validation' ? (
          <ValidateAlert
            isNextLoading={createAlertRequest.isPending}
            alertType={currentAlertCreationType}
            predicates={currentPredicates}
            alertValidationForm={alertValidationForm}
            targets={targetsWithTZ}
            currentStepNumber={currentStepNumber}
            isLastStep
            onPressPrevious={goToPreviousStep}
            onPressNext={submitAlert}
          />
        ) : null}
      </ContentContainer>
    </FullScreen>
  )
}
export default CreateNewAlert
