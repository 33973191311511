import { useClient, useQuery } from '@weenat/client'
import { getDeviceCommonName } from '@weenat/client/dist/core/devices'
import { useHasAtLeastOneBilledOrg } from '@weenat/client/dist/core/me'
import { DeviceSummary } from '@weenat/client/dist/resources/measurements.type'
import { Slot } from 'app/routx-router'
import BackgroundMap from 'app/src/dashboard/components/DashboardMap/BackgroundMap'
import WeatherMapOverlay from 'app/src/dashboard/components/DashboardMap/WeatherMapOverlay'
import { useBackgroundMapContext } from 'app/src/dashboard/components/DashboardMap/contexts/BackgroundMapContext'
import usePODClusters from 'app/src/dashboard/components/DashboardMap/hooks/usePODClusters'
import useUniverseBackgroundMapContext from 'app/src/dashboard/components/DashboardMap/universes/useUniverseBackgroundMapContext'
import { MapOverlayFallback } from 'app/src/kit/FeatGuarded'
import AuthenticatedLayout from 'app/src/layouts/AuthenticatedLayout'
import ProtectedRoute from 'app/src/layouts/ProtectedRoute'
import { useToggleFeature, useUniverse } from 'app/state'
import { isEmpty, isNil } from 'lodash-es'
import { useMemo } from 'react'

// 10 Minutes
// Might need to change when frost universe is active
const REFETCH_INTERVAL = 10 * 60 * 1000

const EMPTY_DEVICE_SUMMARIES: DeviceSummary[] = []

const WeatherMapLayout = () => {
  const [universe] = useUniverse()
  const client = useClient()
  const [{ redesign }] = useToggleFeature()

  const { hasAtLeastOneBilledOrg, isLoading: isOrgLoading } = useHasAtLeastOneBilledOrg()
  const { focusedMetricId } = useUniverseBackgroundMapContext()
  const { search } = useBackgroundMapContext()

  const deviceSummariesRequest = useQuery(
    client.measurements.devices.summaries.getAllPages({
      universe
    }),
    {
      enabled: hasAtLeastOneBilledOrg,
      refetchInterval: REFETCH_INTERVAL
    }
  )

  const summaries = deviceSummariesRequest.data ?? EMPTY_DEVICE_SUMMARIES
  const filteredSummaries = useMemo(() => {
    let items = summaries
    if (redesign) {
      items = items.filter((item) => {
        const { device } = item.metadata
        return !(
          !isNil(search) &&
          !(
            device.endUserName.join('').toLowerCase().includes(search.toLowerCase()) ||
            (device.reverseGeocoding?.toLowerCase().includes(search.toLowerCase()) ?? false) ||
            getDeviceCommonName(device).toLowerCase().includes(search.toLowerCase())
          )
        )
      })
    } else {
      if (!isNil(focusedMetricId)) {
        items = items.filter((sum) => {
          const metaItem = sum.metadata.device

          return (
            !isEmpty(metaItem.availableMeasures) &&
            metaItem.availableMeasures.some((metric) => metric === focusedMetricId)
          )
        })
      }
    }

    return items
  }, [summaries, redesign, search, focusedMetricId])

  const isLoading = isOrgLoading || deviceSummariesRequest.isLoading
  usePODClusters({
    summaries: filteredSummaries,
    isPlotsMap: false,
    isFetching: deviceSummariesRequest.isFetching
  })

  return (
    <ProtectedRoute>
      <AuthenticatedLayout>
        {redesign ? (
          <BackgroundMap
            isPlotsMap={false}
            getBoundingBoxRequester={client.boundingBoxes.devices.getBoundingBox}
          />
        ) : null}
        <WeatherMapOverlay
          summaries={summaries}
          isLoading={isLoading}
          filteredSummaries={filteredSummaries}
        >
          {hasAtLeastOneBilledOrg ? <Slot /> : isLoading ? null : <MapOverlayFallback />}
        </WeatherMapOverlay>
      </AuthenticatedLayout>
    </ProtectedRoute>
  )
}

export const Component = WeatherMapLayout
export default WeatherMapLayout
