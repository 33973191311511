import { useSearchParams } from 'app/routx-router'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { z } from 'zod'

// TODO: this should be set on each respective screen (login/register) to not confuse with wishedNetwork
const searchParamsSchema = z.object({
  networkId: z.coerce.string().optional()
})

const useWishedNetworkFromUrl = (): { networkId: number; trial: boolean } | undefined => {
  const [{ networkId }] = useSearchParams<typeof searchParamsSchema>()

  let result

  if (!isNil(networkId) && !isEmpty(networkId)) {
    result = {
      networkId: typeof networkId === 'string' ? parseInt(networkId, 10) : networkId,
      // trial is true because otherwize its generate accouting error in networks because they don't understand that this user will be charged onto their bill
      trial: true
    }
  }

  return result
}

export default useWishedNetworkFromUrl
