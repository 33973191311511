import { css, styled } from 'styled-components'

const Container = styled(Flex)<{ $docking: 'left' | 'right' }>`
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.grayscale.white};

  ${({ $docking, theme }) =>
    $docking === 'left'
      ? css`
          border-right: 1px solid ${theme.colors.grayscale[300]};
        `
      : css`
          border-left: 1px solid ${theme.colors.grayscale[300]};
        `};
`

interface PanelProps {
  width: number
  className?: string
  docking: 'left' | 'right'
}

const Panel: FC<PanelProps> = ({ children, className, docking, width = 240 }) => {
  return (
    <Container $width={width} $docking={docking} className={className}>
      {children}
    </Container>
  )
}

export default Panel
