import { useClient, useQuery } from '@weenat/client'
import { NotificationStatus } from '@weenat/client/dist/resources/notifications'
import useDisclosure from 'app/src/hooks/useDisclosure'
import { Dropdown, DropdownTrigger } from 'app/src/kit/fields/Select/SelectDropdown'
import Icons from 'app/src/kit/Icons'
import NotificationsDropdown from 'app/src/notifications/NotificationsDropdown'
import logEvent from 'app/utils/analytics'
import { isNil } from 'lodash-es'
import { useCallback, useState } from 'react'

interface AccountNotificationsProps {}

const AccountNotifications: FC<AccountNotificationsProps> = () => {
  const client = useClient()

  const {
    isOpen: shouldDisplayNotifications,
    close: closeNotifications,
    toggle: toggleNotifications
  } = useDisclosure(false)

  const [shownNotificationStatus, setShownNotificationStatus] = useState<NotificationStatus>(
    NotificationStatus.unread
  )

  // This request is only to get the total number of notifications
  // Menu is rendered on all screens, even public ones,
  // we do not want to get a 401 when not logged in
  const notificationsRequest = useQuery(
    client.notifications.getMany({ limit: 1, status: NotificationStatus.unread })
  )

  const totalNumberOfUnreadNotifications = isNil(notificationsRequest.data?.count)
    ? 0
    : notificationsRequest.data.count

  const onClickOnNotificationsIcon = useCallback(() => {
    if (!shouldDisplayNotifications) logEvent('open_notif')
    toggleNotifications()
  }, [toggleNotifications, shouldDisplayNotifications])

  const onCloseNotifications = useCallback(() => {
    closeNotifications()
  }, [closeNotifications])

  const TouchableNotificationIcon =
    totalNumberOfUnreadNotifications > 0 ? Icons.NotificationsUnread : Icons.Notifications

  return (
    <Dropdown
      isVisible={shouldDisplayNotifications}
      onVisibleChange={onClickOnNotificationsIcon}
      marginFromParent={12}
    >
      <DropdownTrigger>
        <TouchableNotificationIcon
          $size='lg'
          $p={'md'}
          onPress={onClickOnNotificationsIcon}
          className='notificationIcon'
        />
      </DropdownTrigger>

      <NotificationsDropdown
        totalNumberOfUnreadNotifications={totalNumberOfUnreadNotifications}
        onClose={onCloseNotifications}
        onStatusChange={(newStatus) => {
          setShownNotificationStatus(newStatus)
        }}
        currentStatus={shownNotificationStatus}
      />
    </Dropdown>
  )
}

export default AccountNotifications
