import { HTMLProps } from 'react'
import { css, styled } from 'styled-components'

import checkmark from '../../images/svg/checkmark.svg'

const StyledCheckbox = styled.input`
  appearance: none;
  flex-shrink: 0;

  height: ${(p) => p.theme.typography.sizes.rg}px;
  width: ${(p) => p.theme.typography.sizes.rg}px;

  padding: 2px;
  vertical-align: middle;
  fill-opacity: 0;
  border-radius: 3px;

  background-color: ${(p) => (p.checked ? p.theme.colors.primary[500] : 'transparent')};
  border: 1px solid
    ${(p) => (p.checked ? p.theme.colors.primary[500] : p.theme.colors.grayscale[300])};

  ${(p) =>
    p.checked
      ? css`
          background-image: url(${checkmark});
          background-repeat: no-repeat;
        `
      : null}

  box-shadow: none;

  transition:
    background-color 200ms ease-in-out 0s,
    border-color 200ms ease-in-out 0s;
`

const Checkbox: FC<HTMLProps<HTMLInputElement>> = ({ children, checked, ...props }) => {
  return (
    <StyledCheckbox type='checkbox' checked={checked} readOnly {...props}>
      {children}
    </StyledCheckbox>
  )
}

export default Checkbox
