import { WeenatColor } from '@weenat/theme'
import { useIntl } from '@weenat/wintl'
import { useSearchParams } from 'app/routx-router'
import styled from 'styled-components'
import { displaySettingsSearchParamsSchema } from '../myFarm/tools/DisplaySettings'
import Grid from './Grid'
import Icons from './Icons'
import Text from './Text'

const SelectableFlex = styled(Flex)`
  &:hover {
    cursor: pointer;
    border-color: ${(p) => p.theme.colors.grayscale.black};
    border-width: 1px;
    border-style: solid;
  }
`

const ViewKindSelector = ({ className }: { className?: string }) => {
  const { t } = useIntl()
  const selectedBackgroundColor: WeenatColor = 'grayscale.black'
  const selectedBackColor: WeenatColor = 'grayscale.white'
  const [searchParams, setSearchParams] =
    useSearchParams<typeof displaySettingsSearchParamsSchema>()
  const isListSelected = searchParams.view === 'list'

  return (
    <Grid
      $gap='sm'
      $templateColumns='1fr 1fr'
      $backgroundColor='grayscale.white'
      $borderRadius='lg'
      $borderWidth={1}
      $borderColor='grayscale.black'
      $p='sm'
      $pointerEvents='auto'
      $width='fit-content'
      $alignSelf='center'
      className={className}
    >
      <SelectableFlex
        $alignItems='center'
        $justifyContent='center'
        $borderRadius='12px'
        $py='md'
        $px='lg'
        $gap='md'
        $backgroundColor={!isListSelected ? selectedBackgroundColor : undefined}
        onClick={() => setSearchParams({ ...searchParams, view: 'map' })}
        $borderWidth={1}
        $borderColor={!isListSelected ? selectedBackgroundColor : 'grayscale.white'}
      >
        <Icons.Map $size='md' $color={!isListSelected ? selectedBackColor : undefined} />
        <Text $color={!isListSelected ? selectedBackColor : undefined}>{t('map.views.map')}</Text>
      </SelectableFlex>
      <SelectableFlex
        $alignItems='center'
        $justifyContent='center'
        $borderRadius='12px'
        $py='md'
        $px='lg'
        $gap='md'
        $backgroundColor={isListSelected ? selectedBackgroundColor : undefined}
        onClick={() => setSearchParams({ ...searchParams, view: 'list' })}
        $borderWidth={1}
        $borderColor={isListSelected ? selectedBackgroundColor : 'grayscale.white'}
      >
        <Icons.List $size='md' $color={isListSelected ? selectedBackColor : undefined} />
        <Text $color={isListSelected ? selectedBackColor : undefined}>{t('map.views.list')}</Text>
      </SelectableFlex>
    </Grid>
  )
}

export default ViewKindSelector
