/* eslint-disable no-restricted-imports */
import { CustomAddToast, UseToasts } from '@weenat/client/dist/core/types'
import { useCallback } from 'react'
import { useToasts as useToastsFromLib } from 'react-toast-notifications'

/**
 * Hook supercharging useToasts from react-toast-notifications to avoid repeating the appearance option prop
 */
const useToasts: UseToasts = () => {
  const { addToast, removeAllToasts, removeToast, toastStack, updateToast } = useToastsFromLib()

  const customAddToast: CustomAddToast = useCallback(
    (content, options, callback) => {
      if (options?.replace === true) {
        removeAllToasts()
      }
      return addToast(content, { ...options }, callback)
    },
    [addToast, removeAllToasts]
  )

  // preemptively using useCallback since the hook is used often
  const addSuccessToast: CustomAddToast = useCallback(
    (content, options, callback) => {
      return customAddToast(content, { ...options, appearance: 'success' as const }, callback)
    },
    [customAddToast]
  )

  // preemptively using useCallback since the hook is used often
  const addInfoToast: CustomAddToast = useCallback(
    (content, options, callback) => {
      return customAddToast(content, { ...options, appearance: 'info' as const }, callback)
    },
    [customAddToast]
  )

  // preemptively using useCallback since the hook is used often
  const addErrorToast: CustomAddToast = useCallback(
    (content, options, callback) => {
      return customAddToast(
        content,
        { ...options, autoDismiss: false, appearance: 'error' as const },
        callback
      )
    },
    [customAddToast]
  )

  return {
    addErrorToast,
    addInfoToast,
    addSuccessToast,
    addToast: addToast as CustomAddToast,
    removeAllToasts,
    removeToast,
    toastStack,
    updateToast
  }
}

export default useToasts
