import { IconProps } from 'app/src/kit/Icon'
import Icons from 'app/src/kit/Icons'
import Text from 'app/src/kit/Text'
import WeenatPressable, { WeenatPressableProps } from 'app/src/kit/WeenatPressable'
import { AddModalSelectParams, logAddModal } from 'app/utils/analytics'
import isNil from 'lodash-es/isNil'
import { MouseEventHandler } from 'react'
import { styled } from 'styled-components'

const Container = styled(WeenatPressable)`
  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: ${(p) => p.theme.radiuses.md}px;
  padding: ${(p) => p.theme.spacings.md}px;
  gap: ${(p) => p.theme.spacings.md}px;
`

type AddModalItemProps = Pick<WeenatPressableProps, 'onPress' | 'isDisabled'> & {
  Icon: FC<IconProps>
  /** Name of the event to log on click, this will be prefixed with ""add_modal_select_" */
  event?: AddModalSelectParams['selected']
}

const AddModalItem: FC<AddModalItemProps> = ({
  Icon,
  children,
  onPress,
  event,
  isDisabled = false
}) => {
  const color = isDisabled ? 'grayscale.700' : undefined

  const defaultIconProps: Partial<IconProps> = {
    $size: 'lg',
    $color: color
  }

  const handlePress: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!isNil(event)) {
      logAddModal({ action: 'select', selected: event })
    }
    if (!isDisabled) {
      onPress?.(e)
    }
  }

  const TrailingIcon = isDisabled ? Icons.Lock : Icon

  return (
    <Container isDisabled={isDisabled} onPress={handlePress}>
      <TrailingIcon {...defaultIconProps} $color={color} />
      <Flex $flexDirection='column' $flex={1}>
        {typeof children === 'string' ? (
          <Text $color={color} $fontWeight='medium' $lineHeight='none'>
            {children}
          </Text>
        ) : (
          children
        )}
      </Flex>
    </Container>
  )
}

export default AddModalItem
