import React from 'react'
import { styled } from 'styled-components'
import WindDirectionIcon from '../icons/WindDirectionIcon'

const ICON_SIZE = 12

const IconDirectionContainer = styled.foreignObject`
  position: relative;
  z-index: -1;
  pointer-events: none;
  transform-origin: middle middle;
`

export interface WindDirectionProps {
  x: number
  y: number
  value: number
}

const WindDirection: React.FC<WindDirectionProps> = ({ x, y, value }) => {
  return (
    <IconDirectionContainer
      width={ICON_SIZE}
      height={ICON_SIZE}
      transform={`translate(${x - ICON_SIZE / 2}, ${y - ICON_SIZE / 2})`}
    >
      <WindDirectionIcon windOrigin={value} $color={'metrics.FF.500'} $size='sm' />
    </IconDirectionContainer>
  )
}

export default WindDirection
