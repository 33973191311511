import { useMetricSummary } from '@weenat/client/dist/core/metrics'
import { Devices } from '@weenat/client/dist/enums'
import { AllKey } from '@weenat/client/dist/enums/MetricIds'
import { DeviceAvailableMeasure } from '@weenat/client/dist/resources/devices'
import { MeasurementConfig } from '@weenat/client/dist/resources/measurementConfigs'
import { PlotAvailableMeasure } from '@weenat/client/dist/resources/plots'
import { useIntl } from '@weenat/wintl'
import { RestrictionReason } from 'app/src/hooks/useIsAppAccessRestricted'
import InfoCard from 'app/src/kit/InfoCard'
import isNil from 'lodash-es/isNil'
import { useMemo } from 'react'
import MetricSummaryHeader from './MetricSummaryHeader'
import MetricSummaryValue from './MetricSummaryValue'

const metric_agro: AllKey[] = ['SSI', 'etp', 'T_DEW', 'PPFD']

interface DumbMetricSummaryProps {
  /** Timestamp at which the latest data was received */
  latestTimestamp?: number
  metric: DeviceAvailableMeasure | PlotAvailableMeasure
  isShrunk?: boolean
  getFullSummaryValue: ReturnType<typeof useMetricSummary>['getFullSummaryValue']
  metricProvider?: string
  warningThreshold?: Devices.UpdateWarningThresholds
  hasVirtualDevice?: boolean
  etpValue?: number | null
  isRestrictedMetric?: boolean
  /** does not account for virtual device rules */
  isRestrictedByPlan?: boolean
  reason?: RestrictionReason
  measurementConfig?: MeasurementConfig
  deviceId?: number
}

const DumbMetricSummary: FC<DumbMetricSummaryProps> = ({
  latestTimestamp,
  metric,
  isShrunk,
  getFullSummaryValue,
  metricProvider,
  warningThreshold,
  hasVirtualDevice = false,
  etpValue,
  isRestrictedMetric = false,
  isRestrictedByPlan,
  reason,
  measurementConfig,
  deviceId
}) => {
  const { t } = useIntl()

  const renderedValues = useMemo(() => {
    switch (metric) {
      case 'U':
      case 'T_SOIL':
      case 'T_CAL':
      case 'T_WET':
      case 'T_DRY':
      case 'T':
        return (
          <>
            <MetricSummaryValue
              metric={metric}
              isMainValue
              isShrunk={isShrunk}
              summaryValue={getFullSummaryValue('latest')}
              isRestricted={isRestrictedByPlan && !hasVirtualDevice}
              reason={reason}
            />
            <MetricSummaryValue
              metric={metric}
              isShrunk={isShrunk}
              summaryValue={getFullSummaryValue('minToday')}
              isRestricted={isRestrictedByPlan && !hasVirtualDevice}
              reason={reason}
            />
            <MetricSummaryValue
              metric={metric}
              isShrunk={isShrunk}
              summaryValue={getFullSummaryValue('maxToday')}
              isRestricted={isRestrictedByPlan && !hasVirtualDevice}
              reason={reason}
            />
          </>
        )

      case 'RR':
        return (
          <>
            <MetricSummaryValue
              metric={metric}
              isMainValue
              isShrunk={isShrunk}
              summaryValue={getFullSummaryValue('today')}
              isRestricted={isRestrictedByPlan && !hasVirtualDevice}
              reason={reason}
            />
            <MetricSummaryValue
              metric={metric}
              isShrunk={isShrunk}
              summaryValue={getFullSummaryValue('untilMidnight')}
              isRestricted={isRestrictedByPlan}
              reason={reason}
            />
            <MetricSummaryValue
              metric={metric}
              isShrunk={isShrunk}
              summaryValue={getFullSummaryValue('lastSlidingHour')}
              isRestricted={isRestrictedByPlan && !hasVirtualDevice}
              reason={reason}
            />
            <MetricSummaryValue
              metric={metric}
              isShrunk={isShrunk}
              summaryValue={getFullSummaryValue('yesterday')}
              isRestricted={isRestrictedByPlan}
              reason={reason}
            />
          </>
        )

      case 'FF':
        return (
          <>
            <MetricSummaryValue
              metric={metric}
              isMainValue
              isShrunk={isShrunk}
              summaryValue={getFullSummaryValue('latest')}
              isRestricted={isRestrictedByPlan && !hasVirtualDevice}
              reason={reason}
            />
            <MetricSummaryValue
              metric={metric}
              isShrunk={isShrunk}
              summaryValue={getFullSummaryValue('DD')}
              isRestricted={isRestrictedByPlan && !hasVirtualDevice}
              reason={reason}
            />
            <MetricSummaryValue
              metric={metric}
              isShrunk={isShrunk}
              summaryValue={getFullSummaryValue('FXY')}
              isRestricted={isRestrictedByPlan && !hasVirtualDevice}
              reason={reason}
            />
          </>
        )

      case 'SSI':
        const sunlightHoursSummary = getFullSummaryValue('SSI_HOURS', 'today')

        return (
          <>
            <MetricSummaryValue
              metric={metric}
              isMainValue
              isShrunk={isShrunk}
              summaryValue={getFullSummaryValue('latest')}
              isRestricted={isRestrictedByPlan}
              reason={reason}
            />
            {!isNil(sunlightHoursSummary.value) ? (
              <MetricSummaryValue
                metric={metric}
                isShrunk={isShrunk}
                summaryValue={sunlightHoursSummary}
                isRestricted={isRestrictedByPlan}
                reason={reason}
              />
            ) : null}
          </>
        )

      case 'etp':
        return (
          <MetricSummaryValue
            metric={metric}
            isMainValue
            isShrunk={isShrunk}
            summaryValue={{
              aggregationId: 'etp',
              value: isNil(etpValue) ? null : etpValue,
              label: t('periods.over_the_day', { capitalize: true })
            }}
            isRestricted={isRestrictedByPlan}
            reason={reason}
          />
        )

      case 'T_DEW':
      case 'PPFD':
      case 'LW_V':
        return (
          <MetricSummaryValue
            metric={metric}
            isMainValue
            isShrunk={isShrunk}
            summaryValue={getFullSummaryValue('latest')}
            isRestricted={isRestrictedByPlan}
            reason={reason}
          />
        )

      default:
        return null
    }
  }, [
    metric,
    isShrunk,
    getFullSummaryValue,
    isRestrictedByPlan,
    hasVirtualDevice,
    reason,
    etpValue,
    t
  ])

  // checking for renderedValues avoids showing headers without values
  return renderedValues ? (
    <Box>
      <MetricSummaryHeader
        metric={metric}
        latestUpdate={latestTimestamp}
        provider={metricProvider}
        hasVirtualDevice={hasVirtualDevice}
        isShrunk={isShrunk}
        warningThreshold={warningThreshold}
        measurementConfig={measurementConfig}
        deviceId={deviceId}
      />

      {isRestrictedMetric ? (
        <InfoCard
          $my='lg'
          type='warning'
          title={t('status.no_data')}
          message={t('ecommerce.plans.irrigation_data_restricted_for_non_expert')}
        />
      ) : (
        <Flex
          $pb={isShrunk ? 'xs' : 'lg'}
          $px={isShrunk ? 'lg' : 0}
          $justifyContent='space-between'
          $gap={isRestrictedByPlan ? 'lg' : undefined}
        >
          {renderedValues}
        </Flex>
      )}
    </Box>
  ) : null
}

export default DumbMetricSummary
