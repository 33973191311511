import { useClient, useQuery } from '@weenat/client'
import { Id } from '@weenat/client/dist/resources/types'
import usePlotsOrDevicesFromPathname from 'app/src/hooks/usePlotsOrDevicesFromPathname'
import isNil from 'lodash-es/isNil'
import useUniverseOptions from './useUniverseOptions'

const useUniverseOptionsQuery = (
  organizationId: Id | null | undefined,
  plotsOrDevicesFromProps?: 'plots' | 'devices'
) => {
  const client = useClient()
  const plotsOrDevicesFromHook = usePlotsOrDevicesFromPathname()
  const plotsOrDevices = plotsOrDevicesFromProps || plotsOrDevicesFromHook
  const isPlotsMap = plotsOrDevices === 'plots'
  const universesRequest = useQuery(
    isPlotsMap
      ? client.measurements.plotsAndStations.summaries.getAvailableUniverses({
          organizationId: organizationId as Id
        })
      : client.measurements.devices.summaries.getAvailableUniverses(),
    { enabled: !isNil(organizationId) && !isNil(plotsOrDevices) }
  )
  const universeOptions = useUniverseOptions(universesRequest.data)

  return { universeOptions, universesRequest }
}

export default useUniverseOptionsQuery
