import { queryClient, useClient, useQuery } from '@weenat/client'
import { OrgOption, useAllUsersOrgs } from '@weenat/client/dist/hooks/useAllUsersOrgs'
import { Org } from '@weenat/client/dist/resources/orgs'
import { AbsoluteHref } from '@weenat/client/dist/routx/runtime-core'
import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import { useMatch, useNavigate, useParams, usePathname, useSearchParams } from 'app/routx-router'
import useDisclosure from 'app/src/hooks/useDisclosure'
import Collapsible from 'app/src/kit/Collapsible'
import InvisibleTextInput from 'app/src/kit/fields/InvisibleInput'
import Icons from 'app/src/kit/Icons'
import { LogoSphere } from 'app/src/kit/icons/logos'
import Link from 'app/src/kit/LinkComponent'
import LoadingCircle from 'app/src/kit/loaders/LoadingCircle'
import MenuItem from 'app/src/kit/MenuItem'
import Modal from 'app/src/kit/Modal'
import Text from 'app/src/kit/Text'
import { useToken } from 'app/state'
import { isEmpty, isNil } from 'lodash-es'
import { useMemo, useState } from 'react'
import { styled } from 'styled-components'
// TODO: Add null as a possibility for childOrganizationId

interface CollapsibleSelectOrgProps {
  groupLabel: string
  options: OrgOption[]
  isSelected: (value: OrgOption) => boolean
  modalClose: () => void
  shouldRedirect: boolean
  onChange?: (newOrgId: number) => void
}

const FieldContainer = styled(Flex)<{ $isFarmInNetwork: boolean; $isInteractive: boolean }>`
  flex-direction: column;
  margin-top: ${(p) => p.theme.spacings.lg}px;
  margin-bottom: ${(p) => p.theme.spacings.sm}px;
  border-radius: ${(p) => p.theme.radiuses.md}px;
  &:hover {
    border-color: ${(p) => (p.$isInteractive ? p.theme.colors.primary['500'] : '')};
    cursor: ${(p) => (p.$isInteractive ? 'pointer' : 'default')};
    background-color: ${(p) => (p.$isInteractive ? p.theme.colors.primary['200'] : '')};
  }
`

const NetworkLogo = styled.img`
  width: 24px;
  height: fit-content;
  max-height: 24px;
`

const DisplayText = styled(Text)`
  overflow: hidden;
  text-align: center;
`

const OrgText = styled(Text)<{ $isInteractive: boolean }>`
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
  font-weight: ${(p) =>
    p.$isInteractive ? p.theme.typography.weights.medium : p.theme.typography.weights.bold};
`

const SearchField = styled(Flex)`
  position: sticky;
  padding: ${(p) => p.theme.spacings.md}px;
  background: ${(p) => p.theme.colors.grayscale['50']};
  border-bottom: ${(p) => p.theme.borderWidths.sm}px solid ${(p) => p.theme.colors.grayscale['300']};
  top: 0px;
  z-index: 1;
  & input {
    font-size: 16px;
  }
  height: 48px;
`

const CollapsibleGroupHeader = styled(Flex)`
  padding: ${(p) => p.theme.spacings.md}px;
  position: sticky;
  background: ${(p) => p.theme.colors.grayscale['100']};
  border-bottom: ${(p) => p.theme.borderWidths.sm}px solid ${(p) => p.theme.colors.grayscale['300']};
  & > * {
    color: ${(p) => p.theme.colors.grayscale['700']};
    font-size: ${(p) => p.theme.typography.sizes.sm}px;
    font-weight: ${(p) => p.theme.typography.weights.medium};
  }
  &:hover {
    cursor: pointer;
    background: ${(p) => p.theme.colors.grayscale['300']};
    & > * {
      color: ${(p) => p.theme.colors.grayscale['900']};
    }
  }
`

const StyledFlex = styled(Flex)<{ $isOpen: boolean }>`
  flex-direction: column;
  &:not(:last-child) {
    border-bottom: ${(p) =>
      p.$isOpen ? p.theme.borderWidths.sm + 'px solid ' + p.theme.colors.grayscale['300'] : 'none'};
  }
`

const SelectOrgField = styled(Flex)`
  height: 40px;
  border-radius: ${(p) => p.theme.radiuses.md}px;
  border: ${(p) => p.theme.borderWidths.sm}px solid ${(p) => p.theme.colors.grayscale['300']};
  padding: ${(p) => p.theme.spacings.md}px;
  justify-content: space-between;
  gap: ${(p) => p.theme.spacings.md}px;
  &:first-child:not(:last-child) {
    border-bottom: 0px;
    border-bottom-left-radius: ${(p) => p.theme.radiuses.none}px;
    border-bottom-right-radius: ${(p) => p.theme.radiuses.none}px;
  }
  &:last-child:not(:first-child) {
    border-top-left-radius: ${(p) => p.theme.radiuses.none}px;
    border-top-right-radius: ${(p) => p.theme.radiuses.none}px;
  }
  & > div:first-child {
    gap: ${(p) => p.theme.spacings.md}px;
    align-items: center;
    & > div:first-child {
      min-width: max-content;
      border: ${(p) => p.theme.borderWidths.none};
    }
  }
`

const OrgMenuItem = styled(MenuItem)<{ $isSelected: boolean }>`
  &::after {
    background-color: ${(p) => (p.$isSelected ? p.theme.colors.primary['200'] : '')};
  }
`
/**
 * Component that render organizations in a collapsible list of Links
 */
const CollapsibleOrgSelect = ({
  groupLabel,
  options,
  isSelected,
  modalClose,
  shouldRedirect,
  onChange
}: CollapsibleSelectOrgProps) => {
  const { isOpen, toggle } = useDisclosure(true)
  const nav = useNavigate()
  const pathname = usePathname()
  const { farmId } = useParams()
  const [searchParams] = useSearchParams()

  const client = useClient()

  const matchAdminPath = useMatch('/farms/*/admin/*')
  const isAdmin = !isNil(matchAdminPath)

  const handleChange = async (newOrgId: number) => {
    const requester = client.orgs.get(newOrgId)
    await queryClient.prefetchQuery({
      queryKey: requester.key,
      queryFn: requester.request.bind(this)
    })

    onChange?.(newOrgId)

    if (shouldRedirect) {
      if (isAdmin && !isNil(farmId)) {
        // Looking for segments with number to only redirect if we are on a details page
        const hasMoreThanTwoNumberSegments =
          pathname.split('/').filter((segment) => !Number.isNaN(parseInt(segment, 10))).length > 1

        nav(
          hasMoreThanTwoNumberSegments
            ? `/farms/${newOrgId}/admin`
            : (pathname.replace(farmId.toString(), newOrgId.toString()) as AbsoluteHref)
        )
      }
      // Always redirecting to the farms home page on org change
      else {
        //Keep the view (list or map) chosen by the user
        nav(`/farms/${newOrgId}`, { search: searchParams })
      }
    }

    modalClose()
  }

  return (
    <>
      <CollapsibleGroupHeader onClick={toggle}>
        <Text>{groupLabel}</Text>
        <Icons.ArrowDropDown $rotate={isOpen ? 0 : -90} />
      </CollapsibleGroupHeader>
      <StyledFlex $isOpen={isOpen}>
        <Collapsible isOpen={isOpen} direction='vertical' $py='sm'>
          <Flex $flexDirection='column' $gap='sm'>
            {options.map((option) => (
              <Link key={option.value}>
                <OrgMenuItem
                  onPress={() => handleChange(option.value)}
                  $isSelected={isSelected(option)}
                >
                  <Flex $alignItems='center' $px='lg' $py='md' $gap='sm'>
                    <Text>{option.label}</Text>
                    {option.isMainNetworkFarm ? <Icons.NewReleases /> : <></>}
                  </Flex>
                </OrgMenuItem>
              </Link>
            ))}
          </Flex>
        </Collapsible>
      </StyledFlex>
    </>
  )
}

const RedesignSelectOrg: FC<{
  org?: Org
  onChange?: (newOrgId: number) => void
  shouldRedirect?: boolean
}> = ({ onChange, shouldRedirect = isNil(onChange), org: orgFromProps }) => {
  const { t } = useIntl()
  const client = useClient()
  const orgFromContext = useOrgContext()
  const [token] = useToken()
  const org = orgFromProps ?? orgFromContext

  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined)
  const { isOpen, toggle, close } = useDisclosure(false)
  const { farmsOptions, isLoading } = useAllUsersOrgs({ enabled: isOpen && !isNil(token) })

  const networkId = org.subscribedNetworkId ?? org.id
  const network = useQuery(client.networks.get(networkId), {
    enabled: true
  }).data

  const selectedOption = useMemo(() => {
    //Fallback option to render component based on context if useSelectAllOrg hasnt resolved
    const fallbackSelectOption: OrgOption = {
      value: org.id,
      label: org.name,
      networkId: network ? network.id : 0,
      networkName: network ? network.name : '',
      isFarmInNetwork: true,
      isMainNetworkFarm: false
    }

    return farmsOptions.find((option) => option.value === org.id) ?? fallbackSelectOption
  }, [org.id, org.name, network, farmsOptions])

  const { isFarmInNetwork } = selectedOption

  const searchInputId = `searchQueryFor${org.name}`
  const autocompletePlaceholder = t('actions.search_farm')

  const optionsByGroupLabel: { [key: string]: OrgOption[] } = {}

  const filteredOptions = useMemo(
    () =>
      farmsOptions.filter(
        (option) =>
          isNil(searchQuery) || option.label.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    [farmsOptions, searchQuery]
  )

  filteredOptions.forEach((option) => {
    const groupLabel = option.networkName
    if (!Object.keys(optionsByGroupLabel).includes(groupLabel)) {
      optionsByGroupLabel[groupLabel] = []
    }
    optionsByGroupLabel[groupLabel].push(option)
  })

  return (
    <>
      <FieldContainer $isFarmInNetwork={isFarmInNetwork} $isInteractive={true}>
        {isFarmInNetwork ? (
          <SelectOrgField onClick={toggle}>
            <Flex $gap={'md'} $overflow='hidden'>
              {isNil(network?.logo) ? (
                <LogoSphere height='100%' width={24} />
              ) : (
                <NetworkLogo src={network.logo} />
              )}
              <OrgText $isInteractive={true}>{selectedOption.networkName}</OrgText>
            </Flex>
          </SelectOrgField>
        ) : null}
        <SelectOrgField onClick={toggle}>
          <Flex $gap={'md'} $overflow='hidden'>
            {selectedOption.value === selectedOption.networkId ? (
              <Icons.CertifiedWarehouse $size='xl' />
            ) : (
              <Icons.Warehouse $size='xl' />
            )}
            <OrgText $isInteractive={true}>{selectedOption.label}</OrgText>
          </Flex>
          <Icons.Unfold $size='lg' $color={'grayscale.black'} />
        </SelectOrgField>
      </FieldContainer>
      <Modal
        isOpen={isOpen}
        close={close}
        title={t('actions.change_farm')}
        $noInnerPadding={true}
        headerProps={{ $height: '52px' }}
        hasCloseIcon={true}
        width={640}
        height={412}
      >
        <SearchField>
          <Icons.Search $size='md' />
          <InvisibleTextInput
            shouldFocusOnMount
            id={searchInputId}
            name={searchInputId}
            autoComplete='on'
            autoCorrect='off'
            type='text'
            value={searchQuery}
            onChange={(e) => {
              if (!isNil(e.currentTarget)) setSearchQuery(e.currentTarget.value)
            }}
            placeholder={autocompletePlaceholder}
          />
        </SearchField>
        <Flex $flexDirection='column'>
          {isLoading ? (
            <Flex $flex={1} $flexDirection='column' $py='lg' $px='lg' $gap='xl'>
              <LoadingCircle size='xl' />
              <Text $textAlign='center'>{t('status.loading')}</Text>
            </Flex>
          ) : !isEmpty(filteredOptions) ? (
            <>
              {Object.entries(optionsByGroupLabel).map(([groupLabel, options]) => (
                <CollapsibleOrgSelect
                  key={groupLabel}
                  groupLabel={groupLabel}
                  options={options}
                  isSelected={(option) => option.value === selectedOption.value}
                  modalClose={close}
                  shouldRedirect={shouldRedirect}
                  onChange={onChange}
                />
              ))}
            </>
          ) : (
            <Box $py='lg' $px='lg'>
              <DisplayText>
                {t('map.no_summaries_matching_search_term', {
                  searchTerm: !isNil(searchQuery) ? searchQuery : ''
                })}
              </DisplayText>
            </Box>
          )}
        </Flex>
      </Modal>
    </>
  )
}

export default RedesignSelectOrg
