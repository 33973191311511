import { HistoryPeriod, timestampToMoment } from '@weenat/client/dist/core/history'
import TimeSteps, { underDayTimeSteps } from '@weenat/client/dist/enums/TimeSteps'
import { useIntl } from '@weenat/wintl'
import isEqual from 'lodash-es/isEqual'
import isNil from 'lodash-es/isNil'
import { MomentRange } from 'moment-range'
import moment, { Moment } from 'moment-timezone'
import { memo, useMemo } from 'react'
import { useTheme } from 'styled-components'
import { VictoryAxis, VictoryAxisProps, VictoryLabel, VictoryLabelStyleObject } from 'victory'
import { bottomPadding } from './victoryTheme'

const axisStyle: VictoryAxisProps['style'] = {
  grid: {
    fill: 'none',
    strokeWidth: 0
  }
}

interface TimeAxisProps extends Omit<VictoryAxisProps, 'scale'> {
  period: HistoryPeriod
  timezone: string
  timeStep: TimeSteps
  /** moment date format string */
  customDateFormat?: string
}

/** @deprecated remove with chartWithD3 flag  */
const TimeAxis: FC<TimeAxisProps> = memo(
  ({
    domain,
    period,
    timeStep,
    timezone,
    style,
    tickValues,
    customDateFormat,
    fixLabelOverlap = true,
    ...props
  }) => {
    const { t } = useIntl()

    const sinceAsMoment = timestampToMoment(period.since, timezone)
    const beforeAsMoment = timestampToMoment(period.before, timezone)

    const values = useMemo(
      () =>
        Array.from(
          (moment as unknown as MomentRange)
            .range(sinceAsMoment, beforeAsMoment)
            .by(
              underDayTimeSteps.includes(timeStep)
                ? 'hours'
                : timeStep === TimeSteps.perMonth
                  ? 'months'
                  : 'days'
            )
        ).reduce((acc, aMoment) => {
          if (timeStep !== TimeSteps.perWeek || aMoment.isoWeekday() === 1) {
            acc.push(aMoment.toDate())
          }
          return acc
        }, [] as Date[]),
      [sinceAsMoment, beforeAsMoment, timeStep]
    )

    const duration = moment.duration(beforeAsMoment.diff(sinceAsMoment))

    const formatTickOnShortTimeSteps = (currentDate: Moment) => {
      if (duration.as('hours') > 24) return currentDate.format(t('formats.datetime_chart'))
      return currentDate.format(t('formats.hour'))
    }

    const formatXAxisTicks = (tick: Date) => {
      const currentMoment = moment(tick).tz(timezone)

      if (!isNil(customDateFormat)) {
        return currentMoment.format(customDateFormat)
      } else {
        switch (timeStep) {
          case TimeSteps.maximum: {
            return formatTickOnShortTimeSteps(currentMoment)
          }
          case TimeSteps.perQuarterHours: {
            return formatTickOnShortTimeSteps(currentMoment)
          }
          case TimeSteps.perHalfHours: {
            return formatTickOnShortTimeSteps(currentMoment)
          }
          case TimeSteps.perHours: {
            return formatTickOnShortTimeSteps(currentMoment)
          }
          case TimeSteps.perDays: {
            return currentMoment.format(t('formats.date_short_chart'))
          }
          case TimeSteps.perWeek: {
            return currentMoment.format(t('formats.date_short_chart'))
          }
          case TimeSteps.perMonth: {
            return currentMoment.format(t('formats.month_short'))
          }
          default: {
            return currentMoment.format('DD/MM hh:mm')
          }
        }
      }
    }

    return (
      <VictoryAxis
        {...props}
        style={{ ...axisStyle, ...style }}
        domain={domain}
        scale='time'
        tickFormat={(x) => formatXAxisTicks(x)}
        tickValues={tickValues || values}
        fixLabelOverlap={fixLabelOverlap}
        crossAxis={false}
        offsetY={bottomPadding - 8}
      />
    )
  },
  (pp, np) => {
    const { scale: _2, ...ppNoScale } = pp
    const { scale: _1, ...npNoScale } = np
    return isEqual(ppNoScale, npNoScale)
  }
)

/** @deprecated remove with chartWithD3 flag  */
export const BubbleAxis: FC<Omit<TimeAxisProps, 'tickLabelComponent'>> = (props) => {
  const { colors } = useTheme()

  const labelStyle: VictoryLabelStyleObject = { fontSize: 8, fontFamily: 'Inter' }

  const labelBackgroundStyle: VictoryLabelStyleObject = {
    fill: colors.grayscale.white,
    stroke: colors.grayscale[700],
    strokeWidth: 2,
    rx: 8,
    ry: 8
  }

  const labelPadding = { top: 2, bottom: 2, left: 8, right: 8 }

  const timeAxisStyle = useMemo(
    () => ({
      axis: { stroke: colors.grayscale[100] }
    }),
    [colors]
  )
  return (
    <TimeAxis
      {...props}
      style={timeAxisStyle}
      tickLabelComponent={
        <VictoryLabel
          style={labelStyle}
          backgroundPadding={labelPadding}
          backgroundStyle={labelBackgroundStyle}
          lineHeight={1.5}
        />
      }
    />
  )
}

TimeAxis.displayName = 'VictoryAxis'
BubbleAxis.displayName = 'VictoryAxis'

export default TimeAxis
