import { DeviceItemContextProvider } from '@weenat/client/dist/ItemContext'
import canBeAStation from '@weenat/client/dist/core/devices/canBeAStation'
import { metricsWithoutChart } from '@weenat/client/dist/core/metrics'
import { isMetricVisibleOnWeatherMapSummary } from '@weenat/client/dist/enums/MetricIds'
import { Device } from '@weenat/client/dist/resources/devices'
import { useIntl } from '@weenat/wintl'
import useDisclosure from 'app/src/hooks/useDisclosure'
import Button from 'app/src/kit/Button'
import Icons from 'app/src/kit/Icons'
import InfoCard from 'app/src/kit/InfoCard'
import SmartText from 'app/src/kit/SmartText'
import { useToggleFeature } from 'app/state'
import { memo } from 'react'
import MetricsDetails from '../../common/summary/MetricsDetails'
import AddStationFromDeviceModal from './AddStationFromDeviceModal'

const DeviceSummary: FC<{ device: Device }> = ({ device }) => {
  const { t } = useIntl()
  const [{ redesign }] = useToggleFeature()
  const { isOpen, toggle, close } = useDisclosure()

  const visibleMetrics = device.availableMeasures.filter((metric) =>
    isMetricVisibleOnWeatherMapSummary(metric)
  )

  const visibleCharts = visibleMetrics.filter((metric) => !metricsWithoutChart.includes(metric))

  return (
    <DeviceItemContextProvider device={device}>
      <Flex $flexDirection='column' $px={redesign ? 'lg' : undefined}>
        <MetricsDetails
          metricsToDisplay={visibleMetrics}
          chartsToDisplay={visibleCharts}
          resource={device}
          isExpertOrg={false}
        />
        {canBeAStation(device) ? (
          <InfoCard title={t('models.device.tips.add_to_my_farm_title')} type='tip' $mb='md'>
            <Flex $flexDirection='column' $gap='lg'>
              <SmartText tkey='models.device.tips.add_to_my_farm_explain' />
              <Box $alignSelf='flex-end'>
                <Button onPress={() => toggle()} IconLeft={Icons.Farm}>
                  {t('models.device.actions.add_to_my_farm', { capitalize: true })}
                </Button>
              </Box>
            </Flex>
          </InfoCard>
        ) : null}
        {isOpen ? (
          <AddStationFromDeviceModal isOpen={isOpen} close={close} device={device} />
        ) : null}
      </Flex>
    </DeviceItemContextProvider>
  )
}

export default memo(DeviceSummary)
