import { useClient, useQuery } from '@weenat/client'
import { canUser, ops } from '@weenat/client/dist/core/access'
import {
  isNetworkMember,
  useIsMemberOfCommunityNetwork,
  useIsPartOfANetwork
} from '@weenat/client/dist/core/orgs'
import { InvitationStatus } from '@weenat/client/dist/enums'
import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import Icons from 'app/src/kit/Icons'
import { LogoSphere } from 'app/src/kit/icons/logos'
import Link from 'app/src/kit/LinkComponent'
import Panel from 'app/src/kit/panels/Panel'
import PanelLinkSection from 'app/src/kit/panels/PanelLinkSection'
import PanelSection from 'app/src/kit/panels/PanelSection'
import SidePanelLink from 'app/src/kit/SidePanelLink'
import SidePanelLinkGroup from 'app/src/kit/SidePanelLinkGroup'
import TextTooltip from 'app/src/kit/tooltips/TextTooltip'
import { useToggleFeature, useToken } from 'app/state'
import { useUserContext } from 'app/userProvider'
import { isNil, noop } from 'lodash-es'
import styled from 'styled-components'
import RedesignSelectOrg from '../DashboardMap/RedesignSelectOrg'
import SelectOrg from '../DashboardMap/SelectOrg'
import AccountAvatar from './AccountAvatar'
import AccountNotifications from './AccountNotifications'
import AddModal from './AddModal'

const ScrollablePanelSection = styled(PanelSection)`
  flex: 1;

  overflow-y: hidden;
  scroll-behavior: smooth;
  scrollbar-gutter: stable both-edges;

  &:hover {
    overflow-y: scroll;
  }
`

const NavPanel = () => {
  const client = useClient()
  const { t } = useIntl()
  const org = useOrgContext()
  const { me } = useUserContext()
  const [token] = useToken()
  const [{ redesign }] = useToggleFeature()

  const { isMemberOfCommunityNetwork } = useIsMemberOfCommunityNetwork(org)
  const { isPartOfANetwork } = useIsPartOfANetwork(token)

  // This request is only to get the total number of invitations
  // Menu is rendered on all screens, even public ones,
  // we do not want to get a 401 when not logged in
  const invitationsRequest = useQuery(
    client.invitations.received.getMany({ limit: 1, status: InvitationStatus.pending })
  )

  const receivedInvitations = invitationsRequest.data

  const pathToCurrentOrganization = `/farms/${org?.id}/admin` as const
  const accessContext = !isNil(org) ? { organization: org } : null

  const isNetwork = !isNil(org?.networkType)

  // ORG ACCESS
  const canViewSentMemberInvitations = !isNil(accessContext)
    ? canUser(ops.view, 'organizationInvitations', accessContext)
    : false
  const canViewMembers = !isNil(accessContext) ? canUser(ops.view, 'members', accessContext) : false
  const canViewDevices = !isNil(accessContext) ? canUser(ops.view, 'devices', accessContext) : false
  const canViewOptions = !isNil(accessContext) ? canUser(ops.view, 'options', accessContext) : false

  // NETWORK ACCESS
  const canViewNetworkAdmin = !isNil(accessContext)
    ? canUser(ops.view, 'organizationNetworkAdministrationScreen', accessContext)
    : false

  const canEditNetwork = !isNil(accessContext)
    ? canUser(ops.edit, 'networks', accessContext)
    : false
  const canViewNetworkOptionRequests = !isNil(accessContext)
    ? canUser(ops.view, 'networkOptionRequests', accessContext)
    : false

  // BILLING ACCESS
  // Only for users that have access rights inside networks, standalone orgs, or orgs that are members of a community network
  const canViewBilling =
    !isNil(accessContext) &&
    canUser(ops.view, 'billingScreen', accessContext) &&
    (isNetwork || !isNetworkMember(org) || isMemberOfCommunityNetwork)

  return (
    <Panel width={256} docking='left'>
      {/* HEADER SECTION */}
      <PanelSection $gap='none'>
        {/* Logo - Account Tools */}
        <Flex $alignItems='center' $justifyContent='space-between' $width={'100%'}>
          <Link to='/'>
            <LogoSphere width={32} height={32} />
          </Link>
          <Flex $alignItems='center' $justifyContent='flex-end' $gap={'md'}>
            <AccountNotifications />
            <AccountAvatar me={me} pendingInvitationCount={receivedInvitations?.count} />
          </Flex>
        </Flex>
        {redesign ? <RedesignSelectOrg /> : <SelectOrg />}
      </PanelSection>

      {/* LINKS */}
      <ScrollablePanelSection>
        <div>
          {/* HEAD */}
          <Flex $alignItems='center' $gap={'md'} $pb={'md'}>
            <Box $flex={1}>
              <SidePanelLink
                LeftIcon={Icons.Farm}
                label={t('titles.my_farm')}
                to={`/farms/${org.id}`}
              />
            </Box>
            <AddModal />
          </Flex>

          {/* ADMINISTRATION */}
          <PanelLinkSection title={t('titles.admin')}>
            {canViewDevices ? (
              <SidePanelLinkGroup
                mainLink={{
                  id: `devices`,
                  to: `${pathToCurrentOrganization}/devices`,
                  label: t('models.device.filters.all_devices', { capitalize: true }),
                  LeftIcon: Icons.SingleSensorFilled,
                  iconProps: { $size: 'lg' },
                  matchExactPath: true
                }}
                subLinks={[
                  {
                    id: `agro-weather`,
                    to: `${pathToCurrentOrganization}/devices/agro-weather`,
                    label: t('models.agro_weather_device.model.name', { capitalize: true }),
                    iconProps: { $size: 'lg' },
                    matchExactPath: true
                  },
                  {
                    id: `irrigation`,
                    to: `${pathToCurrentOrganization}/devices/irrigation`,
                    label: t('models.irrigation_probe.model.name', { capitalize: true }),
                    iconProps: { $size: 'lg' },
                    matchExactPath: true
                  }
                ]}
              />
            ) : null}
            {canViewMembers ? (
              <SidePanelLink
                label={t('models.member.model.name', { capitalize: true })}
                to={`${pathToCurrentOrganization}/members`}
                matchExactPath={false}
                LeftIcon={Icons.Person}
                iconProps={{ $size: 'lg' }}
              />
            ) : null}
            {canViewSentMemberInvitations ? (
              <SidePanelLink
                label={t('titles.invitations_sent_to_users')}
                to={`${pathToCurrentOrganization}/members-invitations`}
                matchExactPath={false}
                LeftIcon={Icons.Send}
                iconProps={{ $size: 'lg' }}
              />
            ) : null}
            {canViewOptions ? (
              <SidePanelLink
                label={t('models.option.model.name', { capitalize: true })}
                to={`${pathToCurrentOrganization}/options`}
                matchExactPath={false}
                LeftIcon={Icons.AddOption}
                description={t('models.option.misc.option_explanation')}
              />
            ) : null}
            <SidePanelLink
              label={t('titles.general_infos')}
              to={`${pathToCurrentOrganization}/info`}
              matchExactPath
              LeftIcon={Icons.InfoCircle}
              iconProps={{ $size: 'lg' }}
            />
          </PanelLinkSection>

          {/* NETWORK */}
          {isNetwork && canViewNetworkAdmin ? (
            <PanelLinkSection title={t('titles.network_admin')}>
              {canEditNetwork ? (
                <>
                  <SidePanelLinkGroup
                    mainLink={{
                      id: 'network/members',
                      to: `${pathToCurrentOrganization}/network/members`,
                      label: t('titles.network_members'),
                      matchExactPath: false,
                      LeftIcon: Icons.GroupPersons
                    }}
                    subLinks={[
                      {
                        id: 'network/members/trials',
                        to: `${pathToCurrentOrganization}/network/members-trials`,
                        label: t('titles.on_trial'),
                        matchExactPath: false
                      },
                      {
                        id: 'network/invitations',
                        to: `${pathToCurrentOrganization}/network/invitations`,
                        label: t('models.invitation.model.name'),
                        matchExactPath: false
                      }
                    ]}
                  />
                  <SidePanelLink
                    label={t('titles.network_received_membership_requests')}
                    to={`${pathToCurrentOrganization}/network/membership-requests`}
                    description={t('ecommerce.billing.subtitle')}
                    matchExactPath={false}
                    LeftIcon={Icons.WaveHand}
                  />
                </>
              ) : null}

              {canViewNetworkOptionRequests ? (
                <SidePanelLink
                  label={t('titles.network_received_option_activation_approval_requests')}
                  to={`${pathToCurrentOrganization}/network/option-requests`}
                  description={t('ecommerce.billing.subtitle')}
                  matchExactPath={false}
                  LeftIcon={Icons.RequestQuote}
                />
              ) : null}
            </PanelLinkSection>
          ) : null}

          {/* BILLING */}
          {canViewBilling ? (
            <PanelLinkSection title={t('titles.billing')}>
              <SidePanelLink
                label={t('breadcrumbs./farms/*/admin/billing')}
                to={`${pathToCurrentOrganization}/billing`}
                description={t('ecommerce.billing.subtitle')}
                matchExactPath={false}
                LeftIcon={Icons.Wallet}
              />
            </PanelLinkSection>
          ) : null}
        </div>
      </ScrollablePanelSection>

      {/* FOOTER */}
      <PanelSection $gap='none'>
        {isPartOfANetwork ? null : (
          <SidePanelLink
            LeftIcon={Icons.Map}
            label={t('models.network.model.name', { capitalize: true })}
            to='/networks'
          />
        )}
        <SidePanelLink LeftIcon={Icons.Map} label={t('titles.devices_map')} to='/weather-map' />
        <SidePanelLink
          LeftIcon={Icons.Campaign}
          label={t('models.alert.model.name', { capitalize: true })}
          to='/alerts'
        />
        <Flex $flexDirection='row' $gap={'md'} $alignItems='center'>
          <Box $flex={1}>
            <SidePanelLink
              LeftIcon={Icons.TravelExplore}
              label={t('actions.discover')}
              to='/discover'
            />
          </Box>
          <TextTooltip content={t('titles.help')}>
            <Link to='/help'>
              <Icons.Help $size='lg' $p={'md'} onPress={noop} />
            </Link>
          </TextTooltip>
        </Flex>
      </PanelSection>
    </Panel>
  )
}

export default NavPanel
