import styled from 'styled-components'

export const SearchSelectableElement = styled(Flex)`
  padding: ${(p) => p.theme.spacings.md}px;
  justify-content: space-between;
  align-items: center;
  border-radius: ${(p) => p.theme.spacings.md}px;
  cursor: pointer;
  &:hover {
    background-color: ${(p) => p.theme.colors.primary[200]};
  }
`
