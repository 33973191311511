import { Slot } from 'app/routx-router'
import AuthenticatedLayout from 'app/src/layouts/AuthenticatedLayout'
import ProtectedRoute from 'app/src/layouts/ProtectedRoute'

export const Component = () => {
  return (
    <ProtectedRoute>
      <AuthenticatedLayout>
        <Slot />
      </AuthenticatedLayout>
    </ProtectedRoute>
  )
}

export default Component
