import { core } from '@weenat/client'
import { useIntl } from '@weenat/wintl'
import { Slot, useNavigate } from 'app/routx-router'
import AlertsCTAIllustration from 'app/src/discover-menu/svg/AlertsCTAIllustration'
import { useIsAppAccessRestricted } from 'app/src/hooks/useIsAppAccessRestricted'
import useLogAndNavToUpgradePlans from 'app/src/hooks/useLogAndNavToUpgradePlans'
import CTAFullScreen from 'app/src/kit/CTAFullScreen'
import LockedFeat from 'app/src/kit/LockedFeat'
import AuthenticatedLayout from 'app/src/layouts/AuthenticatedLayout'
import MenuLayout from 'app/src/layouts/MenuLayout'
import ProtectedRoute from 'app/src/layouts/ProtectedRoute'
import PublicLayout from 'app/src/layouts/PublicLayout'
import { useToggleFeature } from 'app/state'

const AlertsLayout: FC = () => {
  const nav = useNavigate()
  const { t } = useIntl()
  const [{ redesign }] = useToggleFeature()

  const { hasAtLeastOneBilledOrg, isLoading: isLoadingOneBilledOrg } =
    core.me.useHasAtLeastOneBilledOrg()
  const { isRestricted, reason, isLoading: isLoadingRestriction } = useIsAppAccessRestricted()
  const logAndNavToUpgradePlans = useLogAndNavToUpgradePlans()

  return (
    <ProtectedRoute>
      <AuthenticatedLayout>
        {isLoadingRestriction || isLoadingOneBilledOrg ? null : hasAtLeastOneBilledOrg &&
          !isRestricted ? (
          <>
            {redesign ? (
              <MenuLayout>
                <Flex $flexDirection='column' $px={'lg'}>
                  <Slot />
                </Flex>
              </MenuLayout>
            ) : (
              <PublicLayout>
                <Slot />
              </PublicLayout>
            )}
          </>
        ) : isRestricted && reason === 'freemium' ? (
          <CTAFullScreen
            isOpen
            onClose={() => {
              nav('/')
            }}
            title={t('discover_menu.calls_to_action.alerts_title', { capitalize: true })}
            description={t('discover_menu.calls_to_action.alerts_description', {
              capitalize: true
            })}
            mainCTA={{
              label: t('actions.i_subscribe', { capitalize: true }),
              onPress: () => {
                logAndNavToUpgradePlans()
              }
            }}
            secondaryCTA={{
              label: t('discover_menu.calls_to_action.alert_secondary_cta', { capitalize: true }),
              to: '/discover/application/alerts'
            }}
            Illustration={<AlertsCTAIllustration />}
          />
        ) : isRestricted && reason === 'downgrade' ? (
          <LockedFeat
            title='ecommerce.plans.restrictions.alerts_title'
            body='ecommerce.plans.restrictions.carousel_value_downgrade_message'
            showCTA
          />
        ) : null}
      </AuthenticatedLayout>
    </ProtectedRoute>
  )
}

export const Component = AlertsLayout
export default AlertsLayout
