import { queryClient, useClient, useMutation } from '@weenat/client'
import {
  NotificationFilterCategory,
  NotificationFilterCategoryId,
  getPathFromNotification
} from '@weenat/client/dist/core/notifications'
import { Notification } from '@weenat/client/dist/resources/notifications'
import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import { IconProps } from 'app/src/kit/Icon'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import moment from 'moment-timezone'
import { transparentize } from 'polished'
import { useCallback, useMemo } from 'react'
import { styled, useTheme } from 'styled-components'
import { capitalize } from '../../utils'
import Icons from '../kit/Icons'

const Container = styled(Flex)`
  border-top: 1px solid ${(p) => p.theme.colors.grayscale[100]};
  border-bottom: 1px solid ${(p) => p.theme.colors.grayscale[100]};
  box-shadow: ${(p) => p.theme.shadows.sm.boxShadow};
`

const ClickableContainer = styled(Container)`
  cursor: pointer;

  &:hover {
    background-color: ${(props) => transparentize(0.95, props.theme.colors.primary[500])};
  }

  will-change: background-color;
  transition: background-color 0.2s ease-in-out;
`

const NotificationCategoryIcons: Record<NotificationFilterCategoryId, FC<IconProps>> = {
  alerts: Icons.Campaign,
  'equipment-park': Icons.DeviceFaultEvent,
  'org-administration': Icons.GroupPersons,
  'user-account': Icons.AccountSettings,
  'weenat-tips': Icons.Lightbulb
}

const MarkAsReadButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
`

export interface NotificationListItemProps {
  notification: Notification
  onNotificationPress?: () => void
  onMarkedAsRead: () => void
  hideReadButton?: boolean
  category: NotificationFilterCategory | undefined
}

const pathRegex = /\/farms\/(\d+)/

const NotificationListItem: FC<NotificationListItemProps> = ({
  notification,
  onMarkedAsRead,
  onNotificationPress,
  hideReadButton = false,
  category
}) => {
  const { t } = useIntl()
  const { colors } = useTheme()
  const client = useClient()
  const nav = useNavigate()

  const { id, message, triggeredAt, networkId, type, link } = notification

  const [markAsRead, markAsReadState] = useMutation(client.notifications.markAsRead(id), {
    onSuccess: onMarkedAsRead
  })

  const messageElement = <Text $lineHeight='md'>{capitalize(message.trim())}</Text>

  const destination = useMemo(() => getPathFromNotification(notification), [notification])

  const handleRedirect = useCallback(
    async () => {
      if (!isNil(destination)) {
        const orgIdFromPath = pathRegex.exec(destination)?.at(1)
        if (!isNil(orgIdFromPath)) {
          const requester = client.orgs.get(parseInt(orgIdFromPath, 10))
          await queryClient.prefetchQuery({
            queryKey: requester.key,
            queryFn: requester.request.bind(this)
          })
        }
        nav(destination as '/farms')
        onNotificationPress?.()
      }
    },
    // do not add nav to the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [destination, type, networkId, onNotificationPress, link]
  )

  const ContainerElement = !isNil(destination) ? ClickableContainer : Container

  const NotificationCategoryIcon = !isNil(category) ? NotificationCategoryIcons[category.id] : null

  return (
    <ContainerElement
      $p='lg'
      $backgroundColor={colors.grayscale.white}
      $mb='md'
      $gap='md'
      onClick={
        !isNil(destination)
          ? () => {
              handleRedirect()
            }
          : undefined
      }
    >
      {!isNil(NotificationCategoryIcon) ? (
        <Box>
          <NotificationCategoryIcon $size='rg' $backgroundColor={'grayscale.100'} $p='md' />
        </Box>
      ) : null}
      <Flex $flexDirection='column' $flex={1}>
        <Flex width={'100%'} $gap='md' $alignItems='center' $pb='sm'>
          <Flex $flexDirection='column' $flex={1}>
            <Text $fontSize='sm' $fontWeight='bold'>
              {moment.unix(parseInt(triggeredAt, 10)).format(t('formats.datetime'))}
            </Text>
            <Text $fontSize='sm' $fontWeight='medium'>
              {category?.name}
            </Text>
          </Flex>

          {hideReadButton ? null : (
            <MarkAsReadButton
              onClick={(e) => {
                e.stopPropagation()
                markAsRead()
              }}
            >
              <Icons.Checkmark
                $isLoading={markAsReadState.isPending}
                $backgroundColor={'transparent'}
                $color={'primary.500'}
              />
              <Text $fontSize='sm' $color={'primary.500'} $fontWeight='semiBold'>
                {t('models.notification.actions.mark_as_read')}
              </Text>
            </MarkAsReadButton>
          )}
        </Flex>
        <Box>{messageElement}</Box>
      </Flex>
    </ContainerElement>
  )
}

export default NotificationListItem
