import AppUsageSupportSection from 'app/src/help/components/AppUsageSupportSection'
import ConfidentialitySection from 'app/src/help/components/ConfidentialitySection'
import CookiesPolicySection from 'app/src/help/components/CookiesPolicySection'
import CustomerSupportSection from 'app/src/help/components/CustomerSupportSection'
import DSSGuidesSection from 'app/src/help/components/DSSGuidesSection'
import LegalNoticeSection from 'app/src/help/components/LegalNoticeSection'
import SensorsSetupSection from 'app/src/help/components/SensorsSetupSection'
import Scrollable from 'app/src/kit/Scrollable'
import HeaderActions from 'app/src/layouts/HeaderActions'
import { useToggleFeature } from 'app/state'

const HelpContent = () => {
  return (
    <>
      <CustomerSupportSection />
      <AppUsageSupportSection />
      <SensorsSetupSection />
      <DSSGuidesSection />
      <LegalNoticeSection />
      <ConfidentialitySection />
      <CookiesPolicySection />
    </>
  )
}

const HelpScreen = () => {
  const [{ redesign }] = useToggleFeature()

  return (
    <>
      <HeaderActions />
      {redesign ? (
        <Scrollable $p={'lg'}>
          <HelpContent />
        </Scrollable>
      ) : (
        <HelpContent />
      )}
    </>
  )
}

export const Component = HelpScreen
export default HelpScreen
