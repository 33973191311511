import { Universe } from '@weenat/client/dist/core/universes'
import { WeenatColor } from '@weenat/theme'
import { useIntl } from '@weenat/wintl'
import Icon from 'app/src/kit/Icon'
import Icons from 'app/src/kit/Icons'
import Pop from 'app/src/kit/Pop'
import Text from 'app/src/kit/Text'
import UniverseIcons from 'app/src/kit/icons/UniverseIcons'
import { useUniverse } from 'app/state'
import isNil from 'lodash-es/isNil'

const UniverseOption = ({
  universe,
  isAccessRestrictedByUnpaidBill
}: {
  universe: Universe
  isAccessRestrictedByUnpaidBill?: boolean
}) => {
  const { t } = useIntl()
  const [currentUniv] = useUniverse()

  const isDisabled =
    isAccessRestrictedByUnpaidBill &&
    universe !== Universe.weather &&
    universe !== Universe.overview
  const UniverseIcon = isDisabled ? Icons.Lock : UniverseIcons[universe]
  const isSelected = currentUniv === universe

  const color: WeenatColor = isDisabled
    ? 'grayscale.300'
    : isSelected
      ? 'grayscale.white'
      : 'grayscale.black'

  const inner = (
    <Flex $alignItems='center' $py='sm' $gap='sm'>
      {!isNil(Icon) && <UniverseIcon $size='md' $color={color} />}
      <Text $color={color}>{t(`map.universes.all.${Universe[universe] as 'weather'}`)}</Text>
    </Flex>
  )

  return universe === Universe.frost ? (
    <Pop tourName='discover_universes' stepName='change_universe_view'>
      {inner}
    </Pop>
  ) : (
    inner
  )
}

export default UniverseOption
