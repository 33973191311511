import { BreadcrumbsContextKey, useBreadcrumbsCtx } from 'app/state'
import { isNil } from 'lodash-es'
import { useLayoutEffect } from 'react'

const useSetKeyToBreadcrumbsCtx = (key: BreadcrumbsContextKey, newVal?: string) => {
  const [breadcrumbsCtx, setBreadcrumbsCtx] = useBreadcrumbsCtx()

  useLayoutEffect(() => {
    if (!isNil(newVal) && breadcrumbsCtx[key] !== newVal) {
      setBreadcrumbsCtx((currentBreadcrumbsCtx) => ({ ...currentBreadcrumbsCtx, [key]: newVal }))
    } else if (isNil(newVal) && !isNil(breadcrumbsCtx[key])) {
      setBreadcrumbsCtx((currentBreadcrumbsCtx) => {
        const newBreadcrumbs = { ...currentBreadcrumbsCtx }
        delete newBreadcrumbs[key]
        return newBreadcrumbs
      })
    }
  }, [newVal])
}

export default useSetKeyToBreadcrumbsCtx
