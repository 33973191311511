import { ChartContextProvider, makeXScale, makeYScale } from '@weenat/client/dist/core/charts'
import { isNil } from 'lodash-es'
import { CSSProperties, FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import useResizeObserver, { ResizeHandler } from 'use-resize-observer'
import Guidelines from './Guidelines'

export interface ChartProps {
  height: number
  width?: CSSProperties['width']
  onResize?: ResizeHandler
  marginTop?: number
  marginRight?: number
  marginBottom?: number
  marginLeft?: number
  children: ReactNode
  domain: { x: [Date, Date]; y: [number, number] }
  domainPadding?: {
    top?: number
    bottom?: number
    right?: number
    left?: number
  }
  ticksCount?: number
  hideGuidelines?: boolean
}

const Chart: FC<ChartProps> = ({
  height,
  width: cssWidth,
  marginBottom = 32,
  marginLeft = 32,
  marginRight = 16,
  marginTop = 16,
  children,
  domain,
  domainPadding,
  ticksCount,
  hideGuidelines = false,
  onResize
}) => {
  const [width, setWidth] = useState<number>()
  const svgRef = useRef<SVGSVGElement>(null)

  useResizeObserver({
    ref: svgRef,
    onResize: (size) => {
      setWidth(size.width)
      onResize?.(size)
    }
  })

  const xScale = useMemo(
    () =>
      makeXScale({
        domain: domain.x,
        marginLeft,
        width: width ?? 0,
        marginRight,
        paddingStart: domainPadding?.left,
        paddingEnd: domainPadding?.right
      }),
    [domain.x, domainPadding?.left, domainPadding?.right, marginLeft, marginRight, width]
  )

  const yScale = useMemo(
    () =>
      makeYScale({
        domain: domain.y,
        height,
        marginBottom,
        marginTop,
        paddingEnd: domainPadding?.top,
        paddingStart: domainPadding?.bottom
      }),
    [domain.y, domainPadding?.bottom, domainPadding?.top, height, marginBottom, marginTop]
  )

  const y0 = useCallback(() => yScale(domain.y[0]), [yScale, domain.y])

  return (
    <ChartContextProvider
      height={height}
      width={width ?? 0}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginTop={marginTop}
      xScale={xScale}
      yScale={yScale}
      y0={y0}
      svgRef={svgRef}
      ticksCount={ticksCount}
    >
      <svg width={cssWidth} height={height} ref={svgRef}>
        {hideGuidelines ? null : <Guidelines />}
        {!isNil(width) ? children : null}
      </svg>
    </ChartContextProvider>
  )
}

export default Chart
