import { useExtractAvailableHorizonsFromSummaries } from '@weenat/client/dist/core/horizons'
import { Horizon } from '@weenat/client/dist/resources/horizons'
import { Summary } from '@weenat/client/dist/resources/measurements.type'
import { Id } from '@weenat/client/dist/resources/types'
import DropdownSelect, { DropdownSelectable } from 'app/src/kit/DropdownSelect'
import Icons from 'app/src/kit/Icons'
import { isEmpty, isNil } from 'lodash-es'
import React, { useEffect, useMemo } from 'react'

const EMPTY_HORIZONS: Horizon[] = []
const EMPTY_SUMMARIES: Summary[] = []

const HORIZON_REQUEST_STALE_TIME = 60 * 1000
/** 30 CM */
const DEFAULT_HORIZON_ID = 6

export interface HorizonSelectorProps {
  value?: number | null
  onChange: (horizonId: Id) => void
  summaries: Summary[]
}

const HorizonSelector: React.FC<HorizonSelectorProps> = ({ value, onChange, summaries }) => {
  const availableHorizonOptions = useExtractAvailableHorizonsFromSummaries(summaries)
  // initializing horizon if value is null
  useEffect(() => {
    if (
      !isEmpty(availableHorizonOptions) &&
      (isNil(value) || isNil(availableHorizonOptions.find((h) => h.value === value)))
    ) {
      const [firstOpt] = availableHorizonOptions
      onChange(firstOpt?.value)
    }
  }, [availableHorizonOptions, value, onChange])

  const options = useMemo(
    () =>
      availableHorizonOptions.map((h) => {
        const option: DropdownSelectable<number> = {
          ...h,
          label: `${h.label} cm`,
          backgroundColor: 'grayscale.white',
          borderColor: 'grayscale.black',
          color: 'grayscale.black'
        }
        return option
      }),
    [availableHorizonOptions]
  )

  return (
    <Box>
      <DropdownSelect
        value={value}
        options={options}
        onChange={(newId: number) => {
          onChange(newId)
        }}
        icon={Icons.Depth}
      />
    </Box>
  )
}

export default HorizonSelector
