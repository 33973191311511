import { useClient, useQuery } from '@weenat/client'
import useMetricSummary from '@weenat/client/dist/core/metrics/useMetricSummary'
import { UseMetricSummaryChartInterface } from '@weenat/client/dist/core/metrics/useMetricSummaryChart'
import { isPlot } from '@weenat/client/dist/core/utils/resourcesTypeCheckers'
import { Device, DeviceAvailableMeasure } from '@weenat/client/dist/resources/devices'
import { ForecastResults } from '@weenat/client/dist/resources/forecasts'
import { MeasurementConfig } from '@weenat/client/dist/resources/measurementConfigs'
import { SummaryData } from '@weenat/client/dist/resources/measurements.type'
import { Plot, PlotAvailableMeasure } from '@weenat/client/dist/resources/plots'
import { Station } from '@weenat/client/dist/resources/stations'
import { useIntl } from '@weenat/wintl'
import { useIsAppAccessRestricted } from 'app/src/hooks/useIsAppAccessRestricted'
import Text from 'app/src/kit/Text'
import FrostMetricsSummaryChart from 'app/src/kit/charts/metrics/FrostMetricsSummaryChart'
import MetricSummaryChart from 'app/src/kit/charts/metrics/MetricSummaryChart'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useTheme } from 'styled-components'
import DumbMetricSummary from './DumbMetricSummary'

interface MetricSummaryProps {
  /** Metric to display */
  metric: DeviceAvailableMeasure | PlotAvailableMeasure
  /** Timestamp at which the latest data was received */
  latestTimestamp?: number
  /** Whether or not to show a chart */
  isShowingChart?: boolean
  /** Whether or not the summary should be isShrunk */
  isShrunk?: boolean
  summary: SummaryData
  etpValue?: number | null
  /** Whether or not to show a CTA for the given metric */
  isRestrictedMetric?: boolean
  /** forecasts data from start of hour to tomorrow midnight */
  forecastsResults: ForecastResults | undefined
  resource: Plot | Device | Station
  prefetch?: UseMetricSummaryChartInterface['prefetch']
  isExpertOrg: boolean
}

const MetricSummary: FC<MetricSummaryProps> = ({
  etpValue,
  forecastsResults,
  isExpertOrg,
  isRestrictedMetric = false,
  isShowingChart = false,
  isShrunk = false,
  latestTimestamp,
  metric,
  prefetch,
  resource,
  summary
}) => {
  const { t } = useIntl()
  const { colors } = useTheme()
  const client = useClient()

  const metricColor = colors.metrics[metric]?.['500'] || colors.grayscale.black

  const { getFullSummaryValue, metricProvider, hasVirtualDevice, warningThreshold, deviceId } =
    useMetricSummary({ resource, forecastsResults, metric, summary, isExpertOrg })
  const { isRestricted: isAccessRestricted, reason } = useIsAppAccessRestricted()

  const shouldShowChart = isShowingChart && !isRestrictedMetric && !isShrunk
  const completeMeasurementConfigsRequest = useQuery(
    client.measurementsConfig.plots.getMany({ plotId: resource.id }),
    { enabled: isPlot(resource) }
  )
  let matchingConfig: MeasurementConfig | undefined
  if (isPlot(resource)) {
    const measurementConfigs = completeMeasurementConfigsRequest.data

    matchingConfig = !isEmpty(measurementConfigs)
      ? measurementConfigs?.find((conf) => {
          return !isNil(conf.deviceId) && isNil(conf.endDate) && metric === conf.metric
        })
      : undefined
  }
  return (
    <Box $pt={isShrunk ? 0 : 'md'}>
      <DumbMetricSummary
        measurementConfig={matchingConfig}
        metricProvider={metricProvider}
        hasVirtualDevice={hasVirtualDevice}
        warningThreshold={warningThreshold}
        latestTimestamp={latestTimestamp}
        getFullSummaryValue={getFullSummaryValue}
        isShrunk={isShrunk}
        metric={metric}
        etpValue={etpValue}
        isRestrictedMetric={isRestrictedMetric}
        isRestrictedByPlan={isAccessRestricted}
        reason={reason}
        deviceId={deviceId}
      />
      {/* METRIC CHART */}
      {shouldShowChart && (!isAccessRestricted || hasVirtualDevice) ? (
        <>
          <Box $pb='sm'>
            <Text $fontSize='sm' $color={metricColor}>
              {t('summaries.chart_title')}
            </Text>
          </Box>
          {metric === 'T_WET' ? (
            <FrostMetricsSummaryChart prefetch={prefetch} />
          ) : (
            <MetricSummaryChart metric={metric} prefetch={prefetch} />
          )}
        </>
      ) : null}
    </Box>
  )
}

export default MetricSummary
