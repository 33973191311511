import { Universe } from '@weenat/client/dist/core/universes'
import union from 'lodash-es/union'
import { ReactNode } from 'react'
import UniverseOption from './UniverseOption'

/**
 * All available options for a universe selector/chips tabs
 */
const useUniverseOptions = (
  universes: Universe[] | undefined,
  isAccessRestrictedByUnpaidBill?: boolean
) => {
  const allUniverses = union([Universe.overview], universes)

  // We do not display the options if there is only the weather universe
  return allUniverses.map((univ) => ({
    value: univ,
    label: (
      <UniverseOption
        universe={univ}
        isAccessRestrictedByUnpaidBill={isAccessRestrictedByUnpaidBill}
      />
    ) as ReactNode,
    isDisabled: isAccessRestrictedByUnpaidBill
  }))
}

export default useUniverseOptions
