import AuthProviders from 'app/AuthProviders'
import GlobalStyles from 'app/GlobalStyles'
import UnLoggedProviders from 'app/Providers'
import { Slot, usePathname } from 'app/routx-router'
import { BackgroundMapContextProvider } from 'app/src/dashboard/components/DashboardMap/contexts/BackgroundMapContext'
import { RadarContextProvider } from 'app/src/dashboard/components/DashboardMap/contexts/RadarContext'
import DownloadMobileScreen from 'app/src/kit/DownloadMobileScreen'
import { HTMLPageTitle } from 'app/src/kit/breadcrumbs/Breadcrumbs'
import { useToken } from 'app/state'
import breakpoints from 'app/utils/window/breakpoints'
import { isNil } from 'lodash-es'
import { useWindowSize } from 'react-use'

const AppLayout: FC = ({ children }) => {
  const pathname = usePathname()
  const { width } = useWindowSize()
  const [token] = useToken()

  const upgradePathMatch = pathname.includes('upgrade-to-premium')

  // Why are verify and upgrade excluded ? Can we open them on a phone ?
  const shouldDisplayDownloadMobileScreen =
    width < breakpoints.TABLET && !pathname.includes('verify-email') && !upgradePathMatch

  return shouldDisplayDownloadMobileScreen ? (
    <DownloadMobileScreen />
  ) : (
    <BackgroundMapContextProvider>
      <RadarContextProvider>
        <Flex $height={'100vh'} $position='relative' $flexDirection='column'>
          {
            // we don't want to trigger a rerender of whole layout when we set token on register page because
            // if we beg to join a network call will block naviagtation and if layout is changed register page is reload and form is clear
            // during call to join network so we delay render of LoggedInProviders by checking pathname
          }
          {!isNil(token) && !pathname.startsWith('/auth') ? (
            <AuthProviders>
              <HTMLPageTitle />
              {children}
            </AuthProviders>
          ) : (
            children
          )}
        </Flex>
      </RadarContextProvider>
    </BackgroundMapContextProvider>
  )
}

const ProvidersWrappedAppLayout = () => {
  return (
    <UnLoggedProviders>
      <GlobalStyles />
      <AppLayout>
        <Slot />
      </AppLayout>
    </UnLoggedProviders>
  )
}

export const Component = ProvidersWrappedAppLayout
export default ProvidersWrappedAppLayout
