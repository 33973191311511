import { Org } from '@weenat/client/dist/resources/orgs'
import { createContext, useContext } from 'react'

export const CURRENT_ORG_KEY = '@currentOrgId'

type IOrgContext = Org

const OrgContext = createContext<IOrgContext | null>(null)

export function useOrgContext() {
  const context = useContext<IOrgContext | null>(OrgContext)

  if (!context) {
    throw new Error(
      'You may have forgot to render <OrgContextProvider /> because OrgContext is not defined'
    )
  }
  return context
}

interface OrgContextProviderProps {
  org: Org
}

export const OrgContextProvider: FC<OrgContextProviderProps> = ({ org, children }) => {
  localStorage.setItem(CURRENT_ORG_KEY, `${org.id}`)
  return <OrgContext.Provider value={org}>{children}</OrgContext.Provider>
}
