import { WeenatColor, fromColorPathToColor } from '@weenat/theme'
import { styled } from 'styled-components'

export const DEFAULT_MARKER_SIZE_IN_PX = 56

const DEFAULT_BORDER_RADIUS_IN_PX = 999

export interface MapMarkerOutlineProps {
  $backgroundColor?: WeenatColor
  $isFocused?: boolean
  /** in pixels */
  $size?: number
  $zIndex?: number
  /** in pixels */
  $borderRadius?: number
  $lat: number
  $lng: number
}

// Use attrs for performance
const MapMarkerOutline = styled.div.attrs<MapMarkerOutlineProps>(
  ({
    theme,
    $isFocused = false,
    $backgroundColor = 'transparent',
    $zIndex = 1,
    $size = DEFAULT_MARKER_SIZE_IN_PX,
    $borderRadius = DEFAULT_BORDER_RADIUS_IN_PX
  }) => {
    return {
      style: {
        zIndex: $zIndex,
        width: $size,
        height: $size,
        /** the marker will be displayed on map by its top left corner so we have to offset it by -50% */
        marginTop: -1 * ($size / 2),
        marginLeft: -1 * ($size / 2),
        borderRadius: $borderRadius,
        backgroundColor: fromColorPathToColor($backgroundColor),
        boxShadow: `0px 0px 16px rgba(0, 38, 53, ${$isFocused ? 0.5 : 0.25})`,
        border: `2px solid ${$isFocused ? theme.colors.primary[500] : theme.colors.grayscale.white}`
      }
    }
  }
)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  pointer-events: auto;

  transition:
    background-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out,
    border-width 0.2s ease-in-out;

  will-change: border-width, box-shadow, background-color, z-index;
`

export default MapMarkerOutline
