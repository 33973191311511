import { ResourceAction } from '@weenat/client/dist/core/actions'
import { NavigateOptions } from '@weenat/client/dist/routx/runtime-core'
import { isKeyOf } from '@weenat/client/dist/utils'
import { WeenatColor } from '@weenat/theme'
import { useNavigate, useSearchParams } from 'app/routx-router'
import Text from 'app/src/kit/Text'
import { isNil } from 'lodash-es'
import { MouseEvent } from 'react'
import useDisclosure from '../hooks/useDisclosure'
import ConfirmationModal from './ConfirmationModal'
import Icons from './Icons'
import MenuItem from './MenuItem'

export type ActionItemsVariants = 'list' | 'tooltip'
interface ActionItemProps {
  action: ResourceAction
  onActionFinished?: () => void
  variant?: ActionItemsVariants
  withRightArrow?: boolean
}

const ActionItem: FC<ActionItemProps> = ({
  action,
  onActionFinished,
  variant = 'tooltip',
  withRightArrow = false
}) => {
  const nav = useNavigate()
  const [searchParams] = useSearchParams()

  const { open, close, isOpen } = useDisclosure()

  const textColor: WeenatColor | undefined = action.isDanger ? 'feedback.error.500' : undefined
  const { iconName, iconNode } = action
  const Icon = !isNil(iconName) && isKeyOf(iconName, Icons) ? Icons[iconName] : undefined
  const isLoading = 'state' in action ? action.state?.isPending : false

  const executeAction = async () => {
    if ('onPress' in action) {
      await action.onPress?.()
      if (action.needConfirmation) close()
    }

    if ('to' in action) {
      const { to, search } = action

      const options: NavigateOptions = {
        replace: false,
        search: { ...searchParams, ...search }
      }
      nav(to, options)
    }

    onActionFinished?.()
  }

  const openConfirmationOrExecuteAction = async (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    if (action.needConfirmation) {
      open()
    } else {
      await executeAction()
    }
  }

  return (
    <>
      <MenuItem
        key={action.id}
        onPress={isLoading ? undefined : openConfirmationOrExecuteAction}
        variant={variant}
      >
        <Flex
          $alignItems='center'
          $px={variant === 'tooltip' ? 'lg' : 'md'}
          $py='md'
          $gap='sm'
          $width={'100%'}
        >
          {!isNil(Icon) ? (
            <Icon
              $size={variant === 'tooltip' ? 'md' : 'lg'}
              $color={textColor}
              $isLoading={isLoading}
            />
          ) : null}
          {!isNil(iconNode) ? iconNode : null}
          <Text
            $color={textColor}
            $fontWeight={variant === 'list' ? 'medium' : undefined}
            style={{ flex: 1 }}
          >
            {action.label}
          </Text>
          {withRightArrow && <Icons.ArrowRight $size={variant === 'tooltip' ? 'md' : 'lg'} />}
        </Flex>
      </MenuItem>
      {action.needConfirmation && isOpen ? (
        <ConfirmationModal
          title={action.label}
          isOpen={isOpen}
          close={close}
          onCancel={close}
          onConfirm={async () => {
            await executeAction()
          }}
          confirmButtonState={
            'state' in action && !isNil(action.state)
              ? { ...action.state, isIdle: false, isPaused: false }
              : undefined
          }
        />
      ) : null}
    </>
  )
}

export default ActionItem
