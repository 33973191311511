import { useIntl } from '@weenat/wintl'
import Icons from 'app/src/kit/Icons'
import Text from 'app/src/kit/Text'

import { SearchSelectableElement } from './SearchSelectableElement'

export const PreviousSearchElt: FC<{ value: string; onPress: (slectedValue: string) => void }> = ({
  value,
  onPress
}) => {
  const { t } = useIntl()
  return (
    <SearchSelectableElement
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onPress(value)
      }}
    >
      <Flex $alignItems='center' $gap='md'>
        <Icons.SearchActivity $size='md' />
        <Text $fontWeight='bold'>{value}</Text>
      </Flex>
      <Flex $alignItems='center' $gap={'md'}>
        <Text $color='grayscale.700' $fontWeight='semiBold' $lineHeight={undefined}>
          {t('actions.relaunch')}
        </Text>
        <Icons.ArrowRight $size='lg' $color='grayscale.700' />
      </Flex>
    </SearchSelectableElement>
  )
}
