import { useIntl } from '@weenat/wintl'
import useDisclosure from 'app/src/hooks/useDisclosure'
import Modal from 'app/src/kit/Modal'
import Text from 'app/src/kit/Text'
import { styled } from 'styled-components'
import Icons from './Icons'

const DewRiskHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const DewRiskInfo: FC<{ showLabel?: boolean }> = ({ showLabel = false }) => {
  const { t } = useIntl()
  const { open, close, isOpen } = useDisclosure()

  return (
    <>
      <DewRiskHolder onClick={open}>
        {showLabel ? (
          <>
            <Icons.AlertTriangle $color={'grayscale.700'} $size='rg' />
            <Text $fontSize='xs' $fontStyle='italic' $color={'grayscale.700'}>
              {t('help.dew_point_risk')}
            </Text>
          </>
        ) : (
          <Icons.AlertTriangle $color={'grayscale.700'} $size='lg' onPress={open} />
        )}
      </DewRiskHolder>
      <Modal
        close={close}
        isOpen={isOpen}
        title={
          <Text $fontWeight='bold' $fontSize='md'>
            {t('help.dew_point_risk')}
          </Text>
        }
      >
        <Text>{t('help.dew_point_risk_explanation')}</Text>
      </Modal>
    </>
  )
}

export default DewRiskInfo
