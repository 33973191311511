import { HTMLProps } from 'react'
import styled from 'styled-components'
import { ActionItemsVariants } from './ActionItem'

const PressableZone = styled.div<{ $isInList: boolean; $backgroundActive?: boolean }>`
  position: relative;
  cursor: pointer;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    background-color: ${({ $backgroundActive, theme }) =>
      $backgroundActive ? theme.colors.primary[200] : 'transparent'};
    inset: ${(p) => (p.$isInList ? '0' : '0 4px')};
    border-radius: ${(p) => p.theme.radiuses.sm}px;
  }

  &:hover::after {
    background-color: ${(p) => p.theme.colors.primary[200]};
  }
`

interface MenuItemProps {
  onPress: HTMLProps<HTMLDivElement>['onClick']
  className?: HTMLProps<HTMLDivElement>['className']
  variant?: ActionItemsVariants
  forceActivity?: boolean
}

const MenuItem: FC<MenuItemProps> = ({
  onPress,
  children,
  className,
  variant = 'tooltip',
  forceActivity = false
}) => {
  return (
    <PressableZone
      onClick={onPress}
      className={className}
      $isInList={variant === 'list'}
      $backgroundActive={forceActivity}
    >
      {children}
    </PressableZone>
  )
}

export default MenuItem
