import { createGlobalStyle, css } from 'styled-components'

const minireset = css`
  html,
  body,
  p,
  ol,
  ul,
  li,
  dl,
  dt,
  dd,
  blockquote,
  figure,
  fieldset,
  legend,
  textarea,
  pre,
  iframe,
  hr,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
    font-weight: normal;
    webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
  }

  ul {
    list-style: none;
  }

  button,
  input,
  select,
  textarea {
    margin: 0;
    user-select: none;
  }

  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  img,
  video {
    height: auto;
    max-width: 100%;
  }

  iframe {
    border: 0;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  td,
  th {
    padding: 0;
    text-align: left;
  }
`

const reset = css`
  ${minireset}
  html {
    -moz-osx-font-smoothing: grayscale;
  }

  *,
  *::before,
  *::after {
    appearance: none;
    -webkit-touch-callout: default;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
    -moz-tap-highlight-color: rgb(0 0 0 / 0%);
    -ms-tap-highlight-color: rgb(0 0 0 / 0%);
    -o-tap-highlight-color: rgb(0 0 0 / 0%);
    highlight-color: rgb(0 0 0 / 0%);
    box-shadow: none;
    text-decoration: none;
    transition: none;
    outline: none;
    text-shadow: none;
  }

  button[disabled],
  input[disabled],
  textarea[disabled],
  select[disabled] {
    cursor: not-allowed;
  }
`

const createGlobalStyles = (additionalCss = '') => createGlobalStyle`
  ${reset}

  html {
    font-size: 14px;
    font-family: ${(p) => p.theme.typography.families.pm}, sans-serif;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.grayscale.black};
  }

  svg {
    fill: ${({ theme }) => theme.colors.grayscale.black};
  }

  svg.text {
    font-family: ${(p) => p.theme.typography.families.pm}, sans-serif;
  }

  /* Scroll bar stylings */
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(0,38,43, 0.1); 
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0,38,43, 0.2); 
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0,38,43, 0.4); 
  }

  ${additionalCss}
`

export default createGlobalStyles
