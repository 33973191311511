import { SwitchUniverseParams } from '@weenat/client/dist/core/analytics'
import { Universe } from '@weenat/client/dist/core/universes'
import { useOrgContext } from 'app/orgProvider'
import usePlotsOrDevicesFromPathname from 'app/src/hooks/usePlotsOrDevicesFromPathname'
import ChipsTabs from 'app/src/kit/ChipsTabs'
import Pop from 'app/src/kit/Pop'
import FadeIn from 'app/src/kit/animations/FadeIn'
import { useUniverse } from 'app/state'
import { logSwitchUniverse } from 'app/utils/analytics'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useEffect } from 'react'
import useUniverseOptionsQuery from './useUniverseOptionsQuery'

/** @deprecated redesign */
const UniverseChipsTabs = ({ omit }: { omit?: Universe[] }) => {
  const [currentUniv, setUniv] = useUniverse()
  const org = useOrgContext()
  const plotsOrDevices = usePlotsOrDevicesFromPathname()

  const { universeOptions: allUniverseOption, universesRequest } = useUniverseOptionsQuery(org.id)

  const universeOptions = !isNil(omit)
    ? allUniverseOption.filter((univOption) => !omit.includes(univOption.value))
    : allUniverseOption

  const onChange = (u: Universe) => {
    if (!isNil(plotsOrDevices)) {
      logSwitchUniverse({
        action: `${plotsOrDevices}_switch_universe`,
        selected: Universe[u] as SwitchUniverseParams['selected']
      })
    }
    setUniv(u)
  }

  // Resetting the current universe to weather if the org ID is defined and the current universe is not inside the available options
  useEffect(
    () => {
      if (!isEmpty(universeOptions)) {
        const currentOptionAvailable = universeOptions.some(
          (opt) => opt.value === currentUniv && !opt.isDisabled
        )

        if (!isNil(org.id) && universesRequest.isSuccess && !currentOptionAvailable) {
          setUniv(universeOptions[0].value)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [org.id, universeOptions]
  )

  return universesRequest.isSuccess ? (
    <FadeIn>
      <Box $pointerEvents='auto'>
        <Pop tourName='discover_irrigation' stepName='change_univ'>
          <ChipsTabs
            options={universeOptions}
            value={currentUniv}
            onChange={onChange}
            $isRounded
            $isShadowed
          />
        </Pop>
      </Box>
    </FadeIn>
  ) : null
}

export default UniverseChipsTabs
