// Generated code. DO NOT EDIT WILL BE OVERRIDE.
import * as z from 'zod'
import { pathParamsParsers } from '@weenat/client/dist/routx/runtime-core'

declare module '@weenat/client/dist/routx/runtime-core' {
  interface PathParams {
    orgId: number
    deviceId: number
    alertId: string
    loginMagicToken: string
    resetPasswordUid: string
    resetPasswordToken: string
    verifyEmailKey: string
    sectionSlug: string
    articleSlug: string
    farmId: number
    memberId: number
    invitationId: number
    networkOrgId: number
    membershipRequestId: number
    optionCode: string
    plotId: number
    stationId: number
    networkId: number
    thresholdId: number
    invitationBase64: string
  }

  type GoBackHref = -1 | -2
  type RelativeHref =
    | 'accept-pp'
    | 'accept-tos'
    | 'actura'
    | 'administration'
    | 'billing'
    | 'organizations'
    | 'devices'
    | 'events'
    | 'alerts'
    | 'create'
    | 'auth'
    | 'login'
    | 'magic'
    | 'confirm'
    | 'register'
    | 'reset-password'
    | 'send-verification-email'
    | 'verify-email'
    | 'discover'
    | 'export'
    | 'farms'
    | 'admin'
    | 'history'
    | 'my-subscription-detail'
    | 'next'
    | 'linked-plots'
    | 'add'
    | 'agro-weather'
    | 'irrigation'
    | 'edit'
    | 'headquarter'
    | 'info'
    | 'members'
    | 'invite'
    | 'members-invitations'
    | 'my-farm'
    | 'network'
    | 'invitations'
    | 'upgrade'
    | 'members-trials'
    | 'membership-requests'
    | 'option-requests'
    | 'options'
    | 'plots-activations'
    | 'settings'
    | 'stations-activations'
    | 'org-plots'
    | 'plots'
    | 'option-activations'
    | 'stations'
    | 'upgrade-to-premium'
    | 'onboarding'
    | 'members-tips'
    | 'networks'
    | 'find'
    | 'sensors'
    | 'success'
    | 'welcome'
    | 'crop-tracking'
    | 'decitrait'
    | 'sanitary-state-notes'
    | 'treatments'
    | 'forecasts'
    | 'frost'
    | 'irrelis'
    | 'create-plot'
    | 'reports'
    | 'thresholds'
    | 'maizy'
    | 'overview'
    | 'summary'
    | 'tavmills'
    | 'water-balance'
    | 'water-balance-ri'
    | 'weather'
    | 'weephyt'
    | 'archived'
    | 'help'
    | 'me'
    | 'account'
    | 'api'
    | 'cluster-settings'
    | 'contact-information'
    | 'debug'
    | 'external-connections'
    | 'feature-toggle'
    | 'accept'
    | 'mobile-devices'
    | 'preferences'
    | 'notifications'
    | 'profile'
    | 'payment'
    | 'failed'
    | 'push-notification'
    | 'smag'
    | 'unsubscribe-emails'
    | 'weather-map'
  type AbsoluteHref =
    | `/accept-pp`
    | `/accept-tos`
    | `/actura`
    | `/administration`
    | `/administration/${number}`
    | `/administration/${number}/billing`
    | `/administration/organizations`
    | `/administration/organizations/${number}/devices/${number}/events`
    | `/alerts/${string}`
    | `/alerts/create`
    | `/alerts`
    | `/auth`
    | `/auth/login`
    | `/auth/login/magic/confirm`
    | `/auth/login/magic/confirm/${string}`
    | `/auth/login/magic`
    | `/auth/register`
    | `/auth/reset-password`
    | `/auth/reset-password/${string}`
    | `/auth/reset-password/${string}/${string}`
    | `/auth/send-verification-email`
    | `/auth/verify-email`
    | `/auth/verify-email/${string}`
    | `/discover/${string}`
    | `/discover/${string}/${string}`
    | `/discover`
    | `/export`
    | `/farms/${number}/admin/billing/history`
    | `/farms/${number}/admin/billing`
    | `/farms/${number}/admin/billing/my-subscription-detail`
    | `/farms/${number}/admin/billing/next`
    | `/farms/${number}/admin/devices/${number}/events`
    | `/farms/${number}/admin/devices/${number}`
    | `/farms/${number}/admin/devices/${number}/linked-plots`
    | `/farms/${number}/admin/devices/add`
    | `/farms/${number}/admin/devices/agro-weather`
    | `/farms/${number}/admin/devices`
    | `/farms/${number}/admin/devices/irrigation`
    | `/farms/${number}/admin/edit/headquarter`
    | `/farms/${number}/admin/edit`
    | `/farms/${number}/admin`
    | `/farms/${number}/admin/info`
    | `/farms/${number}/admin/members/${number}`
    | `/farms/${number}/admin/members`
    | `/farms/${number}/admin/members/invite`
    | `/farms/${number}/admin/members-invitations/${number}`
    | `/farms/${number}/admin/members-invitations`
    | `/farms/${number}/admin/my-farm`
    | `/farms/${number}/admin/network`
    | `/farms/${number}/admin/network/invitations/${number}`
    | `/farms/${number}/admin/network/invitations`
    | `/farms/${number}/admin/network/invite`
    | `/farms/${number}/admin/network/members/${number}`
    | `/farms/${number}/admin/network/members/${number}/upgrade`
    | `/farms/${number}/admin/network/members`
    | `/farms/${number}/admin/network/members-trials/${number}`
    | `/farms/${number}/admin/network/members-trials`
    | `/farms/${number}/admin/network/membership-requests/${number}`
    | `/farms/${number}/admin/network/membership-requests`
    | `/farms/${number}/admin/network/option-requests/${number}`
    | `/farms/${number}/admin/network/option-requests`
    | `/farms/${number}/admin/options/${string}`
    | `/farms/${number}/admin/options/${string}/plots-activations`
    | `/farms/${number}/admin/options/${string}/settings`
    | `/farms/${number}/admin/options/${string}/stations-activations`
    | `/farms/${number}/admin/options`
    | `/farms/${number}/admin/org-plots`
    | `/farms/${number}/admin/org-plots/${number}`
    | `/farms/${number}/admin/plots/${number}/devices`
    | `/farms/${number}/admin/plots/${number}`
    | `/farms/${number}/admin/plots/${number}/option-activations`
    | `/farms/${number}/admin/plots`
    | `/farms/${number}/admin/stations/${number}`
    | `/farms/${number}/admin/stations/${number}/option-activations`
    | `/farms/${number}/admin/stations`
    | `/farms/${number}/admin/upgrade-to-premium`
    | `/farms/${number}`
    | `/farms/${number}/onboarding`
    | `/farms/${number}/onboarding/members-tips`
    | `/farms/${number}/onboarding/my-farm`
    | `/farms/${number}/onboarding/networks/${number}`
    | `/farms/${number}/onboarding/networks/find`
    | `/farms/${number}/onboarding/networks`
    | `/farms/${number}/onboarding/sensors/add`
    | `/farms/${number}/onboarding/sensors`
    | `/farms/${number}/onboarding/sensors/success`
    | `/farms/${number}/onboarding/welcome`
    | `/farms/${number}/plots/${number}/alerts`
    | `/farms/${number}/plots/${number}/crop-tracking`
    | `/farms/${number}/plots/${number}/decitrait`
    | `/farms/${number}/plots/${number}/decitrait/sanitary-state-notes`
    | `/farms/${number}/plots/${number}/decitrait/settings`
    | `/farms/${number}/plots/${number}/decitrait/treatments/add`
    | `/farms/${number}/plots/${number}/decitrait/treatments`
    | `/farms/${number}/plots/${number}/forecasts`
    | `/farms/${number}/plots/${number}/frost`
    | `/farms/${number}/plots/${number}/history`
    | `/farms/${number}/plots/${number}`
    | `/farms/${number}/plots/${number}/irrelis/create-plot`
    | `/farms/${number}/plots/${number}/irrelis`
    | `/farms/${number}/plots/${number}/irrelis/settings`
    | `/farms/${number}/plots/${number}/irrigation`
    | `/farms/${number}/plots/${number}/irrigation/reports`
    | `/farms/${number}/plots/${number}/irrigation/settings/thresholds/${number}`
    | `/farms/${number}/plots/${number}/irrigation/settings/thresholds/create`
    | `/farms/${number}/plots/${number}/irrigation/settings/thresholds`
    | `/farms/${number}/plots/${number}/maizy`
    | `/farms/${number}/plots/${number}/options`
    | `/farms/${number}/plots/${number}/overview`
    | `/farms/${number}/plots/${number}/summary`
    | `/farms/${number}/plots/${number}/tavmills`
    | `/farms/${number}/plots/${number}/water-balance`
    | `/farms/${number}/plots/${number}/water-balance/reports`
    | `/farms/${number}/plots/${number}/water-balance/settings`
    | `/farms/${number}/plots/${number}/water-balance-ri`
    | `/farms/${number}/plots/${number}/water-balance-ri/reports`
    | `/farms/${number}/plots/${number}/water-balance-ri/settings`
    | `/farms/${number}/plots/${number}/weather`
    | `/farms/${number}/plots/${number}/weephyt`
    | `/farms/${number}/plots/archived`
    | `/farms/${number}/plots/create`
    | `/farms/${number}/plots/edit`
    | `/farms/${number}/plots`
    | `/farms/${number}/stations/${number}/alerts`
    | `/farms/${number}/stations/${number}/forecasts`
    | `/farms/${number}/stations/${number}/history`
    | `/farms/${number}/stations/${number}`
    | `/farms/${number}/stations/${number}/options`
    | `/farms/${number}/stations/${number}/summary`
    | `/farms/${number}/stations/${number}/weather`
    | `/farms/${number}/stations/${number}/weephyt`
    | `/farms/${number}/stations`
    | `/farms`
    | `/help`
    | `/`
    | `/me/account`
    | `/me/api`
    | `/me/cluster-settings`
    | `/me/contact-information`
    | `/me/debug`
    | `/me/external-connections`
    | `/me/feature-toggle`
    | `/me`
    | `/me/invitations/accept/${string}`
    | `/me/invitations/accept`
    | `/me/invitations`
    | `/me/mobile-devices`
    | `/me/preferences`
    | `/me/preferences/notifications`
    | `/me/profile`
    | `/networks/${number}`
    | `/networks`
    | `/payment/failed`
    | `/payment/success`
    | `/plots/${number}/forecasts`
    | `/plots/${number}/history`
    | `/plots/${number}`
    | `/plots/${number}/summary`
    | `/plots`
    | `/push-notification`
    | `/smag`
    | `/unsubscribe-emails`
    | `/weather-map/${number}/alerts`
    | `/weather-map/${number}/forecasts`
    | `/weather-map/${number}/history`
    | `/weather-map/${number}`
    | `/weather-map/${number}/summary`
    | `/weather-map/${number}/weather`
    | `/${string}`
  type PathPattern =
    | '/accept-pp'
    | '/accept-tos'
    | '/actura'
    | '/administration'
    | '/administration/*'
    | '/administration/*/*'
    | '/administration/*/billing'
    | '/administration/organizations'
    | '/administration/organizations/*'
    | '/administration/organizations/*/devices/*/events'
    | '/alerts/*'
    | '/alerts/create'
    | '/alerts'
    | '/auth'
    | '/auth/*'
    | '/auth/login'
    | '/auth/login/*'
    | '/auth/login/magic/confirm'
    | '/auth/login/magic/confirm/*'
    | '/auth/login/magic'
    | '/auth/login/magic/*'
    | '/auth/register'
    | '/auth/reset-password'
    | '/auth/reset-password/*'
    | '/auth/reset-password/*/*'
    | '/auth/send-verification-email'
    | '/auth/verify-email'
    | '/auth/verify-email/*'
    | '/discover/*'
    | '/discover/*/*'
    | '/discover'
    | '/export'
    | '/farms/*/admin/billing/history'
    | '/farms/*/admin/billing'
    | '/farms/*/admin/billing/*'
    | '/farms/*/admin/billing/my-subscription-detail'
    | '/farms/*/admin/billing/next'
    | '/farms/*/admin/devices/*/events'
    | '/farms/*/admin/devices/*'
    | '/farms/*/admin/devices/*/*'
    | '/farms/*/admin/devices/*/linked-plots'
    | '/farms/*/admin/devices/add'
    | '/farms/*/admin/devices/agro-weather'
    | '/farms/*/admin/devices'
    | '/farms/*/admin/devices/irrigation'
    | '/farms/*/admin/edit/headquarter'
    | '/farms/*/admin/edit'
    | '/farms/*/admin/edit/*'
    | '/farms/*/admin'
    | '/farms/*/admin/*'
    | '/farms/*/admin/info'
    | '/farms/*/admin/members/*'
    | '/farms/*/admin/members/*/*'
    | '/farms/*/admin/members'
    | '/farms/*/admin/members/invite'
    | '/farms/*/admin/members-invitations/*'
    | '/farms/*/admin/members-invitations/*/*'
    | '/farms/*/admin/members-invitations'
    | '/farms/*/admin/my-farm'
    | '/farms/*/admin/network'
    | '/farms/*/admin/network/*'
    | '/farms/*/admin/network/invitations/*'
    | '/farms/*/admin/network/invitations/*/*'
    | '/farms/*/admin/network/invitations'
    | '/farms/*/admin/network/invite'
    | '/farms/*/admin/network/members/*'
    | '/farms/*/admin/network/members/*/*'
    | '/farms/*/admin/network/members/*/upgrade'
    | '/farms/*/admin/network/members'
    | '/farms/*/admin/network/members-trials/*'
    | '/farms/*/admin/network/members-trials/*/*'
    | '/farms/*/admin/network/members-trials'
    | '/farms/*/admin/network/membership-requests/*'
    | '/farms/*/admin/network/membership-requests/*/*'
    | '/farms/*/admin/network/membership-requests'
    | '/farms/*/admin/network/option-requests/*'
    | '/farms/*/admin/network/option-requests/*/*'
    | '/farms/*/admin/network/option-requests'
    | '/farms/*/admin/options/*'
    | '/farms/*/admin/options/*/*'
    | '/farms/*/admin/options/*/plots-activations'
    | '/farms/*/admin/options/*/settings'
    | '/farms/*/admin/options/*/stations-activations'
    | '/farms/*/admin/options'
    | '/farms/*/admin/org-plots'
    | '/farms/*/admin/org-plots/*'
    | '/farms/*/admin/plots/*/devices'
    | '/farms/*/admin/plots/*/devices/*'
    | '/farms/*/admin/plots/*'
    | '/farms/*/admin/plots/*/*'
    | '/farms/*/admin/plots/*/option-activations'
    | '/farms/*/admin/plots'
    | '/farms/*/admin/stations/*'
    | '/farms/*/admin/stations/*/*'
    | '/farms/*/admin/stations/*/option-activations'
    | '/farms/*/admin/stations'
    | '/farms/*/admin/upgrade-to-premium'
    | '/farms/*'
    | '/farms/*/*'
    | '/farms/*/onboarding'
    | '/farms/*/onboarding/*'
    | '/farms/*/onboarding/members-tips'
    | '/farms/*/onboarding/my-farm'
    | '/farms/*/onboarding/networks/*'
    | '/farms/*/onboarding/networks/*/*'
    | '/farms/*/onboarding/networks/find'
    | '/farms/*/onboarding/networks'
    | '/farms/*/onboarding/sensors/add'
    | '/farms/*/onboarding/sensors'
    | '/farms/*/onboarding/sensors/*'
    | '/farms/*/onboarding/sensors/success'
    | '/farms/*/onboarding/welcome'
    | '/farms/*/plots/*/alerts'
    | '/farms/*/plots/*/crop-tracking'
    | '/farms/*/plots/*/decitrait'
    | '/farms/*/plots/*/decitrait/*'
    | '/farms/*/plots/*/decitrait/sanitary-state-notes'
    | '/farms/*/plots/*/decitrait/settings'
    | '/farms/*/plots/*/decitrait/treatments/add'
    | '/farms/*/plots/*/decitrait/treatments'
    | '/farms/*/plots/*/decitrait/treatments/*'
    | '/farms/*/plots/*/forecasts'
    | '/farms/*/plots/*/frost'
    | '/farms/*/plots/*/history'
    | '/farms/*/plots/*'
    | '/farms/*/plots/*/*'
    | '/farms/*/plots/*/irrelis/create-plot'
    | '/farms/*/plots/*/irrelis'
    | '/farms/*/plots/*/irrelis/*'
    | '/farms/*/plots/*/irrelis/settings'
    | '/farms/*/plots/*/irrigation'
    | '/farms/*/plots/*/irrigation/*'
    | '/farms/*/plots/*/irrigation/reports'
    | '/farms/*/plots/*/irrigation/settings/thresholds/*'
    | '/farms/*/plots/*/irrigation/settings/thresholds/*/*'
    | '/farms/*/plots/*/irrigation/settings/thresholds/create'
    | '/farms/*/plots/*/irrigation/settings/thresholds'
    | '/farms/*/plots/*/maizy'
    | '/farms/*/plots/*/options'
    | '/farms/*/plots/*/overview'
    | '/farms/*/plots/*/summary'
    | '/farms/*/plots/*/tavmills'
    | '/farms/*/plots/*/water-balance'
    | '/farms/*/plots/*/water-balance/*'
    | '/farms/*/plots/*/water-balance/reports'
    | '/farms/*/plots/*/water-balance/settings'
    | '/farms/*/plots/*/water-balance-ri'
    | '/farms/*/plots/*/water-balance-ri/*'
    | '/farms/*/plots/*/water-balance-ri/reports'
    | '/farms/*/plots/*/water-balance-ri/settings'
    | '/farms/*/plots/*/weather'
    | '/farms/*/plots/*/weephyt'
    | '/farms/*/plots/archived'
    | '/farms/*/plots/create'
    | '/farms/*/plots/edit'
    | '/farms/*/plots'
    | '/farms/*/stations/*/alerts'
    | '/farms/*/stations/*/forecasts'
    | '/farms/*/stations/*/history'
    | '/farms/*/stations/*'
    | '/farms/*/stations/*/*'
    | '/farms/*/stations/*/options'
    | '/farms/*/stations/*/summary'
    | '/farms/*/stations/*/weather'
    | '/farms/*/stations/*/weephyt'
    | '/farms/*/stations'
    | '/farms'
    | '/help'
    | '/help/*'
    | '/'
    | '/me/account'
    | '/me/api'
    | '/me/cluster-settings'
    | '/me/contact-information'
    | '/me/debug'
    | '/me/external-connections'
    | '/me/feature-toggle'
    | '/me'
    | '/me/*'
    | '/me/invitations/accept/*'
    | '/me/invitations/accept'
    | '/me/invitations'
    | '/me/invitations/*'
    | '/me/mobile-devices'
    | '/me/preferences'
    | '/me/preferences/*'
    | '/me/preferences/notifications'
    | '/me/profile'
    | '/networks/*'
    | '/networks/*/*'
    | '/networks'
    | '/payment/failed'
    | '/payment/success'
    | '/plots/*/forecasts'
    | '/plots/*/history'
    | '/plots/*'
    | '/plots/*/*'
    | '/plots/*/summary'
    | '/plots'
    | '/push-notification'
    | '/smag'
    | '/unsubscribe-emails'
    | '/weather-map/*/alerts'
    | '/weather-map/*/forecasts'
    | '/weather-map/*/history'
    | '/weather-map/*'
    | '/weather-map/*/*'
    | '/weather-map/*/summary'
    | '/weather-map/*/weather'
    | '/*'
  type Href = GoBackHref | AbsoluteHref | RelativeHref | '.'
  type SearchParamsByAbsoluteHref = {
    [H in `/actura`]: z.infer<typeof import('./routes/actura').settings.search>
  } & {
    [H in `/auth/login`]: z.infer<typeof import('./routes/auth/login/index').settings.search>
  } & {
    [H in `/auth/login/magic`]: z.infer<
      typeof import('./routes/auth/login/magic/index').settings.search
    >
  } & {
    [H in `/auth/register`]: z.infer<typeof import('./routes/auth/register').settings.search>
  } & {
    [H in `/auth/reset-password`]: z.infer<
      typeof import('./routes/auth/reset-password/index').settings.search
    >
  } & {
    [H in `/auth/send-verification-email`]: z.infer<
      typeof import('./routes/auth/send-verification-email').settings.search
    >
  } & { [H in `/export`]: z.infer<typeof import('./routes/export').settings.search> } & {
    [H in `/farms/${number}/admin/upgrade-to-premium`]: z.infer<
      typeof import('./routes/farms/[farmId]/admin/upgrade-to-premium').settings.search
    >
  } & {
    [H in `/farms/${number}/onboarding/members-tips`]: z.infer<
      typeof import('./routes/farms/[farmId]/onboarding/members-tips').settings.search
    >
  } & {
    [H in `/farms/${number}/onboarding/my-farm`]: z.infer<
      typeof import('./routes/farms/[farmId]/onboarding/my-farm').settings.search
    >
  } & {
    [H in `/farms/${number}/onboarding/networks`]: z.infer<
      typeof import('./routes/farms/[farmId]/onboarding/networks/index').settings.search
    >
  } & {
    [H in `/farms/${number}/onboarding/sensors`]: z.infer<
      typeof import('./routes/farms/[farmId]/onboarding/sensors/index').settings.search
    >
  } & {
    [H in `/farms/${number}/onboarding/sensors/success`]: z.infer<
      typeof import('./routes/farms/[farmId]/onboarding/sensors/success').settings.search
    >
  } & {
    [H in `/farms/${number}/onboarding/welcome`]: z.infer<
      typeof import('./routes/farms/[farmId]/onboarding/welcome').settings.search
    >
  } & {
    [H in `/farms/${number}/plots/${number}/forecasts`]: z.infer<
      typeof import('./routes/farms/[farmId]/plots/[plotId]/forecasts').settings.search
    >
  } & {
    [H in `/farms/${number}/plots/${number}/irrigation/settings/thresholds/create`]: z.infer<
      typeof import('./routes/farms/[farmId]/plots/[plotId]/irrigation/settings/thresholds/create').settings.search
    >
  } & {
    [H in `/farms/${number}/stations/${number}/forecasts`]: z.infer<
      typeof import('./routes/farms/[farmId]/stations/[stationId]/forecasts').settings.search
    >
  } & { [H in `/me/debug`]: z.infer<typeof import('./routes/me/debug').settings.search> } & {
    [H in `/push-notification`]: z.infer<
      typeof import('./routes/push-notification').settings.search
    >
  } & { [H in `/smag`]: z.infer<typeof import('./routes/smag').settings.search> } & {
    [H in `/unsubscribe-emails`]: z.infer<
      typeof import('./routes/unsubscribe-emails').settings.search
    >
  } & {
    [H in `/weather-map/${number}/forecasts`]: z.infer<
      typeof import('./routes/weather-map/[deviceId]/forecasts').settings.search
    >
  }

  export interface GeneratedTypes {
    PathParams: PathParams
    Href: Href
    SearchParamsByAbsoluteHref: SearchParamsByAbsoluteHref
    AbsoluteHref: AbsoluteHref
    PathPattern: PathPattern
  }
}

export const paramsParserMap = {
  orgId: pathParamsParsers.number,
  deviceId: pathParamsParsers.number,
  alertId: pathParamsParsers.string,
  loginMagicToken: pathParamsParsers.string,
  resetPasswordUid: pathParamsParsers.string,
  resetPasswordToken: pathParamsParsers.string,
  verifyEmailKey: pathParamsParsers.string,
  sectionSlug: pathParamsParsers.string,
  articleSlug: pathParamsParsers.string,
  farmId: pathParamsParsers.number,
  memberId: pathParamsParsers.number,
  invitationId: pathParamsParsers.number,
  networkOrgId: pathParamsParsers.number,
  membershipRequestId: pathParamsParsers.number,
  optionCode: pathParamsParsers.string,
  plotId: pathParamsParsers.number,
  stationId: pathParamsParsers.number,
  networkId: pathParamsParsers.number,
  thresholdId: pathParamsParsers.number,
  invitationBase64: pathParamsParsers.string,
  '*': pathParamsParsers.string
}
export const publicPathPatterns = {
  only: {
    '/actura': true,
    '/auth': true,
    '/auth/login': true,
    '/auth/login/magic/confirm': true,
    '/auth/login/magic/confirm/*': true,
    '/auth/login/magic': true,
    '/auth/register': true,
    '/auth/reset-password': true,
    '/auth/reset-password/*': true,
    '/auth/reset-password/*/*': true,
    '/auth/verify-email': true,
    '/auth/verify-email/*': true,
    '/smag': true
  },
  always: {
    '/auth/send-verification-email': true,
    '/me/invitations/accept/*': true,
    '/unsubscribe-emails': true
  }
} as const
export const pathPatternResolver = {
  '': true,
  '*': true,
  me: {
    '*': true,
    api: true,
    debug: true,
    account: true,
    profile: true,
    invitations: { '*': true, accept: { '*': true } },
    preferences: { '*': true, notifications: true },
    'feature-toggle': true,
    'mobile-devices': true,
    'cluster-settings': true,
    'contact-information': true,
    'external-connections': true
  },
  auth: {
    '*': true,
    login: { '*': true, magic: { '*': true, confirm: { '*': true } } },
    register: true,
    'verify-email': { '*': true },
    'reset-password': { '*': { '*': true } },
    'send-verification-email': true
  },
  help: { '*': true },
  smag: true,
  farms: {
    '*': {
      '*': true,
      admin: {
        '*': true,
        edit: { '*': true, headquarter: true },
        info: true,
        plots: { '*': { '*': true, devices: { '*': true }, 'option-activations': true } },
        billing: { '*': true, next: true, history: true, 'my-subscription-detail': true },
        devices: {
          '*': { '*': true, events: true, 'linked-plots': true },
          add: true,
          irrigation: true,
          'agro-weather': true
        },
        members: { '*': { '*': true }, invite: true },
        'my-farm': true,
        network: {
          '*': true,
          invite: true,
          members: { '*': { '*': true, upgrade: true } },
          invitations: { '*': { '*': true } },
          'members-trials': { '*': { '*': true } },
          'option-requests': { '*': { '*': true } },
          'membership-requests': { '*': { '*': true } }
        },
        options: {
          '*': {
            '*': true,
            settings: true,
            'plots-activations': true,
            'stations-activations': true
          }
        },
        stations: { '*': { '*': true, 'option-activations': true } },
        'org-plots': { '*': true },
        'upgrade-to-premium': true,
        'members-invitations': { '*': { '*': true } }
      },
      plots: {
        '*': {
          '*': true,
          frost: true,
          maizy: true,
          alerts: true,
          history: true,
          irrelis: { '*': true, settings: true, 'create-plot': true },
          options: true,
          summary: true,
          weather: true,
          weephyt: true,
          overview: true,
          tavmills: true,
          decitrait: {
            '*': true,
            settings: true,
            treatments: { '*': true, add: true },
            'sanitary-state-notes': true
          },
          forecasts: true,
          irrigation: {
            '*': true,
            reports: true,
            settings: { thresholds: { '*': { '*': true }, create: true } }
          },
          'crop-tracking': true,
          'water-balance': { '*': true, reports: true, settings: true },
          'water-balance-ri': { '*': true, reports: true, settings: true }
        },
        edit: true,
        create: true,
        archived: true
      },
      stations: {
        '*': {
          '*': true,
          alerts: true,
          history: true,
          options: true,
          summary: true,
          weather: true,
          weephyt: true,
          forecasts: true
        }
      },
      onboarding: {
        '*': true,
        'my-farm': true,
        sensors: { '*': true, add: true, success: true },
        welcome: true,
        networks: { '*': { '*': true }, find: true },
        'members-tips': true
      }
    }
  },
  plots: { '*': { '*': true, history: true, summary: true, forecasts: true } },
  actura: true,
  alerts: { '*': true, create: true },
  export: true,
  discover: { '*': { '*': true } },
  networks: { '*': { '*': true } },
  'accept-pp': true,
  'accept-tos': true,
  'weather-map': {
    '*': { '*': true, alerts: true, history: true, summary: true, weather: true, forecasts: true }
  },
  administration: {
    '*': { '*': true, billing: true },
    organizations: { '*': { devices: { '*': { events: true } } } }
  },
  payment: { failed: true, success: true },
  'push-notification': true,
  'unsubscribe-emails': true
} as const
