import { useIntl } from '@weenat/wintl'
import { RestrictionReason } from 'app/src/hooks/useIsAppAccessRestricted'
import { memo } from 'react'
import useUniverseBackgroundMapContext from '../universes/useUniverseBackgroundMapContext'
import PlotListItemValue, { PlotSummaryValue } from './PlotListItemValue'
import PlotListItemValuesContainer from './PlotListItemValuesContainer'
import { PlotListItemsVariants } from './PlotListItemMetricSummary'

interface SolarPlotListItemValuesProps {
  now: PlotSummaryValue
  max: PlotSummaryValue
  min: PlotSummaryValue
  metric: 'SSI' | 'SSI_MAX' | 'SSI_MIN' | 'PPFD' | 'PPFD_MAX' | 'PPFD_MIN'
  isVirtualDevice: boolean
  isRestricted: boolean
  reason: RestrictionReason
  variant: PlotListItemsVariants
}

const SolarPlotListItemValues: FC<SolarPlotListItemValuesProps> = ({
  now,
  max,
  min,
  metric,
  isVirtualDevice,
  isRestricted: isRestrictedFromProps,
  reason,
  variant
}) => {
  const { t } = useIntl()
  const { universeAggregate } = useUniverseBackgroundMapContext()
  const isRestricted = isRestrictedFromProps && !isVirtualDevice

  return (
    <PlotListItemValuesContainer $isRestricted={isRestricted}>
      <PlotListItemValue
        isMainValue
        value={now}
        name={t(`map.universes.aggregate.real-time`)}
        metric={metric}
        selected={universeAggregate === 'real-time'}
        isRestricted={isRestricted && !isVirtualDevice}
        reason={reason}
        variant={variant}
      />
      <PlotListItemValue
        value={min}
        metric={metric}
        name={t(`map.universes.aggregate.real-time-PPFD_MIN`)}
        selected={
          universeAggregate === `real-time-SSI_MIN` || universeAggregate === `real-time-PPFD_MIN`
        }
        isRestricted={isRestricted && !isVirtualDevice}
        reason={reason}
        variant={variant}
      />
      <PlotListItemValue
        value={max}
        metric={metric}
        name={t(`map.universes.aggregate.real-time-PPFD_MAX`)}
        selected={
          universeAggregate === `real-time-SSI_MAX` || universeAggregate === `real-time-PPFD_MAX`
        }
        isRestricted={isRestricted && !isVirtualDevice}
        reason={reason}
        variant={variant}
      />
    </PlotListItemValuesContainer>
  )
}

export default memo(SolarPlotListItemValues)
