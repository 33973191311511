import { getClosest, makeXScale } from '@weenat/client/dist/core/charts'
import { DataPoint } from '@weenat/client/dist/core/charts/d3-charts.type'
import TimeSteps from '@weenat/client/dist/enums/TimeSteps'
import { isNil } from 'lodash-es'
import { FC, ReactNode, forwardRef, useCallback, useMemo, useState } from 'react'
import Chart, { ChartProps } from './Chart'
import Cursor, { CursorProps } from './Cursor'

const captureDivStyle = { display: 'contents' }

export type CursorChartProps = ChartProps & {
  data: DataPoint[]
  setDate?: (date: Date | undefined) => void
  renderTooltip?: (dp: DataPoint) => ReactNode
  timeStep: TimeSteps
} & Pick<CursorProps, 'tooltipWidth' | 'tooltipHeight'>

const CursorChart: FC<CursorChartProps> = forwardRef<HTMLDivElement, CursorChartProps>(
  (
    {
      children,
      marginBottom = 32,
      marginLeft = 48,
      marginRight = 16,
      marginTop = 16,
      data,
      renderTooltip,
      tooltipHeight,
      tooltipWidth,
      setDate,
      domain,
      domainPadding,
      width: forcedWidth,
      timeStep,
      ...chartProps
    },
    ref
  ) => {
    const [width, setWidth] = useState<number | undefined>(
      typeof forcedWidth === 'number' ? forcedWidth : undefined
    )
    const [cursorPos, setCursorPos] = useState<{ x: number; y: number } | undefined>(undefined)
    const [activeDataPoint, setActiveDataPoint] = useState<DataPoint | undefined>(undefined)

    const xScale = makeXScale({
      domain: domain.x,
      marginLeft,
      width: width ?? 0,
      marginRight,
      paddingStart: domainPadding?.left,
      paddingEnd: domainPadding?.right
    })

    const stampsX = useMemo(() => data?.map((d) => xScale(d.date.getTime())), [data, xScale])

    const setDateHandler = useCallback(
      (index: number | undefined) => {
        if (!isNil(index)) {
          const newDataPoint = data[index]
          setDate?.(newDataPoint.date)
          setActiveDataPoint(newDataPoint)
        } else {
          setDate?.(undefined)
          setActiveDataPoint(undefined)
        }
      },
      [data, setDate]
    )

    const clear = useCallback(() => {
      setDateHandler(undefined)
      setCursorPos(undefined)
    }, [setDateHandler])

    return (
      <div
        onMouseMove={(e) => {
          if (
            e.nativeEvent.offsetX <= (marginLeft ?? 0) ||
            (typeof forcedWidth === 'number' &&
              e.nativeEvent.offsetX >= forcedWidth - marginRight) ||
            e.nativeEvent.offsetY <= (marginTop ?? 0) ||
            e.nativeEvent.offsetY >= chartProps.height - marginBottom
          ) {
            clear()
          } else if (!isNil(stampsX) && !isNil(data)) {
            const closestX = getClosest(stampsX, e.nativeEvent.offsetX)
            const snappedX = xScale(data[closestX.idx].date)

            setDateHandler(closestX.idx)
            setCursorPos({
              x: snappedX,
              y: e.nativeEvent.offsetY
            })
          }
        }}
        onMouseOut={clear}
        onBlur={clear}
        style={captureDivStyle}
        ref={ref}
      >
        <Chart
          marginBottom={marginBottom}
          marginLeft={marginLeft}
          marginRight={marginRight}
          marginTop={marginTop}
          domain={domain}
          domainPadding={domainPadding}
          onResize={({ width: resizedWidth }) => setWidth(resizedWidth)}
          width={forcedWidth}
          {...chartProps}
        >
          {children}
          {cursorPos ? (
            <Cursor
              x={cursorPos.x}
              y={cursorPos.y}
              renderTooltip={renderTooltip ? () => renderTooltip(activeDataPoint) : undefined}
              tooltipHeight={tooltipHeight}
              tooltipWidth={tooltipWidth}
              timeStep={timeStep}
            />
          ) : null}
        </Chart>
      </div>
    )
  }
)

export default CursorChart
