import { useIntl } from '@weenat/wintl'
import LoadingCircle from '../loaders/LoadingCircle'
import Text from '../Text'

interface D3ChartWrapperProps {
  /** height of the cart to avoid layout shifts - defaults to 240 */
  height: number
  isLoading: boolean
  isEmpty: boolean
}

/**
 * Simple component that will wrap charts to show a loading or an empty state
 */
const D3ChartWrapper: FC<D3ChartWrapperProps> = ({
  children,
  isLoading,
  isEmpty,
  height = 240
}) => {
  const { t } = useIntl()

  return isLoading ? (
    <Flex
      $flexDirection='column'
      $backgroundColor='grayscale.100'
      $alignItems='center'
      $justifyContent='center'
      $gap={'md'}
      $height={height}
      $width={'100%'}
    >
      <LoadingCircle />
      <Text $textAlign='center'>{t('status.loading')}</Text>
    </Flex>
  ) : isEmpty ? (
    <Flex
      $flexDirection='column'
      $backgroundColor='grayscale.100'
      $alignItems='center'
      $justifyContent='center'
      $gap={'md'}
      $height={height}
      $width={'100%'}
    >
      <Text $textAlign='center'>{t('charts.no_data')}</Text>
    </Flex>
  ) : (
    children
  )
}

export default D3ChartWrapper
