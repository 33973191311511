import { Head, usePathname } from 'app/routx-router'
import Text from 'app/src/kit/Text'
import { useSlotProvider } from 'app/state'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { ReactNode, useCallback, useLayoutEffect, useState } from 'react'
import { useDeepCompareEffect } from 'react-use'
import { styled } from 'styled-components'
import Breadcrumbs from '../kit/breadcrumbs/Breadcrumbs'
import useBreadcrumbs from '../kit/breadcrumbs/useBreadcrumbs'
import PanelLinkSection, {
  IPanelLinkSection,
  isPanelLinkSectionItemGroup,
  PanelLinkSectionItem,
  renderPanelLinkSectionItem
} from '../kit/panels/PanelLinkSection'
import Scrollable from '../kit/Scrollable'
import TextEllipsisContainer from '../kit/TextEllipsisContainer'
import HeaderActionsArea from './HeaderActionsArea'

const editPlotDevicePathnameRegex =
  /\/administration\/organizations\/\d+\/plots\/\d+\/devices\/update\//
const editPaymentMethodPathnameRegex =
  /\/administration\/organizations\/\d+\/billing\/payment-method\//

const LEFT_PANEL_WIDTH = 400
const MAX_CONTENT_WIDTH = 1080

const LeftPanel = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;

  flex-direction: column;
  height: 100%;
  width: ${LEFT_PANEL_WIDTH}px;
  min-width: ${LEFT_PANEL_WIDTH}px;
  max-width: ${LEFT_PANEL_WIDTH}px;

  background-color: ${(p) => p.theme.colors.grayscale.white};
  border-radius: 0 ${(p) => p.theme.radiuses.xl}px 0 0;
  box-shadow: ${(p) => p.theme.shadows.md.boxShadow};
`

const LeftMenuLinksContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const ContentScroll = styled(Scrollable)`
  padding: ${(p) => p.theme.spacings.lg}px;
`

const ChildrenWrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  flex: 1;
  width: calc(100vw - 16px);
  background-color: ${(p) => p.theme.colors.grayscale[50]};
  border-radius: 0 ${(p) => p.theme.radiuses.xl}px 0 0;
  padding-left: ${LEFT_PANEL_WIDTH}px;
  margin-right: ${(p) => p.theme.spacings.lg}px;
`

const ChildrenContainer = styled(Flex)<{ $useAllWidth: boolean; $useRelativeHeight: boolean }>`
  flex: 1;
  padding: ${(p) => p.theme.spacings.xl}px;
  padding-top: 0;
  ${(p) =>
    !p.$useAllWidth &&
    `
      max-width: ${MAX_CONTENT_WIDTH}px;
    `}
  margin: auto;
  height: 100%;
  ${(p) =>
    p.$useRelativeHeight &&
    `
      height: initial;
    `}
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  margin-bottom: 16px;
`

const HeaderContent = styled(Flex)`
  align-items: center;
  justify-content: space-between;
`

const Header = styled(Box)`
  padding: 8px 32px 16px;
  width: 100%;
  max-width: ${MAX_CONTENT_WIDTH}px;
  margin: auto;
`

interface FocusLayoutProps {
  title: string
  titleTemplate?: string
  defaultTitle?: string
  /** Menu or panel title */
  menuTitle?: string
  /** Menu or panel header */
  menuHeader?: ReactNode
  /** Menu or panel footer */
  menuFooter?: ReactNode
  menuSections: IPanelLinkSection[]
}

/**
 * @deprecated remove with redesign flag
 * Layout with left side panel
 * */
const FocusLayout: FC<FocusLayoutProps> = ({
  children,
  defaultTitle,
  menuFooter = null,
  menuHeader = null,
  menuSections,
  menuTitle = null,
  title,
  titleTemplate
}) => {
  const pathname = usePathname()
  const breadcrumbs = useBreadcrumbs()
  const [, setSlotProvider] = useSlotProvider()

  const [currentLink, setCurrentLink] = useState<
    Pick<PanelLinkSectionItem, 'to' | 'label' | 'description'> | undefined
  >({ to: pathname, label: '', description: undefined })

  const isEditDeviceOnPlot = !isNil(pathname) ? editPlotDevicePathnameRegex.test(pathname) : false
  const isPaymentMethod = !isNil(pathname) ? editPaymentMethodPathnameRegex.test(pathname) : false

  const resetHeaderActions = useCallback(
    () => setSlotProvider((currentSlotProvider) => ({ ...currentSlotProvider, headerActions: [] })),
    [setSlotProvider]
  )

  const onLinkActive = useCallback(
    ({ to, label, description }: Pick<PanelLinkSectionItem, 'to' | 'label' | 'description'>) => {
      resetHeaderActions()
      setCurrentLink({ to, label, description })
    },
    [resetHeaderActions]
  )

  /**
   * Would overwrite the screen set header actions if this was not a useLayoutEffect,
   * prevent stale headerActions from showing if there is no explicit HeaderActions reset rendered in the current screen
   */
  useLayoutEffect(() => {
    resetHeaderActions()
  }, [pathname])

  // update current link if menuLinks has change
  useDeepCompareEffect(() => {
    let newCurrent: PanelLinkSectionItem | undefined

    if (!isEmpty(menuSections)) {
      const allSectionsItems = menuSections?.reduce((acc, s) => {
        s.items.forEach((it) => {
          if (isPanelLinkSectionItemGroup(it)) {
            const linksInGroup = [it.mainLink, ...it.subLinks]
            acc.push(...linksInGroup)
          } else {
            acc.push(it)
          }
        })

        return acc
      }, [] as PanelLinkSectionItem[])

      // Matching the first element of every items
      // Will match on /, no slash, and /id
      newCurrent = allSectionsItems?.find(({ to }) => {
        const stringAsRegex = to?.split('/').join(`\/`)
        const regex = new RegExp(`${stringAsRegex}(\/*\\d*)*$`)

        return !isNil(pathname) ? pathname.match(regex) : false
      })

      if (newCurrent)
        setCurrentLink({
          to: newCurrent.to,
          label: newCurrent.label,
          description: newCurrent.description
        })
    }
  }, [menuSections, pathname])

  return (
    <>
      <Head title={title} titleTemplate={titleTemplate} defaultTitle={defaultTitle} />
      <ChildrenWrapper>
        <LeftPanel>
          {!isNil(menuTitle) ? (
            <Box $p='lg'>
              <Text $fontSize='lg' $fontWeight='medium' $ellipsis>
                {menuTitle}
              </Text>
            </Box>
          ) : null}
          {menuHeader}
          <LeftMenuLinksContainer>
            <ContentScroll scrollToTopOnRouteChange={false}>
              {!isNil(menuSections)
                ? menuSections.map((section) => (
                    <PanelLinkSection key={section.id} title={section.title}>
                      {section.items.map((item) => renderPanelLinkSectionItem(item, onLinkActive))}
                    </PanelLinkSection>
                  ))
                : null}
            </ContentScroll>
          </LeftMenuLinksContainer>
          {menuFooter}
        </LeftPanel>

        {/* Header */}
        <Header>
          <HeaderContent>
            <TextEllipsisContainer>
              <Breadcrumbs crumbs={breadcrumbs} />
            </TextEllipsisContainer>
            <HeaderActionsArea />
          </HeaderContent>
          {!isNil(currentLink) && 'description' in currentLink ? (
            <Text>{currentLink.description}</Text>
          ) : null}
        </Header>

        {/* CONTENT */}
        <ChildrenContainer $useAllWidth={isEditDeviceOnPlot} $useRelativeHeight={isPaymentMethod}>
          {children}
        </ChildrenContainer>
      </ChildrenWrapper>
    </>
  )
}

export default FocusLayout
