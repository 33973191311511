import { useClient, useQuery } from '@weenat/client'
import { Device, GetDeviceQueryParams } from '@weenat/client/dist/resources/devices'
import { useParams } from 'app/routx-router'
import isNil from 'lodash-es/isNil'
import WithClientResourceWrapper from './WithClientResourceWrapper'

interface WithDevice {
  device: Device
}

/**
 * HOC that provides a device (from url params) & request state
 */
function withClientDevice<T extends WithDevice = WithDevice>({
  predicate = () => true,
  compoProps,
  query
}: {
  predicate?: (device?: Device) => boolean
  compoProps?: T
  query?: GetDeviceQueryParams
} = {}) {
  return (WrappedComponent: React.ComponentType<T>) => {
    const WrapperComponent = (wrapperProps: unknown) => {
      const { deviceId } = useParams()
      const client = useClient()

      const deviceRequest = useQuery(client.devices.get(deviceId, query), {
        enabled: !isNil(deviceId)
      })
      const device = deviceRequest.data

      return (
        <WithClientResourceWrapper
          isError={deviceRequest.isError}
          isLoading={deviceRequest.isLoading}
          error={deviceRequest.error}
          isResourceDefined={!isNil(device)}
          predicate={predicate(device)}
          WrappedComponent={<WrappedComponent {...wrapperProps} {...compoProps} device={device} />}
        />
      )
    }
    WrapperComponent.displayName = 'withClientDevice'
    return WrapperComponent
  }
}

export default withClientDevice
