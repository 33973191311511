import { DISCOVER_MENU_SECTIONS, DiscoverMenuSection } from '@weenat/client/dist/core/discover-menu'
import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import DiscoverSectionItem from 'app/src/discover-menu/DiscoverSectionItem'
import appCover from 'app/src/discover-menu/img/app.jpg'
import dssCover from 'app/src/discover-menu/img/dss.jpg'
import sensorsCover from 'app/src/discover-menu/img/sensors.jpg'
import Grid from 'app/src/kit/Grid'
import HeaderActions from 'app/src/layouts/HeaderActions'
import logEvent from 'app/utils/analytics'

const coversPerSections: Record<DiscoverMenuSection, string> = {
  application: appCover,
  dss: dssCover,
  sensors: sensorsCover
}

export default function Discover() {
  const nav = useNavigate()
  const { t } = useIntl()

  return (
    <>
      <HeaderActions />
      <Flex $flex={1}>
        <Grid $templateColumns='1fr 1fr 1fr' $templateRows='1fr' $gap={'lg'} $width={'100%'}>
          {DISCOVER_MENU_SECTIONS.map((section) => {
            return (
              <DiscoverSectionItem
                key={section}
                itemType='section'
                $objectFit='cover'
                cover={coversPerSections[section]}
                overview={t(`discover_menu.sections.${section}.overview`)}
                title={t(`discover_menu.sections.${section}.title`)}
                onPress={() => {
                  logEvent('discover_menu_access', { target: section })
                  nav(`/discover/${section}`)
                }}
              />
            )
          })}
        </Grid>
      </Flex>
    </>
  )
}

export const ErrorBoundary = () => null
