import { useChartCtx } from '@weenat/client/dist/core/charts'
import TimeSteps, { underDayTimeSteps } from '@weenat/client/dist/enums/TimeSteps'
import { WeenatColor, fromColorPathToColor } from '@weenat/theme'
import * as d3 from 'd3'
import { isNil } from 'lodash-es'
import moment from 'moment-timezone'
import { FC, ReactNode } from 'react'

const TEXT_CHARACTER_WIDTH = 8
const PADDING = 4
const spaceBetweenCursorAndTooltip = 8
export const CURSOR_HEIGHT = 20

export interface CursorProps {
  x: number
  y: number
  /** render function that render a tooltip result of this function will be place in a foreign object in svg  */
  renderTooltip?: () => ReactNode
  tooltipWidth?: number
  tooltipHeight?: number
  color?: WeenatColor
  format?: string
  timeStep: TimeSteps
  invalid?: boolean
  invalidMessage?: string
}

const Cursor: FC<CursorProps> = ({
  x,
  y,
  renderTooltip,
  tooltipWidth = 144,
  tooltipHeight = 80,
  format = 'DD/MM/YYYY - HH:mm',
  color = 'grayscale.black',
  timeStep,
  invalid,
  invalidMessage
}) => {
  const { height, marginBottom, xScale, width: chartWidth } = useChartCtx()

  const middle = d3.median(xScale.range())
  const isAfterMiddle = x > (middle ?? 0)

  const date = xScale.invert(x)

  const isUnderDay = underDayTimeSteps.includes(timeStep)

  const formattedDate = moment(date).format(isUnderDay ? format : format.replace(' - HH:mm', ''))
  const letters = invalid ? invalidMessage?.length ?? format.length : format.length
  const rectWidth = letters * TEXT_CHARACTER_WIDTH + PADDING * 2
  const halfWidth = rectWidth / 2

  const outsideOnRight = x + halfWidth > chartWidth
  const outsideOnLeft = x - halfWidth < 0

  const rectPos = outsideOnLeft ? 0 : outsideOnRight ? -rectWidth : -halfWidth

  const rectTransform = `translate(${rectPos}, 0)`

  return (
    <g transform={`translate(${x}, 0)`}>
      <line
        strokeWidth={1}
        stroke={fromColorPathToColor(color)}
        x1={0}
        x2={0}
        y1={height - marginBottom}
        y2={6}
        strokeLinecap='round'
      />

      {!isNil(renderTooltip) ? (
        <foreignObject
          x={
            isAfterMiddle
              ? -tooltipWidth - spaceBetweenCursorAndTooltip
              : spaceBetweenCursorAndTooltip
          }
          y={y}
          width={tooltipWidth}
          height={tooltipHeight}
        >
          {renderTooltip()}
        </foreignObject>
      ) : (invalid && !isNil(invalidMessage)) || !invalid ? (
        <g transform={rectTransform}>
          <rect
            y={4}
            rx={4}
            ry={4}
            height={CURSOR_HEIGHT}
            width={rectWidth}
            color={fromColorPathToColor(color)}
          />
          <text x={halfWidth} y={18} fontVariant='tabular-nums' textAnchor='middle' fill='white'>
            {invalid ? invalidMessage : formattedDate}
          </text>
        </g>
      ) : null}
    </g>
  )
}

export default Cursor
