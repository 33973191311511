import { Universe } from '@weenat/client/dist/core/universes'
import { DeviceSummary } from '@weenat/client/dist/resources/measurements.type'
import { useIntl } from '@weenat/wintl'
import Icons from 'app/src/kit/Icons'
import { TextFieldPrimitive } from 'app/src/kit/fields/TextField'
import MarkerLegend from 'app/src/map/MarkerLegend'
import MyFarmTools from 'app/src/myFarm/tools/MyFarmTools'
import { useToggleFeature } from 'app/state'
import isNil from 'lodash-es/isNil'
import noop from 'lodash-es/noop'
import { useCallback, useEffect, useRef } from 'react'
import { styled } from 'styled-components'
import Geolocation from '../../../map/Geolocation'
import ControlsContainer from './ControlsContainer'
import DashboardMapLoading from './DashboardMapLoading'
import MapControlsOverlay from './MapControlsOverlay'
import MapOverlay from './MapOverlay'
import Radar from './Radar/Radar'
import useIsRadarEnabled from './Radar/hooks/useIsRadarEnabled'
import RefreshBackgroundMap from './RefreshBackgroundMap'
import ZoomButtons from './ZoomButtons'
import { elementIds } from './constants'
import {
  useBackgroundMapContext,
  useBackgroundMapDispatcher
} from './contexts/BackgroundMapContext'
import NewActionsControls from './universes/NewActionsControls'
import UniverseChipsTabs from './universes/UniverseChipsTabs'

const PlacesInput = styled(TextFieldPrimitive)`
  position: relative;
  z-index: 1;

  min-width: 256px;
  max-width: 480px;

  margin: 0px;
  pointer-events: auto;
`

const EMPTY_SUMMARIES: DeviceSummary[] = []

interface WeatherMapControlsProps {
  summaries: DeviceSummary[]
  filteredSummaries?: DeviceSummary[]
}

const WeatherMapControls: FC<WeatherMapControlsProps> = ({ summaries, filteredSummaries }) => {
  const { t } = useIntl()
  const [isRadarEnabled] = useIsRadarEnabled()
  const [{ redesign }] = useToggleFeature()

  const {
    api: { map, maps }
  } = useBackgroundMapContext()
  const dispatch = useBackgroundMapDispatcher()

  const autocompleteInputRef = useRef(null)

  const onGeolocationSuccess = useCallback<PositionCallback>(
    ({ coords: { latitude, longitude } }) => {
      dispatch({
        type: 'setGeolocation',
        newGeolocation: {
          lat: latitude,
          lng: longitude
        }
      })
    },
    [dispatch]
  )

  useEffect(() => {
    if (!isNil(map) && !isNil(maps) && !isNil(autocompleteInputRef.current)) {
      new maps.places.PlacesService(map)

      const googlePlacesAutocompleteInput = new maps.places.Autocomplete(
        autocompleteInputRef.current
      )

      const placeChangeListener = maps.event.addListener(
        googlePlacesAutocompleteInput,
        'place_changed',
        () => {
          const { geometry: { location, viewport } = {} } = googlePlacesAutocompleteInput.getPlace()

          if (!isNil(location) && !isNil(viewport)) {
            dispatch({
              type: 'setTownGeolocation',
              newGeolocation: {
                location: {
                  lat: location.lat(),
                  lng: location.lng()
                },
                bounds: viewport
              }
            })
          }
        }
      )

      return () => maps.event.removeListener(placeChangeListener)
    }
    return undefined
  }, [map])

  return (
    <MapControlsOverlay $redesign={redesign}>
      {!isRadarEnabled ? (
        <Flex $flex={1} $flexDirection='column' $justifyContent='space-between'>
          {/* TOP */}
          {!redesign ? (
            <Flex $alignItems='flex-start' $justifyContent='space-between' $gap='md'>
              <Flex $alignItems='center' $gap='md' $flex={1}>
                <UniverseChipsTabs omit={[Universe.overview]} />

                <PlacesInput
                  onChange={noop}
                  hideErrors
                  id='googlePlacesAutocompleteInput'
                  type='text'
                  ref={autocompleteInputRef}
                  placeholder={t('actions.search_city')}
                  leftAdornment={<Icons.TravelExplore $size='md' />}
                />
              </Flex>
            </Flex>
          ) : (
            <MyFarmTools summaries={summaries} filteredResults={filteredSummaries} />
          )}

          {/* FILL SPACE */}
          <Flex $flexDirection='column' $flex={1} $width='100%' $gap='lg'>
            <Flex
              $flexDirection='column'
              $gap='lg'
              $justifyContent='center'
              $alignItems='center'
              $alignSelf='flex-end'
              $flex={1}
              $mt={80}
            >
              <Flex $flexDirection='column' $gap='md'>
                <ControlsContainer $flexDirection='column-reverse' $py='sm' $gap='sm'>
                  <ZoomButtons />
                </ControlsContainer>

                <ControlsContainer>
                  <Geolocation onSuccess={onGeolocationSuccess} tooltipPlacement='left' />
                </ControlsContainer>

                <ControlsContainer>
                  <RefreshBackgroundMap tooltipPlacement='left' $size='xl' $m='sm' />
                </ControlsContainer>

                <Radar map={map} maps={maps} />

                {redesign && (
                  <ControlsContainer>
                    <MarkerLegend tooltipPlacement='left' />
                  </ControlsContainer>
                )}
              </Flex>
            </Flex>
            {!redesign ? <NewActionsControls summaries={summaries} /> : null}
          </Flex>
        </Flex>
      ) : (
        <Radar map={map} maps={maps} />
      )}
    </MapControlsOverlay>
  )
}

type WeatherMapOverlayProps = {
  isLoading: boolean
  summaries: DeviceSummary[]
  filteredSummaries?: DeviceSummary[]
}

const WeatherMapOverlay: FC<WeatherMapOverlayProps> = ({
  children,
  isLoading,
  summaries,
  filteredSummaries
}) => {
  const [{ redesign }] = useToggleFeature()
  const {
    api: { map, maps }
  } = useBackgroundMapContext()

  const displayControls = !isNil(map) && !isNil(maps)

  return (
    <MapOverlay id={elementIds.OverlayContainer} $flexDirection={redesign ? 'column' : 'row'}>
      {isLoading ? (
        <DashboardMapLoading />
      ) : (
        <>
          {displayControls ? (
            <WeatherMapControls
              // all summaries not only current
              summaries={summaries}
              filteredSummaries={filteredSummaries}
            />
          ) : null}
          {children}
        </>
      )}
    </MapOverlay>
  )
}

export default WeatherMapOverlay
