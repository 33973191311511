import { useMatch } from 'app/routx-router'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { styled, useTheme } from 'styled-components'
import CountIndicator from './CountIndicator'
import { IconProps } from './Icon'
import Link, { LinkProps } from './LinkComponent'

const Container = styled(Flex)<{ $isActive: boolean }>`
  align-items: center;
  justify-content: space-between;

  padding: ${(p) => `${p.theme.spacings.md}px`};
  margin: ${(p) => `${p.theme.spacings.xs}px 0px`};

  max-height: 32px;

  border-radius: ${(p) => `${p.theme.radiuses.md}px`};
  background-color: ${({ $isActive: isActive, theme }) =>
    isActive ? theme.colors.primary[200] : 'transparent'};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary[200]};
  }
`

export interface SidePanelLinkProps extends Omit<LinkProps, 'active'> {
  LeftIcon?: FC<IconProps> | null
  iconProps?: IconProps
  label: string
  matchExactPath?: boolean
  description?: string
  indicator?: string | number
}

const SidePanelLink = ({
  LeftIcon = null,
  iconProps,
  label,
  onActive,
  to,
  matchExactPath = false,
  indicator,
  ...linkProps
}: SidePanelLinkProps) => {
  const { colors } = useTheme()

  const defaultIconProps: Partial<IconProps> = {
    $size: 'lg'
  }

  const matchPattern = `${to}${matchExactPath ? '' : '/*'}`
  // @ts-expect-error This work because of call site but is ambiguous
  const newActive = useMatch(matchPattern)
  const isActive = !isNil(newActive)

  const iconColor = isActive
    ? 'primary.500'
    : !isNil(iconProps?.$color)
      ? iconProps?.$color
      : 'grayscale.black'

  return (
    <Link active={isActive} onActive={onActive} to={to} {...linkProps}>
      <Container $isActive={isActive}>
        <Flex $alignItems='center' $gap='md' $flex={1}>
          {!isNil(LeftIcon) ? (
            <LeftIcon {...defaultIconProps} {...iconProps} $color={iconColor} />
          ) : null}
          <Box $flex={1}>
            <Text $color={isActive ? 'primary.500' : undefined}>{label}</Text>
          </Box>
          {!isNil(indicator) ? (
            <CountIndicator
              backgroundColor='primary.500'
              color={colors.grayscale.white}
              count={indicator}
            />
          ) : null}
        </Flex>
      </Container>
    </Link>
  )
}

export default SidePanelLink
