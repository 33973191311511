import { canUser, ops } from '@weenat/client/dist/core/access'
import useVirtualDevice from '@weenat/client/dist/core/devices/useVirtualDevice'
import { BillingStatus, PlanName } from '@weenat/client/dist/enums'
import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import { useNavigate } from 'app/routx-router'
import useDisclosure from 'app/src/hooks/useDisclosure'
import Icons from 'app/src/kit/Icons'
import Modal from 'app/src/kit/Modal'
import Text from 'app/src/kit/Text'
import PanelSectionTitle from 'app/src/kit/panels/PanelSectionTitle'
import { logAddModal } from 'app/utils/analytics'
import { isNil } from 'lodash-es'
import { memo } from 'react'
import AddModalItem from './AddModalItem'

const AvailableWithSubscriptionText = ({ plan }: { plan: PlanName | null }) => {
  const { t } = useIntl()

  return plan === 'essential' ? (
    <Text $fontSize='sm'>{t('ecommerce.plans.restrictions.only_premium_users')}</Text>
  ) : null
}

/**
 * Use absolute paths inside this component since it is rendered at many paths
 */
const AddModal = () => {
  const { t } = useIntl()
  const nav = useNavigate()
  const org = useOrgContext()

  const { isOpen: isAddModalOpen, close, open } = useDisclosure()

  const closeAddModal = () => {
    logAddModal({ action: 'close' })
    close()
  }

  const openAddModal = () => {
    logAddModal({ action: 'open' })
    open()
  }

  // Getting the plan & remaining virtual devices
  // Essential users cannot create more plots than they have virtual devices
  const plan = BillingStatus[org.billingStatus] as PlanName

  const { remainingVirtualDeviceActivations } = useVirtualDevice({ orgId: org.id })

  const canEditNetwork = canUser(ops.edit, 'networks', { organization: org })
  const canViewOptions = canUser(ops.view, 'options', { organization: org })
  const administrationPath = `/farms/${org.id}/admin` as const

  return (
    <>
      <Icons.PlusSignCircle
        $size='lg'
        $p={'md'}
        $color={'grayscale.white'}
        $backgroundColor={'primary.500'}
        onPress={openAddModal}
      />
      <Modal
        title={t('addModelModal.title')}
        isOpen={isAddModalOpen}
        close={closeAddModal}
        width={640}
        minHeight={320}
        contentContainerProps={{ style: { padding: 8 } }}
      >
        {/* Org related actions */}
        <Flex $p={'md'} $flexDirection='column' $gap='md'>
          <PanelSectionTitle>{org.name}</PanelSectionTitle>

          <AddModalItem
            Icon={Icons.SingleSensorFilled}
            onPress={() => {
              close()
              nav(`${administrationPath}/devices/add`)
            }}
            event='add_device'
          >
            {t('addModelModal.device', { capitalize: true })}
          </AddModalItem>

          <AddModalItem
            Icon={Icons.PlotsFilled}
            onPress={() => {
              close()
              nav(`/farms/${org.id}/plots/create`)
            }}
            event='create_plot'
            isDisabled={
              !isNil(remainingVirtualDeviceActivations) &&
              remainingVirtualDeviceActivations <= 0 &&
              org.billingStatus === BillingStatus.essential
            }
          >
            <Text $fontWeight='medium'>{t('addModelModal.plot', { capitalize: true })}</Text>
            {plan === 'essential' ? (
              <Text $fontSize='sm'>
                {t('models.plot.actions.virtual_device_remaining_activations', {
                  value: remainingVirtualDeviceActivations as number
                })}
              </Text>
            ) : null}
          </AddModalItem>

          {canViewOptions && (
            <AddModalItem
              Icon={Icons.AddOption}
              onPress={() => {
                close()
                nav(`${administrationPath}/options`)
              }}
              event='add_option'
            >
              {t('addModelModal.option', { capitalize: true })}
            </AddModalItem>
          )}

          <AddModalItem
            Icon={Icons.Person}
            onPress={() => {
              close()
              nav(`${administrationPath}/members/invite`)
            }}
            event='invite_member'
          >
            <Text $fontWeight='medium'>{t('addModelModal.member', { capitalize: true })} </Text>
            <AvailableWithSubscriptionText plan={plan} />
          </AddModalItem>
        </Flex>

        {/*  Network related actions */}
        {canEditNetwork ? (
          <Flex $p={'md'} $flexDirection='column' $gap='md'>
            <PanelSectionTitle>
              {t('models.network.model.singular_name', { capitalize: true })}
            </PanelSectionTitle>

            <AddModalItem
              Icon={Icons.GroupPersons}
              onPress={() => {
                close()
                nav(`${administrationPath}/network/invite`)
              }}
              event='invite_network_member'
            >
              {t('addModelModal.networkOrganization', { capitalize: true })}
            </AddModalItem>
          </Flex>
        ) : null}

        {/* Account related actions */}
        <Flex $p={'md'} $flexDirection='column' $gap='md'>
          <PanelSectionTitle>{t('titles.my_account', { capitalize: true })}</PanelSectionTitle>
          <AddModalItem
            Icon={Icons.Campaign}
            onPress={() => {
              close()
              nav('/alerts/create')
            }}
            event='create_alert'
          >
            <Text $fontWeight='medium'>{t('addModelModal.alert', { capitalize: true })} </Text>
            <AvailableWithSubscriptionText plan={plan} />
          </AddModalItem>
        </Flex>
      </Modal>
    </>
  )
}

export default memo(AddModal)
