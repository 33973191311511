import type { SVGProps } from 'react'
const SvgDiscreteDeviceMarker = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={36} height={36} {...props}>
    <rect
      width={34}
      height={34}
      x={1}
      y={1}
      stroke={props.color ?? '#fff'}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      rx={9.067}
      ry={9.067}
      style={{
        paintOrder: 'fill markers stroke'
      }}
    />
    <path
      fill='#fff'
      d='M18 8a1 1 0 0 0-1 1v1h-3a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h3v5a1 1 0 1 0 2 0v-5h3a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2h-3V9a1 1 0 0 0-1-1zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6z'
      color='#000'
      style={{
        inlineSize: 0,
        isolation: 'auto',
        mixBlendMode: 'normal',
        shapeMargin: 0,
        whiteSpace: 'normal'
      }}
    />
  </svg>
)
export default SvgDiscreteDeviceMarker
