import { useIntl } from '@weenat/wintl'
import Icons from 'app/src/kit/Icons'
import Text from 'app/src/kit/Text'
import React, { ReactElement, useCallback } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import {
  PlotSummary,
  Summary,
  isDeviceSummary,
  isPlotSummary,
  isStationSummary
} from '@weenat/client/dist/resources/measurements.type'
import Button from 'app/src/kit/Button'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'

import { getDeviceCommonName } from '@weenat/client/dist/core/devices'
import { UniverseMetric } from '@weenat/client/dist/core/universes'
import { useOrgContext } from 'app/orgProvider'
import useDisclosure from 'app/src/hooks/useDisclosure'
import PanelSectionTitle from 'app/src/kit/panels/PanelSectionTitle'
import { usePastSearches, useRecentlyOpenedElements } from 'app/state'
import { isEmpty, isNil } from 'lodash-es'
import { PreviousSearchElt } from './PreviousSearchElement'
import { SearchResult } from './SearchResult'

const ABSOLUTE_POSITIONING = { top: 0, left: 0 }

export const SearchOverlay: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <>
      {createPortal(
        <Box
          $position='fixed'
          $width='100%'
          $height='100%'
          $zIndex={0}
          style={ABSOLUTE_POSITIONING}
          onClick={onClick}
        />,
        document.body
      )}
      <Box
        $position='absolute'
        $width='100%'
        $height='100%'
        style={ABSOLUTE_POSITIONING}
        onClick={onClick}
        $pointerEvents='auto'
      />
    </>
  )
}

const SearchContainer = styled(Box)`
  position: absolute;
  z-index: 1;
  background-color: ${(p) => p.theme.colors.grayscale.white};
  border: 1px solid ${(p) => p.theme.colors.grayscale[100]};
  border-top: 0;
  border-radius: ${(p) => p.theme.radiuses.md}px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
`

const StyledFlex = styled(Flex)`
  border-radius: ${(p) => p.theme.radiuses.md}px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid ${(p) => p.theme.colors.grayscale[100]};
  padding: ${(p) => p.theme.spacings.md}px;
  align-items: center;
  z-index: 2;
  position: relative;
`

const StyledInput = styled.input`
  flex: 1;
  height: '100%';
  appearance: 'none';
  outline: 'none';
  box-shadow: 'none';
  border: 0;
  font-family: 'Inter';
`

export interface SearchProps {
  value: string | undefined
  onSearch: (newVal: string | undefined) => void
  width?: number
  displaySettingsElement?: ReactElement
  metricSelector: ReactElement
  universeSelector: ReactElement | null
  enabledMetrics: UniverseMetric[]
  filteredResults: Summary[] | undefined
}

const Search: React.FC<SearchProps> = ({
  value,
  onSearch,
  width,
  displaySettingsElement,
  metricSelector,
  universeSelector,
  filteredResults
}) => {
  const { t } = useIntl()
  const searchModal = useDisclosure()
  const org = useOrgContext()
  const currentOrgId = org.id

  const [pastSearches, setPastSearches] = usePastSearches()
  const handleNewSearch = useCallback(() => {
    setPastSearches((currentPastSearches) => {
      if (!isNil(value)) {
        return Array.from(new Set([value, ...currentPastSearches])).slice(0, 3)
      } else {
        return currentPastSearches
      }
    })
  }, [value, setPastSearches])

  const [recentlyOpenedElements] = useRecentlyOpenedElements()

  const searchSettings = (
    <Flex $alignItems='center' $justifyContent='space-between' $p='md'>
      <Flex $alignItems='center' $gap='md'>
        {universeSelector}
        {!searchModal.isOpen ? metricSelector : null}
      </Flex>

      {displaySettingsElement}
    </Flex>
  )

  return (
    <>
      <StyledFlex>
        <Icons.Search $size='xl' />
        <StyledInput
          placeholder={t('actions.search')}
          value={value ?? ''}
          onChange={(v) => onSearch(v.target.value)}
          onClick={searchModal.open}
        />
        <Text>
          {t('filtering.matching_elements', { numberOfMatches: filteredResults?.length ?? 0 })}
        </Text>
      </StyledFlex>
      {searchModal.isOpen && (
        <>
          <SearchContainer $width={width ?? '100%'}>
            {searchSettings}
            {isEmpty(value) ? (
              <DelimitedFlex $isDelimitedOnTop $flexDirection='column' $gap='xl' $p='lg'>
                <Box>
                  <PanelSectionTitle>{t('filtering.previous_searches')}</PanelSectionTitle>
                  <Box $pt='md'>
                    {!isEmpty(pastSearches) ? (
                      pastSearches.map((pastSearch) => (
                        <PreviousSearchElt key={pastSearch} value={pastSearch} onPress={onSearch} />
                      ))
                    ) : (
                      <Flex $p='xl' $justifyContent='center'>
                        <Text $fontWeight='bold'> {t('filtering.previous_searches_no_data')}</Text>
                      </Flex>
                    )}
                  </Box>
                </Box>

                <Box>
                  <PanelSectionTitle>{t('filtering.previously_opened')}</PanelSectionTitle>
                  <Box $pt='md'>
                    {!isEmpty(recentlyOpenedElements) ? (
                      recentlyOpenedElements.map((recentlyOpenedStationOrPlot) => (
                        <SearchResult
                          key={recentlyOpenedStationOrPlot.id}
                          {...recentlyOpenedStationOrPlot}
                        />
                      ))
                    ) : (
                      <Flex $p='xl' $justifyContent='center'>
                        <Text $fontWeight='bold'> {t('filtering.previously_opened_no_data')}</Text>
                      </Flex>
                    )}
                  </Box>
                </Box>
              </DelimitedFlex>
            ) : !isEmpty(value) ? (
              <DelimitedFlex $isDelimitedOnTop $flexDirection='column'>
                <Flex $flexDirection='column' $gap='xl' $p='lg'>
                  <Box>
                    <Text $fontWeight='bold' $fontSize='sm' $color='grayscale.500'>
                      {t('filtering.results')}
                    </Text>
                    {!isNil(filteredResults) && !isEmpty(filteredResults) ? (
                      <Box $pt='md' $overflowY='auto' $maxHeight='70vh'>
                        {filteredResults.map((summary) => {
                          const isAPlotSummary = isPlotSummary(summary)
                          const elt: Pick<
                            PlotSummary['metadata']['plot'],
                            'id' | 'reverseGeocoding' | 'name'
                          > & { isFavorite: boolean | undefined } = isAPlotSummary
                            ? summary.metadata.plot
                            : isStationSummary(summary)
                              ? summary.metadata.station
                              : {
                                  id: summary.metadata.device.id,
                                  isFavorite: undefined,
                                  name: getDeviceCommonName(summary.metadata.device),
                                  reverseGeocoding: summary.metadata.device.reverseGeocoding
                                }
                          return !isNil(elt) ? (
                            <SearchResult
                              key={`${isPlotSummary(summary) ? 'plot' : isStationSummary(summary) ? 'station' : 'device'}_${elt.id}`}
                              id={elt.id}
                              isFavorite={elt.isFavorite}
                              name={elt.name}
                              organizationId={currentOrgId}
                              technicalName={
                                isDeviceSummary(summary)
                                  ? summary.metadata.device.endUserName.join(' ')
                                  : undefined
                              }
                              reverseGeocoding={elt.reverseGeocoding}
                              kind={
                                isPlotSummary(summary)
                                  ? 'plot'
                                  : isStationSummary(summary)
                                    ? 'station'
                                    : 'device'
                              }
                              onPress={handleNewSearch}
                            />
                          ) : null
                        })}
                      </Box>
                    ) : (
                      <Flex
                        $flexDirection='column'
                        $p='xl'
                        $justifyContent='center'
                        $alignItems='center'
                        $gap='md'
                      >
                        <Text $fontWeight='bold'>{t('filtering.no_search_results')}</Text>
                        <Text>
                          {t(
                            'filtering.empty_explanation',
                            { searchTerm: value ?? '' },
                            { capitalize: true }
                          )}
                        </Text>
                      </Flex>
                    )}
                  </Box>
                </Flex>
                <DelimitedFlex $justifyContent='flex-end' $isDelimitedOnTop $px='lg' $py={'md'}>
                  <Button
                    onPress={() => {
                      searchModal.close()
                      handleNewSearch()
                    }}
                    variant='small'
                  >
                    {t('filtering.show_results')}
                  </Button>
                </DelimitedFlex>
              </DelimitedFlex>
            ) : null}
          </SearchContainer>
        </>
      )}
      {searchModal.isOpen && <SearchOverlay onClick={searchModal.close} />}
      {searchSettings}
    </>
  )
}

export default Search
