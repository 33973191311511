import { Me } from '@weenat/client/dist/resources/me'
import useDisclosure from 'app/src/hooks/useDisclosure'
import Avatar from 'app/src/kit/Avatar'
import { Dropdown, DropdownTrigger } from 'app/src/kit/fields/Select/SelectDropdown'
import MyAccountDropdown from 'app/src/me/MyAccountDropdown'
import { isNil } from 'lodash-es'

interface AccountAvatarProps {
  me: Me | null
  pendingInvitationCount: number | undefined
}

/** An interactive avatar with an account dropdown */
const AccountAvatar: FC<AccountAvatarProps> = ({ me, pendingInvitationCount = 0 }) => {
  const { isOpen: isMyAccountDropdownOpen, toggle: toggleMyAccountDropdown } = useDisclosure(false)

  return (
    <Dropdown isVisible={isMyAccountDropdownOpen} onVisibleChange={toggleMyAccountDropdown}>
      {!isNil(me) && (
        <>
          <DropdownTrigger>
            <Avatar me={me} onPress={toggleMyAccountDropdown} className='navbarAvatar' />
          </DropdownTrigger>
          <MyAccountDropdown pendingInvitationCount={pendingInvitationCount} me={me} />
        </>
      )}
    </Dropdown>
  )
}

export default AccountAvatar
