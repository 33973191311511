import { useClient, useQuery } from '@weenat/client'
import { useIsPartOfANetwork } from '@weenat/client/dist/core/orgs'
import { InvitationStatus } from '@weenat/client/dist/enums'
import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import { useMatch } from 'app/routx-router'
import FakeAlertScreenShot from 'app/src/alerts/svg/FakeAlertScreenShot'
import FakeIrrigationScreenShot from 'app/src/alerts/svg/FakeIrrigationScreenShot'
import Text from 'app/src/kit/Text'
import { useToken } from 'app/state'
import { useUserContext } from 'app/userProvider'
import isNil from 'lodash-es/isNil'
import { memo, useCallback } from 'react'
import { styled } from 'styled-components'
import logEvent from '../../utils/analytics'
import AccountAvatar from '../dashboard/components/common/AccountAvatar'
import AccountNotifications from '../dashboard/components/common/AccountNotifications'
import Link from '../kit/LinkComponent'
import Pop from '../kit/Pop'
import { LogoSphere } from '../kit/icons/logos'
import FakeScreenShotHolder from '../tours/FakeScreenShotHolder'
import { FindOutMorePop, MyFarmFirstStepPop, MyFarmOutroStepPop } from '../tours/pops'

const NavBarContainer = styled(Flex)`
  /* So that it is displayed above the map */
  z-index: 4;
  position: relative;
  align-items: center;
  background-color: ${(p) => p.theme.colors.grayscale[50]};
  border-radius: ${(p) => p.theme.radiuses.rounded}px;
  border: 1px solid ${(p) => p.theme.colors.grayscale[300]};
  box-shadow: ${(p) => p.theme.shadows.md.boxShadow};
  width: 100%;
`

const WeenatContainer = styled(Flex)`
  align-items: center;
  margin: 8px;
`

const LinksContainer = styled(Flex)`
  position: relative;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding: 0 ${(p) => p.theme.spacings.md}px;
  border-left: 1px solid ${(p) => p.theme.colors.grayscale[100]};
  border-right: 1px solid ${(p) => p.theme.colors.grayscale[100]};
`

const TextAsLink = styled(Text)<{ $isActive?: boolean; $backgroundColor?: string }>`
  white-space: nowrap;
  line-height: ${(p) => p.theme.typography.lineHeights.sm};
  padding: ${(p) => `${p.theme.spacings.sm}px ${p.theme.spacings.lg}px`};
  margin: 0;
  border: 1px solid transparent;
  border-radius: ${(p) => p.theme.radiuses.rounded}px;

  &:hover {
    border-color: ${(p) => p.theme.colors.grayscale[100]};
  }

  ${(p) =>
    p.$isActive &&
    `
      color: ${p.theme.colors.primary[500]};
      border-color: ${p.theme.colors.primary[500]};
      &:hover {
        border-color: ${p.theme.colors.primary[500]};
      }
    `}
`

const DiscoverLink = styled(TextAsLink)<{ $isActive?: boolean }>`
  background-color: ${(p) => p.theme.colors.plans.expert[500]};
  color: ${(p) => p.theme.colors.grayscale.white};

  ${(p) =>
    p.$isActive &&
    `
      color: ${p.theme.colors.grayscale.white};
      border-color: ${p.theme.colors.primary[800]};
      &:hover {
        border-color: ${p.theme.colors.primary[500]};
      }
    `}
`

/** @deprecated remove with redesign flag */
const NavBar: FC = memo(() => {
  const client = useClient()
  const { t } = useIntl()
  const [token] = useToken()
  const { me } = useUserContext()
  const org = useOrgContext()

  const isAuthenticated = !isNil(token)

  const { isPartOfANetwork } = useIsPartOfANetwork(token)

  // This request is only to get the total number of invitations
  // Menu is rendered on all screens, even public ones,
  // we do not want to get a 401 when not logged in
  const invitationsRequest = useQuery(
    client.invitations.received.getMany({ limit: 1, status: InvitationStatus.pending }),
    {
      enabled: isAuthenticated
    }
  )

  const receivedInvitations = invitationsRequest.data

  const handleAlertClick = useCallback(() => {
    logEvent('menu_go_to_alert')
  }, [])

  const weatherMapPathMatch = useMatch('/weather-map/*')
  const alertsPathMatch = useMatch('/alerts/*')
  const adminPathMatch = useMatch('/farms/*/admin/*')
  const allFarmsPathsMatch = useMatch('/farms/*')
  const myFarmPathsMatch = !isNil(adminPathMatch) ? null : allFarmsPathsMatch
  const networkPathMatch = useMatch('/networks/*')
  const helpPathMatch = useMatch('/help')

  return (
    <Box $width='100%' $p='lg'>
      <NavBarContainer>
        <Link to='/'>
          <WeenatContainer>
            <LogoSphere width={32} height={32} />
          </WeenatContainer>
        </Link>
        <LinksContainer>
          <Flex $gap='md'>
            <FindOutMorePop
              link={'https://weenat.com/situation-hydrique-france/'}
              tourName='discover_irrigation'
            >
              <Link to={`/farms/${org.id}`}>
                <MyFarmFirstStepPop>
                  <Pop
                    headerContent={<FakeIrrigationScreenShot />}
                    tourName='discover_irrigation'
                    stepName='discover'
                  >
                    <Pop
                      headerContent={
                        <FakeScreenShotHolder>
                          <FakeIrrigationScreenShot />
                        </FakeScreenShotHolder>
                      }
                      tourName='discover_universes'
                      stepName='discover'
                    >
                      <Pop tourName='discover_universes' stepName='new_map_nav'>
                        <TextAsLink $isActive={!isNil(myFarmPathsMatch)}>
                          {t('titles.my_farm', { capitalize: true })}
                        </TextAsLink>
                      </Pop>
                    </Pop>
                  </Pop>
                </MyFarmFirstStepPop>
              </Link>
            </FindOutMorePop>
            <Link to='/weather-map'>
              <FindOutMorePop
                link={
                  'https://weenat.com/nouveautes-application/?utm_source=application&utm_medium=tour-app&utm_campaign=nouveautes-application-fevrier2023'
                }
                tourName='discover_universes'
              >
                <TextAsLink $isActive={!isNil(weatherMapPathMatch)}>
                  {t('titles.devices_map')}
                </TextAsLink>
              </FindOutMorePop>
            </Link>
            <Link to='/alerts' onClick={handleAlertClick} id={'alerts-menu-item'}>
              <Pop
                headerContent={
                  <FakeScreenShotHolder>
                    <FakeAlertScreenShot />
                  </FakeScreenShotHolder>
                }
                tourName='discover_alerts'
                stepName='discover'
              >
                <TextAsLink $isActive={!isNil(alertsPathMatch)}>
                  {t('models.alert.model.name', { capitalize: true })}
                </TextAsLink>
              </Pop>
            </Link>
            <Link to={`/farms/${org.id}/admin`}>
              <MyFarmOutroStepPop>
                <TextAsLink $isActive={!isNil(adminPathMatch)}>{t('titles.admin')}</TextAsLink>
              </MyFarmOutroStepPop>
            </Link>
            {isPartOfANetwork ? null : (
              <Link to='/networks'>
                <TextAsLink $isActive={!isNil(networkPathMatch)}>
                  {t('models.network.model.name', { capitalize: true })}
                </TextAsLink>
              </Link>
            )}
            <Link to='/help'>
              <TextAsLink $isActive={!isNil(helpPathMatch)}>{t('titles.help')}</TextAsLink>
            </Link>
            <Link to='/discover'>
              <DiscoverLink>{t('actions.discover')}</DiscoverLink>
            </Link>
          </Flex>
        </LinksContainer>

        <Flex $alignItems='center' $m={8} $gap='md'>
          <Box>
            <AccountNotifications />
          </Box>

          <Box>
            <AccountAvatar me={me} pendingInvitationCount={receivedInvitations?.count} />
          </Box>
        </Flex>
      </NavBarContainer>
    </Box>
  )
})

export default NavBar
