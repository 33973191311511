import { MeasurementConfig } from '@weenat/client/dist/resources/measurementConfigs'
import { useIntl } from '@weenat/wintl'
import isNil from 'lodash-es/isNil'
import { useTheme } from 'styled-components'
import Icons from './Icons'
import SmartText from './SmartText'
import Text from './Text'
import { Tooltip, TooltipContent, TooltipTrigger } from './tooltips/Tooltip'

const TOO_FAR_METERS_THRESHOLD = 8000

const DeviceDistanceFromPlot = ({
  measurementConfig
}: {
  measurementConfig: MeasurementConfig
}) => {
  const { t, formatNumber } = useIntl()
  const { colors } = useTheme()

  if (isNil(measurementConfig.distance)) return null

  const isTooFar = measurementConfig.distance >= TOO_FAR_METERS_THRESHOLD
  const color = isTooFar ? colors.feedback.error['500'] : colors.grayscale['700']
  const distanceMsg = `${formatNumber(measurementConfig.distance / 1000)} ${t(
    'units.kilometers_abbr'
  )}`

  const contentStyle = {
    backgroundColor: colors.feedback.error['500'],
    borderBlockColor: colors.feedback.error['800'],
    maxWidth: 320
  }

  return (
    <Tooltip disabled={!isTooFar}>
      <TooltipTrigger>
        <Flex $alignItems='center'>
          <Icons.Distance $color={color} $size='rg' />
          <Text $fontSize='sm' $color={color} unselectable='on'>
            {distanceMsg}
          </Text>
        </Flex>
      </TooltipTrigger>
      <TooltipContent style={contentStyle}>
        <Text $fontWeight='bold' $color={'grayscale.white'}>
          {t('models.device.too_far_warning_title')}
        </Text>
        <SmartText
          tkey='models.device.too_far_warning'
          vars={{ km_threshold: TOO_FAR_METERS_THRESHOLD / 1000 }}
          $color={'grayscale.white'}
        />
      </TooltipContent>
    </Tooltip>
  )
}

export default DeviceDistanceFromPlot
