import { usePlanFeat } from '@weenat/client/dist/core/billing'
import { PlanFeatName } from '@weenat/client/dist/resources/billing'
import { Org } from '@weenat/client/dist/resources/orgs'
import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import { useNavigate } from 'app/routx-router'
import DevicesMapCTAIllustration from 'app/src/discover-menu/svg/DevicesMapCTAIllustration'
import UpgradeToPremiumCTA from '../administration/billing/UpgradeToPremiumCTA'
import CTAFullScreen from './CTAFullScreen'

export interface FeatGuardedProps {
  org?: Org
  feat: PlanFeatName
  fallback?: React.ReactElement | null
  mapFallback?: boolean
}

export const MapOverlayFallback = () => {
  const nav = useNavigate()
  const { t } = useIntl()
  const { id } = useOrgContext()

  return (
    <CTAFullScreen
      isOpen
      onClose={() => {
        nav(`/farms/${id}`)
      }}
      title={t('titles.devices_map', { capitalize: true })}
      description={t('discover_menu.calls_to_action.devices_map_description', { capitalize: true })}
      Illustration={<DevicesMapCTAIllustration />}
      mainCTA={{
        label: t('discover_menu.calls_to_action.discover_all_sensors', { capitalize: true }),
        to: '/discover/sensors'
      }}
      secondaryCTA={{
        label: t('discover_menu.calls_to_action.discover_closest_networks', { capitalize: true }),
        to: '/networks'
      }}
    />
  )
}

const FeatGuarded: FC<FeatGuardedProps> = ({
  feat,
  org,
  children,
  mapFallback,
  fallback = mapFallback ? (
    <MapOverlayFallback />
  ) : (
    <Flex $flex={1} $alignItems='center' $justifyContent='center'>
      <UpgradeToPremiumCTA />
    </Flex>
  )
}) => {
  const hasFeat = usePlanFeat(feat, org?.billingStatus)

  return !org || !hasFeat ? null : hasFeat.active ? <>{children}</> : fallback
}
export default FeatGuarded
