import { schemas, useClient, useMutation, useQuery } from '@weenat/client'
import ErrCode from '@weenat/client/dist/ErrCode'
import { Device } from '@weenat/client/dist/resources/devices'
import { useIntl } from '@weenat/wintl'
import { useNavigate, useParams } from 'app/routx-router'
import useToasts from 'app/src/hooks/useToasts'
import Button from 'app/src/kit/Button'
import Card from 'app/src/kit/Card'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'
import Icons from 'app/src/kit/Icons'
import Modal from 'app/src/kit/Modal'
import SubmitButton from 'app/src/kit/SubmitButton'
import SuperForm from 'app/src/kit/SuperForm'
import Text from 'app/src/kit/Text'
import ToastActions from 'app/src/kit/ToastActions'
import TextField from 'app/src/kit/fields/TextField'
import { TogglePrimitive } from 'app/src/kit/fields/ToggleField'
import LoadingCircle from 'app/src/kit/loaders/LoadingCircle'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useMemo, useState } from 'react'
import { SelectOrgFields } from '../../DashboardMap/SelectOrg'

interface AddStationFromDeviceModalProps {
  close: () => void
  isOpen: boolean
  device: Device
}

const schema = schemas.createStationFromSensor
type SchemaValues = typeof schema.initialValues

const AddStationFromDeviceModal: FC<AddStationFromDeviceModalProps> = ({
  close,
  isOpen,
  device
}) => {
  const { t } = useIntl()
  const { addErrorToast, addSuccessToast } = useToasts()
  const client = useClient()
  const nav = useNavigate()
  const { farmId } = useParams()

  const initialGeocoding = !isNil(device.reverseGeocoding) ? device.reverseGeocoding : undefined

  const [stationGeoCoding, setStationGeocoding] = useState<string | undefined>(initialGeocoding)
  const [isDynamicName, setIsDynamicName] = useState<boolean>(true)

  const longitude = device.location?.coordinates[0]
  const latitude = device.location?.coordinates[1]
  const isLatLngNotNil = !isNil(latitude) && !isNil(longitude)

  const redirectToStation = (stationId: number) =>
    nav(`/farms/${farmId}/stations/${stationId}` as const)

  const reverseGeocodingRequest = useQuery(
    client.reverseGeoCoding.get({ latitude: latitude as number, longitude: longitude as number }),
    {
      enabled: isLatLngNotNil && isNil(stationGeoCoding)
    }
  )

  if (!isNil(reverseGeocodingRequest.data) && isNil(stationGeoCoding)) {
    setStationGeocoding(reverseGeocodingRequest.data.locationAsText)
  }

  const [createStation, createStationRequest] = useMutation(client.stations.create(), {
    onSuccess: (result) => {
      const stationId = result.id
      addSuccessToast(
        <>
          <Box $p='lg' $pt='md'>
            <span>{t('models.addPlotFromSensor.modalSuccess')}</span>
          </Box>
          <ToastActions
            actions={[
              {
                id: 'add_to_farm',
                label: t('models.station.actions.view_station', { capitalize: true }),
                onPress: () => redirectToStation(stationId)
              }
            ]}
          />
        </>
      )
      close()
    },
    onError: (error) => {
      const genericErrorMessage = t('models.addPlotFromSensor.modalGenericError', {
        capitalize: true
      })

      const errors = error.nonFieldErrors?.map((err) => {
        return err.code === ErrCode.stationAlreadyExistInOrg ||
          err.code === ErrCode.notAllowedToCreateStation
          ? t(`error_codes.${err.code}`, { capitalize: true })
          : genericErrorMessage
      })

      if (!isNil(errors) && !isEmpty(errors)) {
        addErrorToast(errors.join('\n'))
      } else {
        addErrorToast(t('models.addPlotFromSensor.modalGenericError'))
      }
    }
  })

  const initialValues = useMemo(
    () => ({
      deviceId: device.id,
      name: stationGeoCoding,
      organizationId: farmId
    }),
    [device, stationGeoCoding, farmId]
  )

  const handleSubmit = (values: SchemaValues) => {
    createStation({
      organizationId: values.childOrganizationId ?? values.organizationId,
      name: isDynamicName ? undefined : values.name,
      deviceId: values.deviceId
    })
  }

  return (
    <Modal
      title={t('models.device.actions.add_to_my_farm', { capitalize: true })}
      width={600}
      isOpen={isOpen}
      close={() => close()}
    >
      {reverseGeocodingRequest.isLoading ? (
        <LoadingCircle />
      ) : (
        <SuperForm schema={schema} initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, submitForm }) => {
            return (
              <>
                <Box>
                  <SelectOrgFields
                    orgFieldName='organizationId'
                    selectedOrgs={{
                      organizationId: values.organizationId ?? null,
                      childOrganizationId: values.childOrganizationId ?? null
                    }}
                  />
                </Box>
                <DelimitedFlex $mb='lg' />
                <Text>{t('addStationModal.dynamicName.title')}</Text>
                <Card $my='lg' $flexDirection='row' $alignItems='center'>
                  <Icons.Move $size='lg' $p='md' />
                  <Box $pl='sm'>
                    <Text $fontWeight='bold'>{t('addStationModal.dynamicName.label')}</Text>
                    <Text>{t('addStationModal.dynamicName.text')}</Text>
                  </Box>
                  <TogglePrimitive
                    value={isDynamicName}
                    onToggle={() => setIsDynamicName(!isDynamicName)}
                  />
                </Card>
                <Box>
                  <TextField
                    name='name'
                    label={t('models.plot.fields.name.label', { capitalize: true })}
                    $isDisabled={createStationRequest.isPending || isDynamicName}
                  />
                </Box>
                <DelimitedFlex $mb='lg' />
                <Flex $gap='md' $alignItems='center' $justifyContent='flex-end'>
                  <Button importance='sd' onPress={() => close()}>
                    {t('actions.cancel')}
                  </Button>
                  <SubmitButton onPress={submitForm} state={createStationRequest} />
                </Flex>
              </>
            )
          }}
        </SuperForm>
      )}
    </Modal>
  )
}

export default AddStationFromDeviceModal
