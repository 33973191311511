import { useSlotProvider } from 'app/state'
import { styled } from 'styled-components'

const ActionsContainer = styled(Flex)`
  flex-direction: row;
  justify-content: flex-end;
`

/**
 * Will not add anything to header actions, simply create a space to land header actions
 */
const HeaderActionsArea = () => {
  const [{ headerActions }] = useSlotProvider()

  return <ActionsContainer>{headerActions}</ActionsContainer>
}

export default HeaderActionsArea
