import { fromColorPathToColor, WeenatColor } from '@weenat/theme'
import { useToggleFeature } from 'app/state'
import styled, { css } from 'styled-components'
import { FlexProps } from './primitives/themeMappings/props'

const Container = styled(Flex)<{
  $isFocused: boolean
  $redesigned: boolean
  $focusColor: WeenatColor
  $focusBorderColor: WeenatColor
}>`
  position: relative;

  align-items: center;
  justify-content: center;

  overflow: hidden;

  gap: ${(p) => p.theme.spacings.md}px;

  background-color: ${({ $isFocused, $focusColor }) =>
    $isFocused ? fromColorPathToColor($focusColor) : 'transparent'};

  ${(p) =>
    p.$redesigned
      ? css`
          border-radius: ${p.theme.radiuses.md}px;
          border: 2px solid;
          border-color: ${p.$isFocused
            ? fromColorPathToColor(p.$focusBorderColor)
            : p.theme.colors.grayscale[100]};
        `
      : css`
          border-top-left-radius: ${p.theme.radiuses.md}px;
          border-top-right-radius: ${p.theme.radiuses.md}px;
        `}

  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ $isFocused, $focusBorderColor }) =>
    $isFocused ? fromColorPathToColor($focusBorderColor) : 'inherit'};
  fill: ${({ $isFocused, $focusBorderColor }) =>
    $isFocused ? fromColorPathToColor($focusBorderColor) : 'inherit'};

  &:hover {
    cursor: pointer;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    opacity: 0.1;
    background-color: 'transparent';
    inset: 0px;
  }

  &:hover::after {
    background-color: ${(p) => p.theme.colors.grayscale.black};
  }

  transition:
    background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out;

  will-change: background-color, border-color;
`

const ActivityIndicator = styled.span<{ $isFocused: boolean }>`
  position: absolute;

  bottom: 0px;
  left: 0px;

  height: 2px;

  width: 100%;

  background-color: ${({ theme, $isFocused }) =>
    $isFocused ? theme.colors.primary[500] : theme.colors.grayscale[300]};

  transition: background-color 0.3s ease-out;
  will-change: background-color;
`

interface TabProps {
  isFocused: boolean
  p?: FlexProps['$p']
  onPress?: FlexProps['onClick']
  flex?: FlexProps['$flex']
  focusColor?: WeenatColor
  focusBorderColor?: WeenatColor
}

const Tab: FC<TabProps> = ({
  children,
  isFocused,
  flex,
  p = 'lg',
  onPress,
  focusColor = 'primary.200',
  focusBorderColor = 'primary.500'
}) => {
  const [{ redesign }] = useToggleFeature()

  return (
    <Container
      $isFocused={isFocused}
      onClick={onPress}
      $flex={flex}
      $p={p}
      $redesigned={redesign}
      $focusColor={focusColor}
      $focusBorderColor={focusBorderColor}
    >
      {children}
      {redesign ? null : <ActivityIndicator $isFocused={isFocused} />}
    </Container>
  )
}

export default Tab
